import React from "react";
import AddMatrialCatUnits from "./AddMatrialCatUnits";
import { toast } from "react-toastify";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "../../../Assets/Img/svg/Delete.svg";
import Edit from "../../../Assets/Img/svg/Edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { CgArrowRightR } from "react-icons/cg";
import {
  selectmaterialuompagination,
  setmaterialuompagination,
} from "../../../features/pagination/paginationSlice";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import PaginationComponent from "../../../ReusableComponent/PaginationComponent";

const MatrialCatalogTab = () => {
  const [opendel, setOpendel] = useState(false);
  const handleOpendel = () => setOpendel(true);
  const handleClosedel = () => setOpendel(false);
  const [MatrialCatalo, setMatrialCatalo] = useState([]);
  const dispatch = useDispatch();
  const currentPage = useSelector(selectmaterialuompagination);
  // pagination recieve
  const [perPage, setPerPge] = useState(10);
  const [totlePage, setTotalPage] = useState("");
  const [uomValue, setUomValue] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [toggleEdit, settoggleEdit] = useState(false);
  const [editUnitOfMeasure, seteditUnitOfMeasure] = useState(uomValue?.uom);
  const [editSpecificationName, setSpecificationName] = useState(
    uomValue?.specification
  );

  const getMatrialCataloguom = () => {
    axiosClientInterceptors
      .get(`/get-all-uom?page=${currentPage}&limit=${perPage}&search=`)
      .then((response) => {
        setMatrialCatalo(response.data.result.listOfUOM);
        dispatch(setmaterialuompagination(response.data.result.currentPage));
        setTotalPage(response.data.result.totalUOM);
      })
      .catch((error) =>
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
  };

  function printChecked(isChecked, value) {
    // const newProjectStages = projectstage.filter((p) => p !== value);

    // if (isChecked) {
    //   newProjectStages.push(value);
    // }

    // Setprojectstage(newProjectStages);
    var items = document.getElementsByName("tr");
    var selectedItems = [];
    for (var i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox" && items[i].checked == true)
        selectedItems.push(items[i].value);
    }
  }

  const edituom = () => {
    let data = {
      specification: editSpecificationName,
      uom: editUnitOfMeasure,
    };

    axiosClientInterceptors
      .post(`/update-uom?_id=${uomValue._id}`, data)
      .then((response) => {
        getMatrialCataloguom();
        if (response.data.status === "success") {
          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) =>
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
  };

  const deleteuom = () => {
    axiosClientInterceptors
      .delete(`/delete-uom?_id=${uomValue._id}`)
      .then((response) => {
        getMatrialCataloguom();
        if (response.data.status === "success") {
          handleClosedel();
          handleClose();
          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) =>
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
  };

  useEffect(() => {
    getMatrialCataloguom();
  }, []);

  useEffect(() => {
    getMatrialCataloguom();
  }, [currentPage, perPage]);

  const handleChangePage = (e, pageSize) => {
    dispatch(setmaterialuompagination(e));
    setPerPge(pageSize);
  };

  return (
    <div className="MatrialCatalogTab_con p-3">
      <AddMatrialCatUnits getMatrialCataloguom={getMatrialCataloguom} />
      <div className="config_material_specs">
        <div className="inner_table_div" style={{}}>
          <table className="table leaderboard_table">
            <tbody>
              <tr class="headers_tag">
                <th className="text-center">Sl. No.</th>
                <th className="text-center">Specification Name</th>
                <th className="text-center">Unit Of Measure</th>
                <th className="text-center">Action</th>
              </tr>
              {MatrialCatalo?.map((val, i) => (
                <tr
                  className="vals"
                  key={i}
                  onClick={() => {
                    setUomValue(val);
                    handleOpen();
                  }}
                >
                  <td className="text-center">
                    {(currentPage - 1) * perPage + i + 1}
                  </td>
                  <td className="text-center">{val.specification}</td>
                  <td className="text-center">{val.uom}</td>
                  <td className="text-center">
                    <CgArrowRightR
                      className="partner_back_btn"
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Modal
          open={open}
          style={{ background: "rgba(34, 30, 31, 0.9)" }}
          className="d-flex justify-content-center align-items-center flex-column "
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="filter_container ">
            <div className="m-3">
              <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 Filter_text">Edit Specification </p>
                <CloseIcon
                  onClick={() => {
                    //   getinvoice();
                    settoggleEdit(false);
                    handleClose();
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="row">
                <div className="col-md-12 mt-4">
                  <div className="w-100 ">
                    <p className=" Package_title">Specification Name</p>
                    {!toggleEdit ? (
                      <div className="w-100  InvoiceNumber_input">
                        {uomValue?.specification}
                      </div>
                    ) : (
                      <input
                        type="text"
                        defaultValue={uomValue?.specification}
                        onChange={(e) => setSpecificationName(e.target.value)}
                        name="invoice_number"
                        className="w-100  InvoiceNumber_input"
                        placeholder="eg: Mtr. , Mm. , Inches"
                      />
                    )}{" "}
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="w-100">
                    <p className=" Package_title">Possible Unit Of Measure</p>
                    {!toggleEdit ? (
                      <div className="w-100  InvoiceNumber_input">
                        {uomValue?.uom}
                      </div>
                    ) : (
                      <input
                        type="text"
                        defaultValue={uomValue?.uom}
                        onChange={(e) => seteditUnitOfMeasure(e.target.value)}
                        name="invoice_number"
                        className="w-100  InvoiceNumber_input"
                        placeholder="eg: Mtr. , Mm. , Inches"
                      />
                    )}{" "}
                  </div>
                  <p
                    className="mb-0 mt-2"
                    style={{ color: "#909090", fontFamily: "Gm" }}
                  >
                    Note : possible unit of measure should be separated by “,”
                  </p>
                </div>
              </div>

              {toggleEdit ? (
                <button
                  className="w-100 configEditDetails mt-3 "
                  disabled={
                    editSpecificationName === "" || editUnitOfMeasure === ""
                  }
                  onClick={() => {
                    settoggleEdit(false);
                    handleClose();
                    edituom();
                  }}
                >
                  <p className="m-0">Save Changes</p>
                </button>
              ) : (
                <div
                  className="d-flex justify-content-between align-items-center mt-4"
                  style={{ gap: "20px" }}
                >
                  <div
                    className="w-50 configDelete"
                    onClick={() => {
                      handleClose();
                      handleOpendel();
                    }}
                  >
                    <img
                      src={Delete}
                      alt="..."
                      className="me-1"
                      style={{ width: "7%" }}
                    />
                    <p className="m-0">Delete</p>
                  </div>
                  <div
                    className="w-50 configEditDetails "
                    onClick={() => {
                      settoggleEdit(true);
                    }}
                  >
                    <img
                      src={Edit}
                      alt="..."
                      style={{ width: "9%" }}
                      className="me-1"
                    />

                    <p className="m-0">Edit Details</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>

        {/* Are you sure wanna delete popup */}
        <Modal
          open={opendel}
          style={{ background: "rgba(34, 30, 31, 0.9)" }}
          className="d-flex justify-content-center align-items-center flex-column "
          onClose={handleClosedel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="filter_container_config p-3">
            <div>
              <div className="popup_icon_center">
                <InfoRoundedIcon className="popup_delete_proj_icon" />
              </div>
              <div className="popup_text_center">
                <span className="popup_addbankdetails_text">
                  <b> Are you sure want to delete this Unit of Measure ?</b>
                </span>
              </div>
              <div className="pt-3 d-flex justify-content-between">
                <div
                  style={{
                    width: "48%",
                    textAlign: "center",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-secondary popup_btn_close"
                    onClick={handleClosedel}
                  >
                    Go Back
                  </button>
                </div>
                <div
                  style={{
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  <button
                    type="button"
                    className="popup_btn_unlinkaccount"
                    onClick={() => {
                      deleteuom();
                    }}
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="w-100 d-flex justify-content-end align-items-center">
        <div className="flotpagination">
          <PaginationComponent
            handleChangePage={handleChangePage}
            perPage={perPage}
            totlePage={totlePage}
            currentpage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default MatrialCatalogTab;
