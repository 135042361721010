import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
// import { fileNameSplit } from "../../../ReusableComponent/Validation";
const ImageCard = ({ path, index, RemoveFile, deletfile }) => {
  return (
    <div
      className="w-100 d-flex align-items-center justify-content-between"
      style={{
        backgroundColor: "#F6F6F6",
        padding: "15px",
        borderRadius: "2px",
      }}
    >
      <div className="d-flex align-items-center">
        <PhotoSizeSelectActualIcon className="me-1" />
        <p className="path">{path}</p>
      </div>
      <CloseIcon onClick={() => RemoveFile(index)} />
    </div>
  );
};

export default ImageCard;
