import React, { useState, useEffect } from "react";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import editpen from "../../../Assets/Img/editpen.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import {
  validateempid,
  validatemob,
  validateuserid,
  validateUserID,
  validatepass,
  validateusertype,
  validateUserName,
  validateUserNameemail,
  validateUserNameemailedit,
} from "../../../ReusableComponent/Validation";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import SelectField from "../MaterialCatalog/SelectField";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import CircleIcon from "@mui/icons-material/Circle";

const ManageUserDetails = ({ singleUser, Id, getusers }) => {
  const [editUserData, seteditUserData] = useState(false);
  const [editemail, seteditemail] = useState(false);
  const [fullName, setFullName] = useState("");
  const [empId, setEmpId] = useState("");
  const [userId, setUserId] = useState("");
  const [userPass, setUserPass] = useState("");
  const [role, setRole] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [userGrp, setUserGrp] = useState([]);
  const [dateofJoining, setDateofJoining] = useState("");
  const [dateofLeaving, setdateofLeaving] = useState("");
  const [projectNames, setProjectNames] = useState("");
  const [editedvalue, seteditedvalue] = useState("");

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const getUsergroups = () => {
    axiosClientInterceptors
      .get(`/get-all-user-group`)
      .then((response) => {
        setUserGrp(response.data.result);
      })
      .catch((error) => console.log("Error", error));
  };

  const getUserDetails = () => {
    const headers = {
      "Content-Type": "application/json",
    };
    axiosClientInterceptors
      .get(`/getuserbyid?_id=${Id}`, {
        headers,
      })
      .then((resulta) => {
        seteditedvalue(resulta.data.User.user_name?.split("@")[0]);
        setFullName(resulta.data.User.name);
        setEmpId(resulta.data.User.emp_ID);
        setUserId(resulta.data.User.user_name);
        setUserPass(resulta.data.User.password);
        setRole(resulta.data.User.user_type);
        setUserMobile(resulta.data.User.phone);
        setUserStatus(resulta.data.User.user_status);
        setDateofJoining(resulta.data.User.creationdate);
        setdateofLeaving(resulta.data.User.date_Of_leaving);
      });
  };

  const activateuserPopUp = () => {
    window.$("#activateuser").modal("show");
  };

  const deactivateuserPopUp = () => {
    window.$("#deactivateuser").modal("show");
  };

  const removeUser = () => {
    window.$("#deleteUser").modal("show");
  };

  const activateUsers = () => {
    axiosClientInterceptors.post(`/activateuser?_id=${Id}`).then((res) => {
      let Status = res.data.status;
      if (Status === "success") {
        window.$("#activateuser").modal("hide");
        getUserDetails();

        toast.success("User Activated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        window.$("#activateuser").modal("hide");
        toast.error("Internal Error try after some time", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const deactivateUsers = () => {
    axiosClientInterceptors.post(`/deactivateuser?_id=${Id}`).then((res) => {
      let Status = res.data.status;
      if (Status === "success") {
        setProjectNames(res.data.projects_name);
        getUserDetails();
        let statusofassign = res.data.assigned_project;
        if (statusofassign === true) {
          window.$("#projectassigned").modal("show");
          window.$("#deactivateuser").modal("hide");
        } else {
          window.$("#deactivateuser").modal("hide");
        }
      } else {
        window.$("#deactivateuser").modal("hide");
        toast.error("Internal Error try after some time", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const deleteuserdetails = () => {
    axiosClientInterceptors
      .delete(`/deleteuser?_id=${Id}`)
      .then((resultuserdeleted) => {
        let Status = resultuserdeleted.data.status;
        if (Status === "success") {
          window.$("#deleteUser").modal("hide");
          toast.success("User Deleted Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.setTimeout(function () {
            singleUser("alluser");
            getusers();
          }, 2000);
        } else {
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  useEffect(() => {
    getUserDetails();
    getUsergroups();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <p className="m-0">
              <span
                className="fontdocument_dim"
                onClick={() => {
                  getusers();
                  singleUser("alluser");
                }}
              >
                Manage users{" "}
              </span>
              / <span className="fontdocument_active">User Details</span>
            </p>
          </div>
        </div>
        <div className="dropdown action">
          <button
            className="btn dropdown-toggle dropdown_action"
            type="button"
            id="dropdownMenu2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Action
          </button>
          {userStatus === "" ? (
            ""
          ) : userStatus === "active" ? (
            <div
              className="dropdown-menu w-100"
              aria-labelledby="dropdownMenu2"
            >
              <button className="dropdown-item" onClick={deactivateuserPopUp}>
                Deactivate User
              </button>
            </div>
          ) : (
            <div
              className="dropdown-menu w-100"
              aria-labelledby="dropdownMenu2"
            >
              <button className="dropdown-item" onClick={activateuserPopUp}>
                Activate User
              </button>
              <div className="dropdown-divider"></div>
              <button className="dropdown-item" onClick={removeUser}>
                Remove User
              </button>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 col-md-8">
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: fullName,
              emp_ID: empId,
              phone: userMobile?.split("+91")[1],
              user_type: role,
            }}
            onSubmit={(values) => {
              const headers = {
                "Content-Type": "application/json",
              };

              const data = {
                name: values.name,
                emp_ID: values.emp_ID.toUpperCase(),
                user_type: values.user_type,
                phone: values.phone,
              };

              axiosClientInterceptors
                .post(`/updateuser?_id=${Id}`, data, {
                  headers,
                })
                .then((resultfse) => {
                  let Status = resultfse.data.status;
                  if (Status === "success") {
                    toast.success("Profile Details are Updated Sucessfully!!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    seteditUserData(false);
                    getUserDetails();
                  } else if (Status === "failed") {
                    // seteditUserData(false);
                    toast.error(resultfse.data.msg, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                })
                .catch((err) => {
                  alert("Some Internal Error", err);
                });
            }}
          >
            {({ errors, touched, handleSubmit, values }) => (
              <Form>
                <div className="p-3 details_area">
                  <div className="d-flex justify-content-between head_txt head_txt_margin">
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <p>Personal Info</p>
                      <div>
                        {userStatus === "active" ? (
                          <button
                            className="user_active"
                            style={{ padding: "5px 12px" }}
                          >
                            Active
                          </button>
                        ) : userStatus === "inactive" ? (
                          <button className="user_inactive">Inactive</button>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                    <div
                      className="savebtnedit"
                      onClick={() => seteditUserData(!editUserData)}
                    >
                      {editUserData ? (
                        <button
                          type="submit"
                          className="savebtnedit"
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                      ) : (
                        <img src={editpen} alt="..." />
                      )}
                    </div>
                  </div>

                  {editUserData ? (
                    <div className="row">
                      <div className="col-3 col-md-3">
                        <p className="m-0 cardtitle">Full Name</p>
                        <Field
                          type="text"
                          className="editinputuser"
                          id="name"
                          name="name"
                          value={values.name}
                          validate={validateUserName}
                        />
                        {errors.name && touched.name && (
                          <p className="error">{errors.name}</p>
                        )}
                      </div>
                      <div className="col-3 col-md-3">
                        <p className="m-0 cardtitle">Employee ID</p>
                        <Field
                          type="text"
                          className="editinputuser"
                          id="emp_ID"
                          name="emp_ID"
                          value={values.emp_ID}
                          validate={validateempid}
                        />
                        {errors.emp_ID && touched.emp_ID && (
                          <p className="error">{errors.emp_ID}</p>
                        )}
                      </div>
                      <div className="col-3 col-md-3">
                        <p className="m-0 cardtitle">User Type</p>
                        <SelectField
                          style={{ border: "none" }}
                          className="editinputuser"
                          name="user_type"
                          validate={validateusertype}
                        >
                          <option value="" selected disabled>
                            Choose user type
                          </option>
                          {userGrp?.map((el, index) => {
                            return (
                              <>
                                <option value={el.user_group}>
                                  {el.user_group}
                                </option>
                              </>
                            );
                          })}
                        </SelectField>
                      </div>
                      <div className="col-md-3">
                        <p className="m-0 cardtitle">Mobile Number</p>
                        <div className="numberedit">
                          <span className="plusnineone">+91</span>
                          <Field
                            type="tel"
                            className="editinputuser"
                            id="phone"
                            name="phone"
                            value={values.phone}
                            validate={validatemob}
                            maxLength={10}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                        {errors.phone && touched.phone && (
                          <p className="error">{errors.phone}</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-3 col-md-3">
                        <p className="m-0 cardtitle">Full Name</p>
                        <span className="cardsubtitle">
                          {fullName === "" ? "-" : fullName}
                        </span>
                      </div>
                      <div className="col-3 col-md-3">
                        <p className="m-0 cardtitle">Employee Id</p>
                        <span className="cardsubtitle">
                          {empId === "" ? "-" : empId}
                        </span>
                      </div>
                      <div className="col-3 col-md-3">
                        <p className="m-0 cardtitle">User Type</p>
                        <span className="cardsubtitle">
                          {role === "" ? "-" : role}
                        </span>
                      </div>
                      <div className="col-3 col-md-3">
                        <p className="m-0 cardtitle">Mobile Number</p>
                        <span className="cardsubtitle">{userMobile}</span>
                      </div>
                      <div className="col-3 col-md-3 mt-3">
                        <p className="m-0 cardtitle">Date Of Joining</p>
                        <span className="cardsubtitle">
                          {dateofJoining
                            ? moment(dateofJoining).format("DD/MM/YYYY")
                            : "-"}
                        </span>
                      </div>
                      {userStatus === "inactive" ? (
                        <div className="col-3 col-md-3 mt-3">
                          <p className="m-0 cardtitle">Date Of leaving</p>
                          <span className="cardsubtitle">
                            {dateofLeaving
                              ? moment(dateofLeaving).format("DD/MM/YYYY")
                              : "-"}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-4">
          <Formik
            enableReinitialize={true}
            initialValues={{
              user_name: editedvalue,
              password: userPass,
            }}
            onSubmit={(values) => {
              const headers = {
                "Content-Type": "application/json",
              };

              const data = {
                user_name: `${values.user_name}@cendrol.com`,
                password: values.password,
              };

              axiosClientInterceptors
                .post(`/updateuser?_id=${Id}`, data, {
                  headers,
                })
                .then((resultfse) => {
                  let Status = resultfse.data.status;
                  if (Status === "success") {
                    toast.success("Credentials are Updated Sucessfully!!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    seteditemail(false);
                    getUserDetails();
                  } else if (Status === "failed") {
                    seteditemail(false);
                    toast.error("Internal Error try after some time!!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                })
                .catch((err) => {
                  alert("Some Internal Error", err);
                });
            }}
          >
            {({ errors, touched, handleSubmit, values }) => (
              <Form>
                <div className="p-3 details_area">
                  <div className="d-flex justify-content-between head_txt head_txt_margin">
                    <p>Credentials</p>
                    <div
                      className="savebtnedit"
                      onClick={() => seteditemail(!editemail)}
                    >
                      {editemail ? (
                        <button
                          type="submit"
                          className="savebtnedit"
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                      ) : (
                        <img src={editpen} alt="..." />
                      )}
                    </div>
                  </div>

                  {editemail ? (
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <p className="m-0 cardtitle">User ID</p>
                        <div className="d-flex align-items-center ">
                          <Field
                            type="email"
                            className="editinputuser"
                            id="user_name"
                            name="user_name"
                            cendrol={true}
                            value={values.user_name}
                            validate={validateUserID}
                          />{" "}
                          <p className="cendrolcom mb-0">@cendrol.com</p>
                        </div>

                        {errors.user_name && touched.user_name && (
                          <p className="error">{errors.user_name}</p>
                        )}
                      </div>
                      <div className="col-12 col-md-12 mt-3">
                        <p className="m-0 cardtitle">Password</p>
                        <div
                          className="d-flex align-items-center"
                          data-tip
                          data-for="registerTip"
                          data-toggle="tooltip"
                          data-placement="top"
                          style={{
                            lineHeight: "0rem",
                            border: "none",
                            borderRadius: "0px",
                            background: "#FFFFFF",
                          }}
                        >
                          <Field
                            className="editinputuser"
                            id="password"
                            name="password"
                            type={passwordShown ? "text" : "password"}
                            value={values.password}
                            validate={validatepass}
                          />

                          {passwordShown ? (
                            <VisibilityIcon
                              style={{ color: "#FCC200", fontSize: "21px" }}
                              onClick={togglePassword}
                            />
                          ) : (
                            <VisibilityOffIcon
                              style={{ color: "#FCC200", fontSize: "21px" }}
                              onClick={togglePassword}
                            />
                          )}
                        </div>
                        {errors.password && touched.password && (
                          <p className="error">{errors.password}</p>
                        )}
                        <ReactTooltip
                          className="tooltip   shadow"
                          backgroundColor="white"
                          textColor="black"
                          id="registerTip"
                          place="bottom"
                          effect="solid"
                        >
                          <p className="notepass">
                            Password should meet following Requirements
                          </p>

                          <div className="mt-2 d-flex   justify-content-start align-items-center">
                            <CircleIcon
                              className="me-1"
                              style={{ width: "15px" }}
                            />

                            {/* <div className="listiconroundcurrect me-2">
                                      <CheckIcon style={{ width: "15px" }} />
                                    </div> */}

                            <p className="characters">At least 8 characters</p>
                          </div>

                          <div className="mt-1 d-flex justify-content-start align-items-center ">
                            <CircleIcon
                              className="me-1"
                              style={{ width: "15px" }}
                            />

                            <p className="characters">
                              A mixture of both uppercase and lowercase letters.
                            </p>
                          </div>

                          <div className="mt-1 d-flex justify-content-start align-items-center">
                            <CircleIcon
                              className="me-1"
                              style={{ width: "15px" }}
                            />

                            <p className="characters">
                              A mixture of letters and numbers.
                            </p>
                          </div>

                          <div className="mt-1 d-flex justify-content-start align-items-center">
                            <CircleIcon
                              className="me-1"
                              style={{ width: "15px" }}
                            />

                            <p className="characters">
                              Inclusion of at least one special character, e.g.,
                              ! @ # ?
                            </p>
                          </div>
                        </ReactTooltip>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <p className="m-0 cardtitle">User ID</p>
                        <span
                          className="cardsubtitle"
                          style={{ cursor: "pointer" }}
                          data-tip
                          data-for="registerTip"
                          data-toggle="tooltip"
                          data-placement="top"
                        >
                          {userId}
                        </span>
                      </div>
                      <div className="col-12 col-md-12 mt-3">
                        <p className="m-0 cardtitle">Password</p>
                        <span className="cardsubtitle">
                          {userPass !== "" ? "****" : "NA"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {/* Activate User */}
      <div
        id="activateuser"
        className="modal fade partner_details_delete"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ border: "none" }}
          >
            <div className="head_modal" style={{ background: "#3ADD5E" }}></div>
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <ErrorRoundedIcon className="popup_linkbankdetails_icon" />
                </div>
                <div className="pt-2 popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Activate this User ?
                  </span>
                </div>
                <div className="pt-3 d-flex" style={{ gap: "10px" }}>
                  <button
                    type="button"
                    className="popup_btn_linkaccount"
                    onClick={activateUsers}
                  >
                    Activate
                  </button>

                  <button className="popup_btn_close" data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Deactivate User */}
      <div
        id="deactivateuser"
        className="modal fade partner_details_delete"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ border: "none" }}
          >
            <div className="head_modal"></div>
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <ErrorRoundedIcon className="popup_unlinkbankdetails_icon" />
                </div>
                <div className="pt-2 popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Deactivate this User ?
                  </span>
                </div>
                <div className="pt-3 d-flex" style={{ gap: "10px" }}>
                  <button
                    type="button"
                    className="popup_btn_unlinkaccount"
                    onClick={deactivateUsers}
                  >
                    Deactivate
                  </button>

                  <button className="popup_btn_close" data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Deactivate User */}
      <div
        id="projectassigned"
        className="modal fade partner_details_delete"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ border: "none" }}
          >
            <div className="project_assigned_head"></div>
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <ErrorRoundedIcon className="popup_unlinkbankdetails_icon1" />
                </div>
                <div className="pt-2 popup_text_center">
                  <span className="popup_addbankdetails_text">
                    {fullName} is assigned to {projectNames}{" "}
                    {projectNames.split(",").length > 1
                      ? "Projects"
                      : "Project"}
                  </span>
                  <div className="mt-4 project_note">
                    <p>
                      Assign this project to another user to deactivate this
                      user
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation */}
      <div
        id="deleteUser"
        className="modal fade partner_details_delete"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ border: "none" }}
          >
            <div className="head_modal"></div>
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <ErrorRoundedIcon className="popup_unlinkbankdetails_icon" />
                </div>
                <div className="pt-2 popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Delete this User ?
                  </span>
                </div>
                <div className="pt-3 d-flex" style={{ gap: "10px" }}>
                  <button
                    type="button"
                    className="popup_btn_unlinkaccount"
                    onClick={deleteuserdetails}
                  >
                    Delete
                  </button>

                  <button className="popup_btn_close" data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUserDetails;
