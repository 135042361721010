import React from "react";
import { ErrorMessage, useField } from "formik";

export const TextField = ({ margin, label, cendrol, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={`${margin ? "mb-0 w-100" : "mb-2"}`}>
      <label htmlFor={field.name} className="pb-2 label_textfields">
        {label}
      </label>
      <div className={`${cendrol && "d-flex  align-items-center"}`}>
        <input
          className={`form-control bluck_input shadow-none  ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
          autoComplete="off"
        />
        {cendrol && <p className="cendrolcom mb-0">@cendrol.com</p>}
      </div>

      <ErrorMessage component="div" name={field.name} className="error pt-2" />
    </div>
  );
};
