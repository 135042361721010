import React from "react";
import PaginationComponent from "../../../ReusableComponent/PaginationComponent";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import Upload from "../../../Assets/Img/Upload.svg";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { useState } from "react";
import { toast } from "react-toastify";
import filtersImg from "../../../Assets/Img/svg/filter.svg";
import File_logo from "../../../Assets/Img/File_logo.svg";
import NoRecordFound from "../../../Assets/Img/NoRecordFound.svg";
import {
  selectvendorFilter,
  selectvendorpagination,
  setvendorFilter,
  setvendorpagination,
} from "../../../features/pagination/paginationSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAddVendorScreen,
  setAddVendorScreen,
} from "../../../features/Screen/screen";
import AddVendor from "./AddVendor";
import Modal from "@mui/material/Modal";
import Select from "react-select";
import Dropzone from "react-dropzone";
import VendorDetailsPage from "./VendorDetailsPage";
import { CircularProgress } from "@mui/material";
import { DateRangePicker } from "rsuite";
import { Ranges } from "../../../ReusableComponent/Validation";
import {
  selecvendorDateRange,
  setvendorDateRange,
} from "../../../features/DateRangeSlice/DateRangeSlice";
import { fileNameSplit } from "../../../ReusableComponent/Validation";
import ViewPO from "./ViewPO";
import PurchaseOrderDOc from "../PoAdmin/PurchaseOrderDOc";

const Vendor = ({ SocketCall, SocketCallData }) => {
  const { afterToday } = DateRangePicker;

  const screenvendor = useSelector(selectAddVendorScreen);
  const dateRangeData = useSelector(selecvendorDateRange);
  const [materialCatalog, setMaterialCatalog] = useState([]);
  const dispatch = useDispatch();
  const [addmatrial, setaddMatrial] = useState("");
  const filterDAta = useSelector(selectvendorFilter);
  const [selectedmatrial, setselectedmatrial] = useState(filterDAta);
  const currentpage = useSelector(selectvendorpagination);
  const [perPage, setPerPge] = useState(10);
  const [totalPage, setTotalPage] = useState("");
  const [searchTermVendor, setSearchTermVendor] = useState("");
  const [vendorDetails, setVendorDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [vendordetailsID, setvendordetailsID] = useState("");
  const [fileName, setfileName] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    setfileName();
    setOpen3(false);
  };

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [loader, setloader] = useState(false);

  const [bulkUploadMessage, setBulkUploadMessage] = useState("");
  const [duplicateData, setDuplicateData] = useState("");

  const [bulkFile, setBulkFile] = useState("");
  const handleChangeSelectfliter = (data) => {
    setselectedmatrial(data);
  };
  const onChange = (dates) => {
    dispatch(setvendorDateRange(dates));
  };

  // to get list of categories existing
  const getAllMaterialCategory = () => {
    axiosClientInterceptors.get(`/getcategories`).then((response) => {
      setMaterialCatalog(
        response.data.Category.map((val, index) => {
          return {
            ...val,
            ["value"]: val.category_name,
            ["label"]: val.category_name,
          };
        })
      );
    });
  };
  // all terms and condition
  const getAllterms = () => {
    axiosClientInterceptors.get(`/get-all-credit-terms`).then((response) => {});
  };

  // Function to add material category
  const addmatcategory = () => {
    axiosClientInterceptors
      .post(`/addcategory`, {
        category_name: addmatrial,
      })
      .then((response) => {
        toast.info(`${response.data.msg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllMaterialCategory();
        setaddMatrial("");
      });
  };
  const getvendorListiing = () => {
    dispatch(setvendorFilter(selectedmatrial));
    let activityFrom;
    let activityTo;
    let rangedatestart = dateRangeData[1]; //moment().format("YYYY-MM-DD");
    let rangedateend = dateRangeData[0]; //moment().format("YYYY-MM-DD");
    if (rangedatestart > rangedateend) {
      activityFrom = rangedateend;
      activityTo = rangedatestart;
    } else {
      activityFrom = rangedatestart;
      activityTo = rangedateend;
    }

    axiosClientInterceptors
      .post(
        `/getvendors?page=${currentpage}&limit=${perPage}&search=${searchTermVendor}`,
        {
          category:
            selectedmatrial.length > 0 &&
            selectedmatrial.map((val, index) => {
              return val._id;
            }),
          from_date: activityFrom,
          to_date: activityTo,
        }
      )
      .then((rso) => {
        dispatch(setvendorpagination(rso.data.result.currentPage));
        setTotalPage(rso.data.result.totalVendors);
        // Setdataofmaterialcategory(rso.data.Materials);
        setVendorDetails(rso.data.result.listOfVendors);
      });
  };
  const getvendorListiing2 = () => {
    dispatch(setvendorFilter([]));
    axiosClientInterceptors
      .post(`/getvendors?page=1&limit=${perPage}&search=`)
      .then((rso) => {
        dispatch(setvendorpagination(rso.data.result.currentPage));
        setTotalPage(rso.data.result.totalVendors);
        // Setdataofmaterialcategory(rso.data.Materials);
        setVendorDetails(rso.data.result.listOfVendors);
      });
  };
  const handleChangePage = (e, pageSize) => {
    dispatch(setvendorpagination(e));
    setPerPge(pageSize);
  };

  const handleImage = (acceptedFiles) => {
    setfileName(acceptedFiles[0]);
  };

  const submitVendorBulk = () => {
    if (fileName.length === 0) {
      alert("Please upload the file");
    } else {
      var formdata = new FormData();
      formdata.append("file", fileName);
      const headers = {
        "content-type": "multipart/form-data",
      };
      setloader(true);
      axiosClientInterceptors
        .post(`/upload-bulk-vendor-temp`, formdata, {
          headers,
        })
        .then((response) => {
          if (response.data.status === "success") {
            toast.success("Vendor data Uploaded Successfully!!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setBulkUploadMessage(response.data.msg);
            setDuplicateData(response.data.duplicateData);
            handleClose1();
            handleOpen2();
            setloader(false);
            getvendorListiing();
          } else {
            toast.error(response.data.msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleClose1();
            setfileName("");
            setloader(false);
          }
        })
        .catch((error) =>
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
    }
  };

  const downloadFileFormat = () => {
    alert("hi");
  };

  const getLeadBulk = () => {
    axiosClientInterceptors
      .get(`/get-cofig-document?document_type=Bulk Vendor`)
      .then((response) => {
        setBulkFile(response.data.result);
      });
  };
  const downloadFormat3 = () => {
    saveAs(
      `${process.env.REACT_APP_IMG_URL}/${bulkFile[0].document}`,
      `${fileNameSplit(bulkFile[0].document)}`
    );
  };

  useEffect(() => {
    getvendorListiing();
  }, [currentpage, perPage, selectedmatrial]);
  useEffect(() => {
    getAllMaterialCategory();
    getAllterms();
    getLeadBulk();
  }, []);

  return (
    <>
      <div className="">
        <Modal
          className="d-flex justify-content-center align-items-center flex-column"
          open={open}
          style={{ background: "rgba(34, 30, 31, 0.9)" }}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="filter_container" style={{ width: "40%" }}>
            <div className="m-3  " style={{ padding: "13px 0px 16px 0px" }}>
              <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 Filter_text ">Apply Filter</p>
                <CloseIcon onClick={handleClose} />
              </div>
              {/* body */}
              <div className="mt-4">
                <p className="Material__filter">Creation Date</p>
                <DateRangePicker
                  className="mt-2"
                  // style={{ border: "1px solid black" }}
                  placement={"bottom"}
                  style={{
                    fontFamily: "Gm",
                    border: "1px solid hsl(0, 0%, 70%)",
                    backgroundColor: "white !important",
                  }}
                  name="start"
                  placeholder="Select Date Range"
                  format="dd/MM/yyyy"
                  character="-"
                  onChange={onChange}
                  ranges={Ranges}
                  defaultValue={dateRangeData}
                  cleanable={true}
                  disabledDate={afterToday()}
                />
              </div>
              <div className="mt-4">
                <p className="Material__filter">Material Category</p>
                <Select
                  name="Material_Category"
                  options={materialCatalog}
                  defaultValue={filterDAta}
                  // name="Material_Category"
                  // value={multiselect}
                  onChange={handleChangeSelectfliter}
                  isMulti
                  className="selectMul"
                />
              </div>

              <div className="d-flex justify-content-between align-items-center mt-4">
                <div className="w-50 me-3 ResetAll">
                  <p
                    className="m-0"
                    onClick={() => {
                      dispatch(setvendorDateRange(""));
                      getvendorListiing2();
                      handleClose();
                      dispatch(setvendorFilter([]));
                      setselectedmatrial([]);
                    }}
                  >
                    Reset All
                  </p>
                </div>

                <button
                  type="submit"
                  className="m-0 w-50 Allfilter "
                  onClick={() => {
                    getvendorListiing();
                    handleClose();
                  }}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {screenvendor === "listing" && (
          <div className="p-4">
            <div className="">
              {/* Upload Bullk Vendor First Modal or Popup */}
              <Modal
                className="d-flex justify-content-center align-items-center flex-column"
                open={open1}
                style={{ background: "rgba(34, 30, 31, 0.9)" }}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="Vendor_BulkContainer_Box">
                  <div className="Vendor_ContentBox">
                    <h3 className="Vendor_Bulk_heading">Bulk Upload</h3>
                    <CloseIcon
                      onClick={handleClose1}
                      style={{
                        fontSize: "24px",
                        color: "#0E0E0E",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className="Vendor_bulk_paraBox">
                    <p className="Vendor_Bulk_Para">
                      <span className="Vendor_Bulk_Para_Span">Note:</span>{" "}
                      Conditions to use this Excel for Bulk upload of Vendor:
                    </p>
                    <p className="Vendor_Bulk_Para">
                      1. Vendors with Single Payment Term
                    </p>
                    <p className="Vendor_Bulk_Para">
                      2. Vendors with Bank Details
                    </p>
                    <p className="Vendor_Bulk_Para">
                      3. Material categories should have comma{" "}
                      <span className="V3vendor">separated values</span>
                    </p>
                    <p className="Vendor_Bulk_Para">
                      4. Cells with yellow color are mandatory
                    </p>
                  </div>
                  <div className="vendor_BultButtonBox">
                    <button
                      className="Vendor_BulkBtn Vendor_BulkCancel"
                      onClick={() => {
                        handleClose1();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="Vendor_BulkBtn Vendor_Bulkcontinue"
                      onClick={() => handleOpen3()}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </Modal>
              {/*   Upload Bullk Vendor Second Modal or Popup  */}
              <Modal
                className="d-flex justify-content-center align-items-center flex-column"
                open={open3}
                style={{ background: "rgba(34, 30, 31, 0.1)" }}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="filter_container" style={{ width: "40%" }}>
                  <div
                    className="m-3  "
                    style={{ padding: "13px 0px 16px 0px" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="m-0 Filter_text ">Upload Document</p>
                      <CloseIcon
                        onClick={handleClose1}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    {/* body */}
                    <Dropzone onDrop={handleImage}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className: "uplode_file_dot_border rounded w-100",
                          })}
                        >
                          <div className="mb-3">
                            <img
                              className="mx-auto"
                              src={File_logo}
                              alt="..."
                            />
                          </div>
                          <input
                            name="bulkdata"
                            multiple
                            {...getInputProps()}
                          />

                          <p className="uplode_file_text">
                            Upload your file here
                          </p>

                          <p className="Fileformat">
                            File format : Only .xlsx(Excel)
                          </p>

                          <p>{fileName?.name}</p>

                          <div className="uplode_file_btn_container ">
                            <button
                              className=" mx-auto uplode_file_btn px-4 py-2  rounded"
                              type="button"
                            >
                              Browse File
                            </button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <p className="accept_files">
                      Accepted File Format :{" "}
                      <span
                        className="Download_link text-dark"
                        onClick={() => downloadFormat3()}
                      >
                        <b>click here to download</b>
                      </span>
                    </p>
                    <button
                      // disabled={fileName?.length === ""}
                      className="w-100 me-3  Upload_Document "
                      onClick={() => {
                        submitVendorBulk();
                      }}
                    >
                      <p className="m-0">
                        {loader ? (
                          <CircularProgress color="inherit" size={30} />
                        ) : (
                          "Upload Vendors"
                        )}
                      </p>
                    </button>
                  </div>
                </div>
              </Modal>

              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="dashboardtitle m-0">
                    All Vendors <br />
                  </p>
                  <p className="m-0 usertitle">{totalPage} Vendors</p>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <div>
                    <button
                      className="upload_bulk_btn"
                      onClick={() => handleOpen1()}
                    >
                      <img src={Upload} className="ms-2" />
                      Upload Bulk Vendors
                    </button>
                  </div>
                  <div
                    className="select_category_btn"
                    onClick={() => {
                      dispatch(setAddVendorScreen("Addvendor"));
                    }}
                  >
                    <AddIcon style={{ width: "20px" }} /> Add Vendor
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex mt-3 justify-content-between w-100">
              <div className="" style={{ width: "100%", marginRight: "10px" }}>
                <div
                  className="d-flex search_mat_parent"
                  style={{ background: "#F9F9F9" }}
                >
                  <input
                    style={{ background: "#F9F9F9" }}
                    type="text"
                    value={searchTermVendor}
                    class="w-100 inputsearch_mat"
                    placeholder="Search by vendor name, material category"
                    onChange={(e) => setSearchTermVendor(e.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        getvendorListiing();
                      }
                    }}
                  />
                  {searchTermVendor.length > 0 && (
                    <CloseIcon
                      className="mt-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        getvendorListiing2();
                        setSearchTermVendor("");
                      }}
                    />
                  )}
                  <div
                    className="serchbuttonrequest"
                    onClick={getvendorListiing}
                  >
                    <SearchRoundedIcon fontSize="small" />
                    <span>Search</span>
                  </div>
                </div>
              </div>

              <div
                className="d-flex "
                style={{ justifyContent: "space-between" }}
              >
                <button
                  style={{ background: "#F9F9F9" }}
                  className="add_material"
                  // onClick={openVendor}
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <img src={filtersImg} alt="" className="me-1" /> Filter
                </button>
              </div>
            </div>
            {vendorDetails.length === 0 ? (
              <div className=" mt-3 NoRecordFound">
                <img src={NoRecordFound} />
              </div>
            ) : (
              <>
                <div className="admin_inner_table_div">
                  <div className="inner_table_div">
                    <table
                      // style={{ borderWidth: "0px" }}
                      className="table leaderboard_table"
                    >
                      {/* <thead>
                      <tr>
                        <th style={{ borderWidth: "0px" }}>Sl.No.</th>
                        <th style={{ borderWidth: "0px" }}>Vendor ID</th>
                        <th style={{ borderWidth: "0px" }}>Company Name</th>
                        <th style={{ borderWidth: "0px" }}>Vendor Name</th>
                        <th
                          style={{ borderWidth: "0px" }}
                          className="text-center"
                        >
                          Phone Number
                        </th>
                        <th style={{ borderWidth: "0px" }}>Status</th>
                        <th style={{ borderWidth: "0px" }}>Material Category</th>
                      </tr>
                    </thead> */}
                      <tbody>
                        <tr className="headers_tag">
                          <th
                            style={{ width: "115px" }}
                            className="text-center"
                          >
                            Sl.No.
                          </th>
                          <th
                            style={{ width: "115px" }}
                            className="text-center"
                          >
                            Vendor ID
                          </th>
                          <th className="text-center">Company Name</th>
                          <th className="text-center">Vendor Name</th>
                          <th className="text-center">Phone Number</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Material Category</th>
                        </tr>
                        {vendorDetails?.map((po, index) => {
                          return (
                            <tr
                              className="vals"
                              key={index}
                              onClick={() => {
                                setvendordetailsID(po._id);
                                dispatch(setAddVendorScreen("vendorDetails"));
                              }}
                            >
                              <td className="text-center">
                                {" "}
                                {(currentpage - 1) * perPage + index + 1}
                              </td>
                              <td className="text-center"> {po.vendor_id}</td>
                              <td className="text-center">{po.company_name}</td>
                              <td className="text-center">{po.vendor_name}</td>
                              <td className="text-center">
                                +91{po.contact_no}
                              </td>
                              <td className="text-center">
                                {po.status === "active" ? (
                                  <button className="user_active">
                                    Active
                                  </button>
                                ) : po.status === "inactive" ? (
                                  <button className="user_inactive">
                                    Inactive
                                  </button>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td className="text-center">
                                {po.category.join(",")}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-end align-items-center">
                  <div className="flotpagination">
                    <PaginationComponent
                      handleChangePage={handleChangePage}
                      perPage={perPage}
                      totlePage={totalPage}
                      currentpage={currentpage}
                    />
                  </div>
                </div>
              </>
            )}

            <Modal
              open={open2}
              style={{ background: "rgba(34, 30, 31, 0.9)" }}
              className="d-flex justify-content-center align-items-center flex-column"
              onClose={handleClose2}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="filter_container ">
                <div className="m-3 text-center">
                  <CheckCircleIcon className="tick_mark" />
                  <div className="text-center success_msg">
                    {bulkUploadMessage}
                    <br />
                    {duplicateData} Duplicate Data
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        )}

        {screenvendor === "Addvendor" && (
          <AddVendor
            materialCatalog={materialCatalog}
            getvendorListiing={getvendorListiing}
          />
        )}

        {screenvendor === "vendorDetails" && (
          <VendorDetailsPage
            getvendorListiing={getvendorListiing2}
            materialCatalog={materialCatalog}
            vendordetailsID={vendordetailsID}
          />
        )}

        {screenvendor === "viewPO" && (
          <ViewPO
            getvendorListiing={getvendorListiing2}
            materialCatalog={materialCatalog}
            vendordetailsID={vendordetailsID}
            // SocketCall={SocketCall}
            // SocketCallData={SocketCallData}
          />
        )}
      </div>
      <div className="p-1">
        {screenvendor === "IdvPurchaseOrderDOc" && (
          <PurchaseOrderDOc
            SocketCall={SocketCall}
            SocketCallData={SocketCallData}
          />
        )}
      </div>
    </>
  );
};

export default Vendor;
