import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useNavigate } from "react-router-dom";
import { setsidebaractivetab } from "../features/counter/counterSlice";
import { setscreen } from "../features/Screen/screen";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AutoLogout = ({ children }) => {
  const dispatch = useDispatch();
  let timer;
  let history = useNavigate();
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleTimer();
      });
    });
  }, []);
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 1800000);
  };

  const logoutAction = () => {
    localStorage.removeItem("Token");
    localStorage.clear();
    history("/");
    dispatch(setsidebaractivetab("Requests"));
    dispatch(setscreen("listing"));
  };
  return children;
};
export default AutoLogout;
