import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";

import { DateRangePicker } from "rsuite";
import DeleteIcon from "@mui/icons-material/Delete";
import ProjectDetails from "../../Admin/AllProject/ProjectDetails";
import CloseIcon from "@mui/icons-material/Close";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import PaginationComponent from "../../../ReusableComponent/PaginationComponent";
import $ from "jquery";
import filters from "../../../Assets/Img/svg/filter.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllprojectDaterange,
  selecAllprojectDaterange,
} from "../../../features/DateRangeSlice/DateRangeSlice";
import {
  selectallprojectStage,
  selectAllTheSiteEng,
  setrequestIdFRomProject,
} from "../../../features/counter/counterSlice";
import {
  selectcurrentpage,
  setcurentpage,
  Setfilterdata,
  selectfilterdata,
} from "../../../features/pagination/paginationSlice";
import AddProjectPopup from "./AddProjectPopup";
import Modal from "@mui/material/Modal";
import ProjectsDocument from "./ProjectsDocument";
import AllInvoice from "./AllInvoice";
import Requests from "../AdminRequests/Requests";
import Loader from "../../../ReusableComponent/Loader";
import NoRecordFound from "../../../Assets/Img/NoRecordFound.svg";
import Viewinvoice from "./Viewinvoice";
import { fuctureDate } from "../../../ReusableComponent/Validation";
import {
  selectProjectScreen,
  setProjectScreen,
} from "../../../features/Screen/screen";
import PurchageOrder from "../PoAdmin/PurchageOrder";
import PurchaseOrderDOc from "../PoAdmin/PurchaseOrderDOc";

export default function AllProjects() {
  const siteEngineer = useSelector(selectAllTheSiteEng);
  const filter = useSelector(selectfilterdata);
  const packages = useSelector(selectallprojectStage);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const currentpage = useSelector(selectcurrentpage);
  const projectScreen = useSelector(selectProjectScreen);
  const dispatch = useDispatch();
  let history = useNavigate();
  const [projectdata, Setprojectdata] = useState([]);
  const [counter, Setcounter] = useState(0);
  const [search, setsearch] = useState("");
  const [singleview, setsingleView] = useState("allusers");
  const [singleViewId, setsingleViewId] = useState("");
  const [AllprojectHealth, setAllprojectHealth] = useState([]);
  const [Isloding, setIsloding] = useState(false);
  // pagination
  const [perPage, setPerPge] = useState(10);
  const [totlePage, setTotalPage] = useState("");
  const daterangefilter = useSelector(selecAllprojectDaterange);
  //  filters
  const [Packagefilter, setPackagefilter] = useState(filter.package);
  const [SiteEngineerfilter, setSiteEngineerfilter] = useState(
    filter.site_engineer
  );
  const [ProjectHealthfilter, setProjectHealthfilter] = useState(
    filter.project_health
  );
  const [SiteAddressfilter, setSiteAddressfilter] = useState(filter.location);
  const [projectID, setprojectID] = useState("");
  const [Project_id, setProject_id] = useState("");

  const onChange = (dates) => {
    dispatch(setAllprojectDaterange(dates));
  };

  // pagination handlar
  const handleChangePage = (e, pageSize) => {
    dispatch(setcurentpage(e));
    setPerPge(pageSize);
  };
  useEffect(() => {
    projectlisting();
    if (localStorage.getItem("username") === null) {
      history("/");
    }
  }, []);

  // to list out all projects
  const projectlisting = () => {
    setIsloding(true);
    const headers = {
      "Content-Type": "application/json",
    };
    let activityFrom;
    let activityTo;
    let rangedatestart = daterangefilter[1];
    let rangedateend = daterangefilter[0];
    if (rangedatestart > rangedateend) {
      activityFrom = rangedateend;
      activityTo = rangedatestart;
    } else {
      activityFrom = rangedatestart;
      activityTo = rangedateend;
    }

    var filterrequest = {
      package: Packagefilter,
      site_engineer: SiteEngineerfilter,
      project_health: ProjectHealthfilter,
      start_from_date: activityFrom,
      start_to_date: activityTo,
      location: SiteAddressfilter,
    };
    dispatch(Setfilterdata(filterrequest));
    axiosClientInterceptors
      .post(
        `/getprojects?page=${currentpage}&limit=${perPage}&search=${search}`,
        filterrequest,
        {
          headers,
        }
      )
      .then((resultr) => {
        setIsloding(false);
        dispatch(setcurentpage(resultr.data.result.currentPage));
        Setprojectdata(resultr.data.result.listofprojects);
        setTotalPage(resultr.data.result.totalprojects);
      });
  };

  const projectlisting2 = () => {
    setIsloding(true);
    setPackagefilter("");
    setSiteEngineerfilter("");
    setProjectHealthfilter("");
    setSiteAddressfilter("");
    dispatch(setAllprojectDaterange(""));
    dispatch(Setfilterdata(""));
    var filterrequest = {
      package: "",
      site_engineer: "",
      project_health: "",
      start_from_date: "",
      start_to_date: "",
      location: "",
    };

    const headers = {
      "Content-Type": "application/json",
    };
    axiosClientInterceptors
      .post(`/getprojects?page=1&limit=${perPage}&search=`, filterrequest, {
        headers,
      })
      .then((resultr) => {
        dispatch(setcurentpage(resultr.data.result.currentPage));
        Setprojectdata(resultr.data.result.listofprojects);
        setTotalPage(resultr.data.result.totalprojects);
        setIsloding(false);
      });
  };

  // Logout Function
  const Logout = () => {
    localStorage.clear("username");
    localStorage.removeItem("username");
    $(".modal-backdrop").remove();
    history("/");
  };

  // getSiteProjectHealth
  const getSiteProjectHealth = () => {
    axiosClientInterceptors.get(`/get-project-health`).then((resp) => {
      setAllprojectHealth(resp.data.result);
    });
  };
  // Multiple delete
  const deletemultipleprojects = async () => {
    let userids = [];
    await projectdata.forEach((d) => {
      if (d.select) {
        userids.push(d._id);
      }
    });
    axiosClientInterceptors
      .post(`/deletemultipleprojects`, {
        userids,
      })
      .then((resultdeleted) => {
        projectlisting();
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    projectlisting();
  }, [currentpage, perPage]);

  return (
    <div className="parent-request">
      {projectScreen === "allusers" && (
        <div className="inner_body">
          <div className="navbars">
            <div
              className="d-flex align-items-center justify-content-between my-3"
              style={{ width: "97%", margin: "auto" }}
            >
              <div>
                <p className="dashboardtitle m-0">All Projects</p>
                <p className="m-0 usertitle">{totlePage} Projects</p>
              </div>

              {localStorage.getItem("role") === "Admin" && (
                <AddProjectPopup projectlisting={projectlisting} />
              )}
            </div>
          </div>
          <div className="">
            <div className="px-3 ">
              <div className="">
                <hr className="mt-0" />
                <div className="req_right_div">
                  <div
                    className="d-flex justify-content-between"
                    width="100%"
                  ></div>
                  <div className="mt-1 admin_table_outer">
                    {counter <= 0 && (
                      <div className="d-flex mt-1">
                        <div className="searchrequst d-flex me-2 ">
                          <input
                            type="text"
                            value={search}
                            onChange={(e) => setsearch(e.target.value)}
                            className="w-100 inputsearch"
                            placeholder="Search by id, name"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                projectlisting();
                              }
                            }}
                          />
                          {search.length > 0 && (
                            <CloseIcon
                              className="mt-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                projectlisting2();
                                setsearch("");
                              }}
                            />
                          )}
                          <button
                            className="serchbuttonrequest"
                            disabled={search.length === 0}
                          >
                            <SearchRoundedIcon fontSize="small" />
                            <span onClick={() => projectlisting()}>Search</span>
                          </button>
                        </div>
                        <div className="filterusers">
                          <Modal
                            className="d-flex justify-content-center align-items-center flex-column"
                            open={open}
                            style={{ background: "rgba(34, 30, 31, 0.9)" }}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <div
                              className="filter_container"
                              style={{ width: "40%" }}
                            >
                              <div
                                className="m-3  "
                                style={{ padding: "13px 0px 16px 0px" }}
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="m-0 Filter_text ">
                                    Apply Filter
                                  </p>
                                  <CloseIcon onClick={handleClose} />
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-4 ">
                                  <div className="w-50 me-3">
                                    <p className=" Package_title mb-2">
                                      Package
                                    </p>
                                    <select
                                      className="filter_usertype"
                                      aria-label="Default select example"
                                      name="package"
                                      vlaue={Packagefilter}
                                      defaultValue={filter.package}
                                      onChange={(e) => {
                                        setPackagefilter(e.target.value);
                                      }}
                                      style={{
                                        backgroundColor: "#f8f8f8",
                                        border: "none",
                                        padding: "15px 32px 14px 13px",
                                      }}
                                    >
                                      <option value="" selected disabled>
                                        Choose package
                                      </option>
                                      {packages?.map((val, index) => (
                                        <option
                                          key={index}
                                          value={val.package_name}
                                        >
                                          {val.package_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="w-50">
                                    <p className=" Package_title mb-2">
                                      Site Engineer
                                    </p>
                                    <select
                                      className="filter_usertype"
                                      name="site_engineer"
                                      aria-label="Default select example"
                                      style={{
                                        backgroundColor: "#f8f8f8",
                                        border: "none",
                                        padding: "15px 32px 14px 13px",
                                      }}
                                      defaultValue={filter.site_engineer}
                                      value={SiteEngineerfilter}
                                      onChange={(e) =>
                                        setSiteEngineerfilter(e.target.value)
                                      }
                                    >
                                      <option value="" selected disabled>
                                        Choose site engineer
                                      </option>
                                      {siteEngineer?.map((val, i) => (
                                        <option key={i} value={val._id}>
                                          {val.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-4  mb-3">
                                  <div className="w-50 me-3 ">
                                    <p className=" Package_title mb-2">
                                      Project Health
                                    </p>
                                    <select
                                      className="filter_usertype"
                                      name="project_health"
                                      style={{
                                        backgroundColor: "#f8f8f8",
                                        border: "none",
                                        padding: "15px 32px 14px 13px",
                                      }}
                                      aria-label="Default select example"
                                      value={ProjectHealthfilter}
                                      defaultValue={filter.project_health}
                                      onChange={(e) =>
                                        setProjectHealthfilter(e.target.value)
                                      }
                                    >
                                      <option value="" selected disabled>
                                        Choose project health
                                      </option>
                                      {AllprojectHealth.map((val, i) => (
                                        <option
                                          key={i}
                                          value={val.project_health}
                                        >
                                          {val.project_health}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="w-50">
                                    <p className=" Package_title mb-2">
                                      Site Address
                                    </p>
                                    <input
                                      type="text"
                                      name="location"
                                      value={SiteAddressfilter}
                                      defaultValue={filter.location}
                                      onChange={(e) =>
                                        setSiteAddressfilter(e.target.value)
                                      }
                                      className="ConstructionStart filter_usertype"
                                      placeholder="eg. Jp nagar, BTM, HSR layout"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div className="w-100">
                                    <p className=" Package_title mb-2">
                                      Construction Start
                                    </p>
                                    <DateRangePicker
                                      placement={"topEnd"}
                                      style={{ fontFamily: "Gm" }}
                                      name="start"
                                      placeholder="Choose date range"
                                      format="dd/MM/yyyy"
                                      character="-"
                                      onChange={onChange}
                                      ranges={fuctureDate}
                                      defaultValue={daterangefilter}
                                      cleanable={true}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-4">
                                  <div
                                    className="w-50 me-3 ResetAll"
                                    onClick={() => {
                                      dispatch(setAllprojectDaterange(""));
                                      handleClose();
                                      projectlisting2();
                                    }}
                                  >
                                    <p className="m-0">Reset All</p>
                                  </div>

                                  <button
                                    type="submit"
                                    className="m-0 w-50 Allfilter "
                                    onClick={() => {
                                      projectlisting();
                                      handleClose();
                                    }}
                                  >
                                    Apply Filter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Modal>
                          <div
                            className="filterbutton"
                            onClick={() => {
                              handleOpen();
                              getSiteProjectHealth();
                            }}
                          >
                            <img src={filters} alt="" />
                            <p className="m-0 Filter">Filter</p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="d-flex justify-content-end">
                      <div className="d-flex crud_button">
                        {counter > 0 && (
                          <button
                            className="admin_pro_delete mt-3"
                            data-bs-toggle="modal"
                            data-bs-target="#deletepro"
                          >
                            <DeleteIcon className="delete_icon_admin" />
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                    {Isloding ? (
                      <div
                        className="App d-flex justify-content-center align-items-center"
                        style={{
                          width: "100%",
                          textAlign: "center",
                          height: "100vh",
                          background: "white",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <>
                        {projectdata.length === 0 ? (
                          <div className=" mt-3 NoRecordFound">
                            <img src={NoRecordFound} />
                          </div>
                        ) : (
                          <div className="admin_inner_table_div">
                            <div className="inner_table_div">
                              <table className="table leaderboard_table">
                                <tbody>
                                  <tr className="headers_tag">
                                    <th className="text-center">Sl. No.</th>
                                    <th className="text-center">Project Id</th>
                                    <th className="text-center">
                                      Project Name
                                    </th>
                                    <th className="text-center">
                                      Package Selected
                                    </th>
                                    <th className="text-center">
                                      Site Eng. Name
                                    </th>
                                    <th className="text-center">
                                      Project Health
                                    </th>
                                    <th className="text-center">
                                      Construction Start Date
                                    </th>
                                  </tr>
                                  {projectdata != ""
                                    ? projectdata.map((cust, index) => {
                                      return (
                                        <tr
                                          key={cust._id}
                                          className="vals"
                                          onClick={() => {
                                            setprojectID(cust.project_id);
                                            dispatch(setrequestIdFRomProject(cust._id));
                                            setsingleViewId(cust._id);
                                          }}
                                        >
                                          <td
                                            className="text-center"
                                            onClick={(e) => {
                                              dispatch(
                                                setProjectScreen("singleuser")
                                              );
                                              setsingleView("singleuser");
                                            }}
                                          >
                                            {(currentpage - 1) * perPage +
                                              index +
                                              1}
                                          </td>
                                          <td
                                            className="text-center"
                                            onClick={(e) => {
                                              setsingleView("singleuser");
                                              dispatch(
                                                setProjectScreen("singleuser")
                                              );
                                            }}
                                          >
                                            {cust.project_id}
                                          </td>
                                          <td
                                            className="text-center"
                                            onClick={(e) => {
                                              setsingleView("singleuser");
                                              dispatch(
                                                setProjectScreen("singleuser")
                                              );
                                            }}
                                          >
                                            {cust.project_name}
                                          </td>
                                          <td
                                            className="text-center"
                                            onClick={(e) => {
                                              setsingleView("singleuser");
                                              dispatch(
                                                setProjectScreen("singleuser")
                                              );
                                            }}
                                          >
                                            <button className="admin_lead_package_btn2 ">
                                              {cust.package}
                                            </button>
                                          </td>
                                          <td
                                            className="text-center"
                                            onClick={(e) => {
                                              setsingleView("singleuser");
                                              dispatch(
                                                setProjectScreen("singleuser")
                                              );
                                            }}
                                          >
                                            {cust.site_engineer}
                                          </td>
                                          <td
                                            className="text-center"
                                            onClick={(e) => {
                                              setsingleView("singleuser");
                                              dispatch(
                                                setProjectScreen("singleuser")
                                              );
                                            }}
                                          >
                                            <div>
                                              {cust.project_health ==
                                                "Subcritical Project" ? (
                                                <button className="project_health_subcritical">
                                                  Subcritical Project
                                                </button>
                                              ) : cust.project_health ==
                                                "Super Critical" ? (
                                                <button className="project_health_supcritical">
                                                  Super Critical
                                                </button>
                                              ) : cust.project_health ==
                                                "Handover Stage" ? (
                                                <button className="project_health_handover">
                                                  Handover Stage
                                                </button>
                                              ) : cust.project_health ==
                                                "New Project" ? (
                                                <button className="project_new">
                                                  New Project
                                                </button>
                                              ) : cust.project_health ==
                                                "Critical" ? (
                                                <button className="project_health_critical">
                                                  Critical
                                                </button>
                                              ) : cust.project_health ==
                                                "Progressing" ? (
                                                <button className="project_progressing">
                                                  Progressing
                                                </button>
                                              ) : cust.project_health ==
                                                "Completed" ? (
                                                <button className="project_completed">
                                                  Completed
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </td>
                                          <td className="text-center">
                                            {cust.construction_startdate}
                                          </td>
                                        </tr>
                                      );
                                    })
                                    : "no record found"}
                                </tbody>
                              </table>
                            </div>

                            <div
                              id="deletepro"
                              className="modal fade "
                              role="dialog"
                              aria-labelledby="myLargeModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog  modal-dialog-centered ">
                                <div
                                  className="modal-content modal_content_whole w-75"
                                  style={{ background: "white" }}
                                >
                                  <div className="modal-body">
                                    <div>
                                      <div className="popup_icon_center">
                                        <InfoRoundedIcon className="popup_delete_proj_icon" />
                                      </div>
                                      <div className="popup_text_center">
                                        <span className="popup_addbankdetails_text">
                                          <b>
                                            {" "}
                                            Are you sure want to Delete this
                                            Project ?
                                          </b>
                                        </span>
                                      </div>
                                      <div className="pt-3 d-flex">
                                        <div
                                          style={{
                                            width: "50%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="popup_btn_unlinkaccount"
                                            onClick={() => {
                                              deletemultipleprojects();
                                            }}
                                            data-bs-dismiss="modal"
                                          >
                                            Delete
                                          </button>
                                        </div>
                                        <div
                                          style={{
                                            width: "50%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-secondary popup_btn_close"
                                            data-bs-dismiss="modal"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Are you ssure want to logout popup */}
                            <div
                              id="logout"
                              className="modal fade "
                              role="dialog"
                              aria-labelledby="myLargeModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog  modal-dialog-centered ">
                                <div
                                  className="modal-content modal_content_whole"
                                  style={{ background: "white" }}
                                >
                                  <div className="modal-body">
                                    <div>
                                      <div className="popup_icon_center">
                                        <InfoRoundedIcon className="popup_delete_proj_icon" />
                                      </div>
                                      <div className="popup_text_center">
                                        <h4 className="popup_addbankdetails_text">
                                          Are you sure want to Logout.?
                                        </h4>
                                      </div>
                                      <div className="pt-3 d-flex justify-content-between">
                                        <div
                                          style={{
                                            width: "48%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="popup_btn_unlinkaccount"
                                            onClick={Logout}
                                          >
                                            Yes
                                          </button>
                                        </div>
                                        <div
                                          style={{
                                            width: "48%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-secondary popup_btn_close"
                                            data-bs-dismiss="modal"
                                          >
                                            No
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <div className="w-100 d-flex justify-content-end align-items-center">
                      <div className="flotpagination">
                        <PaginationComponent
                          handleChangePage={handleChangePage}
                          perPage={perPage}
                          totlePage={totlePage}
                          currentpage={currentpage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {projectScreen === "singleuser" && (
        <ProjectDetails
          setProject_id={setProject_id}
          singleViewId={singleViewId}
          setsingleView={setsingleView}
          projectlisting={projectlisting}
        />
      )}
      {projectScreen === "ProjectsDocument" && (
        <ProjectsDocument
          setsingleView={setsingleView}
          singleViewId={singleViewId}
        />
      )}
      {projectScreen === "AllInvoice" && (
        <AllInvoice
          Project_id={Project_id}
          setsingleView={setsingleView}
          singleViewId={singleViewId}
          projectID={projectID}
        />
      )}

      {projectScreen === "addminAllrequest" && (
        <Requests
          setsingleView={setsingleView}
          singleViewId={singleViewId}
          singleview={singleview}
        />
      )}
      {projectScreen === "invoiceView" && (
        <Viewinvoice
          setsingleView={setsingleView}
          singleViewId={singleViewId}
          singleview={singleview}
        />
      )}
      {projectScreen === "addminAllpo" && (
        <>
          <div className="dashboardtabsection">
            <div className="p-4">
              <PurchageOrder singleViewId={singleViewId} />
            </div>
          </div>
        </>
      )}
      {projectScreen === "singlepo" && (
        <>
          <PurchaseOrderDOc
          // SocketCallData={SocketCallData}
          // SocketCall={SocketCall}
          // requestAllDetails_id={requestAllDetails._id}
          />
        </>
      )}
    </div>
  );
}
