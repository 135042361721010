import React from "react";
import Illustration from "../Assets/Img/Illustration.png";

const Error = () => {
  return (
    <div className="partnetDiv">
      <img
        className="notFoundImage"
        src={Illustration}
        alt="404 Page Not Found"
      />
      <h3 className="headerTxt">Page Not Found</h3>
      <p className="msgTxt">
        We tried so hard (with a magnifying glass and our thinking caps), but
        couldn't find what you are looking for, sorry!
      </p>
      {/* <button
        type="button"
        className="homeBtn"
        // onclick={() => window.history.back()}
      >
        Back to home
      </button> */}
    </div>
  );
};

export default Error;
