import React, { useEffect, useState } from "react";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { useCallback } from "react";
export default function EditNewRow({
  ListingUOMMatrial,
  row,
  setRows,
  index,
  rows,
}) {
  const [AddUoMList, setAddUoMList] = useState([]);
  const getUom1 = (specs) => {
    axiosClientInterceptors
      .get(`/get-specs-uom?specification=${specs}`)
      .then((rpes) => {
        setAddUoMList(rpes.data.result);
      });
  };

  const handleRowChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };

  // const errors = validateAllRows();

  const UomDropdown = useCallback(() => {
    return (
      <select
        className="add_material_select"
        value={row.specification_uom}
        onChange={(e) => {
          handleRowChange(index, "specification_uom", e.target.value);
        }}
      >
        {" "}
        <option value="">Choose</option>
        {AddUoMList?.map((values, Index) => (
          <option value={values.value}>{values.value}</option>
        ))}
      </select>
    );
  }, [AddUoMList]);
  useEffect(() => {
    getUom1(row.specification_name);
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-5">
          <select
            name="specification_name"
            className="add_material_select"
            value={row.specification_name}
            onChange={(e) => {
              handleRowChange(index, "specification_name", e.target.value);
              getUom1(row.specification_name);
            }}
          >
            <option value="">Choose</option>

            {ListingUOMMatrial?.map((values, Index) => (
              <option value={values.label}>{values.label}</option>
            ))}
          </select>
        </div>
        <div className="col-3 ">
          <UomDropdown />{" "}
        </div>
        <div className="col-4">
          <div className="d-flex align-items-center">
            <input
              type="text"
              className="add_material_select "
              value={row.specification_value}
              onChange={(e) =>
                handleRowChange(index, "specification_value", e.target.value)
              }
              placeholder="eg. 3,4,5.."
            />

            <span className="unit_tag">{row.specification_uom}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
