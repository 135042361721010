import React, { useEffect, useState } from "react";
import dashbordtabone from "../../../Assets/Img/dashbordtabone.svg";
import dashboardtabtwo from "../../../Assets/Img/dashboardtabtwo.svg";
import DateRangePicker from "rsuite/DateRangePicker";
import { useSelector } from "react-redux";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
import DashboardAdminGraph from "./DashboardAdminGraph";
import Loader from "../../../ReusableComponent/Loader";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
export default function DashboardAdmin() {
  const [isLoading, setisLoading] = useState(false);
  const [pocreated, Setpocreated] = useState("");
  const [totalinvoice, Settotalinvoice] = useState("");
  const [apprreq, Setapprreq] = useState("");
  useEffect(() => {
    axiosClientInterceptors.get(`/procuredashboard`).then((repo) => {
      Setpocreated(repo.data.Data.PO);
      Settotalinvoice(repo.data.Data.Invoices);
      Setapprreq(repo.data.Data.Requests);
    });
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="dashboardtabsection">
          <div className="dashbordScreenContainer">
            <div>
              <p className="dashboardtitle">Dashboard</p>
              <hr />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center dashtab ">
                <img alt="..." src={dashbordtabone} className="me-3" />
                <div>
                  <p className="mb-1 dashtabtxt  ">{pocreated}</p>
                  <p className="m-0 dashtabsubtxt">
                    Total Purchase Orders Created
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center dashtab">
                <img alt="..." src={dashboardtabtwo} className="me-3" />
                <div>
                  <p className="mb-1 dashtabtxt">{totalinvoice}</p>
                  <p className="m-0 dashtabsubtxt">Total Invoice Uploaded</p>
                </div>
              </div>
              <div className="d-flex align-items-center dashtab">
                <img alt="..." src={dashbordtabone} className="me-3" />
                <div>
                  <p className="mb-1 dashtabtxt">{apprreq}</p>
                  <p className="m-0 dashtabsubtxt">
                    Total Number of Approved Requests
                  </p>
                </div>
              </div>
            </div>
            {/* graph */}
            <div className="d-flex justify-content-between align-items-center mt-4">
              <p className="RequestCreated">Request Created</p>
              <div className="d-flex align-items-center  justify-content-between">
                <select
                  className="form-select me-2 dashboard_select"
                  aria-label="Default select example"
                >
                  <option selected>Select Project</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <DateRangePicker
                  // ranges={LastActivityDate}
                  className=" selectoption"
                  size="md"
                  placement="bottomEnd"
                  // onChange={onChange}
                  placeholder="Select Date Range"
                  // format="YYYY-MM-DD"
                  character="/"
                  cleanable={true}
                />
              </div>
            </div>
            <div className="mt-3">
              <DashboardAdminGraph />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
