import { PureComponent } from "react";
import React, { useState, useEffect } from "react";
// import { Bar } from "react-chartjs-2";

import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";

export default function RequestCreated() {
  const [dashboarddata, Setdashboarddata] = useState([]);
  useEffect(() => {
    axiosClientInterceptors.post(`/requestsgraph`).then((dashdata) => {
      Setdashboarddata(dashdata.data.Requests);
    });
  }, []);

  const data = {
    labels: dashboarddata.map((x) => x.month),
    datasets: [
      {
        label: "PO DATA",
        data: dashboarddata.map((x) => x.Requests),
        backgroundColor: "#FFE185",
      },
    ],
  };

  const options = {
    // responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        // textAlign: "right",
      },
      //   title: {
      //     display: true,
      //     text: "Chart.js Line Chart",
      //   },
    },
  };
  return (
    <div className="">
      {/* <Bar style={{ height: "330px" }} options={options} data={data}></Bar> */}
    </div>
  );
}
