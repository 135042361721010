import React from "react";

const VendorDays = ({ Day, setDay, days, paymentTermsdata }) => {
  return (
    <div className="mt-3">
      <div className=" ">
        <div className="">
          <p className="titleInput">{days ? "Days" : "Percentage"}</p>
          <div className="d-flex  align-items-center g-3 w-50 mt-2">
            <div className=" d-flex align-items-center w-25">
              {/* <input
                type="number"
                min="1"
                step="1"
                placeholder="eg. 30/45"
                className="AddvendorInput "
                value={Day}
                onChange={(e) => setDay(e.target.value)}
              /> */}
              <input
                className="AddvendorInput "
                type="number"
                // onInput={(e) =>
                //   (e.target.value = parseInt(e.target.value) || 0)
                // }
                onKeyDown={(event) => {
                  const value = event.target.value;
                  if (value.length >= 3 && event.keyCode !== 8) {
                    event.preventDefault();
                  }
                }}
                onInput={(e) =>
                  (e.target.value = parseInt(e.target.value) || 0)
                }
                placeholder="eg. 30/45"
                autofocus=""
                value={Day}
                onChange={(e) => setDay(e.target.value)}
              />
              <p className="PluseNO">{days ? "Days" : "%"}</p>
            </div>
            <p className="mb-0 invoice_date ms-2">
              {days
                ? "From the date of invoice"
                : "should be paid before date of invoice"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorDays;
