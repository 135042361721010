import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebaractivetab: "Requests",
  requestIdFRomProject: "",
  NotificationStatusScreen: "",
  NotificationData: [],
  POswitch: "maintable",
  allProjectIndivusal: "",
  authcrd: "",
  status: "idle",
  poID: "",
  FinalpoData: [],
  Termsncondition: [],
  AllTheSiteEng: [],
  AllListmanagers: [],
  allprojectStage: [],
  allPD: [],
  allProjects: [],
  allInvoiceImg: [],
  allfunctions: "",
  idtodelete: "",
  CancelPoDataFromlisting: [],
  requestID: "",
  allMaterialCategory: [],
  notificationCount: "0",
  newRequestID: "",
  ManageuserFilter: "",
  CopyRequestID: "",
  RFQID: "",
  RequestDataFromRFQID: "",
  POvendor: [],
  vendorCompanyName: "",
  POprojectName: [],
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRFQID: (state, action) => {
      state.RFQID = action.payload;
    },
    setCopyRequestID: (state, action) => {
      state.CopyRequestID = action.payload;
    },
    setrequestIdFRomProject: (state, action) => {
      state.requestIdFRomProject = action.payload;
    },
    setManageuserFilter: (state, action) => {
      state.ManageuserFilter = action.payload;
    },
    setnewRequestID: (state, action) => {
      state.newRequestID = action.payload;
    },
    setsidebaractivetab: (state, action) => {
      state.sidebaractivetab = action.payload;
    },
    setNotificationData: (state, action) => {
      state.NotificationData = action.payload;
    },
    setauthcrd: (state, action) => {
      state.authcrd = action.payload;
    },
    setallProjectIndivusal: (state, action) => {
      state.allProjectIndivusal = action.payload;
    },
    setPOswitch: (state, action) => {
      state.POswitch = action.payload;
    },
    setAllTheSiteEng: (state, action) => {
      state.AllTheSiteEng = action.payload;
    },
    setAllListmanagers: (state, action) => {
      state.AllListmanagers = action.payload;
    },
    setallprojectStage: (state, action) => {
      state.allprojectStage = action.payload;
    },
    setPoID: (state, action) => {
      state.poID = action.payload;
    },
    setFinalpoData: (state, action) => {
      state.FinalpoData = action.payload;
    },
    setTermsncondition: (state, action) => {
      state.Termsncondition = action.payload;
    },
    setallPD: (state, action) => {
      state.allPD = action.payload;
    },
    setallProjects: (state, action) => {
      state.allProjects = action.payload;
    },
    setallInvoiceImg: (state, action) => {
      state.allInvoiceImg = action.payload;
    },
    setallfunctions: (state, action) => {
      state.allfunctions = action.payload;
    },
    setdeleteid: (state, action) => {
      state.idtodelete = action.payload;
    },
    setCancelPoDataFromlisting: (state, action) => {
      state.CancelPoDataFromlisting = action.payload;
    },
    setrequestID: (state, action) => {
      state.requestID = action.payload;
    },
    setallMaterialCategory: (state, action) => {
      state.allMaterialCategory = action.payload;
    },
    setNotificationStatusScreen: (state, action) => {
      state.NotificationStatusScreen = action.payload;
    },
    setnotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    setRequestDataFromRFQID: (state, action) => {
      state.RequestDataFromRFQID = action.payload;
    },
    setPOvendor: (state, action) => {
      state.POvendor = action.payload;
    },
    setvendorCompanyName: (state, action) => {
      state.vendorCompanyName = action.payload
    },
    setPOprojectName: (state, action) => {
      state.POprojectName = action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});

export const {
  setsidebaractivetab,
  setrequestIdFRomProject,
  setNotificationData,
  setauthcrd,
  setallProjectIndivusal,
  setPOswitch,
  setAllTheSiteEng,
  setAllListmanagers,
  setallprojectStage,
  setPoID,
  setFinalpoData,
  setTermsncondition,
  setallPD,
  setallProjects,
  setallInvoiceImg,
  setallfunctions,
  setdeleteid,
  setCancelPoDataFromlisting,
  setrequestID,
  setallMaterialCategory,
  setNotificationStatusScreen,
  setnotificationCount,
  setnewRequestID,
  setManageuserFilter,
  setCopyRequestID,
  setRFQID,
  setRequestDataFromRFQID,
  setPOvendor,
  setvendorCompanyName,
  setPOprojectName,
} = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectactivetab = (state) => state.counter.sidebaractivetab;
export const selectauth = (state) => state.counter.authcrd;
export const selectManageuserFilter = (state) => state.counter.ManageuserFilter;
export const selectnewRequestID = (state) => state.counter.newRequestID;
export const selectallProjectIndivusal = (state) =>
  state.counter.allProjectIndivusal;
export const selectPOswitch = (state) => state.counter.POswitch;
export const selectAllTheSiteEng = (state) => state.counter.AllTheSiteEng;
export const selectAllListmanagers = (state) => state.counter.AllListmanagers;
export const selectallprojectStage = (state) => state.counter.allprojectStage;
export const selectPoID = (state) => state.counter.poID;
export const selectFinalpoData = (state) => state.counter.FinalpoData;
export const selectTermsncondition = (state) => state.counter.Termsncondition;
export const selectallPD = (state) => state.counter.allPD;
export const selectallProjects = (state) => state.counter.allProjects;
export const selectallInvoiceImg = (state) => state.counter.allInvoiceImg;
export const selectallfunctions = (state) => state.counter.allfunctions;
export const selectdeleteid = (state) => state.counter.idtodelete;
export const selectCancelPoDataFromlisting = (state) =>
  state.counter.CancelPoDataFromlisting;
export const selectrequestID = (state) => state.counter.requestID;
export const selectallMaterialCategory = (state) =>
  state.counter.allMaterialCategory;
export const selectNotificationStatusScreen = (state) =>
  state.counter.NotificationStatusScreen;
export const selectnotificationCount = (state) =>
  state.counter.notificationCount;
export const selectrequestIdFRomProject = (state) =>
  state.counter.requestIdFRomProject;
export const selectNotificationData = (state) => state.counter.NotificationData;
export const selectCopyRequestID = (state) => state.counter.CopyRequestID;
export const selectRFQID = (state) => state.counter.RFQID;
export const selectRequestDataFromRFQID = (state) =>
  state.counter.RequestDataFromRFQID;
export const selectPOvendor = (state) => state.counter.POvendor
export const selectvendorCompanyName = (state) => state.counter.vendorCompanyName
export const selectsetPOprojectName = (state) => state.counter.POprojectName
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default counterSlice.reducer;
