import React from "react";
import { DocumentViewer } from "react-documents";
import saveAs from "file-saver";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

const MatrialView = ({ matrialViewstate }) => {
  const [singleIMg, setsingleIMg] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="wrap_img">
      <div className="row">
        {matrialViewstate?.map((val, i) => (
          <div className="col-md-3" key={i}>
            <img
              className="imgg mt-3"
              onClick={() => {
                handleOpen();
                setsingleIMg(val);
              }}
              src={`${process.env.REACT_APP_IMG_URL}/${val}`}
            />
          </div>
        ))}
      </div>
      <Modal
        open={open}
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        className="d-flex justify-content-center align-items-center flex-column "
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="filter_container "
          style={{ position: "relative", zIndex: 1 }}
        >
          <div className="m-3 ">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0 Filter_text ">Material Details</p>
              <CloseIcon onClick={handleClose} />
            </div>
            <div className="loderimg">
              <CircularProgress />
            </div>

            <iframe
              style={{
                position: "relative",
                zIndex: 3,
                height: "400px",
                width: "100%",
              }}
              src={`${process.env.REACT_APP_BASE_URL}/view-file?file=${singleIMg}`}
            ></iframe>

            <div
              className="downloadme mt-2"
              onClick={() =>
                saveAs(
                  `${process.env.REACT_APP_BASE_URL}/view-file?file=${singleIMg}`,
                  `${singleIMg.split("/").at(-1).split(".").at(-2)}.pdf`
                )
              }
            >
              <div className="d-flex align-items-center">
                <SaveAltIcon fontSize="small" className="me-2 text-black" />
                <p className="m-0 text-black">Download</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MatrialView;
