import React from "react";
import "./Vendor.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "react-select";
import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dropzone from "react-dropzone";
import File_logo from "../../../Assets/Img/File_logo.svg";
import VendorDays from "./VendorDays";
import DynamicRowVendor from "./DynamicRowVendor";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import ImageCard from "./ImageCard";
import { toast } from "react-toastify";

import { setAddVendorScreen } from "../../../features/Screen/screen";
import { useDispatch } from "react-redux";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import CircularProgress from "@mui/material/CircularProgress";
import DropQr from "./DropQr";

const customStyles = {
  control: (provided, state) => ({
    // Modify control styles for the focused state
    ...provided,
    border: state.isFocused
      ? "0.959133px solid #d0d0d0"
      : " 0.959133px solid #d0d0d0",
    height: state.isFocused ? "47px" : "47px",
    marginTop: "7px",

    borderColor: state.isFocused ? "#d0d0d0" : provided.borderColor,
    boxShadow: state.isFocused
      ? "0.959133px solid #d0d0d0"
      : provided.boxShadow,
  }),
};

const AddVendor = ({ materialCatalog, getvendorListiing }) => {
  const dispatch = useDispatch();
  const options = materialCatalog;
  const [inputs, setInputs] = useState([
    { persentage: "", no_of_days: "", description: "" },
  ]);

  const [BulkFile, setBulkFile] = useState([]);
  const [multiselect, setmultiselect] = useState([]);
  const [Day, setDay] = useState(0);
  const [Radiobtn_Value, setRadiobtn_Value] = useState("Single Payment Term");
  const [errors, setErrors] = useState([]);
  const [Checkbox, setCheckbox] = useState(false);
  const [onsaveApidata, setonsaveApidata] = useState([]);
  const [bankDetails, setbankDetails] = useState("");
  const [btn_loader, setbtn_loader] = useState(false);
  const [paymentswitch, setpaymentswitch] = useState("Account Number");
  const [qrcodeDoc, setqrcodeDoc] = useState("");

  const handleImage = (acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    setBulkFile(acceptedFiles);
  };
  const handleChangeSelect = (data) => {
    setmultiselect(data);
    // setmultiselect(
    console.log("data", data);
    //   data?.map((val, index) => {
    //     return {
    //       ["category_name"]: val.category_name,
    //       ["creationdate"]: val.category_name,
    //       ["updationdate"]: val.updationdate,
    //       ["_id"]: val._id,
    //     };
    //   })
    // );
  };
  useEffect(() => {
    console.log("Checkbox", Checkbox);
  }, [Checkbox]);
  const RemoveFile = (i) => {
    const newBulk = [...BulkFile];
    newBulk.splice(i, 1);
    setBulkFile(newBulk);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { persentage: "", no_of_days: "", description: "" }]);
    setErrors([]);
  };

  const handleRemoveInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
    setErrors([]);
  };

  const validateInput = (value) => {
    if (value.trim() === "") {
      return "This field is required.";
    }
    return "";
  };

  const handleFormSubmit = (e) => {
    // validate inputs
    const newErrors = inputs.map((input) => {
      return {
        persentage: validateInput(input.persentage),
        no_of_days: validateInput(input.no_of_days),
        description: validateInput(input.description),
      };
    });
    setErrors(newErrors);

    // submit form data if all inputs are valid
    const allInputsValid = newErrors.every((error) => {
      return (
        error.persentage === "" &&
        error.no_of_days === "" &&
        error.description === ""
      );
    });
    if (allInputsValid) {
      // submit form data to server
    }
  };
  const Clear = () => {
    setDay("");
    setInputs([{ persentage: "", no_of_days: "", description: "" }]);
  };

  useEffect(() => {
    Clear();
  }, [Radiobtn_Value]);

  function validateArrayOfObjects(arr) {
    let isValid = true;

    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];

      if (!obj.persentage || !obj.no_of_days || !obj.description) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  // formik validation
  const validationSchema = yup.object().shape({
    contact_no: yup
      .string()
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Please enter valid Number"
      )
      .required("required field"),
    company_name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;"'<>,.?/|\\-]*$/,
        "Please enter a valid name"
      )
      .max(100, "Name is too long (max 100 characters)")
      .required("This field is required"),

    vendor_name: yup
      .string()
      .min(3, "Name must be at least 3 characters")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(40)
      .required("required field"),
    email: yup.string().required("required").email("Enter Valid Email ID"),

    // meetingLink: yup
    //   .string()
    //   .test("meetingLinkCondition", "Meeting Link is required", function (value) {
    //     const { mode } = this.parent;
    //     if (mode && mode === "online") {
    //       return yup.string().required().isValidSync(value);
    //     }
    //     return true;
    //   }),
    address: yup.string().required("required field"),
    ifsc_code: yup
      .string()
      .test("ifscCodeCondition", "IFSC Code is required", function (value) {
        if (
          paymentswitch === "Account Number" ||
          paymentswitch === "Account Number + UPI"
        ) {
          return yup.string().required("required field").isValidSync(value);
        }
        return true;
      })
      .matches(/^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/, "Please enter valid IFSC"),

    account_number: yup
      .string()
      .test(
        "ifscCodeCondition",
        "Account Number is required",
        function (value) {
          if (
            paymentswitch === "Account Number" ||
            paymentswitch === "Account Number + UPI"
          ) {
            return yup.string().required("required field").isValidSync(value);
          }
          return true;
        }
      )
      .matches(/[0-9]{9,18}$/, "Please enter valid Account Number"),

    upi: yup
      .string()
      .test("ifscCodeCondition", "UPI deatils are required", function (value) {
        if (
          (paymentswitch === "UPI" && qrcodeDoc.length === 0) ||
          (paymentswitch === "Account Number + UPI" && qrcodeDoc.length === 0)
        ) {
          return yup.string().required(" required field").isValidSync(value);
        }
        return true;
      })
      .matches(/^[\w.-]+@[\w.-]+$/, "invalid UPI-ID"),
    vandor_pan: yup
      .string()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Please enter valid PAN"),
    // .required("required field"),
    bank_address: yup
      .string()
      .test("ifscCodeCondition", "Bank address is required", function (value) {
        if (
          paymentswitch === "Account Number" ||
          paymentswitch === "Account Number + UPI"
        ) {
          return yup.string().required("required field").isValidSync(value);
        }
        return true;
      }),
    alternative_contact_no: yup
      .string()
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Please enter valid Number"
      ),
    // Material_Category: yup.string().required("reque"),
  });

  const formik = useFormik({
    initialValues: {
      company_name: "",
      vendor_name: "",
      contact_no: "",
      alternative_contact_no: "",
      email: "",
      // Checkbox === false && formData.append("gst_number", values.gst_number);
      upi: "",
      gst_number: "",
      address: "",
      ifsc_code: "",
      account_number: "",
      vandor_pan: "",
      bank_address: "",
      Material_Category: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("formData", inputs, Radiobtn_Value);
      if (Radiobtn_Value === "Single Payment Term") {
        let cat = multiselect.map((val, index) => {
          return val._id;
        });
        const formData = new FormData();
        formData.append("vendor_name", values.vendor_name);
        formData.append("contact_no", values.contact_no);
        formData.append("vandor_pan", values.vandor_pan);
        formData.append("company_name", values.company_name);
        formData.append("upi_id", values.upi);
        formData.append(
          "alternative_contact_no",
          values.alternative_contact_no
        );
        formData.append("email", values.email);
        formData.append("address", values.address);
        formData.append("ifsc_code", values.ifsc_code);
        formData.append("account_number", values.account_number);
        formData.append("gst_number", values.gst_number);
        formData.append("single_payment_day", Day);
        formData.append("bank_address", values.bank_address);
        formData.append(
          "branch_name",
          bankDetails.BRANCH ? bankDetails.BRANCH : ""
        );
        formData.append("bank_name", bankDetails.BANK ? bankDetails.BANK : "");
        formData.append("category", cat.join(","));
        formData.append("payment_terms", Radiobtn_Value);

        formData.append("qr_code", qrcodeDoc);

        BulkFile?.forEach((image) => {
          formData.append("documents", image);
        });

        if (Day.length === 0) {
          toast.info("enter the days", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (BulkFile.length === 0) {
          toast.info("select the file", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (multiselect.length === 0) {
          toast.info("Material Category", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setbtn_loader(true);
          const headers = {
            "content-type": "multipart/form-data",
          };

          axiosClientInterceptors
            .post(`/addvendor`, formData)
            .then(function (response) {
              if (response.data.status === "success") {
                toast.success(`${response.data.msg}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                dispatch(setAddVendorScreen("listing"));
                getvendorListiing();
              } else {
                toast.error(`${response.data.msg}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              setbtn_loader(false);
            });
        }
      } else if (Radiobtn_Value === "Installment") {
        console.log(Radiobtn_Value, "Radiobtn_Value");
        let cat = multiselect.map((val, index) => {
          return val._id;
        });

        console.log(cat.join(","), "cat");
        const formData = new FormData();
        formData.append("vendor_name", values.vendor_name);
        formData.append("contact_no", values.contact_no);
        formData.append("vandor_pan", values.vandor_pan);
        formData.append("company_name", values.company_name);
        formData.append("upi_id", values.upi);
        formData.append(
          "alternative_contact_no",
          values.alternative_contact_no
        );
        formData.append("email", values.email);
        formData.append("address", values.address);
        formData.append("ifsc_code", values.ifsc_code);
        formData.append("account_number", values.account_number);
        formData.append("gst_number", values.gst_number);
        formData.append("bank_address", values.bank_address);
        formData.append(
          "branch_name",
          bankDetails.BRANCH ? bankDetails.BRANCH : ""
        );
        formData.append("bank_name", bankDetails.BANK ? bankDetails.BANK : "");
        formData.append("category", cat.join(","));
        formData.append("payment_terms", Radiobtn_Value);
        formData.append("payment_details", JSON.stringify(inputs));

        formData.append("qr_code", qrcodeDoc);

        BulkFile?.forEach((image) => {
          formData.append("documents", image);
        });

        if (!validateArrayOfObjects(inputs)) {
          toast.info(`Please fill The ${Radiobtn_Value} `, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (BulkFile.length === 0) {
          toast.info("select the file", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (multiselect.length === 0) {
          toast.info("Material Category", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setbtn_loader(true);
          const headers = {
            "content-type": "multipart/form-data",
          };
          axiosClientInterceptors
            .post(`/addvendor`, formData)
            .then(function (response) {
              if (response.data.status === "success") {
                toast.success(`${response.data.msg}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                dispatch(setAddVendorScreen("listing"));
                getvendorListiing();
              } else {
                toast.error(`${response.data.msg}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              setbtn_loader(false);
            });
        }
      } else if (Radiobtn_Value === "Advance") {
        const formData = new FormData();
        formData.append("vendor_name", values.vendor_name);
        formData.append("contact_no", values.contact_no);
        formData.append("vandor_pan", values.vandor_pan);
        formData.append("company_name", values.company_name);
        formData.append("upi_id", values.upi);
        formData.append(
          "alternative_contact_no",
          values.alternative_contact_no
        );
        formData.append("email", values.email);
        formData.append("address", values.address);
        formData.append("ifsc_code", values.ifsc_code);
        formData.append("account_number", values.account_number);
        formData.append("gst_number", values.gst_number);
        formData.append("advance_persentage", Day);
        formData.append("bank_address", values.bank_address);
        formData.append(
          "branch_name",
          bankDetails.BRANCH ? bankDetails.BRANCH : ""
        );
        formData.append("bank_name", bankDetails.BANK ? bankDetails.BANK : "");
        formData.append(
          "category",
          multiselect.map((val, index) => {
            return val._id;
          })
        );
        formData.append("payment_terms", Radiobtn_Value);
        BulkFile?.forEach((image) => {
          formData.append("documents", image);
        });

        formData.append("qr_code", qrcodeDoc);

        formData.append("payment_details", JSON.stringify(inputs));

        if (!validateArrayOfObjects(inputs)) {
          toast.info(`Please fill The ${Radiobtn_Value} `, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (Day.length === 0) {
          toast.info("enter the days", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (BulkFile.length === 0) {
          toast.info("select the file", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (multiselect.length === 0) {
          toast.info("Material Category", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setbtn_loader(true);
          const headers = {
            "content-type": "multipart/form-data",
          };
          axiosClientInterceptors
            .post(`/addvendor`, formData)
            .then(function (response) {
              if (response.data.status === "success") {
                toast.success(`${response.data.msg}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                dispatch(setAddVendorScreen("listing"));
                getvendorListiing();
              } else {
                toast.error(`${response.data.msg}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              setbtn_loader(false);
            });
        }
      }

      // bank_name;
      // branch_name;
      // if (BulkFile.length === 0) {
      //   alert("select the file");
      // } else {
      //   alert(
      //     JSON.stringify(
      //       { ...values, ["namej"]: inputs, ["bulk"]: BulkFile },
      //       null,
      //       2
      //     )
      //   );
      // }
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    if (formik.values.ifsc_code?.length === 11) {
      axiosClientInterceptors
        .post(`/get-bank-details`, {
          IFSC: formik.values.ifsc_code,
        })
        .then((response) => {
          // dispatch(setTermsncondition(response.data.result));
          console.log("response", response.data);
          if (response.data.status === "failed") {
            toast.error(`Invalid Bank Details`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setbankDetails(response.data.result);
          }
        })
        .catch((error) => console.log("Error", error));
    }
  }, [formik.values.ifsc_code]);

  return (
    <div className="p-4">
      <form onSubmit={formik.handleSubmit}>
        <div className="d-flex   justify-content-between align-items-center ">
          <p className="m-0">
            <span
              className="fontdocument_dim"
              onClick={() => dispatch(setAddVendorScreen("listing"))}
            >
              All Vendors{" "}
            </span>
            / <span className="fontdocument_active">Add Vendor</span>
          </p>
        </div>
        <hr />
        <div className="scrollvendor">
          <div className="Title_container">
            <p>Personal Information</p>
          </div>
          {/* section 1 */}
          <div className="parentvandorGridone">
            <div class="div1">
              <div>
                <p className="titleInput">Company Name</p>
                <input
                  type="text"
                  placeholder="eg. Jaguar"
                  name="company_name"
                  className="mt-2 AddvendorInput"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.touched.company_name && formik.errors.company_name && (
                <p className="errorRequest mt-1">
                  {formik.errors.company_name}
                </p>
              )}
            </div>

            <div class="div2">
              {" "}
              <div>
                <p className="titleInput">Vendor Name</p>
                <input
                  type="text"
                  name="vendor_name"
                  value={formik.values.vendor_name}
                  onChange={formik.handleChange}
                  placeholder="eg. Ram kumar"
                  className="mt-2 AddvendorInput"
                />
              </div>
              {formik.touched.vendor_name && formik.errors.vendor_name && (
                <p className="errorRequest mt-1">{formik.errors.vendor_name}</p>
              )}
            </div>

            <div class="div3">
              {" "}
              <p className="titleInput">Phone Number</p>
              <div className="d-flex justify-content-center align-items-center mt-2">
                <p className="PluseNO">+91</p>
                <input
                  type="number"
                  maxlength="10"
                  name="contact_no"
                  onKeyDown={(event) => {
                    const value = event.target.value;
                    if (value.length >= 10 && event.keyCode !== 8) {
                      event.preventDefault();
                    }
                  }}
                  value={formik.values.contact_no}
                  onChange={formik.handleChange}
                  placeholder="eg. 8328XXXXXX"
                  className=" AddvendorInput"
                />
              </div>
              {formik.touched.contact_no && formik.errors.contact_no && (
                <p className="errorRequest mt-1">{formik.errors.contact_no}</p>
              )}
            </div>

            <div class="div4">
              {" "}
              <p className="titleInput">Alternative Phone Number</p>
              <div className="d-flex justify-content-center align-items-center mt-2">
                <p className="PluseNO">+91</p>
                <input
                  type="number"
                  maxlength={10}
                  onKeyDown={(event) => {
                    const value = event.target.value;
                    if (value.length >= 10 && event.keyCode !== 8) {
                      event.preventDefault();
                    }
                  }}
                  name="alternative_contact_no"
                  value={formik.values.alternative_contact_no}
                  onChange={formik.handleChange}
                  placeholder="eg. 8328XXXXXX"
                  className=" AddvendorInput"
                />
              </div>
              {formik.touched.alternative_contact_no &&
                formik.errors.alternative_contact_no && (
                  <p className="errorRequest mt-1">
                    {formik.errors.alternative_contact_no}
                  </p>
                )}
            </div>
          </div>
          {/* section 2 */}

          <div className="parentvandorGridtwo mt-3">
            <div className="parentvandorGridtwo1">
              {" "}
              <div>
                {" "}
                <p className="titleInput">Email</p>
                <input
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="eg. sample@gmail.com"
                  className="mt-2 AddvendorInput"
                />
              </div>
              {formik.touched.email && formik.errors.email && (
                <p className="errorRequest mt-1">{formik.errors.email}</p>
              )}
            </div>
            <div className="parentvandorGridtwo2">
              {" "}
              <div>
                <p className="titleInput">GST</p>
                {/* <div className="d-flex justify-content-between  align-items-center">
      
                <div className="d-flex  align-items-center">
                  <input
                    type="checkbox"
                    className="me-2"
                    onChange={(e) => setCheckbox(e.target.checked)}
                  />
                  <p className="titleInput">Unregistered Vendor</p>
                </div>
              </div> */}

                <input
                  type="text"
                  name="gst_number"
                  disabled={Checkbox}
                  value={formik.values.gst_number}
                  onChange={formik.handleChange}
                  placeholder="eg. 1425875XXXX"
                  className="mt-2 AddvendorInput"
                  maxlength="16"
                  // pattern="\d{16}"
                />
              </div>
            </div>
            <div className="parentvandorGridtwo3">
              {" "}
              <div>
                <p className="titleInput">Material Category </p>
                <Select
                  name="Material_Category"
                  options={options}
                  // defaultValue={[options[1], options[2]]}
                  // name="Material_Category"
                  // value={multiselect}
                  styles={customStyles}
                  onChange={handleChangeSelect}
                  isMulti
                  className=" "
                />
              </div>
            </div>
          </div>
          {/* section 3 */}
          <div className="mt-3">
            <p className="titleInput">Full Address</p>
            <textarea
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              className="mt-2 AddvendorInput"
              placeholder="eg. #54, old #28, new sri durgbhavani arcade,2nd floor 5th main MKK road maruthi extension bangalore"
              rows="4"
              cols="50"
            />
          </div>
          {formik.touched.address && formik.errors.address && (
            <p className="errorRequest mt-1">{formik.errors.address}</p>
          )}
          <div className="Title_container mt-4">
            <p>Bank Information </p>
          </div>
          <div className="mt-2 ">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue="Account Number"
              value={paymentswitch}
              onChange={(e) => {
                setpaymentswitch(e.target.value);
              }}
            >
              <FormControlLabel
                value="Account Number"
                control={<Radio style={{ color: "black" }} />}
                label="Account Number"
              />
              <FormControlLabel
                value="UPI"
                control={<Radio style={{ color: "black" }} />}
                label="UPI"
              />
              <FormControlLabel
                value="Account Number + UPI"
                control={<Radio style={{ color: "black" }} />}
                label="Account Number + UPI"
              />
            </RadioGroup>
          </div>
          {paymentswitch === "Account Number" && (
            <>
              {" "}
              {/* section 1 bank */}
              <div class="vendorbankparentone mt-3">
                <div class="vendorbankparentone1">
                  {" "}
                  <div>
                    <p className="titleInput">IFSC Code</p>
                    <input
                      type="text"
                      name="ifsc_code"
                      value={formik.values.ifsc_code}
                      onChange={formik.handleChange}
                      placeholder="eg. 123456XXXX"
                      className="mt-2 AddvendorInput"
                      maxlength="11"
                      // pattern="\d{11}"
                    />
                  </div>
                  {formik.touched.ifsc_code && formik.errors.ifsc_code && (
                    <p className="errorRequest mt-1">
                      {formik.errors.ifsc_code}
                    </p>
                  )}
                </div>
                <div class="vendorbankparentone2">
                  <p className="titleInput">Bank Name</p>
                  <div className="bankvendor mt-2">
                    {bankDetails?.BRANCH || "--------------------------------"}
                  </div>
                </div>
              </div>
              {/* section 2 bank */}
              <div class="vendorbankparenttwo mt-3">
                <div class="vendorbankparenttwo1">
                  {" "}
                  <p className="titleInput">Branch Name</p>
                  <div className="bankvendor mt-2">
                    {bankDetails?.BANK || "--------------------------------"}
                  </div>{" "}
                </div>
                <div class="vendorbankparenttwo2">
                  {" "}
                  <div>
                    <p className="titleInput">Account Number</p>
                    <input
                      type="number"
                      name="account_number"
                      value={formik.values.account_number}
                      onChange={formik.handleChange}
                      placeholder="eg. 123456XXXX"
                      className="mt-2 AddvendorInput"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(event) => {
                        const value = event.target.value;
                        if (value.length >= 18 && event.keyCode !== 8) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>{" "}
                  {formik.touched.account_number &&
                    formik.errors.account_number && (
                      <p className="errorRequest mt-1">
                        {formik.errors.account_number}
                      </p>
                    )}
                </div>
                <div class="vendorbankparenttwo3">
                  {" "}
                  <div>
                    <p className="titleInput">PAN Number</p>
                    <input
                      type="text"
                      name="vandor_pan"
                      value={formik.values.vandor_pan}
                      onChange={formik.handleChange}
                      placeholder="eg. FFJPB4598B"
                      className="mt-2 AddvendorInputpan"
                      maxlength="10"
                      // pattern="\d{10}"
                    />
                  </div>{" "}
                  {formik.touched.vandor_pan && formik.errors.vandor_pan && (
                    <p className="errorRequest mt-1">
                      {formik.errors.vandor_pan}
                    </p>
                  )}
                </div>
              </div>
              {/* section 3 bank */}
              <div className="mt-3">
                <p className="titleInput">Bank Address</p>
                <textarea
                  className="mt-2 AddvendorInput"
                  name="bank_address"
                  value={formik.values.bank_address}
                  onChange={formik.handleChange}
                  placeholder="eg. #54, old #28, new sri durgbhavani arcade,2nd floor 5th main MKK road maruthi extension bangalore"
                  rows="4"
                  cols="50"
                />
              </div>
              {formik.touched.bank_address && formik.errors.bank_address && (
                <p className="errorRequest mt-1">
                  {formik.errors.bank_address}
                </p>
              )}
            </>
          )}
          {paymentswitch === "UPI" && (
            <div className="d-flex  align-items-center justify-content-start gap-3">
              <div>
                <p className="titleInput">UPI</p>
                <input
                  type="text"
                  name="upi"
                  value={formik.values.upi}
                  onChange={formik.handleChange}
                  placeholder="Eg. cendrol@icicibank"
                  className="mt-2 AddvendorInput "
                  style={{ width: "300px" }}
                />
                {formik.touched.upi && formik.errors.upi && (
                  <p className="errorRequest mt-1">{formik.errors.upi}</p>
                )}
              </div>

              <div>
                <p className="titleInput">Upload QR Code</p>
                {/* <input
                  type="file"
                  name="upi"
                  // value={formik.values.ifsc_code}
                  // onChange={formik.handleChange}
                  placeholder="Eg. cendrol@icicibank"
                  className="mt-2 AddvendorInput "
                  style={{ width: "300px" }}
                /> */}
                <DropQr data={qrcodeDoc} setdata={setqrcodeDoc} />
              </div>
            </div>
          )}
          {paymentswitch === "Account Number + UPI" && (
            <>
              {" "}
              {/* section 1 bank */}
              <div class="vendorbankparentone mt-3">
                <div class="vendorbankparentone1">
                  {" "}
                  <div>
                    <p className="titleInput">IFSC Code</p>
                    <input
                      type="text"
                      name="ifsc_code"
                      value={formik.values.ifsc_code}
                      onChange={formik.handleChange}
                      placeholder="eg. 123456XXXX"
                      className="mt-2 AddvendorInput"
                      maxlength="11"
                      // pattern="\d{11}"
                    />
                  </div>
                  {formik.touched.ifsc_code && formik.errors.ifsc_code && (
                    <p className="errorRequest mt-1">
                      {formik.errors.ifsc_code}
                    </p>
                  )}
                </div>
                <div class="vendorbankparentone2">
                  <p className="titleInput">Bank Name</p>
                  <div className="bankvendor mt-2">
                    {bankDetails?.BRANCH || "--------------------------------"}
                  </div>
                </div>
              </div>
              {/* section 2 bank */}
              <div class="vendorbankparenttwo mt-3">
                <div class="vendorbankparenttwo1">
                  {" "}
                  <p className="titleInput">Branch Name</p>
                  <div className="bankvendor mt-2">
                    {bankDetails?.BANK || "--------------------------------"}
                  </div>{" "}
                </div>
                <div class="vendorbankparenttwo2">
                  {" "}
                  <div>
                    <p className="titleInput">Account Number</p>
                    <input
                      type="number"
                      name="account_number"
                      value={formik.values.account_number}
                      onChange={formik.handleChange}
                      placeholder="eg. 123456XXXX"
                      className="mt-2 AddvendorInput"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(event) => {
                        const value = event.target.value;
                        if (value.length >= 18 && event.keyCode !== 8) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>{" "}
                  {formik.touched.account_number &&
                    formik.errors.account_number && (
                      <p className="errorRequest mt-1">
                        {formik.errors.account_number}
                      </p>
                    )}
                </div>
                <div class="vendorbankparenttwo3">
                  {" "}
                  <div>
                    <p className="titleInput">PAN Number</p>
                    <input
                      type="text"
                      name="vandor_pan"
                      value={formik.values.vandor_pan}
                      onChange={formik.handleChange}
                      placeholder="eg. FFJPB4598B"
                      className="mt-2 AddvendorInputpan"
                    />
                  </div>{" "}
                  {formik.touched.vandor_pan && formik.errors.vandor_pan && (
                    <p className="errorRequest mt-1">
                      {formik.errors.vandor_pan}
                    </p>
                  )}
                </div>
              </div>
              {/* section 3 bank */}
              <div className="mt-3">
                <p className="titleInput">Bank Address</p>
                <textarea
                  className="mt-2 AddvendorInput"
                  name="bank_address"
                  value={formik.values.bank_address}
                  onChange={formik.handleChange}
                  placeholder="eg. #54, old #28, new sri durgbhavani arcade,2nd floor 5th main MKK road maruthi extension bangalore"
                  rows="4"
                  cols="50"
                />
              </div>
              {formik.touched.bank_address && formik.errors.bank_address && (
                <p className="errorRequest mt-1">
                  {formik.errors.bank_address}
                </p>
              )}
              <div className="d-flex  align-items-center justify-content-start gap-3 mt-3">
                <div>
                  <p className="titleInput">UPI</p>
                  <input
                    type="text"
                    name="upi"
                    value={formik.values.upi}
                    onChange={formik.handleChange}
                    placeholder="Eg. cendrol@icicibank"
                    className="mt-2 AddvendorInput "
                    style={{ width: "300px" }}
                  />
                  {formik.touched.upi && formik.errors.upi && (
                    <p className="errorRequest mt-1">{formik.errors.upi}</p>
                  )}
                </div>

                <div>
                  <p className="titleInput">Upload QR Code</p>
                  {/* <input
                  type="file"
                  name="upi"
                  // value={formik.values.ifsc_code}
                  // onChange={formik.handleChange}
                  placeholder="Eg. cendrol@icicibank"
                  className="mt-2 AddvendorInput "
                  style={{ width: "300px" }}
                /> */}
                  <DropQr data={qrcodeDoc} setdata={setqrcodeDoc} />
                </div>
              </div>
            </>
          )}

          <div className="Title_container mt-4">
            <p>Payment Terms</p>
          </div>
          {/* radio btn */}
          <div className="mt-2 ">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue="Single Payment Term"
              value={Radiobtn_Value}
              onChange={(e) => {
                setRadiobtn_Value(e.target.value);
              }}
            >
              <FormControlLabel
                value="Single Payment Term"
                control={<Radio style={{ color: "black" }} />}
                label="Single Payment "
              />
              <FormControlLabel
                value="Installment"
                control={<Radio style={{ color: "black" }} />}
                label="Installment"
              />
              <FormControlLabel
                value="Advance"
                control={<Radio style={{ color: "black" }} />}
                label="Advance"
              />
            </RadioGroup>
          </div>
          {/* days */}
          {Radiobtn_Value === "Single Payment Term" && (
            <VendorDays setDay={setDay} Day={Day} days={true} />
          )}
          {Radiobtn_Value === "Installment" && (
            <div className="rowContainer">
              <div>
                {inputs.map((input, index) => {
                  return (
                    <DynamicRowVendor
                      handleFormSubmit={handleFormSubmit}
                      handleChange={handleChange}
                      input={input}
                      index={index}
                      errors={errors}
                      handleRemoveInput={handleRemoveInput}
                    />
                  );
                })}

                <hr />
                <div className="d-flex  align-items-center px-4 pb-4">
                  <div
                    className="AddRow me-2"
                    onClick={() => {
                      handleAddInput();
                    }}
                  >
                    + Add Row
                  </div>
                  {/* <button
                  // type="submit"
                  onClick={() => handleFormSubmit()}
                  className="SaveDetails"
                  // onClick={() => alert(JSON.stringify(inputFields, null, 2))}
                >
                  Save Details
                </button> */}
                </div>
              </div>
            </div>
          )}
          {Radiobtn_Value === "Advance" && (
            <>
              {" "}
              <VendorDays setDay={setDay} Day={Day} days={false} />{" "}
              <div className="rowContainer mt-2">
                <div>
                  {inputs.map((input, index) => {
                    return (
                      <DynamicRowVendor
                        handleFormSubmit={handleFormSubmit}
                        handleChange={handleChange}
                        input={input}
                        index={index}
                        errors={errors}
                        handleRemoveInput={handleRemoveInput}
                      />
                    );
                  })}

                  <hr />
                  <div className="d-flex  align-items-center px-4 pb-4">
                    <div className="AddRow me-2" onClick={handleAddInput}>
                      + Add Row
                    </div>
                    {/* <button
                    onClick={() => {
                      handleFormSubmit();
                    }}
                    className="SaveDetails"
                    // onClick={() => alert(JSON.stringify(inputFields, null, 2))}
                  >
                    Save Details
                  </button> */}
                  </div>
                </div>
              </div>
            </>
          )}
          {/* dragdrop */}
          <div className="Title_container mt-4">
            <p>Documents</p>
          </div>
          <div className="d-flex  align-items-center">
            <Dropzone onDrop={handleImage}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    className: "uplode_file_dot_border rounded",
                  })}
                >
                  <div className="mb-3">
                    <img className="mx-auto" src={File_logo} alt="..." />
                  </div>
                  <input name="bulkdata" multiple {...getInputProps()} />

                  <p className="uplode_file_text">Drag & Drop Your File here</p>

                  {/* <p className="Fileformat">
                  File format : Only .csv, .xls , .xlsx
                </p> */}
                  <div className="uplode_file_btn_container ">
                    <button
                      className=" mx-auto uplode_file_btn px-4 py-2  rounded"
                      type="button"
                    >
                      Browse File
                    </button>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="p-4 image_scrool ">
              {BulkFile.map((val, index) => (
                <div className="mt-2">
                  {" "}
                  <ImageCard
                    path={val.path}
                    index={index}
                    RemoveFile={RemoveFile}
                  />
                </div>
              ))}
            </div>
          </div>

          <button type="submit" className="AddVendor_btn">
            {btn_loader ? <CircularProgress size={20} /> : "+ Add Vendor"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddVendor;
