import React from "react";
import Avatar from "@mui/material/Avatar";
import notificationIcon from "../Assets/Img/notificationIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowCircleRight from "../Assets/Img/Arrow - Right Square.svg";
import moment from "moment";
import { useDispatch } from "react-redux";

import {
  setNotificationStatusScreen,
  setsidebaractivetab,
} from "../features/counter/counterSlice";
import NewRequest from "../../src/Assets/Img/NewRequest.svg";
import Rejected from "../../src/Assets/Img/Rejected.svg";
import POCreated from "../../src/Assets/Img/POCreated.svg";
import PaymentDone from "../../src/Assets/Img/PaymentDone.svg";
import PartiallyReceived from "../../src/Assets/Img/PartiallyReceived.svg";
import MaterialReceived from "../../src/Assets/Img/MaterialReceived.svg";
import PurchaseOrderCancelled from "../../src/Assets/Img/PurchaseOrderCancelled.svg";
import Closed from "../../src/Assets/Img/Closed.svg";
import Approved from "../../src/Assets/Img/Approved.svg";
import { setscreen } from "../features/Screen/screen";
import RFQIMG from "../../src/Assets/Img/RFQIMG.svg";

const NotificationCard = ({ values, setRequestData, ViewNotification }) => {
  const dispatch = useDispatch("");
  const notifIcon = (key) => {
    switch (key) {
      case "New Request":
        return NewRequest;
      case "Partially Received":
        return PartiallyReceived;
      case "Material Received":
        return MaterialReceived;
      case "Rejected":
        return Rejected;
      case "Payment Done":
        return PaymentDone;
      case "Closed":
        return Closed;
      case "PO Created":
        return POCreated;
      case "PO cancelled":
        return PurchaseOrderCancelled;
      case "Approved":
        return Approved;

      default:
        return RFQIMG;
    }
  };

  return (
    <div
      className={`${
        values.isViewed ? "notification_card_active" : "notification_card"
      } mt-3`}
      onClick={() => {
        localStorage.setItem("REqustID", values.request_id);
        dispatch(setsidebaractivetab("Requests"));
        setRequestData("singledata");
        dispatch(setscreen("singledata"));
        ViewNotification(values._id);
      }}
    >
      <div className="d-flex align-items-center">
        {/* {!values.isViewed && (
          <FiberManualRecordIcon
            fontSize="small"
            className="me-2"
            style={{ color: "#F7B314", fontSize: "1rem" }}
          />
        )} */}
        <Avatar
          alt="Remy Sharp"
          src={notifIcon(values.notification_type)}
          className="me-2"
        />
        <div>
          <p className="notification_card_title ">{values.message}</p>
          <p className="notification_card_subtitle ">
            {moment(values.time).format("LT")} |{" "}
            <span style={{ color: "#7E7E7E" }} className="mx-1 ">
              {" "}
              Project :{" "}
            </span>{" "}
            {values.project_name}{" "}
            {values.notification_type === "Approved" && (
              <span style={{ color: "#7E7E7E" }} className="mx-1">
                | Approved By :{" "}
                <span style={{ color: "black" }} className="ms-1">
                  {values.req_approved_by}
                </span>{" "}
              </span>
            )}
            {values.notification_type === "Rejected" && (
              <span style={{ color: "#7E7E7E" }} className="mx-1 ">
                | Rejected By :{" "}
                <span style={{ color: "black" }} className="ms-1">
                  {values.req_rejected_by}
                </span>{" "}
              </span>
            )}
            {values.notification_type === "PO Created" && (
              <span style={{ color: "#7E7E7E" }} className="mx-1 ">
                | Created By :
                <span style={{ color: "black" }} className="ms-1">
                  {values.PO_created_by}
                </span>{" "}
              </span>
            )}
            {values.notification_type === "PO cancelled" && (
              <span style={{ color: "#7E7E7E" }} className="mx-1 ">
                | cancelled By :{" "}
                <span style={{ color: "black" }} className="ms-1">
                  {values.PO_cancelled_by}
                </span>{" "}
              </span>
            )}
            {values.notification_type === "Closed" && (
              <span style={{ color: "#7E7E7E" }} className="mx-1 ">
                | Closed By :
                <span style={{ color: "black" }} className="ms-1">
                  {values.req_closed_by}
                </span>
              </span>
            )}
            {values.notification_type === "Partially Received" && (
              <span style={{ color: "#7E7E7E" }} className="mx-1 ">
                | Site Engineer :{" "}
                <span style={{ color: "black" }} className="ms-1">
                  {values.site_engineer_name}
                </span>{" "}
              </span>
            )}
            {values.notification_type === "Material Received" && (
              <span style={{ color: "#7E7E7E" }} className="mx-1 ">
                | Site Engineer :{" "}
                <span style={{ color: "black" }} className="ms-1">
                  {values.site_engineer_name}
                </span>{" "}
              </span>
            )}
          </p>
        </div>
      </div>

      <img src={ArrowCircleRight} fontSize="small" />
    </div>
  );
};

export default NotificationCard;
