import axios from "axios";

// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// toast.configure();

const SERVER_URL = process.env.REACT_APP_BASE_URL;
// const Token = localStorage.getItem("token");
const timeout = 10000; // milliseconds

const newAxiosClientInterceptors = axios.create({
    baseURL: SERVER_URL,
    // timeout: timeout,
});

const requestHandler = (request) => {
    if (localStorage.getItem("token")) {
        request.headers["x-auth-token"] = localStorage.getItem("token");
    }
    /*
    Use refresh-token to generate new access-token and refresh-token
    If access-token is null
    */
    return request;
};

const responseHandler = (response) => {
    return response;
};

const errorHandler = async (error) => {
    const status = error.response ? error.response.status : null;
    const refereshToken = localStorage.getItem("token");

    if (refereshToken === null || status === 401) {
        window.location.href = "/";
    }
    // else if (status === 401) {
    //   // will loop if refreshToken returns 401
    //   try {
    //     const response = await axios.post(`${SERVER_URL}/refreshtoken`, {
    //       refreshToken: refereshToken,
    //     });
    //     localStorage.setItem("token", response.data.token);
    //     error.response.config.headers["x-auth-token"] = response.data.token;
    //     return await axiosClientInterceptors(error.response.config);
    //   } catch (err) {
    //     window.location.href = "/";
    //   }
    // }

    return Promise.reject(error);
};

newAxiosClientInterceptors.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

newAxiosClientInterceptors.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

/*
Or you can use name apiClient
export default apiClient or httpClient
*/
export default newAxiosClientInterceptors;
