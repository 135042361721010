import React, { useState, useEffect } from "react";
import ArticleIcon from "@mui/icons-material/Article";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import whatsapp from "../../../Assets/Img/whatsapp.svg";
import { useDispatch, useSelector } from "react-redux";
import MatrialView from "../AdminRequests/MatrialView";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import CheckIcon from "@mui/icons-material/Check";
import {
  setPOswitch,
  selectPOswitch,
  selectallProjectIndivusal,
  selectactivetab,
} from "../../../features/counter/counterSlice";
import AllRequestTab from "./AllRequestTab";
import ReceivedTab from "./ReceivedTab";
import ClosedTab from "./ClosedTab";
import RequestDetailPO from "../PoAdmin/RequestDetailPO";
import Modal from "@mui/material/Modal";
import PurchaseOrderDOc from "../PoAdmin/PurchaseOrderDOc";
import saveAs from "file-saver";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CircularProgress from "@mui/material/CircularProgress";
import AddRequestAdmin from "./AddRequestAdmin";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  selectProjectScreen,
  selectscreen,
  setProjectScreen,
  setscreen,
} from "../../../features/Screen/screen";
import RFQ from "./RFQ/RFQ";
import ListingsVendor from "./RFQ/ListingsVendor";
import IndividualVendor from "./RFQ/IndividualVendor";
import moment from "moment";
import { fileNameSplit } from "../../../ReusableComponent/Validation";

const Requests = ({
  singleViewId,
  setsingleView,
  requestdata,
  setRequestData,
  // SocketCall,
  // SocketCallData,
}) => {
  var flag = "is-done";
  const dispatch = useDispatch();
  const poid = useSelector(selectallProjectIndivusal);
  const requestScreen = useSelector(selectscreen);
  const projectScreen = useSelector(selectProjectScreen);
  const activetabsection = useSelector(selectactivetab);
  const POswitch = useSelector(selectPOswitch);
  const [matrialViewstate, setmatrialViewstate] = useState("");
  const [singleIMg, setsingleIMg] = useState("");
  const [requestAllDetails, setRequestAllDetails] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [ValueRadio, setValueRadio] = useState("");
  const [valuesingle, setvaluesingle] = useState("");
  const [vendorID, setVendorId] = useState();
  const [requeststatus, setRequestStatus] = useState("");
  const [activetab, setactivetab] = useState(1);
  const [requestid, Setrequestid] = useState("");
  const [materialtype, Setmaterialtype] = useState("");
  const [RequestIdentity, Setrequestidentity] = useState("");
  const [materialdetails, Setmaterialdetails] = useState([]);
  const [noteforapporrej, Setnoteforapporrej] = useState("");
  const [invoiceamt, Setinvoiceamt] = useState("");
  const [timendate, Settimendate] = useState([]);
  const [statusforprogress, Setstatusforprogress] = useState([]);
  const [invoicenumber, Setinvoicenumber] = useState("");
  const [matarialID, setmatarialID] = useState();
  const ref = React.createRef();
  const [addRequestSwitch, setaddRequestSwitch] = useState(false);
  const [requestIDforVEndor, setrequestIDforVEndor] = useState("");
  const [individualPOcreateID, setindividualPOcreateID] = useState("");
  const [responselenth, setresponselenth] = useState(0);
  const [multiInvoiceDetails, setmultiInvoiceDetails] = useState([]);
  const [purchaseOrderFile, setPurchaseOrderFile] = useState([]);
  const [typeOfPayment, setTypeOfPayment] = useState("");
  const [RFQ_track, setRFQ_track] = useState("");
  const [loader, setloader] = useState(false);
  // multiple invoice
  const [invoiceID, setInvoiceID] = useState([]);

  const getRequestDetails = () => {
    if (localStorage.getItem("REqustID") != null) {
      const headers = {
        "Content-Type": "application/json",
      };
      axiosClientInterceptors
        .get(`/getrequestsbyid?_id=${localStorage.getItem("REqustID")}`, {
          headers,
        })
        .then((resulta) => {
          setRequestAllDetails(resulta.data?.Requests);
          setRequestStatus(resulta?.data?.Requests?.current_status);
          setTypeOfPayment(resulta?.data?.Requests?.payment_terms);
          Setinvoiceamt(resulta.data.Requests.invoice_amount);
          Setrequestid(resulta.data.Requests.request_id);
          Setmaterialtype(resulta.data.Requests.material_category);
          Setmaterialdetails(resulta.data.Requests.materials);
          setmultiInvoiceDetails(resulta.data.Requests.multiple_invoice_data);
          Setinvoicenumber(resulta.data.Requests.invoice_number);
          Setstatusforprogress(resulta.data.Requests.request_status);
          Settimendate(resulta.data.Requests.request_status);
          setmatrialViewstate(resulta);
          setresponselenth(resulta.data.responsed_vendors);
          setPurchaseOrderFile(resulta.data.Requests.po_file);
          setrequestIDforVEndor(resulta.data.Requests._id);
          setmatarialID(resulta.data.Requests.material_category_id);
        });
    }
  };

  // Function to Approve the request
  const sendapproverequest = () => {
    setloader(true);
    axiosClientInterceptors
      .post(
        `/requeststatus?_id=${RequestIdentity || localStorage.getItem("REqustID")
        }&statusvalue=Approved`
      )
      .then((res) => {
        if (res.data.status === "success") {
          window.$("#approveRequest").modal("hide");
          setloader(false);
          getRequestDetails(RequestIdentity);
          // SocketCall("admin-notification-count", null, null, "Approved");
          // SocketCall("pm-notification-count", null, null, "Approved");
          // SocketCall("pd-notification-count", null, null, "Approved");
          // SocketCallData(
          //   "admin-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Approved"
          // );
          // SocketCallData(
          //   "pm-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Approved"
          // );
          // SocketCallData(
          //   "pd-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Approved"
          // );
        }
      });
  };

  const showPurchaseOrder = (val) => {
    console.log("Val", val);
  };

  // Function to Reject the Request

  const sendrejectreq = () => {
    const datas = {
      note: noteforapporrej,
    };
    if (datas.note != "") {
      axiosClientInterceptors
        .post(
          `/requeststatus?_id=${RequestIdentity || localStorage.getItem("REqustID")
          }&statusvalue=Rejected`,
          datas
        )
        .then((res) => {
          window.$("#Reject_order").modal("hide");
          getRequestDetails(RequestIdentity);
          // SocketCall("admin-notification-count", null, null, "Rejected");
          // SocketCall("pm-notification-count", null, null, "Rejected");
          // SocketCall("pd-notification-count", null, null, "Rejected");
          // SocketCallData(
          //   "admin-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Rejected"
          // );
          // SocketCallData(
          //   "pm-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Rejected"
          // );
          // SocketCallData(
          //   "pd-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Rejected"
          // );
        });
    } else {
    }
  };

  const getmatrialsdata = (j) => {
    setmatrialViewstate(j);
  };

  // Function to send Payment Done status of Request
  const paymentdone = () => {
    setloader(true);
    axiosClientInterceptors
      .post(
        `/requeststatus?_id=${RequestIdentity || localStorage.getItem("REqustID")
        }&statusvalue=Payment Initiated`
      )
      .then((res) => {
        if (res.data.status === "success") {
          setloader(false);
          getRequestDetails(RequestIdentity);
          // SocketCall("admin-notification-count", null, null, "Payment Done");
          // SocketCall("pm-notification-count", null, null, "Payment Done");
          // SocketCall("pd-notification-count", null, null, "Payment Done");
          // SocketCallData(
          //   "admin-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Payment Done"
          // );
          // SocketCallData(
          //   "pm-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Payment Done"
          // );
          // SocketCallData(
          //   "pd-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Payment Done"
          // );
        } else {
          alert("Error");
        }
      });
  };

  // Function for Closing the Request
  const closeorder = () => {
    axiosClientInterceptors
      .post(
        `/requeststatus?_id=${RequestIdentity || localStorage.getItem("REqustID")
        }&statusvalue=Closed`
      )
      .then((res) => {
        if (res.data.status === "success") {
          window.$("#closeorder").modal("hide");
          getRequestDetails(RequestIdentity);

          // SocketCall("admin-notification-count", null, null, "Closed");
          // SocketCall("pm-notification-count", null, null, "Closed");
          // SocketCall("pd-notification-count", null, null, "Closed");
          // SocketCallData(
          //   "admin-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Closed"
          // );
          // SocketCallData(
          //   "pm-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Closed"
          // );
          // SocketCallData(
          //   "pd-get-notification",
          //   RequestIdentity || localStorage.getItem("REqustID"),
          //   "Closed"
          // );
        } else {
          alert("Error");
        }
      });
  };

  const getInvoiceImages = (ID) => {
    axiosClientInterceptors
      .get(`/get-invoice-image?invoiceId=${ID}`)
      .then((response) => {
        console.log("(*(", response.data.result.invoice);
        setInvoiceID(response.data.result.invoice);
      });
  };

  // Function to get only PO file name without exte
  function extractFileName(path) {
    // remove the directory path and the extension from the filename
    var filename = path.replace(/^.*[\\/]/, "").replace(/\.[^/.]+$/, "");
    return filename;
  }

  useEffect(() => {
    getRequestDetails();
  }, []);

  // useEffect(() => {
  //   getInvoiceImages();
  // }, [invoiceID]);
  return (
    <>
      {/* Filter Popup for only Partially Recieved */}

      {requestScreen === "listing" && (
        <>
          <div className="dashbordScreenContainer">
            <div className="d-flex justify-content-between align-items-center">
              {activetabsection === "All Projects" ? (
                <>
                  {projectScreen === undefined && (
                    <p className="dashboardtitle">Material Requests</p>
                  )}
                  {projectScreen === "addminAllrequest" && (
                    <p className="m-0">
                      <span
                        className="fontdocument_dim"
                        onClick={() => dispatch(setProjectScreen("allusers"))}
                      >
                        All Projects
                      </span>{" "}
                      /{" "}
                      <span
                        className="fontdocument_dim"
                        onClick={() => dispatch(setProjectScreen("singleuser"))}
                      >
                        Project Details{" "}
                      </span>
                      /{" "}
                      <span className="fontdocument_active">All Requests</span>
                    </p>
                  )}
                </>
              ) : (
                <p className="dashboardtitle m-0">Material Requests</p>
              )}

              {addRequestSwitch ? (
                <div className="">
                  <div
                    onClick={() => setaddRequestSwitch(false)}
                    className="addrequestBTN text-center"
                  >
                    {" "}
                    Close
                  </div>
                </div>
              ) : (
                <>
                  {localStorage.getItem("role") !== "Project Manager" && (
                    <div className="">
                      <div
                        onClick={() => {
                          setaddRequestSwitch(true);
                        }}
                        className="addrequestBTN text-center"
                      >
                        {" "}
                        <AddIcon /> Add Request
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {addRequestSwitch ? (
              <>
                <hr />
                <AddRequestAdmin
                  // SocketCallData={SocketCallData}
                  // SocketCall={SocketCall}
                  setaddRequestSwitch={setaddRequestSwitch}
                />
              </>
            ) : (
              <>
                <div className=" mt-4">
                  <div className="d-flex  align-items-center">
                    <div className="w-25" onClick={() => setactivetab(1)}>
                      <p
                        className={` ${activetab === 1
                          ? "allrequeststabactive"
                          : "allrequeststab"
                          }`}
                      >
                        All Requests{" "}
                        {/* <span style={{ color: "#909090" }}>
                          ( {allCount?.all_request} Request )
                        </span> */}
                      </p>
                    </div>
                    <div className="w-25" onClick={() => setactivetab(2)}>
                      <p
                        className={`${activetab === 2
                          ? "allReceivedtabactive"
                          : "allReceivedtab"
                          }`}
                      >
                        Received{" "}
                        {/* <span style={{ color: "#909090" }}>
                          ( {allCount?.received_request} Request )
                        </span> */}
                      </p>
                    </div>
                    <div className="w-25" onClick={() => setactivetab(3)}>
                      <p
                        className={`${activetab === 3
                          ? "allReceivedtabactive"
                          : "allReceivedtab"
                          }`}
                      >
                        Closed{" "}
                        {/* <span style={{ color: "#909090" }}>
                          ( {allCount?.closed_request} Request )
                        </span> */}
                      </p>
                    </div>
                    <div className="w-25">
                      <p className="allReceivedtabempty">f</p>
                    </div>
                  </div>
                </div>
                {/* table */}
                {activetab === 1 && (
                  <AllRequestTab
                    setRequestData={setRequestData}
                    singleViewId={singleViewId}
                    getRequestDetails={getRequestDetails}
                    Setrequestidentity={Setrequestidentity}
                  />
                )}
                {activetab === 2 && (
                  <ReceivedTab
                    Setrequestidentity={Setrequestidentity}
                    singleViewId={singleViewId}
                    setRequestData={setRequestData}
                    getRequestDetails={getRequestDetails}
                  />
                )}
                {activetab === 3 && (
                  <ClosedTab
                    Setrequestidentity={Setrequestidentity}
                    singleViewId={singleViewId}
                    setRequestData={setRequestData}
                    getRequestDetails={getRequestDetails}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
      {requestScreen === "singledata" && (
        <>
          {POswitch === "PurchaseOrderDOc" ? (
            <PurchaseOrderDOc
              // SocketCallData={SocketCallData}
              // SocketCall={SocketCall}
              requestAllDetails_id={requestAllDetails._id}
            />
          ) : POswitch === "Request detail  from request" ? (
            <>
              <RequestDetailPO
                individualPOcreateID={individualPOcreateID}
                vendorID={vendorID}
                requestIDforVEndor={matarialID}
                // SocketCallData={SocketCallData}
                // SocketCall={SocketCall}
                RequestIdentity={requestid}
                requestAllDetails_id={requestAllDetails._id}
                requestdata={requestdata}
                setRequestData={setRequestData}
              />
            </>
          ) : POswitch === "RFQ" ? (
            <RFQ
              getRequestDetails={getRequestDetails}
              RequestIdentity={requestAllDetails._id}
              // SocketCallData={SocketCallData}
              // SocketCall={SocketCall}
              matarialID={matarialID}
              requestIDforVEndor={requestIDforVEndor}
            />
          ) : POswitch === "listing of vendors verifyed" ? (
            <ListingsVendor
              requestIDforVEndor={requestIDforVEndor}
              setValueRadio={setValueRadio}
              ValueRadio={ValueRadio}
              setvaluesingle={setvaluesingle}
            />
          ) : POswitch === "individual Vendor" ? (
            <IndividualVendor
              setindividualPOcreateID={setindividualPOcreateID}
              vendorID={vendorID}
              setVendorId={setVendorId}
              setvaluesingle={setvaluesingle}
              valuesingle={valuesingle}
            />
          ) : (
            <div
              className="w-100 p-3"
              style={{ height: "100vh", overflowY: "scroll" }}
            >
              {poid.project_id ? (
                <p className="m-0">
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      dispatch(setProjectScreen("allusers"));
                      setsingleView("allusers");
                      dispatch(setscreen("listing"));
                    }}
                  >
                    All Projects
                  </span>{" "}
                  /{" "}
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      setsingleView("singleuser");
                      dispatch(setProjectScreen("singleuser"));
                      dispatch(setscreen("listing"));
                    }}
                  >
                    Project Details{" "}
                  </span>
                  /{" "}
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      dispatch(setscreen("listing"));
                    }}
                  >
                    All Requests{" "}
                  </span>
                  / <span className="fontdocument_active">Request Details</span>
                </p>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <p className="m-0">
                      <span
                        className="fontdocument_dim"
                        onClick={() => {
                          setRequestData("listing");
                          dispatch(setscreen("listing"));
                        }}
                      >
                        Material Requests
                      </span>{" "}
                      /{" "}
                      <span className="fontdocument_active">
                        Request Details
                      </span>
                    </p>
                  </div>
                </div>
              )}

              <hr />
              <div>
                <div className="">
                  <div className="req_details_left">
                    <div className="first_row row">
                      <div className="col-4 col-md-2 ">
                        <div className="details">
                          <span className="mb-1">Project ID</span>
                          <p>{requestAllDetails?.project_id || "-"}</p>
                        </div>
                      </div>
                      <div className="col-4 col-md-2">
                        <div className="details">
                          <span className="mb-1">Request ID</span>
                          <p>{requestAllDetails?.request_id || "-"}</p>
                        </div>
                      </div>
                      <div className="col-4 col-md-2">
                        <div className="details">
                          <span className="mb-1">Project Name</span>
                          <p>{requestAllDetails?.project_name || "-"}</p>
                        </div>
                      </div>
                      <div className="col-4 col-md-2">
                        <div className="details">
                          <span className="mb-1">Project Stage</span>
                          <p>
                            {requestAllDetails?.project_stage
                              ? requestAllDetails?.project_stage[
                              requestAllDetails?.project_stage.length - 1
                              ]
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-4 col-md-2">
                        <div className="details">
                          <span className="mb-1">Site Engineer</span>
                          <p>{requestAllDetails?.site_engineer || "-"}</p>
                        </div>
                      </div>
                      <div className="col-4 col-md-2">
                        <div className="details">
                          <div className="d-flex align-items-center ">
                            <div className="me-2">
                              <span className="mb-1">
                                Site Engg.Phone Number
                              </span>
                              <div className="details d-flex align-items-center">
                                <p className="mb-0">
                                  {requestAllDetails?.Site_eng_no || "-"}
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className="whatsapp_tag">
                                <a
                                  href={`https://wa.me/${requestAllDetails?.Site_eng_no}`}
                                  title={requestAllDetails?.Site_eng_no}
                                  id="whatsapp"
                                  target="_blank"
                                  className="a_mail"
                                >
                                  {" "}
                                  <img src={whatsapp} />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="separator_line" />
                    <div className="first_row row mt-3">
                      <div className="col-4 col-md-2">
                        <div className="details">
                          <span className="mb-1">Approval By</span>
                          <p>{requestAllDetails?.approved_by || "-"}</p>
                        </div>
                      </div>
                      <div className="col-4 col-md-2">
                        <div className="details">
                          <span className="mb-1">Created By</span>
                          <p>{requestAllDetails?.requested_by || "-"}</p>
                        </div>
                      </div>
                      <div className="col-4 col-md-2">
                        <div className="details">
                          <span className="mb-1">Priority</span>
                          {requestAllDetails?.priority === "High" && (
                            <p style={{ color: "#fe3e3e" }}>
                              {requestAllDetails?.priority}
                            </p>
                          )}
                          {requestAllDetails?.priority === "Medium" && (
                            <p style={{ color: "#fc8314" }}>
                              {requestAllDetails?.priority}
                            </p>
                          )}
                          {requestAllDetails?.priority === "Low" && (
                            <p style={{ color: "#3add5e" }}>
                              {requestAllDetails?.priority}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-4 col-md-2">
                        <div className="details">
                          <span className="mb-1">Due Date</span>
                          <p>{requestAllDetails?.due_date || "-"}</p>
                        </div>
                      </div>
                      <div className="col-4 col-md-3">
                        <div className="details">
                          <span className="mb-1">Status</span>
                          {requeststatus === "New Request" && (
                            <p style={{ color: "#3eaefe" }}>{requeststatus}</p>
                          )}
                          {requeststatus === "Approved" && (
                            <p style={{ color: "#3add5e" }}>{requeststatus}</p>
                          )}
                          {requeststatus === "Rejected" && (
                            <p style={{ color: "#fe3e3e" }}>{requeststatus}</p>
                          )}
                          {requeststatus === "PO Created" && (
                            <p style={{ color: "#fcc00" }}>{requeststatus}</p>
                          )}
                          {requeststatus === "Partially Received" && (
                            <p style={{ color: "#fb8c3c" }}>{requeststatus}</p>
                          )}
                          {requeststatus === "Material Received" && (
                            <p style={{ color: "#3add5e" }}>{requeststatus}</p>
                          )}
                          {requeststatus === "Payment Done" && (
                            <p style={{ color: "#cc3efe" }}>{requeststatus}</p>
                          )}
                          {requeststatus === "Closed" && (
                            <p style={{ color: "#0e0e0e" }}>{requeststatus}</p>
                          )}
                          {requeststatus === "PO cancelled" && (
                            <p style={{ color: "#0e0e0e" }}>{requeststatus}</p>
                          )}
                          {requeststatus === "Payment Initiated" && (
                            <p style={{ color: "#0e0e0e" }}>{requeststatus}</p>
                          )}
                          {requeststatus === "Advance Paid" && (
                            <p style={{ color: "#0e0e0e" }}>{requeststatus}</p>
                          )}

                          {"" + requeststatus?.split(" ")[0] === "RFQ" && (
                            <p style={{ color: "#0e0e0e" }}>{requeststatus}</p>
                          )}
                          {"" +
                            requeststatus?.split(" ")[
                            "" + requeststatus?.split(" ").length - 1
                            ] ===
                            "Paid" && (
                              <p style={{ color: "#0e0e0e" }}>{requeststatus}</p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="separator_line" />
                    <div className="first_row row mt-3">
                      <div className="col-4 col-md-4">
                        <div className="details">
                          <span className="mb-1">Purpose</span>
                          <p>{requestAllDetails?.purpose || "-"}</p>
                        </div>
                      </div>
                      <div className="col-4 col-md-8">
                        <div className="details">
                          <span className="mb-1">Material Description</span>
                          <p>
                            {requestAllDetails?.material_description
                              ? requestAllDetails?.material_description
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="separator_line" />
                    {requestAllDetails?.note &&
                      requestAllDetails?.note != "" ? (
                      <>
                        <div className="first_row row mt-3">
                          <div className="col-6 col-md-12">
                            <div className="details">
                              <span className="mb-1">Cancelled Reason</span>
                              <p>{requestAllDetails?.note || "-"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="separator_line" />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="req_details_main_sec2">
                <h4
                  style={{
                    fontFamily: "Gsb",
                    color: "#0e0e0e",
                    fontSize: "18px",
                    marginBottom: "14px",
                  }}
                >
                  Order Tracker
                </h4>
                <div className="row">
                  <div className="col-6 col-md-6">
                    <div className="material_details_div d-flex">
                      <div className="wrapper1">
                        <ul className="StepProgress1">
                          {timendate?.map((ips, index) => {
                            let datetime = new Date(
                              ips.date_time
                            ).toLocaleString("en-IN", {
                              timeZone: "Asia/Kolkata",
                            });
                            let activityDateTime = datetime
                              ?.split("/")
                              .join("-");
                            return (
                              <li className="StepProgress-item1 " key={index}>
                                <span className="material_request_lifecycle_date_time">
                                  {activityDateTime != ""
                                    ? activityDateTime
                                    : "-"}
                                  {/* {datetime != "" ? datetime : "-"} */}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="wrapper">
                        <ul className="StepProgress">
                          {statusforprogress.map((data, index) => {
                            return (
                              <li
                                className={"StepProgress-item " + flag}
                                key={index}
                              >
                                {data.status}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-6">
                    <div className="request_details_material_table_strip">
                      Materials Details
                    </div>
                    <div className="material_details_div">
                      {/* {invoicenumber && <div className="separator_line" />} */}
                      <div className="mt-3 mb-3" />
                      {purchaseOrderFile
                        ? purchaseOrderFile?.map((val, index) => {
                          return (
                            <div className="d-flex justify-content-between align-items-center mb-3 purchasecontainer">
                              <p
                                className="PurchaseOrder"
                                onClick={() => {
                                  console.log(val);
                                }}
                              >
                                Purchase Order
                              </p>
                              <span className="po_no">
                                {extractFileName(val)}
                              </span>
                              <button
                                disabled={val === ""}
                                onClick={() => {
                                  {
                                    val !== "" &&
                                      window.open(
                                        `${process.env.REACT_APP_IMG_URL}/${val}`
                                      );
                                  }
                                }}
                              >
                                {/* <a
                                    target="_blank"
                                    className="po_link"
                                    href={`${process.env.REACT_APP_IMG_URL}/${val}`}
                                  > */}
                                <div className="ViewPurchase">
                                  View Purchase Order
                                </div>
                                {/* </a> */}
                              </button>
                            </div>
                          );
                        })
                        : ""}

                      <div className="p-3" style={{ background: "#F9F9F9" }}>
                        <div className="d-flex justify-content-between material_details">
                          <div className="">
                            <p className="mb-0">Material Category:</p>
                          </div>
                          <div className="">
                            <p className="mb-0">{materialtype}</p>
                          </div>
                        </div>
                        <div className="separator_line" />
                        {statusforprogress
                          .map((e) => e.status)
                          .includes("Partially Received") ? (
                          <div className="row material_details_table">
                            <div className="col-3 text-center ">
                              <p>MATERIAL NAME</p>
                            </div>
                            <div className="col-2 text-center ">
                              <p>QTY</p>
                            </div>
                            <div className="col-2 text-center ">
                              <p>UOM</p>
                            </div>
                            <div className="col-2 text-center">
                              <p>RECIEVED</p>
                            </div>
                            <div
                              className="col-2 text-center"
                              onClick={() => { }}
                            >
                              <p>IMAGES</p>
                            </div>
                          </div>
                        ) : (
                          <div className="p-2" ref={ref}>
                            <img
                              src={whatsapp}
                              id="what_icon"
                              style={{ display: "none" }}
                            />
                            <div className="row material_details_table">
                              <div className="col-4  d-flex justify-content-start">
                                <p>MATERIAL NAME</p>
                                <div style={{ display: "none" }}>Hellsssso</div>
                              </div>
                              <div className="col-4 text-center ">
                                <p>QTY</p>
                              </div>
                              <div className="col-4  text-center">
                                <p className="ms-4">UOM</p>
                              </div>
                            </div>
                            {materialdetails.map((accum, index) => {
                              return (
                                <div
                                  className="row material_details_tabledata"
                                  key={index}
                                >
                                  <div className="col-4 d-flex justify-content-start">
                                    <p>{accum.material_name}</p>
                                  </div>
                                  <div className="col-4  text-center ">
                                    <p>{accum.quantity}</p>
                                  </div>
                                  <div className="col-4  text-center">
                                    <p className="ms-4">{accum.uom}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {/* {
                          statusforprogress
                            .map((e) => e.status)
                            .includes("Partially Received")
                            ?
                            multiInvoiceDetails.map((j, index) => {
                              return (
                                <div
                                  className="row material_details_tabledata mb-0"
                                  key={index}
                                >
                                  {j.matarial_details.map((val, index) => (
                                    <>
                                      <div className="col-3">
                                        <p>{val.material_name}</p>
                                      </div>
                                      <div className="col-2">
                                        <p>{val.quantity}</p>
                                      </div>
                                      <div className="col-2">
                                        <p>{val.uom}</p>
                                      </div>
                                      <div
                                        className="col-2
                                        text-center
                                  "
                                      >
                                        {val.quantity === val.received ? (
                                          <p className="green">
                                            {val.received}
                                          </p>
                                        ) : (
                                          <p className="red">{val.received}</p>
                                        )}
                                      </div>
                                      <div className="col-3">
                                        <div>
                                          <p
                                            onClick={() => {
                                              dispatch(setscreen("showimages"));
                                              getmatrialsdata(val);
                                            }}
                                            style={{
                                              color: "#000",
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                              textDecoration: "underline",
                                            }}
                                          >
                                            View Images
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                  <div className="separator_line" />
                                  <div className="row">
                                    <div className="col-6 col-md-6">
                                      <div className="d-flex justify-content-between">
                                        <div className="details">
                                          <span>Invoice Number</span>
                                          <p>{j.invoice_number}</p>
                                        </div>
                                        <div className="details ">
                                          <span>Total Amount</span>
                                          <p>{j.total_amt} /-</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-6 text-end ">
                                      <div className="">
                                        <div
                                          className=""
                                          onClick={() => {
                                            dispatch(setscreen("showinvoice"));
                                            getInvoiceImages(j.invoice_id);
                                          }}
                                        >
                                          <button className="ViewPurchase">
                                            View Invoice Image
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-6 text-start mt-3 mb-3">
                                      <div className="details">
                                        <span>Uploaded on</span>
                                        <p>
                                          { }{" "}
                                          {moment(
                                            j.creationdate,
                                            "YYYY-MM-DD"
                                          ).format("DD/MM/YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="separator_line" />
                                </div>
                              );
                            })
                            : ""} */}
                        {
                          multiInvoiceDetails.map((j, index) => {
                            return (
                              <div
                                className="row material_details_tabledata mb-0"
                                key={index}
                              >
                                {j.matarial_details.map((val, index) => (
                                  <>
                                    <div className="col-3">
                                      <p>{val.material_name}</p>
                                    </div>
                                    <div className="col-2">
                                      <p>{val.quantity}</p>
                                    </div>
                                    <div className="col-2">
                                      <p>{val.uom}</p>
                                    </div>
                                    {/* {index >= 0 && ( */}
                                    <div
                                      className="col-2
                                            text-center
                                      "
                                    >
                                      {val.quantity === val.received ? (
                                        <p className="green">
                                          {val.received}
                                        </p>
                                      ) : (
                                        <p className="red">{val.received}</p>
                                      )}
                                    </div>
                                    {/* )} */}

                                    {/* {j.received_material.map((remat, index) => {
                                        return ( */}
                                    <div className="col-3">
                                      <div>
                                        <p
                                          onClick={() => {
                                            dispatch(setscreen("showimages"));
                                            getmatrialsdata(val);
                                          }}
                                          style={{
                                            color: "#000",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          View Images
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ))}
                                {/* <hr /> */}
                                <div className="separator_line" />
                                <div className="row">
                                  <div className="col-6 col-md-6">
                                    <div className="d-flex justify-content-between">
                                      <div className="details">
                                        <span>Invoice Number</span>
                                        <p>{j.invoice_number}</p>
                                      </div>
                                      <div className="details ">
                                        <span>Total Amount</span>
                                        <p>{j.total_amt} /-</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-6 text-end ">
                                    <div className="">
                                      <div
                                        className=""
                                        onClick={() => {
                                          dispatch(setscreen("showinvoice"));
                                          getInvoiceImages(j.invoice_id);
                                        }}
                                      >
                                        <button className="ViewPurchase">
                                          View Invoice Image
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-6 text-start mt-3 mb-3">
                                    <div className="details">
                                      <span>Uploaded on</span>
                                      <p>
                                        { }{" "}
                                        {moment(
                                          j.creationdate,
                                          "YYYY-MM-DD"
                                        ).format("DD/MM/YYYY")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="separator_line" />
                              </div>
                            );
                          })
                        }
                      </div>

                      {statusforprogress
                        .map((e) => e.status)
                        .includes("Material Received") ? (
                        <div
                          className="p-3 mt-3"
                          style={{ background: "#f5f5f5" }}
                        >
                          <div
                            className="p-2 mb-3 material_details_tabledata"
                            style={{ borderBottom: "1px solid gray" }}
                          >
                            Material Recieved Summary
                          </div>
                          <div className="row material_details_table mt-3">
                            <div className="col-3">
                              <p>MATERIAL NAME</p>
                            </div>
                            <div className="col-2">
                              <p>QTY</p>
                            </div>
                            <div className="col-2">
                              <p>UOM</p>
                            </div>
                            <div className="col-2 text-center">
                              <p>RECIEVED</p>
                            </div>
                            <div className="col-2 text-center">
                              <p>IMAGES</p>
                            </div>
                          </div>
                          {materialdetails.map((val, index) => {
                            return (
                              <div className="row material_details_tabledata mb-0">
                                <div className="col-3">
                                  <p>{val?.material_name}</p>
                                </div>
                                <div className="col-2">{val?.quantity}</div>
                                <div className="col-2">{val?.uom}</div>
                                <div className="col-2 text-center received_no">
                                  {val?.received}
                                </div>
                                <div className="col-3">
                                  <p
                                    onClick={() => {
                                      dispatch(setscreen("showimages"));
                                      getmatrialsdata(val);
                                    }}
                                    style={{
                                      color: "#000",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    View Images
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}

                      {requeststatus == "New Request" ? (
                        <>
                          <div className="d-flex w-100 justify-content-between mt-4">
                            <div style={{ width: "48%" }}>
                              <button
                                className="reject_btns d-flex  justify-content-center align-items-center"
                                data-bs-toggle="modal"
                                data-bs-target="#Reject_order"
                              >
                                <CloseIcon
                                  fontSize="small"
                                  style={{ color: "#fe3e3e" }}
                                />{" "}
                                <p className="mb-0"> Reject This Request </p>
                              </button>
                            </div>

                            <button
                              disabled={
                                localStorage.getItem("role") ===
                                "Procurement Department"
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#approveRequest"
                              style={{ width: "48%" }}
                              className="approve_btns text-center  d-flex  justify-content-center align-items-center"
                            >
                              <CheckIcon
                                fontSize="small"
                                style={{ color: "#fff" }}
                              />
                              <p>Approve This Request </p>
                            </button>
                          </div>
                          <div
                            className="modal fade"
                            id="Reject_order"
                            tabindex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                  >
                                    Request Reject
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <textarea
                                    required
                                    rows="4"
                                    cols="9"
                                    className="text_area"
                                    placeholder="Enter Reject Reason"
                                    onChange={(e) => {
                                      Setnoteforapporrej(e.target.value);
                                    }}
                                  ></textarea>
                                  <button
                                    disabled={noteforapporrej === ""}
                                    className="reject_popup_button mt-3"
                                    onClick={() => {
                                      sendrejectreq();
                                    }}
                                  >
                                    Reject This Request
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <hr />
                      <div className="w-100">
                        <div className="d-flex justify-content-between">
                          <div
                            id="approveRequest"
                            className="modal fade "
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                              <div
                                className="modal-content modal_content_whole"
                                style={{ background: "none", border: "none" }}
                              >
                                <div
                                  style={{
                                    background: "white",
                                    width: "300px",
                                  }}
                                >
                                  <div className="modal-body " style={{}}>
                                    <div>
                                      <div className="popup_icon_center">
                                        <InfoRoundedIcon
                                          style={{
                                            width: "50px !important",
                                            width: "50px !important",
                                          }}
                                          className="tick_mark"
                                        />
                                      </div>
                                      <div className="popup_text_center">
                                        <h4 className="popup_addbankdetails_text">
                                          Are you sure want to approve this
                                          request?
                                        </h4>
                                      </div>
                                      {loader ? (
                                        <div className="text-center">
                                          <CircularProgress
                                            color="inherit"
                                            className="text-center"
                                            size={30}
                                          />
                                        </div>
                                      ) : (
                                        <div className="pt-3 row">
                                          <div className="col-6">
                                            <button
                                              type="button"
                                              className="popup_btn_unlinkaccount"
                                              style={{ background: "#3ADD5E" }}
                                              onClick={() =>
                                                sendapproverequest()
                                              }
                                            >
                                              Yes
                                            </button>
                                          </div>
                                          <div
                                            className="col-6"
                                            style={{
                                              color: "#000",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-secondary popup_btn_close"
                                              data-bs-dismiss="modal"
                                              style={{
                                                color: "#000",
                                                background: "#f4f4f4",
                                              }}
                                            >
                                              No
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            id="closeorder"
                            className="modal fade "
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                              <div
                                className="modal-content modal_content_whole"
                                style={{ background: "none", border: "none" }}
                              >
                                <div
                                  style={{
                                    background: "white",
                                    width: "300px",
                                  }}
                                >
                                  <div className="modal-body " style={{}}>
                                    <div>
                                      <div className="popup_icon_center">
                                        <InfoRoundedIcon
                                          style={{ color: "#e04128" }}
                                          className="popup_delete_proj_icon"
                                        />
                                      </div>
                                      <div className="popup_text_center">
                                        <h4 className="popup_addbankdetails_text">
                                          Are you sure want to Close order.?
                                        </h4>
                                      </div>
                                      <div className="pt-3 row">
                                        <div className="col-6">
                                          <button
                                            type="button"
                                            className="popup_btn_unlinkaccount"
                                            style={{ background: "#e04128" }}
                                            onClick={closeorder}
                                          >
                                            Yes
                                          </button>
                                        </div>
                                        <div
                                          className="col-6"
                                          style={{
                                            color: "#000",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-secondary popup_btn_close"
                                            data-bs-dismiss="modal"
                                            style={{
                                              color: "#000",
                                              background: "#f4f4f4",
                                            }}
                                          >
                                            No
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style={{ width: "100%" }}>
                            {requeststatus === "Payment Done" && (
                              <>
                                {localStorage.getItem("role") !==
                                  "Project Manager" && (
                                    <button
                                      className="w-100 d-flex align-items-center ml-2 mt-3 side_menu_dashboardbtn_unactive side_menu_dashboardbtn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#closeorder"
                                    >
                                      <p className="mb-0 pl-3">
                                        <CloseIcon
                                          className="side_nav_icons"
                                          style={{
                                            color: "#000",
                                            marginRight: "5px",
                                          }}
                                        />
                                        Close This Request
                                      </p>
                                    </button>
                                  )}
                              </>
                            )}

                            {"" + requeststatus.split(" ")[0] === "RFQ" && (
                              <>
                                {localStorage.getItem("role") ===
                                  "Project Manager" ? (
                                  ""
                                ) : (
                                  <>
                                    <div className="w-100 d-flex justify-content-between align-items-center">
                                      <button
                                        disabled={
                                          localStorage.getItem("role") ===
                                          "Project Manager"
                                        }
                                        className={`d-flex align-items-center me-3 purchase_order_btn`}
                                        onClick={() => {
                                          dispatch(setPOswitch("RFQ"));
                                        }}
                                      >
                                        <ArticleIcon
                                          className="side_nav_icons"
                                          style={{
                                            color: "#000",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <p className="mb-0 pl-3">
                                          Generate Request for Quote
                                        </p>
                                      </button>

                                      <button
                                        style={{ background: "#F6F6F6" }}
                                        disabled={responselenth === 0}
                                        className=" d-flex align-items-center ml-2 purchase_order_btn"
                                        onClick={() => {
                                          dispatch(
                                            setPOswitch(
                                              "listing of vendors verifyed"
                                            )
                                          );
                                        }}
                                      >
                                        <RemoveRedEyeIcon
                                          className="side_nav_icons"
                                          style={{
                                            color: "#000",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <p className="mb-0 pl-3">
                                          View Vendors Response
                                        </p>
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                            {requeststatus === "Approved" && (
                              <>
                                {localStorage.getItem("role") ===
                                  "Project Manager" ? (
                                  ""
                                ) : (
                                  <>
                                    <div className="w-100 d-flex justify-content-between align-items-center">
                                      <button
                                        disabled={
                                          localStorage.getItem("role") ===
                                          "Project Manager"
                                        }
                                        className={`d-flex align-items-center ${responselenth !== 0 && "me-3"
                                          }  purchase_order_btn`}
                                        onClick={() => {
                                          dispatch(setPOswitch("RFQ"));
                                        }}
                                      >
                                        <ArticleIcon
                                          className="side_nav_icons"
                                          style={{
                                            color: "#000",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <p className="mb-0 pl-3">
                                          Generate Request for Quote
                                        </p>
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      {((requeststatus === "PO Created" ||
                        requeststatus === "PO Sent to Vendor") &&
                        typeOfPayment === "Advance") ||
                        requeststatus === "Material Received" ? (
                        <>
                          {(localStorage.getItem("role") ===
                            "Procurement Department" ||
                            localStorage.getItem("role") === "Admin") && (
                              <>
                                {loader ? (
                                  <div className="text-center">
                                    <CircularProgress color="inherit" size={25} />
                                  </div>
                                ) : (
                                  <button
                                    className="mt-3 w-100"
                                    onClick={paymentdone}
                                  >
                                    <div className="mat_recieved">
                                      Make Payment
                                    </div>
                                  </button>
                                )}
                              </>
                            )}
                        </>
                      ) : (
                        ""
                      )}
                      {/* {localStorage.getItem("role") ===
                        "Procurement Department" && (
                        <button className="mt-3 w-100" onClick={paymentdone}>
                          <div className="mat_recieved">Make Payment</div>
                        </button>
                      )} */}
                      {/* {(requeststatus === "PO Created" &&
                        typeOfPayment === "Advance") ||
                      requeststatus === "Material Received" ? (
                        <>
                          {localStorage.getItem("role") ===
                          "Procurement Department" ? (
                            <button
                              disabled={
                                localStorage.getItem("role") ===
                                "Project Manager"
                              }
                              className="mt-3 w-100"
                              onClick={paymentdone}
                            >
                              <div className="mat_recieved">Make Payment</div>
                            </button>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {requestScreen === "showimages" && (
        <>
          <div className="w-100 p-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {activetabsection === "All Projects" ? (
                  <>
                    <p className="m-0">
                      <span
                        className="fontdocument_dim"
                        onClick={() => {
                          dispatch(setProjectScreen("allusers"));
                          dispatch(setscreen("listing"));
                        }}
                      >
                        All Projects
                      </span>{" "}
                      /{" "}
                      <span
                        className="fontdocument_dim"
                        onClick={() => {
                          dispatch(setProjectScreen("singleuser"));
                          dispatch(setscreen("listing"));
                        }}
                      >
                        Project Details{" "}
                      </span>
                      /{" "}
                      <span
                        className="fontdocument_dim"
                        onClick={() => dispatch(setscreen("listing"))}
                      >
                        All Requests{" "}
                      </span>
                      /{" "}
                      <span
                        className="fontdocument_dim"
                        onClick={() => {
                          dispatch(setscreen("singledata"));
                          dispatch(setPOswitch(""));
                        }}
                      >
                        Request Details
                      </span>
                      /
                      <span className="fontdocument_active">
                        Material Recieved Images
                      </span>
                    </p>
                  </>
                ) : (
                  <p className="m-0">
                    <span
                      className="fontdocument_dim"
                      onClick={() => {
                        setRequestData("listing");
                        dispatch(setscreen("listing"));
                      }}
                    >
                      Material Request
                    </span>{" "}
                    /{" "}
                    <span
                      className="fontdocument_dim"
                      onClick={() => {
                        setRequestData("singledata");
                        dispatch(setPOswitch(""));
                        dispatch(setscreen("singledata"));
                      }}
                    >
                      Request Details
                    </span>{" "}
                    /{" "}
                    <span className="fontdocument_active">
                      Material Recieved Images
                    </span>
                  </p>
                )}
              </div>
            </div>
            <hr />

            <MatrialView
              matrialViewstate={matrialViewstate?.received_material}
            />
          </div>
        </>
      )}
      {requestScreen === "showinvoice" && (
        <>
          <div className="w-100 p-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {activetabsection === "All Projects" ? (
                  <>
                    <p className="m-0">
                      <span
                        className="fontdocument_dim"
                        onClick={() => {
                          dispatch(setProjectScreen("allusers"));
                          dispatch(setscreen("listing"));
                        }}
                      >
                        All Projects
                      </span>{" "}
                      /{" "}
                      <span
                        className="fontdocument_dim"
                        onClick={() => {
                          dispatch(setProjectScreen("singleuser"));
                          dispatch(setscreen("listing"));
                        }}
                      >
                        Project Details{" "}
                      </span>
                      /{" "}
                      <span
                        className="fontdocument_dim"
                        onClick={() => dispatch(setscreen("listing"))}
                      >
                        All Requests{" "}
                      </span>
                      /{" "}
                      <span
                        className="fontdocument_dim"
                        onClick={() => {
                          dispatch(setscreen("singledata"));
                          dispatch(setPOswitch(""));
                        }}
                      >
                        Request Details
                      </span>
                      /
                      <span className="fontdocument_active">
                        Invoice Images
                      </span>
                    </p>
                  </>
                ) : (
                  <p className="m-0">
                    <span
                      className="fontdocument_dim"
                      onClick={() => {
                        setRequestData("listing");
                        dispatch(setscreen("listing"));
                      }}
                    >
                      Material Request
                    </span>{" "}
                    /{" "}
                    <span
                      className="fontdocument_dim"
                      onClick={() => {
                        setRequestData("singledata");
                        dispatch(setscreen("singledata"));
                      }}
                    >
                      Request Details{" "}
                    </span>
                    /{" "}
                    <span className="fontdocument_active">Invoice Images</span>
                  </p>
                )}
              </div>
            </div>
            <hr />
            <div className="wrap_img">
              <div className="row">
                {invoiceID?.map((val, index) => {
                  return (
                    <div className="col-md-3" key={index}>
                      <img
                        className="imgg mt-3"
                        onClick={() => {
                          handleOpen();
                          setsingleIMg(val);
                        }}
                        src={`${process.env.REACT_APP_IMG_URL}/${val}`}
                      />
                    </div>
                  );
                })}
                {/* {invoiceID?.map((val, i) => 
                      return (
                        <div className="col-md-3" key={i}>
                        <img
                          className="imgg mt-3"
                          onClick={() => {
                            handleOpen();
                            setsingleIMg(val);
                          }}
                          src={`${process.env.REACT_APP_IMG_URL}/${val}`}
                        />
                        </div>
                      );
                )} */}
              </div>
            </div>
            <Modal
              open={open}
              style={{ background: "rgba(34, 30, 31, 0.9)" }}
              className="d-flex justify-content-center align-items-center flex-column "
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div
                className="filter_container "
                style={{ position: "relative", zIndex: 1 }}
              >
                <div className="m-3 ">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="m-0 Filter_text ">Invoice Details</p>
                    <CloseIcon onClick={handleClose} />
                  </div>
                  <div className="loderimg">
                    <CircularProgress />
                  </div>
                  <iframe
                    style={{
                      position: "relative",
                      zIndex: 3,
                      height: "400px",
                      width: "100%",
                    }}
                    src={`${process.env.REACT_APP_BASE_URL}/view-file?file=${singleIMg}`}
                  ></iframe>
                  <div
                    className="downloadme mt-2"
                    onClick={() =>
                      saveAs(
                        `${process.env.REACT_APP_BASE_URL}/view-file?file=${singleIMg}`,
                        `${singleIMg?.split("/").at(-1).split(".").at(-2)}.pdf`
                      )
                    }
                  >
                    <div className="d-flex align-items-center">
                      <SaveAltIcon
                        fontSize="small"
                        className="me-2 text-black"
                      />
                      <p className="m-0 text-black">Download</p>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default Requests;
