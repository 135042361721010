import { useFormik } from "formik";
import React from "react";
import Select from "react-select";
import EditIcons from "../../../../Assets/Img/EditIcons.svg";
import * as yup from "yup";
import { useEffect } from "react";
import { useState } from "react";
import axiosClientInterceptors from "../../../../ReusableComponent/axiosClentInterceptors";
import { toast } from "react-toastify";
import moment from "moment";
const PersonalInfo = ({
  vendordetailsID,
  personalinfoEdit,
  setpersonalinfoEdit,
  materialCatalog,
  vendorData,
  getVEndordata,
  registered_Vendor,
  userStatus,
  //
  dontShowEdit,
}) => {
  const [selectDAta, setselectDAta] = useState([]);
  let initialValues = vendorData?.registered_Vendor;
  const [Checkbox, setCheckbox] = useState(registered_Vendor);
  const [value, setvalue] = useState("");
  const handleChangeSelect = (data) => {
    setselectDAta(data);
  };
  // formik validation
  const validationSchema = yup.object().shape({
    vendor_name: yup.string().required("required field"),
    company_name: yup.string().required("required field"),
    contact_no: yup
      .string()
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Please enter valid Number"
      )
      .required("required field"),
    alternative_contact_no: yup
      .string()
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Please enter valid Number"
      ),

    email: yup.string().required("required field").email("invalid"),
    address: yup.string().required("required field"),
  });
  const formik = useFormik({
    initialValues: {
      vendor_id: vendorData?.vendor_id,
      vendor_name: vendorData?.vendor_name,
      company_name: vendorData?.company_name,
      contact_no: vendorData?.contact_no,
      alternative_contact_no: vendorData?.alternative_contact_no,
      email: vendorData?.email,
      gst_number: vendorData?.gst_number,
      address: vendorData?.address,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        vendor_id: values.vendor_id,
        vendor_name: values.vendor_name,
        company_name: values.company_name,
        contact_no: values.contact_no,
        alternative_contact_no: values.alternative_contact_no,
        email: values.email,
        gst_number: values.gst_number,
        address: values.address,
        category:
          selectDAta.length === 0
            ? vendorData.category?.map((val, index) => {
                return val._id;
              })
            : selectDAta.map((val, index) => {
                return val._id;
              }),
      };

      axiosClientInterceptors
        .post(`/updatevendor?_id=${vendordetailsID}`, data)
        .then(function (response) {
          if (response.data.status === "success") {
            toast.success(`${response.data.msg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setpersonalinfoEdit(false);
            getVEndordata();
          } else {
            toast.error(`${response.data.msg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (registered_Vendor) {
      setvalue("YES");
    } else {
      setvalue("NO");
    }
  }, [value]);
  useEffect(() => {
    if (registered_Vendor) {
      setvalue("YES");
    } else {
      setvalue("NO");
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className="PersonalInfo">
      <div className="d-flex justify-content-between align-items-center">
        {" "}
        <div
          className="d-flex justify-content-start align-items-center"
          style={{ gap: "10px" }}
        >
          <p className="PersonalInfo_title">Personal Info</p>
          <div>
            {userStatus === "active" ? (
              <div className="user_active" style={{ padding: "5px 12px" }}>
                Active
              </div>
            ) : userStatus === "inactive" ? (
              <div className="user_inactive">Inactive</div>
            ) : (
              "-"
            )}
          </div>
        </div>
        {personalinfoEdit ? (
          <button
            type="submit"
            className=""
            style={{
              color: "#fcc200",
              background: "transparent",
              fontFamily: "Gm",
            }}
            onClick={() => {}}
          >
            Save
          </button>
        ) : (
          <>
            {dontShowEdit === false && (
              <img
                src={EditIcons}
                className="new_edit"
                onClick={() => setpersonalinfoEdit(true)}
              />
            )}
          </>
        )}
      </div>
      <hr />
      <div class="parentPersonalInfo">
        <div class="parentPersonalInfo1">
          <p className="parentPersonalInfo1_text">Vendor ID</p>
          <p className="parentPersonalInfo1_subtext">{vendorData?.vendor_id}</p>
        </div>

        <div class="parentPersonalInfo2">
          {" "}
          <p className="parentPersonalInfo1_text">Vendor Name</p>
          {personalinfoEdit ? (
            <input
              type="text"
              name="vendor_name"
              placeholder="eg. XYZ"
              className="mt-2 AddvendorInput"
              defaultValue={vendorData?.vendor_name}
              value={formik.values.vendor_name}
              onChange={formik.handleChange}
            />
          ) : (
            <p className="parentPersonalInfo1_subtext">
              {" "}
              {vendorData?.vendor_name}
            </p>
          )}
          {formik.touched.vendor_name && formik.errors.vendor_name && (
            <p className="errorRequest mt-1">{formik.errors.vendor_name}</p>
          )}
        </div>

        <div class="parentPersonalInfo3">
          {" "}
          <p className="parentPersonalInfo1_text">Company Name</p>
          {personalinfoEdit ? (
            <input
              type="text"
              placeholder="eg. Jaguar"
              name="company_name"
              className="mt-2 AddvendorInput"
              defaultValue={vendorData?.company_name}
              value={formik.values.company_name}
              onChange={formik.handleChange}
            />
          ) : (
            <p className="parentPersonalInfo1_subtext">
              {" "}
              {vendorData?.company_name}
            </p>
          )}
          {formik.touched.company_name && formik.errors.company_name && (
            <p className="errorRequest mt-1">{formik.errors.company_name}</p>
          )}
        </div>
        <div class="parentPersonalInfo4">
          {" "}
          <p className="parentPersonalInfo1_text">Mobile Number</p>
          {personalinfoEdit ? (
            <input
              type="number"
              placeholder="eg. 8328XXXXXX"
              name="contact_no"
              maxlength="10"
              className="mt-2 AddvendorInput"
              defaultValue={vendorData?.contact_no}
              value={formik.values.contact_no}
              onChange={formik.handleChange}
            />
          ) : (
            <p className="parentPersonalInfo1_subtext">
              {" "}
              +91{vendorData?.contact_no}
            </p>
          )}
          {formik.touched.contact_no && formik.errors.contact_no && (
            <p className="errorRequest mt-1">{formik.errors.contact_no}</p>
          )}
        </div>
        <div class="parentPersonalInfo5">
          {" "}
          <p className="parentPersonalInfo1_text">Alternative Mobile Number</p>
          {personalinfoEdit ? (
            <input
              type="number"
              placeholder="eg. 8328XXXXXX"
              maxlength="10"
              name="alternative_contact_no"
              className="mt-2 AddvendorInput"
              defaultValue={vendorData?.alternative_contact_no}
              value={formik.values.alternative_contact_no}
              onChange={formik.handleChange}
            />
          ) : (
            <p className="parentPersonalInfo1_subtext">
              +91{vendorData?.alternative_contact_no}
            </p>
          )}
          {formik.touched.alternative_contact_no &&
            formik.errors.alternative_contact_no && (
              <p className="errorRequest mt-1">
                {formik.errors.alternative_contact_no}
              </p>
            )}
        </div>
      </div>
      {/*  */}
      <div class="parentPersonalInfosecond mt-4">
        <div class="parentPersonalInfosecond1">
          {" "}
          <p className="parentPersonalInfo1_text">Email</p>
          {personalinfoEdit ? (
            <input
              type="text"
              placeholder="eg. sample@gmail.com"
              name="email"
              className="mt-2 AddvendorInput"
              defaultValue={vendorData?.email}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          ) : (
            <p className="parentPersonalInfo1_subtext">{vendorData?.email}</p>
          )}
          {formik.touched.email && formik.errors.email && (
            <p className="errorRequest mt-1">{formik.errors.email}</p>
          )}
        </div>
        <div class="parentPersonalInfosecond2">
          {" "}
          <div className="d-flex justify-content-between  align-items-center">
            <p className="titleInput">GST</p>
          </div>
          {personalinfoEdit ? (
            <input
              type="text"
              placeholder="eg. 1425875XXXX"
              name="gst_number"
              className="mt-2 AddvendorInput"
              defaultValue={vendorData?.gst_number}
              value={formik.values.gst_number}
              onChange={formik.handleChange}
            />
          ) : (
            <p className="parentPersonalInfo1_subtext mt-2">
              {vendorData?.gst_number || "-"}
            </p>
          )}
        </div>
        <div class="parentPersonalInfosecond3" style={{ width: "" }}>
          {" "}
          <p className="parentPersonalInfo1_text">Material Category</p>
          {personalinfoEdit ? (
            <div
              className="mt-2"
              style={{ border: "0.959133px solid #d0d0d0" }}
            >
              <Select
                name="Material_Category"
                options={materialCatalog}
                defaultValue={vendorData?.category?.map((val, index) => {
                  return {
                    ["value"]: val?.category_name,
                    ["label"]: val?.category_name,
                    ["_id"]: val?._id,
                  };
                })}
                // name="Material_Category"
                // value={multiselect}
                onChange={handleChangeSelect}
                isMulti
                className="selectMul1 mt-2"
              />
            </div>
          ) : (
            <p className="parentPersonalInfo1_subtext d-flex align-items-center ">
              {vendorData?.category?.map((val, i) => (
                <p className="matrialBox me-2 m-0">{val?.category_name}</p>
              ))}
            </p>
          )}
        </div>
        <div class="parentPersonalInfosecond4 mt-3">
          {" "}
          <p className="parentPersonalInfo1_text">Address</p>
          {personalinfoEdit ? (
            <input
              type="text"
              placeholder="eg. #54, old #28, new sri durgbhavani arcade,2nd floor 5th main MKK road maruthi extension bangalore"
              name="address"
              className="mt-2 AddvendorInput"
              defaultValue={vendorData?.address}
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          ) : (
            <p className="parentPersonalInfo1_subtext">{vendorData?.address}</p>
          )}
          {formik.touched.address && formik.errors.address && (
            <p className="errorRequest mt-1">{formik.errors.address}</p>
          )}
        </div>
        {!personalinfoEdit && (
          <div class="parentPersonalInfosecond5 mt-3">
            <p className="parentPersonalInfo1_text">Creation Date</p>
            <p className="parentPersonalInfo1_subtext">
              {moment(vendorData?.creationdate).format("DD/MM/YYYY")}
            </p>
          </div>
        )}
        {!personalinfoEdit && (
          <div class="parentPersonalInfosecond6 mt-3">
            <p className="parentPersonalInfo1_text">Updation Date</p>
            <p className="parentPersonalInfo1_subtext">
              {moment(vendorData?.updationdate).format("DD/MM/YYYY")}
            </p>
          </div>
        )}
      </div>
    </form>
  );
};

export default PersonalInfo;
