import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  AllRequestDateRange: "",
  AllRequestLastActivity: "",
  receivedFilter: "",
  allCloseCreationDate: "",
  allCloseLastActivityDate: "",
  AllprojectDaterange: "",
  POfilter: "",
  closedCreationDate: "",
  closeLastActivityDate: "",
  vendorDateRange: "",
};

export const DateRangeSlice = createSlice({
  name: "DateRangeSlice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setvendorDateRange: (state, action) => {
      state.vendorDateRange = action.payload;
    },
    setAllRequestDateRange: (state, action) => {
      state.AllRequestDateRange = action.payload;
    },
    setAllRequestLastActivity: (state, action) => {
      state.AllRequestLastActivity = action.payload;
    },
    setreceivedFilter: (state, action) => {
      state.receivedFilter = action.payload;
    },
    setallCloseCreationDate: (state, action) => {
      state.allCloseCreationDate = action.payload;
    },
    setallCloseLastActivityDate: (state, action) => {
      state.allCloseLastActivityDate = action.payload;
    },
    setAllprojectDaterange: (state, action) => {
      state.AllprojectDaterange = action.payload;
    },
    setPOfilter: (state, action) => {
      state.POfilter = action.payload;
    },
    setclosedCreationDate: (state, action) => {
      state.closedCreationDate = action.payload;
    },
    setcloseLastActivityDate: (state, action) => {
      state.closeLastActivityDate = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});

export const {
  setreceivedFilter,
  setAllRequestDateRange,
  setAllRequestLastActivity,
  setallCloseCreationDate,
  setallCloseLastActivityDate,
  setAllprojectDaterange,
  setPOfilter,
  setclosedCreationDate,
  setcloseLastActivityDate,
  setvendorDateRange,
} = DateRangeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectAllRequestDateRange = (state) =>
  state.DateRangeSlice.AllRequestDateRange;
export const selectAllRequestLastActivity = (state) =>
  state.DateRangeSlice.AllRequestLastActivity;
export const selectreceivedFilter = (state) =>
  state.DateRangeSlice.receivedFilter;
export const selecallCloseCreationDate = (state) =>
  state.DateRangeSlice.allCloseCreationDate;
export const selecallCloseLastActivityDate = (state) =>
  state.DateRangeSlice.allCloseLastActivityDate;
export const selecAllprojectDaterange = (state) =>
  state.DateRangeSlice.AllprojectDaterange;
export const selecPOfilter = (state) => state.DateRangeSlice.POfilter;
export const selecclosedCreationDate = (state) =>
  state.DateRangeSlice.closedCreationDate;
export const seleccloseLastActivityDate = (state) =>
  state.DateRangeSlice.closeLastActivityDate;
export const selecvendorDateRange = (state) =>
  state.DateRangeSlice.vendorDateRange;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default DateRangeSlice.reducer;
