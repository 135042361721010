import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    POstartDate: null,
    POendDate: null,
    POdateRange: null,
    POprojectName: [],
    POstatus: ""
}

export const purchaseOrderSlice = createSlice({
    name: "purchaseOrder",
    initialState,
    reducers: {
        setPOstartDate: (state, action) => {
            state.POstartDate = action.payload;
        },
        setPOendDate: (state, action) => {
            state.POendDate = action.payload;
        },
        setPOdateRange: (state, action) => {
            state.POdateRange = action.payload
        },
        setPOprojectName: (state, action) => {
            state.POprojectName = action.payload
        },
        setPOstatus: (state, action) => {
            state.POstatus = action.payload
        }
    }
})

export const {
    setPOstartDate,
    setPOendDate,
    setPOdateRange,
    setPOprojectName,
    setPOstatus
} = purchaseOrderSlice.actions

export const selectPOstartDate = (state) => state.purchaseOrder.POstartDate;
export const selectPOendDate = (state) => state.purchaseOrder.POendDate;
export const selectPOdateRange = (state) => state.purchaseOrder.POdateRange;
export const selectPOprojectName = (state) => state.purchaseOrder.POprojectName;
export const selectPOstatus = (state) => state.purchaseOrder.POstatus;


export default purchaseOrderSlice.reducer;