import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Formik, Form, Field } from "formik";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import {
  validateInvoiceNumber,
  validatePurchaseOrderNo,
  validateAmount,
} from "../../../ReusableComponent/Validation";
import { selectallProjectIndivusal } from "../../../features/counter/counterSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";

const AddInvoice = ({ handleClose1, POdropdownList, getinvoice }) => {
  const project_id = useSelector(selectallProjectIndivusal);
  const [fileName, setFileName] = useState("");
  const [invoiceImg, setInvoiceImg] = useState(null);
  const [loader, setloader] = useState(false);
  const handleChange = (e) => {
    setFileName(e.target.value.replace(/^.*[\\\/]/, ""));
    setInvoiceImg(e.target.files[0]);
  };

  function validatePhoto(value) {
    let error;
    if (invoiceImg === null) {
      error = "Invoice Image is Required";
    }
    return error;
  }

  return (
    <Formik
      initialValues={{
        project_id: "",
        invoice_number: "",
        due_date: "",
        purchase_ord_number: "",
        total_amt: "",
        invoice: "",
      }}
      onSubmit={(values) => {
        var formdata = new FormData();
        formdata.append("project_id", project_id.project_id);
        formdata.append("invoice_number", values.invoice_number);
        formdata.append(
          "due_date",
          moment(values.due_date, "yyyy-mm-dd").format("DD/MM/YYYY")
        );
        formdata.append("purchase_ord_number", values.purchase_ord_number);
        formdata.append("total_amt", values.total_amt);
        formdata.append("invoice", invoiceImg);
        const headers = {
          "content-type": "multipart/form-data",
        };
        setloader(true);
        axiosClientInterceptors
          .post(`/addinvoice`, formdata, {
            headers,
          })
          .then((response) => {
            let Status = response.data.status;
            if (Status === "success") {
              toast.success("Invoice Uploaded Successfully!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              handleClose1();
              getinvoice();
            } else {
              toast.error("Something Went Wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            setloader(false);
          })
          .catch((error) =>
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          );
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="m-3  ">
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 Filter_text ">Add Invoice</p>
              <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose1} />
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <p className="Package_title">Invoice Number</p>
                <Field
                  type="text"
                  name="invoice_number"
                  className="w-100  InvoiceNumber_input"
                  placeholder="eg: IN001"
                  validate={validateInvoiceNumber}
                />
                {errors.invoice_number && touched.invoice_number && (
                  <p className="error">{errors.invoice_number}</p>
                )}
              </div>

              <div className="col-12 mt-2">
                <p className=" Package_title mt-2">Purchase Order Number</p>
                <Field
                  as="select"
                  name="purchase_ord_number"
                  class="form-select"
                  aria-label="Default select example"
                  style={{
                    backgroundColor: "#f8f8f8",
                    border: "none",
                    padding: "15px 32px 14px 13px",
                  }}
                  validate={validatePurchaseOrderNo}
                >
                  <option value="" selected disabled>
                    Select Purchase Order
                  </option>
                  {POdropdownList.map((val, i) => (
                    <option key={i} value={val.purchase_order_no}>
                      {val.purchase_order_no}
                    </option>
                  ))}
                </Field>
                {errors.purchase_ord_number && touched.purchase_ord_number && (
                  <p className="error">{errors.purchase_ord_number}</p>
                )}
              </div>
              <div className="col-12 mt-2">
                <p className="Package_title mt-2">Total Amount</p>
                <Field
                  type="tel"
                  name="total_amt"
                  className="w-100 InvoiceNumber_input"
                  placeholder="Enter the Total Amount"
                  validate={validateAmount}
                />
                {errors.total_amt && touched.total_amt && (
                  <p className="error">{errors.total_amt}</p>
                )}
              </div>
              <div className="col-12 mt-2">
                <p className="Package_title mt-2">Upload Invoice</p>

                <div className="file-input " style={{ background: "#F8F8F8" }}>
                  {/* <input type="file" onChange={handleChange} /> */}
                  <Field
                    type="File"
                    name="invoice"
                    validate={validatePhoto}
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="button">
                    <DriveFolderUploadIcon style={{ color: "#FFE185" }} />
                  </span>
                  <span>
                    {fileName.length > 2 ? (
                      <span>{fileName}</span>
                    ) : (
                      <span>No file selected</span>
                    )}{" "}
                  </span>
                </div>
                {errors.invoice && touched.invoice && (
                  <div className="error">{errors.invoice}</div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <button type="submit" className="w-100 Allfilter">
                {loader ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  " Upload Invoice"
                )}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddInvoice;
