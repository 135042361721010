import React from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { DocumentViewer } from "react-documents";
import saveAs from "file-saver";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useDispatch, useSelector } from "react-redux";
import { selectallInvoiceImg } from "../../../features/counter/counterSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { setProjectScreen } from "../../../features/Screen/screen";

const Viewinvoice = ({ setsingleView }) => {
  const dispatch = useDispatch();
  const [singleIMg, setsingleIMg] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dataImg = useSelector(selectallInvoiceImg);

  return (
    <>
      <div className="p-4">
        <div className="d-flex   justify-content-between align-items-center ">
          <p className="m-0">
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setProjectScreen("allusers"));
                setsingleView("allusers");
              }}
            >
              All Projects
            </span>
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setProjectScreen("singleuser"));
                setsingleView("singleuser");
              }}
            >
              Project Details
            </span>
            /
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setProjectScreen("AllInvoice"));
                setsingleView("AllInvoice");
              }}
            >
              All Invoices
            </span>
            / <span className="fontdocument_active">Invoice Details</span>
          </p>
        </div>
        <hr />
        <div className="wrap_img">
          <div className="row">
            {dataImg?.map((val, i) => (
              <div className="col-md-3" key={i}>
                <img
                  className="imgg mt-3"
                  onClick={() => {
                    handleOpen();
                    setsingleIMg(val);
                  }}
                  src={`${process.env.REACT_APP_IMG_URL}/${val}`}
                />
              </div>
            ))}
          </div>
          <Modal
            open={open}
            style={{ background: "rgba(34, 30, 31, 0.9)" }}
            className="d-flex justify-content-center align-items-center flex-column "
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="filter_container " style={{ width: "80%" }}>
              <div
                className="m-3 "
                style={{
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p className="m-0 Filter_text ">Invoice Details</p>
                  <CloseIcon onClick={handleClose} />
                </div>
                <div className="loderimg">
                  <CircularProgress />
                </div>
                <iframe
                  style={{
                    position: "relative",
                    zIndex: 3,
                    height: "400px",
                    width: "100%",
                  }}
                  src={`${process.env.REACT_APP_BASE_URL}/view-file?file=${singleIMg}`}
                ></iframe>

                <div
                  className="downloadme mt-2"
                  onClick={() =>
                    saveAs(
                      `${process.env.REACT_APP_BASE_URL}/view-file?file=${singleIMg}`,
                      `${singleIMg.split("/").at(-1).split(".").at(-2)}.pdf`
                    )
                  }
                >
                  <div className="d-flex align-items-center">
                    <SaveAltIcon fontSize="small" className="me-2 text-black" />
                    <p className="m-0 text-black">Download</p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Viewinvoice;
