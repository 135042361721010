import React, { useCallback, useEffect } from "react";
import "./request.css";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  selectAllListmanagers,
  selectactivetab,
  selectallMaterialCategory,
  selectallProjects,
  selectrequestIdFRomProject,
  setallProjects,
} from "../../../features/counter/counterSlice";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { toast } from "react-toastify";
import { setallprojectpagination } from "../../../features/pagination/paginationSlice";
import FilterResults from "react-filter-search";
import moment from "moment";

const AddRequestAdmin = ({
  setaddRequestSwitch,
  // getcountforinnerreq,
  // SocketCall,
  // SocketCallData,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [counterCall, setCounterCall] = useState(0);
  const [listOfProjects, setListofProjects] = useState([]);
  const projectName = useSelector(selectallProjects);
  const Listmanagers = useSelector(selectAllListmanagers);
  const idvID = useSelector(selectrequestIdFRomProject);
  const switchProjectList = useSelector(selectactivetab);
  const MaterialCategory = useSelector(selectallMaterialCategory);
  const [projectSelected, setProjectSelected] = useState("");
  const [materialCatOptions, setMaterialCatOptions] = useState([]);
  const [requestAPI_Data, setrequestAPI_Data] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [tempcheckboxes, setTempCheckboxes] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [updateArray, setUpdateArray] = useState(false);
  const [search, setsearch] = useState("");
  const [dataDisable, setdataDisable] = useState([]);
  let arrayApi = [];

  const handleCheckboxClick = (material_specification) => {
    const newCheckboxes = tempcheckboxes.map((checkbox) => {
      if (checkbox.material_specification === material_specification) {
        checkbox.checked = !checkbox.checked;
        if (!checkbox.checked) {
          checkbox.units = null;
          checkbox.quantity = null;
        }
        checkbox.dropdownOpen = checkbox.checked;
      }
      return checkbox;
    });
    setTempCheckboxes(newCheckboxes);
  };

  const handleDropdownChange = (material_specification, event, arr) => {
    update_Array(material_specification, event.target.value, null, arr);
  };

  const update_Array = (
    material_specification,
    units = null,
    quantity = null,
    arr
  ) => {
    for (let item of arr) {
      if (item.material_specification == material_specification) {
        if (units != null) {
          item.units = units;
        }
        if (quantity != null) {
          item.quantity = Number(quantity);
        }
      }
    }
    setTempCheckboxes(arr);
  };

  const handleCounterChange = (material_specification, e, arr) => {
    update_Array(material_specification, null, e.target.value, arr);
  };

  //   formik
  const validationSchema = yup.object({
    material_description: yup.string().required(),
    purpose: yup.string().required(),
    due_date: yup.string().required(),
    priority: yup.string().required(),
    // material_category: yup.string().required(),
    // approved_by: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      project_name: projectSelected,
      project_stage: requestAPI_Data?.project_stage,
      project_id: requestAPI_Data?.project_id,
      site_engineer: requestAPI_Data?.site_engineer_name,
      material_description: "",
      purpose: "",
      due_date: "",
      priority: "",
      material_category: "",
      approved_by: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      let data = {
        project_name: requestAPI_Data?.project_name,
        project_stage: requestAPI_Data?.project_stage,
        project_id: requestAPI_Data?.project_id,
        site_engineer: requestAPI_Data?.site_engineer,
        material_description: values.material_description,
        purpose: values.purpose,
        due_date: moment(values.due_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
        priority: values.priority,
        material_category: categorySelected,
        approved_by: requestAPI_Data?.project_manager,
        materials: dataDisable,
      };

      axiosClientInterceptors
        .post(`/addrequests`, data)
        .then(function (response) {
          dispatch(setallprojectpagination(1));
          // getcountforinnerreq();
          setaddRequestSwitch(false);

          if (response.data.status === "success") {
            toast.success(`${response.data.msg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // SocketCall(
            //   "admin-notification-count",
            //   requestAPI_Data?.site_engineer,
            //   values.approved_by,
            //   "New Request"
            // );
            // SocketCall(
            //   "pm-notification-count",
            //   requestAPI_Data?.site_engineer,
            //   values.approved_by,
            //   "New Request"
            // );
            // SocketCall(
            //   "pd-notification-count",
            //   requestAPI_Data?.site_engineer,
            //   values.approved_by,
            //   "New Request"
            // );
            // SocketCallData(
            //   "admin-get-notification",
            //   response.data.result.request_id,
            //   "New Request"
            // );
            // SocketCallData(
            //   "pm-get-notification",
            //   response.data.result.request_id,
            //   "New Request"
            // );
            // SocketCallData(
            //   "pd-get-notification",
            //   response.data.result.request_id,
            //   "New Request"
            // );
          }
        });
    },
  });

  const CounterComponent = useCallback(
    ({ quantity, UOMname, arr }) => {
      return (
        <input
          type="text"
          className="value"
          defaultValue={quantity || 0}
          onChange={(e) => {
            e.target.value != undefined && handleCounterChange(UOMname, e, arr);
            setCounterCall(counterCall);
          }}
          onKeyDown={(event) => {
            const pattern = /^\d{1,4}(\.\d{0,4})?$/;

            // Get the current input value
            const currentValue = event.target.value;
            if(event.keyCode === 8 || event.keyCode ===37 || event.keyCode===39 ){
              return
            }
            // Get the key that the user is trying to input
            const key = event.key;
            // Construct the new value by appending the key to the current value
            const newValue = currentValue + key;
            // Check if the new value matches the pattern
            if (!pattern.test(newValue)) {
              event.preventDefault(); // Prevent the invalid input
            }
          }}
        />
      );
    },
    [counterCall]
  );
  const RenderTableRows = useCallback(() => {
    return dataDisable.map((val, index) => (
      <tr key={val._id} style={{ padding: "10px 0px", marginTop: "10px" }}>
        <td>{index + 1}.</td>
        <td className="MaterialName_text">{val.material_name}</td>
        <td className="MaterialName_text">{val.uom}</td>
        <td className="MaterialName_text">{val.quantity}</td>
      </tr>
    ));
  }, [dataDisable]);

  const handleTypeSelect = (e) => {
    setProjectSelected(e.value);
    // setCategoryname(e.value);
  };

  useEffect(() => {
    arrayApi = [];
    checkboxes.map((val) => {
      if (val.units != null && val.quantity != "") {
        arrayApi.push({
          ["material_name"]: val.material_specification,
          ["quantity"]: val.quantity,
          ["uom"]: val.units,
          ["_id"]: val._id,
        });
      }
    });

    setdataDisable(arrayApi);
    setUpdateArray(false);
  }, [checkboxes, updateArray]);

  // useEffect(() => {
  //   if (projectSelected.length > 2) {
  //     axiosClientInterceptors
  //       .get(`/getprojectbyid?_id=${projectSelected}`)
  //       .then((result) => {
  //         setrequestAPI_Data(result.data.result[0]);
  //       });
  //   }
  // }, [projectSelected]);

  const getAllProjectList = () => {
    if (projectSelected.length > 2) {
      axiosClientInterceptors
        .get(`/getprojectbyid?_id=${projectSelected}`)
        .then((result) => {
          setrequestAPI_Data(result.data.result[0]);
        });
    }
  };

  const getIdvProjectDetails = () => {
    axiosClientInterceptors
      .get(`/getprojectbyid?_id=${idvID}`)
      .then((result) => {
        setrequestAPI_Data(result.data.result[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   switchProjectList === "All Porojects" && getIdvProjectDetails();
  // }, [])

  useEffect(() => {
    switchProjectList == "Requests"
      ? getAllProjectList()
      : getIdvProjectDetails();
  }, [projectSelected]);

  const searchApi = () => {
    axiosClientInterceptors
      .get(`/get-material-specs?category=${categorySelected}&search=`)
      .then((result) => {
        let data = result.data.result.map((v, ind) => ({
          ...v,
          checked: false,
          dropdownOpen: false,
          quantity: "",
        }));
        setdataDisable([]);
        setTempCheckboxes(data);
      });
  };

  const handleTypeSelectCat = (e) => {
    setCategorySelected(e.value);
    // setCategoryname(e.value);
  };

  const getAllCategories = () => {
    // setMaterialCatOptions(MaterialCategory);
    setMaterialCatOptions(
      MaterialCategory?.map((val, index) => {
        return {
          ...val,
          ["value"]: val._id,
          ["label"]: val.category_name,
        };
      })
    );
    console.log("AKS", materialCatOptions);
  };

  useEffect(() => {
    searchApi();
  }, [categorySelected]);

  useEffect(() => {
    getAllCategories();
    axiosClientInterceptors.get(`/get-all-project`).then((result) => {
      dispatch(setallProjects(result.data?.result));
      setListofProjects(
        result.data?.result.map((val, index) => {
          return {
            ...val,
            ["value"]: val._id,
            ["label"]: val.project_name,
          };
        })
      );
    });
  }, []);

  return (
    <div>
      <>
        <p className="mb-2 RequestedCreating">
          Request Creating by{" "}
          <span style={{ color: "black" }}>
            {localStorage.getItem("role") === "Procurement Department"
              ? localStorage.getItem("name")
              : localStorage.getItem("name")}
          </span>
        </p>
        <form onSubmit={formik.handleSubmit}>
          <div
            className="addrequest_container"
            style={{
              height: "calc(90vh - 150px)",
              overflowY: "scroll",
            }}
          >
            <div>
              <div class="parent">
                <div class="div1">
                  <p className=" mb-1 addrequest_title">Project</p>
                  {/* <select
                    className="w-100 addrequestInputtag"
                    name=""
                    onChange={(e) => {
                      setprojectSelected(e.target.value);
                    }}
                  >
                    <option selected value="" disabled>
                      Choose Project
                    </option>
                    {projectName?.map((ki, index) => {
                      return (
                        <option
                          key={ki._id}
                          value={ki._id}
                          name={ki.project_name}
                        >
                          {ki.project_name}
                        </option>
                      );
                    })}
                  </select> */}

                  {switchProjectList === "All Projects" ? (
                    <div className="noneditable_request">
                      {requestAPI_Data?.project_name}
                    </div>
                  ) : (
                    <Select
                      placeholder="Select Project"
                      options={listOfProjects}
                      onChange={handleTypeSelect}
                      value={listOfProjects.filter(function (option) {
                        return option.value === projectSelected;
                      })}
                      style={{ fontFamily: "Gsb" }}
                      label="Single select"
                    />
                  )}
                  {/* <div className="noneditable_request">
                    {requestAPI_Data?.project_name}
                  </div>
                  {/* <Select
                    placeholder="Select Project"
                    options={listOfProjects}
                    onChange={handleTypeSelect}
                    value={listOfProjects.filter(function (option) {
                      return option.value === projectSelected;
                    })}
                    style={{ fontFamily: "Gsb" }}
                    label="Single select"
                  /> */}
                </div>
                <div class="div2">
                  <p className=" mb-1 addrequest_title">Project Stage</p>
                  <div className="noneditable_request">
                    {requestAPI_Data?.project_stage || "-"}
                  </div>
                </div>
                <div class="div3">
                  <p className=" mb-1 addrequest_title">Project Id</p>
                  <div className="noneditable_request">
                    {requestAPI_Data?.project_id || "-"}
                  </div>
                </div>
                <div class="div4">
                  <p className=" mb-1 addrequest_title">Material Category</p>
                  {/* <select
                    name="material_category"
                    value={formik.values.material_category}
                    onChange={formik.handleChange}
                    className="w-100 addrequestInputtag"
                  >
                    <option selected value="" disabled>
                      Select Category
                    </option>
                    {MaterialCategory?.map((val, index) => (
                      <option key={index} value={val._id}>
                        {val.category_name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.material_category &&
                    formik.errors.material_category && (
                      <p className="errorRequest mt-1">
                        Material Category required
                      </p>
                    )} */}
                  <Select
                    placeholder="Select Category"
                    className="nete"
                    options={materialCatOptions}
                    onChange={handleTypeSelectCat}
                    value={materialCatOptions.filter(function (option) {
                      return option.value === categorySelected;
                    })}
                    style={{ fontFamily: "Gsb" }}
                    label="Single select"
                  />
                </div>
              </div>
            </div>
            <div className="row_request w-100 " style={{ marginTop: "24px" }}>
              <table className="w-100">
                <tr
                  style={{
                    borderBottom: "1px solid #E4E4E4",
                    padding: "10px 0px",
                  }}
                >
                  <th className="MaterialName_subtext">Sl.No</th>
                  <th className="MaterialName_subtext">Material Name</th>
                  <th className="MaterialName_subtext">Unit Of Measure</th>
                  <th className="MaterialName_subtext">Qty</th>
                </tr>

                <RenderTableRows />

                {dataDisable.length == 0 && (
                  <p className="noteMaterialCategory mt-3">
                    Note : Select Material Category
                  </p>
                )}
              </table>
              <hr style={{ height: "0.5px" }} />
              <button
                className="EditMaterials"
                type="button"
                disabled={categorySelected.length === 0}
                onClick={() => handleOpen()}
              >
                <AddIcon fontSize="small" style={{ color: "#FCC200" }} />
                Add/ Edit Materials
              </button>
            </div>
            <div className="row " style={{ marginTop: "24px" }}>
              <div className="col-md-6">
                <p className=" mb-1 addrequest_title">Extra Description</p>
                <input
                  type="text"
                  value={formik.values.material_description}
                  onChange={formik.handleChange}
                  name="material_description"
                  className="w-100 addrequestInputtag"
                  placeholder="eg. plumbing items"
                />
                {formik.touched.material_description &&
                  formik.errors.material_description && (
                    <p className="errorRequest mt-1">
                      Extra Description required
                    </p>
                  )}
              </div>
              <div className="col-md-6">
                <p className=" mb-1 addrequest_title">Purpose</p>
                <input
                  type="text"
                  name="purpose"
                  value={formik.values.purpose}
                  onChange={formik.handleChange}
                  className="w-100 addrequestInputtag"
                  placeholder="eg. for plumbing work"
                />
                {formik.touched.purpose && formik.errors.purpose && (
                  <p className="errorRequest mt-1">purpose required</p>
                )}
              </div>
            </div>
            {/* end row */}
            <div class="parent " style={{ marginTop: "24px" }}>
              <div class="div1">
                <p className=" mb-1 addrequest_title">Due Date</p>
                <input
                  type="date"
                  value={formik.values.due_date}
                  onChange={formik.handleChange}
                  name="due_date"
                  min={moment().format("YYYY-MM-DD")}
                  className="w-100 addrequestInputtag"
                  placeholder="eg. plumbing items"
                />
                {formik.touched.due_date && formik.errors.due_date && (
                  <p className="errorRequest mt-1">Due date required</p>
                )}
              </div>
              <div class="div2">
                <p className=" mb-1 addrequest_title">Priority</p>
                <select
                  value={formik.values.priority}
                  onChange={formik.handleChange}
                  name="priority"
                  className="w-100 addrequestInputtag"
                >
                  <option selected disabled value="">
                    Choose Priority
                  </option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
                {formik.touched.priority && formik.errors.priority && (
                  <p className="errorRequest mt-1">Priority required</p>
                )}
              </div>
              <div class="div3">
                <p className=" mb-1 addrequest_title">Site engineer</p>
                <div className="noneditable_request">
                  {requestAPI_Data?.site_engineer_name || "-"}
                </div>
              </div>
              <div class="div4">
                <p className=" mb-1 addrequest_title">Approver</p>
                <div className="noneditable_request">
                  {requestAPI_Data?.project_manager_name || "-"}
                </div>
              </div>
              {/* <div class="div4">
                <p className=" mb-1 addrequest_title">Approver</p>
                <select
                  name="approved_by"
                  value={formik.values.approved_by}
                  onChange={formik.handleChange}
                  className="w-100 addrequestInputtag"
                >
                  <option value="" disabled selected>
                    Select Approver
                  </option>
                  {Listmanagers?.map((val, i) => (
                    <option key={val._id} value={val._id}>
                      {val.name}
                    </option>
                  ))}
                </select>
                {formik.touched.approved_by && formik.errors.approved_by && (
                  <p className="errorRequest mt-1">Approver required</p>
                )}
              </div> */}
            </div>
          </div>
          {/* <button
            type="submit"
            className="addrequest_btn"
            disabled={dataDisable.length == 0 || projectSelected === ""}
          >
            Submit
          </button> */}
          {switchProjectList === "All Projects" ? (
            <button
              type="submit"
              className="addrequest_btn"
              disabled={dataDisable.length == 0}
            >
              Submit
            </button>
          ) : (
            <button
              type="submit"
              className="addrequest_btn"
              disabled={dataDisable.length == 0 || projectSelected === ""}
            >
              Submit
            </button>
          )}
        </form>
        {/* modal start */}
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ background: "rgba(34, 30, 31, 0.9)" }}
          className="d-flex justify-content-center align-items-center flex-column w-100 h-100 "
        >
          <div className="ChooseMaterials p-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="ChooseMaterials_title">Choose Materials</p>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
            <div className="search_container">
              <div class="search">
                <input
                  type="text"
                  value={search}
                  class="search__input"
                  onChange={(e) => setsearch(e.target.value)}
                  placeholder="Search by material name"
                />
                <button class="search__button">
                  <svg
                    class="search__icon"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div
              className="mt-3"
              style={{ height: "410px", overflowY: "scroll" }}
            >
              {" "}
              <FilterResults
                value={search}
                data={tempcheckboxes}
                renderResults={(results) => (
                  <>
                    {results.map((checkbox, i, arr) => (
                      <>
                        <div
                          className="d-flex  align-items-center mt-2"
                          key={i}
                        >
                          <input
                            type="checkbox"
                            className="me-2"
                            checked={checkbox.checked}
                            onClick={() =>
                              handleCheckboxClick(
                                checkbox.material_specification
                              )
                            }
                          />
                          <p className="checkbox_title mb-0">
                            {checkbox.material_specification}
                          </p>
                        </div>
                        {checkbox.checked && (
                          <div className="content_mm_container ">
                            <div className="row">
                              <div className="col-md-6">
                                <p className=" mb-1 addrequest_title">
                                  Unit Of Measure
                                </p>
                                <select
                                  onChange={(e) => {
                                    handleDropdownChange(
                                      checkbox.material_specification,
                                      e,
                                      tempcheckboxes
                                    );
                                  }}
                                  name="cars"
                                  id="cars"
                                  defaultValue={checkbox.units || ""}
                                  className="w-100 addrequestInputtag"
                                >
                                  <option
                                    value="volvo"
                                    className="addrequestInputtag_drop"
                                  >
                                    Select UOM
                                  </option>
                                  {checkbox.uom.map((val, i) => (
                                    <option
                                      key={i}
                                      value={val}
                                      className="addrequestInputtag_drop"
                                    >
                                      {val}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-6">
                                <p className=" mb-1 addrequest_title">
                                  Quantity
                                </p>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ height: "41px" }}
                                >
                                  <button
                                    // disabled={checkbox.units ? false : true}
                                    className="sub"
                                    onClick={() => {
                                      if (checkbox.quantity <= 0) {
                                        checkbox.quantity = 0;
                                      } else {
                                        checkbox.quantity--;
                                      }

                                      setCounterCall(counterCall - 1);
                                    }}
                                  >
                                    <RemoveIcon />
                                  </button>

                                  <CounterComponent
                                    quantity={checkbox.quantity}
                                    UOMname={checkbox.material_specification}
                                    arr={tempcheckboxes}
                                  />
                                  <button
                                    className="add"
                                    // disabled={checkbox.units ? false : true}
                                    onClick={() => {
                                      checkbox.quantity++;
                                      setCounterCall(counterCall + 1);
                                    }}
                                  >
                                    <AddIcon />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </>
                )}
              />
            </div>
            <button
              className="ConfirmMaterials_btn w-100"
              onClick={() => {
                let showAlert = false;
                let updated = tempcheckboxes.find((item) => item.quantity > 0);
                if (!updated) {
                  toast.info(`Please Select Materials `, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  return null;
                }

                for (let item of tempcheckboxes) {
                  if (item.quantity > 0) {
                    if (
                      item.units === undefined ||
                      item.units === null ||
                      item.units?.length === 0
                    ) {
                      showAlert = true;
                      break;
                    }
                  } else if (item.quantity?.length === 0) {
                    if (item.units !== undefined || item.units?.length > 0) {
                      showAlert = true;

                      break;
                    }
                  }
                }

                if (!showAlert) {
                  setCheckboxes(tempcheckboxes);
                  setUpdateArray(true);
                  toast.info(` Materials Selected`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  handleClose();
                  showAlert = true;
                } else {
                  toast.info(`Please Select Materials`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  showAlert = false;
                }
              }}
            >
              Confirm Materials
            </button>
          </div>
        </Modal>
      </>{" "}
    </div>
  );
};

export default AddRequestAdmin;
