import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form } from "formik";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fileNameSplit } from "../../../ReusableComponent/Validation";
import { saveAs } from "file-saver";
import upload_icon from "../../../Assets/Img/svg/upload.svg";
const BulkMaterial = () => {
  const [BtnLoader, setBtnLoader] = useState(false);
  const [bulkLeadFile, setBulkLeadFile] = useState("");
  const [bulkLead, setBulkLead] = useState("");
  const [bulkEditLead, setBulkEditLead] = useState("");

  const handleBulkLead1 = (acceptedFiles) => {
    setBulkLead(acceptedFiles[0]);
  };

  const downloadFormat2 = () => {
    saveAs(
      `${process.env.REACT_APP_IMG_URL}/${bulkLeadFile[0].document}`,
      `${fileNameSplit(bulkLeadFile[0].document)}`
    );
  };

  useEffect(() => {
    getLeadBulk();
  }, []);

  const getLeadBulk = () => {
    axiosClientInterceptors
      .get(`/get-cofig-document?document_type=Bulk Material`)
      .then((response) => {
        setBulkLeadFile(response.data.result);
        setBulkEditLead(
          response.data.result.length > 0
            ? fileNameSplit(response.data.result[0].document)
            : ""
        );
      });
  };

  const uploadLeadBulk = () => {
    if (bulkLead.length === 0) {
      toast.error("Files are Requried", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      bulkLead.type !== "text/csv" &&
      bulkLead.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      bulkLead.type !== "application/vnd.ms-excel"
    ) {
      toast.error("Please Select a CSV or Excel Format File", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setBulkLead("");
    } else {
      setBtnLoader(true);
      const formdata = new FormData();
      formdata.append("document", bulkLead);

      axiosClientInterceptors
        .post(`/upload-cofig-document?document_type=Bulk Material`, formdata)
        .then((res) => {
          let Status = res.data.status;
          if (Status === "failed") {
            toast.error("Some Internal Error try Again Later....", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setBulkLead("");
            window.$("#Add_LeadBulk2").modal("hide");
            window.$("#Edit_LeadBulk2").modal("hide");
            setBtnLoader(false);
          } else if (Status === "success") {
            window.$("#Add_LeadBulk2").modal("hide");
            window.$("#Edit_LeadBulk2").modal("hide");
            toast.success("File has Added sucessfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setBulkLead("");
            getLeadBulk();
            setBtnLoader(false);
          }
        });
    }
  };

  return (
    <div>
      {bulkLeadFile.length > 0 && bulkLeadFile[0].document !== "" ? (
        <>
          <div className="p-3">
            <div className="pb-3" style={{ borderBottom: "1px solid #F2F2F2" }}>
              <span className="bulk_format_txt">Bulk Material Document</span>
            </div>
            <div className="mt-4 d-flex justify-content-between">
              <div
                className="d-flex align-items-center"
                style={{ gap: "14px" }}
              >
                <div className="edit_bulk_file1">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.86076 12.5978H11.0007C11.3127 12.5978 11.5715 12.3364 11.5715 12.0213C11.5715 11.7062 11.3127 11.4525 11.0007 11.4525H6.86076C6.54874 11.4525 6.28999 11.7062 6.28999 12.0213C6.28999 12.3364 6.54874 12.5978 6.86076 12.5978ZM9.433 7.60919H6.86076C6.54874 7.60919 6.28999 7.87053 6.28999 8.18566C6.28999 8.5008 6.54874 8.75445 6.86076 8.75445H9.433C9.74501 8.75445 10.0038 8.5008 10.0038 8.18566C10.0038 7.87053 9.74501 7.60919 9.433 7.60919ZM14.864 6.93719C15.0429 6.93512 15.2377 6.93287 15.4146 6.93287C15.6049 6.93287 15.7571 7.0866 15.7571 7.27876V13.4585C15.7571 15.3647 14.2275 16.9097 12.3401 16.9097H6.28243C4.30379 16.9097 2.69043 15.2879 2.69043 13.2894V5.00362C2.69043 3.09742 4.22768 1.53711 6.12262 1.53711H10.1865C10.3843 1.53711 10.5365 1.69852 10.5365 1.89068V4.36566C10.5365 5.77225 11.6857 6.92519 13.0783 6.93287C13.4036 6.93287 13.6904 6.93531 13.9414 6.93743C14.1366 6.93909 14.3102 6.94056 14.4634 6.94056C14.5717 6.94056 14.7121 6.93894 14.864 6.93719ZM15.074 5.81564C14.4484 5.81795 13.711 5.81564 13.1806 5.81026C12.3389 5.81026 11.6456 5.11004 11.6456 4.25994V2.23384C11.6456 1.90256 12.0436 1.73807 12.2712 1.97711C12.6829 2.40946 13.2486 3.0037 13.8117 3.59528C14.3739 4.18584 14.9336 4.77375 15.335 5.19536C15.5573 5.42825 15.3944 5.81487 15.074 5.81564Z"
                      fill="#151414"
                    />
                  </svg>
                </div>
                <div>
                  <span className="doc_name">
                    {bulkLeadFile[0]?.document
                      ? fileNameSplit(bulkLeadFile[0]?.document)
                      : ""}
                  </span>
                </div>
              </div>

              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="download_bulk_file"
                  onClick={() => downloadFormat2()}
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.875 15C1.35938 15 0.918125 14.8166 0.55125 14.4497C0.18375 14.0822 0 13.6406 0 13.125V10.3125H1.875V13.125H13.125V10.3125H15V13.125C15 13.6406 14.8166 14.0822 14.4497 14.4497C14.0822 14.8166 13.6406 15 13.125 15H1.875ZM7.5 11.25L2.8125 6.5625L4.125 5.20312L6.5625 7.64062V0H8.4375V7.64062L10.875 5.20312L12.1875 6.5625L7.5 11.25Z"
                      fill="#151414"
                    />
                  </svg>
                </div>
                <div
                  className="edit_bulk_file"
                  data-bs-toggle="modal"
                  data-bs-target="#Edit_LeadBulk2"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.0323 15.0209L13.6221 6.49908C13.9802 6.03953 14.1076 5.50822 13.9882 4.96724C13.8847 4.47544 13.5823 4.00783 13.1286 3.65309L12.0224 2.7743C11.0594 2.00838 9.86559 2.089 9.18114 2.96779L8.44099 3.92801C8.34548 4.04814 8.36936 4.22551 8.48874 4.32226C8.48874 4.32226 10.359 5.82185 10.3988 5.85409C10.5262 5.97503 10.6217 6.13628 10.6455 6.32977C10.6853 6.7087 10.4227 7.06344 10.0327 7.11181C9.84967 7.136 9.67458 7.07956 9.54724 6.97475L7.58145 5.41067C7.48594 5.33891 7.34269 5.35423 7.2631 5.45098L2.59135 11.4977C2.28892 11.8766 2.18546 12.3684 2.28892 12.8441L2.88582 15.4321C2.91766 15.5692 3.03704 15.6659 3.1803 15.6659L5.80666 15.6337C6.28418 15.6256 6.72987 15.4079 7.0323 15.0209ZM10.7098 14.2149H14.9923C15.4102 14.2149 15.75 14.5592 15.75 14.9825C15.75 15.4065 15.4102 15.75 14.9923 15.75H10.7098C10.2919 15.75 9.9521 15.4065 9.9521 14.9825C9.9521 14.5592 10.2919 14.2149 10.7098 14.2149Z"
                      fill="#151414"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="p-3 text-center">
            <div className="mt-3">
              <span className="bulk_format_txt">Bulk Material File Format</span>
            </div>

            <div className="my-3 d-flex justify-content-center">
              <button
                className="upload_btn_config"
                style={{ width: "fit-content", padding: "11px 22px" }}
                data-bs-toggle="modal"
                data-bs-target="#Add_LeadBulk2"
              >
                <img src={upload_icon} className="me-2 mb-1" />
                Upload
              </button>
            </div>
          </div>
        </>
      )}

      {/* Add Bulk Format Start */}
      <div
        id="Add_LeadBulk2"
        className="modal fade"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div className="pb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="upload_document_txt">
                      Add Bulk Vendors File Format
                    </span>
                  </div>
                  <div
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setBulkLead("");
                    }}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </div>

              <div>
                <Formik
                  initialValues={{
                    document: "",
                  }}
                  onSubmit={() => {}}
                >
                  <div>
                    <Form>
                      <Dropzone onDrop={handleBulkLead1}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps({
                              className: "dropzone_img",
                            })}
                          >
                            <div>
                              {bulkLead != "" ? (
                                <div>
                                  <div>
                                    <span className="file_ready">
                                      {bulkLead.name}
                                    </span>
                                  </div>
                                  <div
                                    className="pt-3 d-flex"
                                    style={{ gap: "14px" }}
                                  >
                                    <button
                                      className="browse_cancel_btn"
                                      onClick={() => {
                                        setBulkLead("");
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="upload_btn"
                                      type="submit"
                                      onClick={() => {
                                        uploadLeadBulk();
                                      }}
                                    >
                                      {BtnLoader === true ? (
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                      ) : (
                                        ""
                                      )}
                                      Upload
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div>
                                    <svg
                                      width="70"
                                      height="50"
                                      viewBox="0 0 70 50"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.43066 5.33821C4.43066 2.39 6.82066 0 9.76888 0H20.134C20.8804 0 21.6235 0.156569 22.3023 0.466935C29.2355 3.63681 30.8655 5.30629 38.105 6.56585C38.3945 6.61622 38.6923 6.64067 38.9861 6.64067H59.3456C62.2938 6.64067 64.6838 9.03068 64.6838 11.9789V27.4224C64.6838 30.3707 62.2938 32.7607 59.3456 32.7607H9.76888C6.82066 32.7607 4.43066 30.3707 4.43066 27.4224V5.33821Z"
                                        fill="#FCC200"
                                      />
                                      <path
                                        d="M0.725976 23.6718C0.342826 20.9918 2.42239 18.5938 5.12971 18.5938H65.0137C67.6708 18.5938 69.7353 20.9079 69.4334 23.5477L66.9067 45.6405C66.6497 47.8872 64.7482 49.5836 62.487 49.5836H8.28816C6.07453 49.5836 4.19769 47.956 3.88441 45.7646L0.725976 23.6718Z"
                                        fill="url(#paint0_linear_383_27171)"
                                      />
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_383_27171"
                                          x1="35"
                                          y1="10.8463"
                                          x2="41.0588"
                                          y2="53.7076"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stopColor="#FFE185" />
                                          <stop
                                            offset="1"
                                            stopColor="#F7BA00"
                                          />
                                        </linearGradient>
                                      </defs>
                                    </svg>

                                    <input
                                      name="fileNames"
                                      {...getInputProps()}
                                    />
                                    <p className="pt-2 drag_drop_txt">
                                      Drag & drop file to upload <br /> or
                                    </p>
                                  </div>
                                  <div className="pt-3">
                                    <button className="browse_btn">
                                      Browse Files
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Form>
                  </div>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add Bulk Format End */}

      <div
        id="Edit_LeadBulk2"
        className="modal fade"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div className="pb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="doc_name">
                      Add Bulk Material File format
                    </span>
                  </div>
                  <div
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setBulkEditLead(fileNameSplit(bulkLeadFile[0].document));
                      setBulkLead("");
                    }}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </div>

              <div>
                <Formik
                  initialValues={{
                    file: "",
                  }}
                >
                  <div>
                    <Form>
                      {bulkEditLead !== "" || bulkLead !== "" ? (
                        <div className="dropzone_img">
                          <div className="d-flex flex-column">
                            <div>
                              <span className="file_ready">
                                {bulkEditLead !== "" && bulkLead === ""
                                  ? bulkEditLead
                                  : bulkLead.name}
                              </span>
                            </div>

                            <div
                              className="pt-3 d-flex"
                              style={{ gap: "14px" }}
                            >
                              <button
                                className="browse_cancel_btn"
                                onClick={() => {
                                  setBulkLead("");
                                  setBulkEditLead("");
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="upload_btn"
                                type="submit"
                                onClick={() => {
                                  uploadLeadBulk();
                                }}
                              >
                                {BtnLoader === true ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  ""
                                )}
                                Upload
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Dropzone onDrop={handleBulkLead1}>
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps({
                                className: "dropzone_img",
                              })}
                            >
                              <div>
                                <div>
                                  <div>
                                    <svg
                                      width="70"
                                      height="50"
                                      viewBox="0 0 70 50"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.43066 5.33821C4.43066 2.39 6.82066 0 9.76888 0H20.134C20.8804 0 21.6235 0.156569 22.3023 0.466935C29.2355 3.63681 30.8655 5.30629 38.105 6.56585C38.3945 6.61622 38.6923 6.64067 38.9861 6.64067H59.3456C62.2938 6.64067 64.6838 9.03068 64.6838 11.9789V27.4224C64.6838 30.3707 62.2938 32.7607 59.3456 32.7607H9.76888C6.82066 32.7607 4.43066 30.3707 4.43066 27.4224V5.33821Z"
                                        fill="#FCC200"
                                      />
                                      <path
                                        d="M0.725976 23.6718C0.342826 20.9918 2.42239 18.5938 5.12971 18.5938H65.0137C67.6708 18.5938 69.7353 20.9079 69.4334 23.5477L66.9067 45.6405C66.6497 47.8872 64.7482 49.5836 62.487 49.5836H8.28816C6.07453 49.5836 4.19769 47.956 3.88441 45.7646L0.725976 23.6718Z"
                                        fill="url(#paint0_linear_383_27171)"
                                      />
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_383_27171"
                                          x1="35"
                                          y1="10.8463"
                                          x2="41.0588"
                                          y2="53.7076"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stopColor="#FFE185" />
                                          <stop
                                            offset="1"
                                            stopColor="#F7BA00"
                                          />
                                        </linearGradient>
                                      </defs>
                                    </svg>

                                    <input
                                      name="fileNames"
                                      {...getInputProps()}
                                    />
                                    <p className="pt-2 drag_drop_txt">
                                      Drag & drop file to upload <br /> or
                                    </p>
                                  </div>
                                  <div className="pt-3">
                                    <button className="browse_btn">
                                      Browse Files
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      )}
                    </Form>
                  </div>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkMaterial;
