import React from "react";
import {
  selectFinalpoData,
  selectPOswitch,
  selectTerms,
  selectTermsncondition,
  selectvendorCompanyName,
  setPOswitch,
  setsidebaractivetab,
} from "../../../features/counter/counterSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import saveAs from "file-saver";
import Modal from "@mui/material/Modal";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CloseIcon from "@mui/icons-material/Close";
import Cendrol_Logo from "../../../Assets/Img/cenlogos.svg";
import downloadicon from "../../../Assets/Img/DownloadIcon.svg";
import { useEffect } from "react";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { useState } from "react";
import moment from "moment";
import rejectimg from "../../../Assets/Img/rejectimg.svg";
import { ToWords } from "to-words";
import EmailIcon from "@mui/icons-material/Email";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import {
  selectProjectScreen,
  selectscreen,
  setProjectScreen,
  selectAddVendorScreen,
  setAddVendorScreen
} from "../../../features/Screen/screen";
import { useDispatch, useSelector } from "react-redux";

const PurchaseOrderDOc = ({ SocketCallData, SocketCall }) => {

  const dispatch = useDispatch();
  const screenvendor = useSelector(selectAddVendorScreen);
  const comapanyName = useSelector(selectvendorCompanyName)

  const toWords = new ToWords();
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [gstMap, setGstMap] = useState([]);
  const poallData = useSelector(selectFinalpoData);
  const termsconfig = useSelector(selectTermsncondition);
  const projectScreen = useSelector(selectProjectScreen);
  const [Reason, setReason] = useState("");
  const [loader, setloader] = useState(false);
  const cgst = [];
  const cancelledSTatus = ["Payment Done", "Material Received"];

  const sendmail = () => {
    setloader(true);
    axiosClientInterceptors
      .get(
        `/send-po-mail-to-vendor?_id=${poallData._id}&purchase_order_no=${poallData.purchase_order_no}`
      )
      .then((result) => {
        setloader(false);
        if (result.data.status === "success") {
          window.$("#mail_sent_success").modal("show");
          setloader(false);
        }
        setloader(false);
      });
  };

  const generatePO = () => {
    axiosClientInterceptors
      .get(`/generate-po?_id=${poallData?._id}`)
      .then((response) => {
        saveAs(
          `${process.env.REACT_APP_IMG_URL}/${response.data.file}`,
          response.data.file.split("/").at(-1)
        );
      });
  };

  useEffect(() => {
    var newgst = poallData?.materials;
    setGstMap([...new Set(newgst?.map((data) => data?.gst))]);
  }, [poallData]);

  // TO get total no of items
  const TotalItemsCount = (dataval) => {
    let MaterialTotal = 0;
    dataval?.forEach((element) => {
      MaterialTotal = Number(MaterialTotal) + Number(element.quantity);
    });
    return MaterialTotal;
  };

  const submitCancel = () => {
    const data = {
      PO_cancel_reason: Reason,
    };
    axiosClientInterceptors
      .post(`/cancelPO?_id=${poallData?._id}`, data)
      .then((response) => {
        if (screenvendor === "IdvPurchaseOrderDOc") {
          dispatch(setAddVendorScreen("viewPO"));
        } else {
          dispatch(setPOswitch("maintable"));
          dispatch(setsidebaractivetab("Purchase Orders"));
        }
        // SocketCall("admin-notification-count", null, null, "PO cancelled");
        // SocketCall("pm-notification-count", null, null, "PO cancelled");
        // SocketCall("pd-notification-count", null, null, "PO cancelled");
        // SocketCallData(
        //   "admin-get-notification",
        //   response.data.request_id,
        //   "PO cancelled"
        // );
        // SocketCallData(
        //   "pm-get-notification",
        //   response.data.request_id,
        //   "PO cancelled"
        // );
        // SocketCallData(
        //   "pd-get-notification",
        //   response.data.request_id,
        //   "PO cancelled"
        // );

        handleClose1();
      });
  };

  return (
    <>
      {" "}
      <div className="p-3 create_po_page">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center po_bread_crumb">
            {
              screenvendor === "IdvPurchaseOrderDOc" ? (
                <p className="m-0">
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      dispatch(setAddVendorScreen("listing"));
                      // getvendorListiing();
                    }}
                  >
                    All Vendors{" "}
                  </span>
                  /{" "}
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      dispatch(setAddVendorScreen("vendorDetails"));
                      // vendordetailsID();
                      // materialCatalog();
                    }}
                  >
                    Vendor Details{" "}
                  </span>
                  /{" "}
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      dispatch(setAddVendorScreen("viewPO"));
                    }}
                  >Purchase Orders For {comapanyName}
                  </span>
                  {" "}/ <span
                    className="fontdocument_active">
                    Purchase Order
                  </span>
                </p>
              ) : (
                <p className="m-0">
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      dispatch(setPOswitch("maintable"));
                      dispatch(setsidebaractivetab("Purchase Orders"));
                    }}
                  >
                    Purchase Order list{" "}
                  </span>
                  /<span className="fontdocument_active"> Purchase Order</span>
                </p>
              )

            }
          </div>
          <div>
            <div className="d-flex align-items-center " style={{ gap: "10px" }}>
              <div
                className="iconeimage"
                onClick={() => sendmail()}
                style={{ background: "#f6f6f6" }}
              >
                {loader ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <EmailIcon style={{ cursor: "pointer" }} />
                )}
              </div>

              <div
                className="download_po_btn d-flex align-items-center"
                onClick={generatePO}
              >
                <img src={downloadicon} width="" className="me-2" alt="logo" />
                <p className="mb-0 pl-3">Download Purchase Order</p>
              </div>
              {poallData?.PO_status !== "PO cancelled" ? (
                <button
                  disabled={poallData?.stages?.includes("Material Received")}
                  className=" d-flex align-items-center po_cancel_button"
                  onClick={handleOpen1}
                // onClick={() => console.log(poallData.stages)}
                >
                  <CloseIcon style={{ color: "#FE3E3E" }} />
                  <p className="mb-0 pl-3">Cancel Purchase Order</p>
                </button>
              ) : null}

              <Modal
                style={{ background: "rgba(34, 30, 31, 0.9)" }}
                open={open1}
                className="d-flex justify-content-center align-items-center flex-column "
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="filter_container  p-4 ">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 Filter_text ">Cancel Reason</p>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleClose1}
                    />
                  </div>
                  <textarea
                    required
                    rows="4"
                    cols="9"
                    value={Reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="text_area mt-2 text-dark"
                    placeholder="eg. due to incorrect details"
                  ></textarea>

                  <div
                    className="mt-3 PurchaseOrder_cancel"
                    style={{
                      opacity: `${Reason.length === 0 ? "0.5" : "1"}`,
                      pointerEvents: `${Reason.length === 0 ? "none" : "unset"
                        }`,
                    }}
                    onClick={() => {
                      submitCancel();
                    }}
                  >
                    Cancel this Purchase Order
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
        <hr className="po_bread_crumb" />
        {poallData?.PO_status && (
          <div className="w-100 RejectReason_container">
            <div className="d-flex align-items-center">
              <img src={rejectimg} className="me-2" />
              <p className="mb-0 Reject">Cancelled Reason</p>
            </div>
            <p className="Reason">{poallData?.PO_cancel_reason}</p>
          </div>
        )}
        <>
          <div>
            <div className="mt-4 purchaseorderview_main_sec">
              <div className="d-flex justify-content-between">
                <div className="mb-2">
                  <img src={Cendrol_Logo} width="60%" alt="cendrol" />
                </div>
                <div className="po_heading">
                  <h5
                    className=" mb-0 p-2"
                    style={{ color: "#FCC314", fontSize: "24px" }}
                  >
                    PURCHASE ORDER
                  </h5>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div style={{ width: "350px" }}>
                  <span>
                    <h5
                      className="mb-0"
                      style={{
                        fontFamily: "gb",
                        color: "#000",
                        fontSize: "15px",
                      }}
                    >
                      RELIABLE BUILD
                    </h5>
                    <span
                      style={{
                        fontFamily: "gm",
                        fontSize: "13px",
                        color: "#909090",
                      }}
                    >
                      {poallData?.billing_address}
                    </span>
                    <br />
                    <span className="po_label">
                      PAN :
                      <span className="po_value">
                        {" "}
                        {poallData?.company_pan || "-"}
                      </span>
                    </span>
                    <br />
                    <span className="po_label">
                      GST :
                      <span className="po_value">
                        {" "}
                        {poallData?.company_gst}
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{ fontFamily: "", lineHeight: "1.6rem", width: "35%" }}
                >
                  <div className="row">
                    <div className="col-4">
                      <p
                        style={{ fontFamily: "gm", color: "#909090" }}
                        onClick={() => console.log("poallData", poallData)}
                      >
                        PO NO
                      </p>
                    </div>
                    <div className="col-8">
                      <span style={{ color: "#0E0E0E", fontFamily: "Gsb" }}>
                        <b className="text-dark">
                          : {poallData?.purchase_order_no}
                        </b>
                      </span>
                    </div>
                  </div>
                  <span style={{ fontFamily: "gm", color: "#909090" }}>
                    <div className="row">
                      <div className="col-4"> Creation Date </div>
                      <div className="col-8">
                        <span style={{ color: "#0E0E0E", fontFamily: "Gsb" }}>
                          <b className="text-dark">
                            : {poallData?.purchase_order_date}
                          </b>
                        </span>
                      </div>
                    </div>
                  </span>

                  <div className="row">
                    <div className="col-4">
                      {" "}
                      <span style={{ fontFamily: "gm", color: "#909090" }}>
                        Due Date
                      </span>
                    </div>
                    <div className="col-8">
                      <span style={{ color: "#0E0E0E", fontFamily: "Gsb" }}>
                        <b className="text-dark">: {poallData?.due_date}</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <div className="d-flex top_stripe_po">
                  <div className="col-6">
                    <span className="PO_heading_">Vendor Details</span>
                  </div>
                  <div className="col-6">
                    <span className="PO_heading_">Delivery Address</span>
                  </div>
                </div>

                <div className="purchase_order_view_all_details">
                  <div className="row">
                    <div
                      className="col-6"
                      style={{ borderRight: "1px solid #C9C9C9" }}
                    >
                      <div className="row">
                        <div className="col-4">
                          <span className="po_label">Company Name </span>
                        </div>
                        <div className="col-8">
                          <span className="po_value">
                            : {poallData?.vendor_company_name}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <span className="po_label">Vendor Address </span>{" "}
                        </div>
                        <div className="col-8">
                          {" "}
                          <span className="po_value">
                            : {poallData?.vendor_address}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <span className="po_label">Mobile Number </span>
                        </div>
                        <div className="col-8">
                          {" "}
                          <span className="po_value">
                            :{" "}
                            {poallData?.vendor_phone
                              ? `+91${poallData?.vendor_phone}`
                              : "-"}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <span className="po_label">Email </span>
                        </div>
                        <div className="col-8">
                          {" "}
                          <span className="po_value">
                            : {poallData?.vendor_email || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <span className="po_label">GST </span>
                        </div>
                        <div className="col-8">
                          {" "}
                          <span className="po_value">
                            : {poallData?.vendor_gst || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <span className="po_label">PAN </span>
                        </div>
                        <div className="col-8">
                          {" "}
                          <span className="po_value">
                            : {poallData?.vandor_pan || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <div className="">
                          <div className="row">
                            <div className="col-4">
                              <span className="po_label">Shipping Address</span>{" "}
                            </div>
                            <div className="col-8">
                              {" "}
                              <span className="po_value">
                                : {poallData?.shipping_address}
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <span className="po_label">Project Name</span>
                            </div>
                            <div className="col-8">
                              {" "}
                              <span className="po_value">
                                : {poallData?.project_name}
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <span className="po_label">Site Eng. Name</span>
                            </div>
                            <div className="col-8">
                              {" "}
                              <span className="po_value">
                                : {`Mr. ${poallData?.site_eng_name}`}
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <span className="po_label">
                                Site Eng. Mobile Number
                              </span>
                            </div>
                            <div className="col-8">
                              {" "}
                              <span className="po_value">
                                :{" "}
                                {poallData?.site_eng_no
                                  ? poallData?.site_eng_no
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="salutation">
                  <p>
                    Dear Sir/Madam, <br />
                    We are hereby placing our firm purchase order for supply of
                    the materials as specified below.
                  </p>
                </div>
                <div className="mt-4">
                  <table className="table table-bordered  poview_table_content mb-0">
                    <thead
                      className=""
                      style={{
                        color: "#fff",
                        fontFamily: "gsb !important",
                      }}
                    >
                      <tr className="headers_tags_new">
                        <th className="text-center text-dark">
                          <b>Sl. No</b>.
                        </th>
                        <th className="text-center text-dark">
                          {" "}
                          <b>Material Name</b>
                        </th>
                        <th className="text-center text-dark">
                          {" "}
                          <b>HSN/SAV</b>
                        </th>
                        <th className="text-center text-dark">
                          {" "}
                          <b>UOM</b>
                        </th>
                        <th className="text-center text-dark">
                          {" "}
                          <b>QTY</b>
                        </th>
                        <th className="text-center text-dark">
                          {" "}
                          <b>Unit Price</b>
                        </th>
                        <th className="text-center text-dark">
                          {" "}
                          <b>CGST</b>
                        </th>
                        <th className="text-center text-dark">
                          {" "}
                          <b>SGST</b>
                        </th>
                        <th className="text-center text-dark">
                          {" "}
                          <b>Amount</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ borderTop: "none" }}>
                      {poallData?.materials?.map((lp, index) => {
                        return (
                          <tr>
                            <td className="text-center po__val">{index + 1}</td>
                            <td className="text-center po__val">
                              {lp?.material_name}
                            </td>
                            <td className="text-center po__val">
                              {" "}
                              {lp?.hsn_sac ? lp?.hsn_sac : "-"}
                            </td>
                            <td className="text-center po__val"> {lp?.uom}</td>
                            <td className="text-center po__val">
                              {lp?.quantity}
                            </td>
                            <td className="text-center po__val">{lp?.rate}</td>
                            <td className="text-center po__val">
                              {`${lp?.cgst}%` || "-"}
                            </td>
                            <td className="text-center po__val">
                              {`${lp?.sgst}%` || "-"}
                            </td>
                            <td className="text-center po__val">
                              {lp?.amount?.toFixed(2)}/-
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      border: "1px solid lightgray",
                      borderTop: "none",
                      fontFamily: "gm",
                    }}
                  >
                    <div
                      className="p-3"
                      style={{
                        width: "50%",

                        borderRight: "1px solid lightgray",
                      }}
                    >
                      <span className="text-dark" style={{ fontFamily: "Gsb" }}>
                        Items in Total {TotalItemsCount(poallData?.materials)}
                      </span>
                      <br />
                      <b className="text-dark">Note</b> : GST is included in the
                      Grand Total Amount
                    </div>
                    <div style={{ width: "38%", lineHeight: "30px" }}>
                      <div className="d-flex  row ">
                        <div className="text-end po__val col-6">Sub Total </div>
                        <div className="text-center po__val col-6">
                          {Number(poallData?.sub_total)?.toFixed(2)}/-
                        </div>
                      </div>
                      <div className="d-flex justify-content-between row">
                        <div className="text-end po__val col-6">
                          Transportation Charges
                        </div>
                        <div className="text-center po__val col-6">
                          {poallData?.transportation_charges
                            ? `${poallData?.transportation_charges}/-`
                            : "Included"}
                        </div>
                      </div>
                      <div className="d-flex row">
                        <div className="text-end po__val col-6">
                          Loading & Unloading Charges{" "}
                        </div>
                        <div className="text-center po__val col-6">
                          {poallData?.load_unload_charges
                            ? `${poallData?.load_unload_charges}/-`
                            : "Included"}
                        </div>
                      </div>
                      {poallData?.discount === "" ||
                        poallData?.discount === 0 ? (
                        ""
                      ) : (
                        <div className="d-flex row">
                          <div className="text-end po__val col-6">
                            Discount{" "}
                          </div>
                          <div className="text-center po__val col-6">
                          {poallData && poallData.discount ? Number(poallData.discount).toFixed(2) + '/-' : '000.00'}
                          </div>
                        </div>
                      )}
                      <div className="d-flex justify-content-between row text-dark">
                        <div className="text-end po__val col-6">
                          <b> Grand Total</b>
                        </div>
                        <div className="text-center po__val col-6">
                          {" "}
                          <b> {Number(poallData?.grand_total)?.toFixed(0)}/-</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="p-2"
                    style={{
                      border: "1px solid #D5D5D5",
                      fontFamily: "Gm",
                      borderTop: "none",
                    }}
                  >
                    <p>
                      {" "}
                      <b>Total In Words : </b>
                      {"  "}
                      {toWords.convert(Number(poallData?.grand_total || 0), {
                        currency: true,
                      })}{" "}
                    </p>
                  </div>
                </div>
                <h6 className="mt-3 mb-3" style={{ fontFamily: "Gb" }}>
                  HSN/SAC Summary
                </h6>
                <div
                  className="mt-3"
                  style={{
                    border: "1px solid lightgray",
                    borderTop: "none",
                    fontFamily: "gm",
                  }}
                >
                  <table className="table table-bordered poview_table_content mb-0">
                    <thead
                      className=""
                      style={{
                        color: "#fff",
                        fontFamily: "gsb !important",
                      }}
                    >
                      <tr className="headers_tags_new">
                        <th className="text-center text-dark">
                          <b>Sl.No</b>
                        </th>
                        <th className="text-center text-dark">
                          <b>CGST</b>
                        </th>
                        <th className="text-center text-dark">
                          <b>SGST</b>
                        </th>
                        <th className="text-center text-dark">
                          <b>Total Tax Amount</b>
                        </th>
                      </tr>
                    </thead>
                    {poallData?.HSN_SAC_summary?.map((val, i) => {
                      cgst.push(val.SGST_amount);
                      return (
                        <tbody style={{ borderTop: "none" }}>
                          <tr style={{ fontFamily: "Gm" }}>
                            <td className="text-center po__val">{i + 1}</td>
                            <td className="text-center po__val">
                              {`${Number(val.CGST_amount).toFixed(2)} (${val.CGST
                                }%)`}
                            </td>
                            <td className="text-center po__val">
                              {" "}
                              {`${Number(val.SGST_amount).toFixed(2)} (${val.SGST
                                }%)`}
                            </td>
                            <td className="text-center po__val">
                              {Number(val.total_tax_amount).toFixed(2)}/-
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                    <tbody style={{ borderTop: "none" }}>
                      <tr style={{ fontFamily: "Gb" }}>
                        <td className="text-center po__val">Total</td>
                        <td className="text-center po__val">
                          {Number(poallData?.total_CGST_amount).toFixed(2)} (
                          {poallData?.total_CGST}
                          %)
                        </td>
                        <td className="text-center po__val">
                          {Number(poallData?.total_SGST_amount).toFixed(2)} (
                          {poallData?.total_SGST}
                          %)
                        </td>
                        <td className="text-center po__val">
                          {Number(poallData?.total_amount)?.toFixed(2)}/-
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt-4">
                  <>
                    <div className="d-flex top_stripe_po">
                      <div className="col-6">
                        <span className="PO_heading_">Payment Terms</span>
                      </div>
                    </div>
                    <div className="mb-3 border_box">
                      {poallData?.special_instruction?.map((rk, index) => {
                        return (
                          <div className="m-2">
                            <p style={{ fontFamily: "Gm", fontSize: "13px" }}>
                              {" "}
                              {index + 1}.<b> {rk}</b>
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </>

                  <div className="d-flex top_stripe_po">
                    <div className="col-6">
                      <span className="PO_heading_">Terms & Conditions</span>
                    </div>
                  </div>
                  <div className="p-3 border_box">
                    <div>
                      {termsconfig?.map((val, index) => {
                        return (
                          <>
                            <p className="termsandconditions">
                              <b>
                                {index + 1}
                                {".)"}
                              </b>{" "}
                              {val.description}
                            </p>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex justify-content-between align-items-end w-100">
                  <div className="po_value text-center">
                    For Cendrol <br />
                    <br />
                    <br />
                    Authorized Signatory
                  </div>
                  <div className="po_value">
                    Vendor Acceptance with Seal and Signature
                  </div>
                </div>
              </div>
            </div>
            <div className="nosignature">
              This is a System Generated Document and No Signature Required.
            </div>
          </div>
        </>
      </div>
      <div
        className="modal fade"
        id="mail_sent_success"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <div className="modal-body pt-0">
              <div className="form_invoice_upload1">
                <div className="text-center">
                  <CheckCircleIcon className="tick_mark" />
                  <div className="mt-3">
                    <h4> Email Sent Successfully</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrderDOc;
