import React from "react";
import { useState } from "react";
import "./configuration.css";
import CreditTerms from "./CreditTerms";
import DocConfig from "./DocConfig";
import MatrialCatalogTab from "./MatrialCatalogTab";
import POConfig from "./POConfig";
import UserGroups from "./UserGroups";

const Configurations = () => {
  const [configactivetab, setconfigactivetab] = useState(1);
  return (
    <div className="dashbordScreenContainer">
      <div className="d-flex justify-content-between align-items-center">
        <p className="dashboardtitle">Configurations</p>
      </div>
      <div className=" mt-4">
        <div className="d-flex  align-items-center">
          <div className="w-50" onClick={() => setconfigactivetab(1)}>
            <p
              className={` ${
                configactivetab === 1
                  ? "allrequeststabactive text-center"
                  : "allrequeststab text-center"
              }`}
            >
              UOM
            </p>
          </div>
          <div className="w-50" onClick={() => setconfigactivetab(2)}>
            <p
              className={`${
                configactivetab === 2
                  ? "allReceivedtabactive"
                  : "allReceivedtab"
              }`}
            >
              Purchase Orders
            </p>
          </div>
          <div className="w-50" onClick={() => setconfigactivetab(3)}>
            <p
              className={`${
                configactivetab === 3
                  ? "allReceivedtabactive"
                  : "allReceivedtab"
              }`}
            >
              Documents
            </p>
          </div>
          <div className="w-50" onClick={() => setconfigactivetab(4)}>
            <p
              className={`${
                configactivetab === 4
                  ? "allReceivedtabactive"
                  : "allReceivedtab"
              }`}
            >
              User Groups
            </p>
          </div>
          {/* <div className="w-50" onClick={() => setconfigactivetab(5)}>
            <p
              className={`${
                configactivetab === 5
                  ? "allReceivedtabactive"
                  : "allReceivedtab"
              }`}
            >
              Credit Terms
            </p>
          </div> */}

          <div className="w-100">
            <p className="allReceivedtabempty">f</p>
          </div>
        </div>
      </div>
      {configactivetab === 1 && <MatrialCatalogTab />}
      {configactivetab === 2 && <POConfig />}
      {configactivetab === 3 && <DocConfig />}
      {configactivetab === 4 && <UserGroups />}
      {/* {configactivetab === 5 && <CreditTerms />} */}
    </div>
  );
};

export default Configurations;
