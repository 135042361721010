import React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { useState } from "react";

const AddMatrialCatUnits = ({ getMatrialCataloguom }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [SpecificationName, setSpecificationName] = useState("");
  const [UnitOfMeasure, setUnitOfMeasure] = useState("");

  const addUOM = () => {
    let data = {
      specification: SpecificationName,
      uom: UnitOfMeasure,
    };

    axiosClientInterceptors
      .post(`/add-uom`, data)
      .then((response) => {
        getMatrialCataloguom();

        if (response.data.status === "success") {
          setSpecificationName("");
          setUnitOfMeasure("");
          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) =>
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
  };

  return (
    <>
      <div className="MatrialCatalogTab_sub">
        <p className="MaterialSpecification">Material Specification</p>
        <button
          className="AddProject "
          style={{ width: "fit-content" }}
          onClick={() => handleOpen()}
        >
          <AddIcon className="add_icon" />
          Add Specification
        </button>
      </div>
      <Modal
        open={open}
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        className="d-flex justify-content-center align-items-center flex-column "
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="filter_container ">
          <div className="m-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 Filter_text">Add Specification</p>
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div className="w-100 ">
                <p className=" Package_title">Specification Name</p>
                <input
                  type="text"
                  value={SpecificationName}
                  onChange={(e) => setSpecificationName(e.target.value)}
                  name="invoice_number"
                  className="w-100  InvoiceNumber_input"
                  placeholder="eg: Length"
                />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div className="w-100">
                <p className=" Package_title">Possible Unit Of Measure</p>
                <input
                  type="text"
                  value={UnitOfMeasure}
                  onChange={(e) => setUnitOfMeasure(e.target.value)}
                  name="invoice_number"
                  className="w-100  InvoiceNumber_input"
                  placeholder="eg: Mtr. , Mm. , Inches"
                />
              </div>
            </div>
            <p
              className="mb-0 mt-2"
              style={{ color: "#909090", fontFamily: "Gm" }}
            >
              Note : possible unit of measure should be separated by “,”
            </p>

            <button
              className="AddProject w-100 mt-2 "
              style={{ height: "50px" }}
              disabled={
                SpecificationName.length === 0 || UnitOfMeasure.length === 0
              }
              onClick={() => {
                addUOM();
                handleClose();
              }}
            >
              <AddIcon className="add_icon" />
              Add Specification
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddMatrialCatUnits;
