import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectactivetab,
  setFinalpoData,
  setPOswitch,
} from "../../../../features/counter/counterSlice";
import {
  setProjectScreen,
  setscreen,
} from "../../../../features/Screen/screen";
import axiosClientInterceptors from "../../../../ReusableComponent/axiosClentInterceptors";
import moment from "moment";
import { CgArrowRightR } from "react-icons/cg";

const ListingsVendor = ({ requestIDforVEndor, ValueRadio, setvaluesingle }) => {
  const activetabsection = useSelector(selectactivetab);
  const [vendorlisting, setvendorlisting] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    axiosClientInterceptors
      .get(`/get-rfq?request_id=${requestIDforVEndor}`)
      .then((response) => {
        setvendorlisting(response.data.result);
      });
  }, []);

  const click = () => {
    axiosClientInterceptors
      .get(`/getpurchasebyid?_id=${requestIDforVEndor}`)
      .then((responsedata) => {
        console.log("requestIDforVEndor", requestIDforVEndor);
        dispatch(setFinalpoData(responsedata.data.Purchase_Orders[0]));
      });
  };
  useEffect(() => {
    click();
  }, [ValueRadio]);

  return (
    <div className="w-100 p-3">
      {activetabsection === "All Projects" ? (
        <>
          <p className="m-0">
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setProjectScreen("allusers"));
                dispatch(setscreen("listing"));
                dispatch(setPOswitch(""));
              }}
            >
              All Projects
            </span>{" "}
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setProjectScreen("singleuser"));
                dispatch(setscreen("listing"));
                dispatch(setPOswitch(""));
              }}
            >
              Project Details{" "}
            </span>
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setscreen("listing"));
                dispatch(setPOswitch(""));
              }}
            >
              All Requests
            </span>{" "}
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setscreen("singledata"));
                dispatch(setPOswitch(""));
              }}
            >
              Requests Details{" "}
            </span>
            / <span className="fontdocument_active">RFQ</span>
          </p>
        </>
      ) : (
        <p className="m-0">
          <span
            className="fontdocument_dim"
            onClick={() => {
              dispatch(setscreen("listing"));
              dispatch(setPOswitch(""));
            }}
          >
            All Requests
          </span>{" "}
          /{" "}
          <span
            className="fontdocument_dim"
            onClick={() => {
              dispatch(setscreen("singledata"));
              dispatch(setPOswitch(""));
            }}
          >
            Requests Details{" "}
          </span>
          / <span className="fontdocument_active">RFQ</span>
        </p>
      )}
      <hr />
      <p className="pt-1 pb-2 rfqtittle">
        Choose Vendor to Create Purchase Order
      </p>
      <div
        className="mt-3"
        style={{
          background: "#F8F8F8",
        }}
      >
        <div
          className="inner_table_div pt-0"
          style={{ height: "calc(90vh - 125px)" }}
        >
          <table className="table leaderboard_table">
            <tbody>
              <tr className="headers_tag">
                <th className="text-center">Sl.No</th>
                <th className="text-center">Vendor Name</th>
                <th className="text-center">Company Name</th>
                <th className="text-center">Phone Number</th>
                <th className="text-center">Received response On</th>
              </tr>
              {vendorlisting?.map((val, index) => (
                <>
                  <tr className="vals" key={1}>
                    <td
                      className="text-center"
                      onClick={() => {
                        dispatch(setPOswitch("individual Vendor"));
                        setvaluesingle(val._id);
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      className="text-center"
                      onClick={() => {
                        dispatch(setPOswitch("individual Vendor"));
                        setvaluesingle(val._id);
                      }}
                    >
                      {val.vendor_name}
                    </td>
                    <td
                      className="text-center"
                      onClick={() => {
                        dispatch(setPOswitch("individual Vendor"));
                        setvaluesingle(val._id);
                      }}
                    >
                      {val.company_name}{" "}
                    </td>
                    <td
                      className="text-center"
                      onClick={() => {
                        dispatch(setPOswitch("individual Vendor"));
                        setvaluesingle(val._id);
                      }}
                    >
                      +91{val.Phone}{" "}
                    </td>
                    <td
                      className="text-center"
                      onClick={() => {
                        dispatch(setPOswitch("individual Vendor"));
                        setvaluesingle(val._id);
                      }}
                    >
                      {moment(val?.creationdate).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                    {/* <td
                      onClick={() => {
                        dispatch(setPOswitch("individual Vendor"));
                        setvaluesingle(val._id);
                      }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <CgArrowRightR
                        className="partner_back_btn text-center"
                        style={{ cursor: "pointer" }}
                      />
                    </td> */}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ListingsVendor;
