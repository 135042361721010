import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import Logo from "../../Assets/Img/cenlogo.png";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { setscreen } from "../../features/Screen/screen";
import { useDispatch } from "react-redux";

const Login = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, Setemail] = useState("");
  const [password, Setpassword] = useState("");
  const dispatch = useDispatch();
  const history = useNavigate();
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  function LoginFunc() {
    const headers = {
      "Content-type": "application/json",
    };
    const cred = {
      username: email,
      password: password,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/signin`, cred)
      .then((result) => {
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("role", result.data.role);
        localStorage.setItem("username", email);
        localStorage.setItem("_id", result.data._id);

        if (result.data.status == "success") {
          if (result.data.role === "Admin") {
            history("/adminallreq");
            localStorage.setItem("name", result.data.Name);
            window.location.reload();
          } else if (result.data.role === "Project Manager") {
            localStorage.setItem("name", result.data.Name);
            history("/pmdashboard");
            window.location.reload();
          } else if (result.data.role === "Procurement Department") {
            localStorage.setItem("name", result.data.Name);
            history("/pddashboard");
            window.location.reload();
          }
        } else if (result.data.status == "failed") {
          window.$("#CredNotValid").modal("show");
        }
      });
  }
  useEffect(() => {
    dispatch(setscreen("listing"));
  }, []);
  return (
    <div className="parent-login">
      <div className="inner_login">
        <div className="login_body">
          <div className="login_left_div p-3">
            <img src={Logo} width="28%" />
            <div className="welcome_txt">
              <h4>
                <b>Welcome</b>
              </h4>

              <h3>To Procurement</h3>
            </div>
          </div>
          <div className="row login_right_div">
            <div className="col-12 d-flex justify-content-center align-items-center Form_body">
              <div className="version_div ">
                {" "}
                <p classname="version_tag mb-0">Version 0.2.6</p>
              </div>
              <div style={{ width: "35%" }}>
                <div className="d-flex" style={{ gap: "8px" }}>
                  <h4>
                    <b className="logintext">Login</b>
                  </h4>
                </div>
                <div className="form-group mt-4">
                  <label for="username " className="text-dark">
                    User ID
                  </label>
                  <div style={{ marginTop: "8px" }}>
                    <input
                      type="text"
                      name="username"
                      placeholder="Enter User Id"
                      id="username"
                      className="form-control login_input_fileds"
                      onChange={(e) => Setemail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group mt-2">
                  <div className="align-items-center">
                    <label className="select_label text-dark">Password</label>
                    <div
                      className="d-flex"
                      style={{
                        lineHeight: "0rem",
                        marginTop: "8px",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "0.25rem",
                        background: "#f5f5f5",
                      }}
                    >
                      <input
                        className="pass1"
                        style={{
                          width: "90%",
                          border: "none",
                          borderRadius: "0.25rem",
                          background: "#f5f5f5",
                        }}
                        placeholder="Enter Password"
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        onChange={(e) => Setpassword(e.target.value)}
                      />

                      {passwordShown ? (
                        <VisibilityIcon
                          fontSize="small"
                          onClick={togglePassword}
                        />
                      ) : (
                        <VisibilityOffIcon
                          fontSize="small"
                          onClick={togglePassword}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group text-left mt-3">
                  {/* <Link to="/" className="text-dark"> */}
                  {/* <div className="d-flex  align-items-center mb-3">
                    <Checkbox
                      defaultChecked
                      sx={10}
                      style={{ color: "#FCC216" }}
                    />
                    <p className="m-0">Remember me</p>
                  </div> */}
                  {/* </Link> */}
                  <button className="login_buton " onClick={LoginFunc}>
                    LOGIN
                  </button>
                </div>
              </div>
            </div>
            <div className="version_div ">
              {" "}
              <p classname="version_tag mb-0">
                Copyright © {new Date().getFullYear()} cendrol.com All rights
                reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* popup for Invalid Credentials */}
      <div>
        <div
          className="modal fade not_valid_fse"
          aria-hidden="true"
          aria-labelledby="myLargeModalLabel"
          id="CredNotValid"
        >
          <div className="modal-dialog  modal-dialog-centered ">
            <div
              className="modal-content modal_content_whole"
              style={{ background: "white" }}
            >
              <div className="modal-body">
                <div>
                  <div className="popup_icon_center">
                    <InfoRoundedIcon className="popup_warning_icon" />
                  </div>
                  <div className="popup_text_center">
                    <span className="popup_addvalid_cred">
                      Invalid Credentials
                      <br />
                      Please Enter Valid Username and Password
                    </span>
                  </div>
                  <div className="pt-3 d-flex justify-content-center">
                    <div style={{ width: "50%", textAlign: "center" }}>
                      <button
                        // type="button"
                        className="popupinvalid_red_ok"
                        data-bs-dismiss="modal"
                        // onClick={hide}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* popup for Invalid Credentials */}
    </div>
  );
};

export default Login;
