import React from "react";
import { useState, useEffect } from "react";

export default function ({ value, updateFieldValue, index, editable }) {
  const [subTotal, setsubTotal] = useState("");
  useEffect(() => {
    updateFieldValue(
      index,
      "amount",
      ((value.rate * value.quantity) / 100) * (value.sgst + value.cgst) +
        value.rate * value.quantity
    );
    setsubTotal(
      ((value.rate * value.quantity) / 100) * (value.sgst + value.cgst) +
        value.rate * value.quantity
    );
    //     if(value.sgst == undefined && value.cgst ==undefined){
    //       setsubTotal(value.rate * value.quantity);
    //     }
    //     else{
    //  setsubTotal(
    //    ((value.rate * value.quantity) / 100) * (value.sgst + value.cgst) +
    //      value.rate * value.quantity
    //  );
    //     }
    // setsubTotal(value.rate * value.quantity);
  }, [
    value.uom,
    value.material_id,
    value.unit_price,
    value.cgst,
    value.sgst,
    value.rate,
  ]);
  return (
    <>
      <tr className="vals_new">
        <td className="text-center border_td">{index + 1}</td>
        <td className="text-center border_td">{value.material_name}</td>
        <td className="text-center border_td">
          <input
            type="text"
            disabled={!editable}
            className="potable_input m-0"
            placeholder="eg. 1208dsXXX"
            style={{ width: "80px" }}
            value={value.hsn_sac || ""}
            onChange={(e) => {
              updateFieldValue(index, "hsn_sac", e.target.value);
            }}
            onKeyDown={(event) => {
              const value = event.target.value;
              if (value.length >= 6 && event.keyCode !== 8) {
                event.preventDefault();
              }
            }}
          />
        </td>
        <td className="text-center border_td">
          <input
            disabled
            value={value.uom || ""}
            onChange={(e) =>
              updateFieldValue(index, "uom", Number(e.target.value))
            }
            className="potable_input w-75"
            type="text"
            placeholder="eg .72.00"
          />
        </td>
        <td className="text-center border_td">{value.quantity}</td>
        <td className="text-center border_td">
          <div className="d-flex align-items-center justify-content-center">
            <p className="₹">₹</p>
            <input
              disabled={!editable}
              className="potable_input w-50"
              value={value.rate || ""}
              onChange={(e) => {
                updateFieldValue(index, "rate", Number(e.target.value));
              }}
              type="number"
              placeholder="eg .72.00"
            />
          </div>
        </td>
        <td className="text-center border_td">
          {" "}
          <div className="d-flex align-items-center justify-content-center">
            <input
              disabled={!editable}
              className="potable_input w-50 "
              type="number"
              placeholder="eg .10%"
              value={value.cgst || 0}
              onChange={(e) => {
                updateFieldValue(index, "cgst", Number(e.target.value));
              }}
            />
            <p className="₹">%</p>
          </div>
        </td>
        <td className="text-center border_td">
          {" "}
          <div className="d-flex align-items-center justify-content-center">
            <input
              disabled={!editable}
              class={`potable_input w-50 ${
                value.sgst !== value.cgst && "errorfile"
              }`}
              type="number"
              placeholder="eg .10%"
              value={value.sgst || 0}
              onChange={(e) => {
                updateFieldValue(index, "sgst", Number(e.target.value));
              }}
            />
            <p className="₹">%</p>
          </div>
        </td>
        <td className="text-center border_td">
          {!editable
            ? Number(subTotal).toFixed(2)
            : Number.isNaN(value.amount)
            ? 0
            : Number(value.amount)?.toFixed(2)}
          /-
        </td>
      </tr>
    </>
  );
}
