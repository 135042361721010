import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import paginationReducer from "../features/pagination/paginationSlice";
import DateRangeSliceReducer from "../features/DateRangeSlice/DateRangeSlice";
import screenReducer from "../features/Screen/screen";
import purchaseOrderSliceReducer from "../features/idvPurchaseOrderSlice/purchaseOrderSlice";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    pagination: paginationReducer,
    DateRangeSlice: DateRangeSliceReducer,
    screenSlice: screenReducer,
    purchaseOrder: purchaseOrderSliceReducer,
  },
});
