import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Formik, Form, Field } from "formik";
import Plus from "../../../Assets/Img/svg/plus.svg";
import Search from "../../../Assets/Img/svg/search.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NoRecordFound from "../../../Assets/Img/NoRecordFound.svg";
import filtersImg from "../../../Assets/Img/svg/filter.svg";
import { Modal } from "@mui/material";

import {
  validateUserNameemail,
  validateempid,
  validatemob,
  validateusertype,
  validateuserid,
  validatepass,
  validateUserID,
  validateusername,
} from "../../../ReusableComponent/Validation";
import { TextField } from "../../../ReusableComponent/TextField";
import { NumberField } from "../../../ReusableComponent/NumberField";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import SelectField from "../../../ReusableComponent/SelectField";
import { CgArrowRightR } from "react-icons/cg";
import ManageUserDetails from "./ManageUserDetails";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import {
  selectcurrentpage,
  setcurentpage,
} from "../../../features/pagination/paginationSlice";
import PaginationComponent from "../../../ReusableComponent/PaginationComponent";
import { toast } from "react-toastify";
import Loader from "../../../ReusableComponent/Loader";

import {
  selectManageuserFilter,
  setAllListmanagers,
  setAllTheSiteEng,
  setManageuserFilter,
} from "../../../features/counter/counterSlice";
import CheckIcon from "@mui/icons-material/Check";
import ReactTooltip from "react-tooltip";
import CircleIcon from "@mui/icons-material/Circle";

const ManageUsers = () => {
  const currentpage = useSelector(selectcurrentpage);
  const filter = useSelector(selectManageuserFilter);
  const dispatch = useDispatch();
  const [BtnLoader, setBtnLoader] = useState(false);
  const [counter, setCounter] = useState(0);
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [singleUse, setSingleUse] = useState("alluser");
  const [passwordShown, setPasswordShown] = useState(false);
  const [search, setsearch] = useState("");
  const [isLoding, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // pagination
  const [perPage, setPerPge] = useState(10);
  const [totlePage, setTotalPage] = useState("");
  const [userGrp, setUserGrp] = useState([]);
  const [UserType, setUserType] = useState("");
  const [UserStatus, setUserStatus] = useState("");

  useEffect(() => {
    getusers();
    getUsergroups();
  }, [currentpage, perPage]);

  const getUsergroups = () => {
    axiosClientInterceptors
      .get(`/get-all-user-group`)
      .then((response) => {
        setUserGrp(response.data.result);
      })
      .catch((error) => console.log("Error", error));
  };

  const getSiteEngineer = () => {
    axiosClientInterceptors.get(`/getsiteengineer`).then((resp) => {
      dispatch(setAllTheSiteEng(resp.data?.Site_Engineers));
    });
  };
  const getmanagers = () => {
    axiosClientInterceptors.get(`/getmanagernames`).then((result) => {
      dispatch(setAllListmanagers(result.data?.Project_Managers));
    });
  };

  // get mange users
  const getusers = () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
    };

    axiosClientInterceptors
      .post(
        `/getusers?search=${search}&page=${currentpage}&limit=${perPage}`,
        { user_status: UserStatus, user_type: UserType },
        {
          headers,
        }
      )
      .then((resultr) => {
        dispatch(
          setManageuserFilter({ user_status: UserStatus, user_type: UserType })
        );
        dispatch(setcurentpage(resultr.data.data.currentPage));
        setUserData(resultr.data.data.listofusers);
        setCounter(0);
        setTotalPage(resultr?.data.data.totalusers);
        setLoading(false);
      });
  };

  const getusers1 = () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
    };
    axiosClientInterceptors
      .post(
        `/getusers?search=&page=1&limit=${perPage}`,
        { user_status: "", user_type: "" },
        {
          headers,
        }
      )
      .then((resultr) => {
        dispatch(
          setManageuserFilter({
            user_status: "",
            user_type: "",
          })
        );
        dispatch(setcurentpage(resultr.data.data.currentPage));
        setUserData(resultr.data.data.listofusers);
        setCounter(0);
        setTotalPage(resultr?.data.data.totalusers);
        setLoading(false);
      });
  };

  // pagination handlar
  const handleChangePage = (e, pageSize) => {
    dispatch(setcurentpage(e));
    setPerPge(pageSize);
  };

  let userids = [];
  userData.forEach((d) => {
    if (d.select) {
      userids.push(d._id);
    }
  });

  //   multipleuser delete
  const deletemultipleusers = async () => {
    axiosClientInterceptors
      .post(`/deletemultipleusers`, {
        userids,
      })
      .then((resultdeleted) => {
        let Status = resultdeleted.data.status;
        if (Status === "success") {
          window.$("#deleteuser").modal("hide");
          toast.success("User Deleted Successfully!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getusers();
        } else {
          toast.error("Internal Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getusers();
        }
      })
      .catch((err) => alert(err));
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <div className="dashbordScreenContainer">
        <Modal
          className="d-flex justify-content-center align-items-center flex-column "
          style={{ background: "rgba(34, 30, 31, 0.9)" }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className=" allProjectFilter ">
            <div className="d-flex justify-content-between mb-3 align-items-center">
              <p className="m-0 Filter_text ">Apply Filter</p>
              <CloseIcon onClick={handleClose} />
            </div>

            <div>
              <div className="row ">
                <div className="col-md-12 " style={{ marginBottom: "16px" }}>
                  <label className="select_label mb-2">User Type</label>
                  <select
                    className="filter_usertype"
                    name="material_category"
                    style={{ border: "none" }}
                    value={UserType}
                    defaultValue={filter.user_type}
                    onChange={(e) => {
                      setUserType(e.target.value);
                    }}
                  >
                    <option selected value="" disabled>
                      Select User Type
                    </option>
                    {userGrp?.map((el, index) => {
                      return (
                        <>
                          <option value={el.user_group}>{el.user_group}</option>
                        </>
                      );
                    })}
                  </select>
                </div>

                <div className=" col-md-12 " style={{ marginBottom: "16px" }}>
                  <label className="select_label mb-2">User Status</label>
                  <select
                    className="filter_usertype"
                    name="material_category"
                    style={{ border: "none" }}
                    value={UserStatus}
                    defaultValue={filter.user_status}
                    onChange={(e) => {
                      setUserStatus(e.target.value);
                    }}
                  >
                    <option selected value="" disabled>
                      Select User Type
                    </option>

                    <option value="inactive"> Inactive</option>
                    <option value="active"> Active</option>
                  </select>
                </div>
              </div>
              <div className="mt-4">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="filter_op_btns mx-auto"
                      onClick={() => {
                        handleClose();
                        setUserType();
                        setUserStatus("");
                        getusers1();
                      }}
                    >
                      Reset All
                    </div>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={() => {
                        getusers();
                        handleClose();
                      }}
                      className="filter_op_btns1"
                    >
                      Apply Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* remove user alert */}
        <div
          className="modal fade partner_details_deleter"
          role="dialog"
          id="removeUserpopup"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-sm modal-dialog-centered ">
            <div
              className="modal-content modal-sm modal_content_whole"
              style={{ background: "white" }}
            >
              <div className="modal-body">
                <div>
                  <div className="popup_icon_center">
                    <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                  </div>
                  <div className="popup_text_center">
                    <span className="popup_addbankdetails_text">
                      Are you sure
                      <br /> want to remove this user ?
                    </span>
                  </div>
                  <div
                    className="pt-3 d-flex justify-content-center"
                    style={{ gap: "10px" }}
                  >
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <button
                        type="button"
                        className="popup_btn_unlinkaccount"
                        data-toggle="modal"
                        data-target=".partner-details-deleted-success"
                        data-dismiss="modal"
                      >
                        Remove
                      </button>
                    </div>
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <button className="popup_btn_close" data-dismiss="modal">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* DeactivateUser modal*/}
        <div
          className="modal fade partner_details_deleter"
          role="dialog"
          id="DeactivateUserpopup"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-sm modal-dialog-centered ">
            <div
              className="modal-content modal-sm modal_content_whole"
              style={{ background: "white" }}
            >
              <div className="modal-body">
                <div>
                  <div className="popup_icon_center">
                    <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                  </div>
                  <div className="popup_text_center">
                    <span className="popup_addbankdetails_text">
                      Are you sure <br /> want to deactivate this user ?
                    </span>
                  </div>
                  <div
                    className="pt-3 d-flex justify-content-center"
                    style={{ gap: "10px" }}
                  >
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <button
                        type="button"
                        className="popup_btn_unlinkaccount"
                        data-toggle="modal"
                        data-target=".partner-details-deleted-success"
                        data-dismiss="modal"
                      >
                        Deactivate
                      </button>
                    </div>
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <button className="popup_btn_close" data-dismiss="modal">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* deletusermultiple */}
        <div
          id="deleteuser"
          className="modal fade "
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-sm modal-dialog-centered ">
            <div
              className="modal-content modal-sm modal_content_whole"
              style={{ background: "white" }}
            >
              <div className="modal-body">
                <div>
                  <div className="popup_icon_center">
                    <InfoRoundedIcon className="popup_delete_proj_icon" />
                  </div>
                  <div className="popup_text_center">
                    <span className="popup_addbankdetails_text">
                      Are you sure want to Delete {userids.length}{" "}
                      {userids.length > 1 ? "Users" : "User"}?
                    </span>
                  </div>
                  <div className="pt-3 d-flex" style={{ gap: "10px" }}>
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      onClick={() => {
                        deletemultipleusers();
                      }}
                    >
                      Delete
                    </button>

                    <button className="popup_btn_close" data-bs-dismiss="modal">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* add */}
        <div>
          <Formik
            initialValues={{
              name: "",
              phone: "",
              user_name: "",
              password: "",
              user_type: "",
              emp_ID: "",
            }}
            onSubmit={(values, { resetForm }) => {
              setBtnLoader(true);

              const adduserdata = {
                name: values.name,
                phone: values.phone,
                user_name: `${values.user_name.toLowerCase()}@cendrol.com`,
                password: values.password,
                user_type: values.user_type,
                emp_ID: values.emp_ID.toUpperCase(),
              };

              const headers = {
                "Content-Type": "application/json",
              };

              axiosClientInterceptors
                .post(`/adduser`, adduserdata, {
                  headers,
                })
                .then((sentdata) => {
                  let Status = sentdata.data.status;
                  if (Status === "success") {
                    getSiteEngineer();
                    getmanagers();
                    window.$("#adduser").modal("hide");
                    toast.success("User Added Successfully!!!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setBtnLoader(false);
                    resetForm({ values: "" });
                    getusers();
                  } else {
                    setBtnLoader(false);
                    toast.error(`${sentdata.data.msg}`, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    resetForm({ values: "" });
                  }
                });
            }}
          >
            {({ errors, touched, handleSubmit }) => (
              <div>
                <Form>
                  <div
                    className="modal fade"
                    id="adduser"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div
                          className="modal-header"
                          style={{ borderBottom: "none", padding: "15px 20px" }}
                        >
                          <span className="user_modal_head_txt"> Add User</span>
                          <div
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <CloseIcon />
                          </div>
                        </div>
                        <div className="modal-body pt-0">
                          <div className="form_invoice_upload">
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <TextField
                                  type="text"
                                  name="name"
                                  validate={validateusername}
                                  label="User Name"
                                  placeholder="eg. Ajit"
                                />{" "}
                              </div>
                              <div className="col-12 col-md-6">
                                <TextField
                                  type="text"
                                  name="emp_ID"
                                  label="Employee ID"
                                  validate={validateempid}
                                  placeholder="eg. CEN477"
                                />
                              </div>
                              <div className="col-12 col-md-6 mt-2">
                                <NumberField
                                  type="tel"
                                  name="phone"
                                  label="Phone Number"
                                  validate={validatemob}
                                  placeholder="eg. 88676XXXXX"
                                />
                              </div>
                              <div className="col-12 col-md-6 mt-2">
                                <KeyboardArrowDownIcon className="dropdown_arrow" />
                                <SelectField
                                  className="form-control bluck_input shadow-none  false"
                                  name="user_type"
                                  label="User Type"
                                  validate={validateusertype}
                                  style={{ cursor: "pointer" }}
                                >
                                  <option value="" selected disabled>
                                    Choose user type
                                  </option>
                                  {userGrp?.map((el, index) => {
                                    return (
                                      <>
                                        <option value={el.user_group}>
                                          {el.user_group}
                                        </option>
                                      </>
                                    );
                                  })}
                                </SelectField>
                              </div>
                              <div className="col-12 col-md-6">
                                <TextField
                                  type="text"
                                  cendrol={true}
                                  validate={validateUserID}
                                  name="user_name"
                                  label="User ID"
                                  placeholder="eg. tejas"
                                  style={{ textTransform: "lowercase" }}
                                />
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="justify-content-between align-items-center">
                                  <div className="align-items-center">
                                    <label className="select_label pb-2">
                                      Password
                                    </label>
                                    <div
                                      data-tip
                                      data-for="registerTip"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      className="d-flex"
                                      style={{
                                        lineHeight: "0rem",
                                        padding: "11px",
                                        backgroundColor: "#f8f8f8",
                                        fontFamily: "Gm",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <Field
                                        className="pass1"
                                        style={{
                                          width: "90%",
                                          border: "none",
                                          borderRadius: "0.25rem",
                                          backgroundColor: "#f8f8f8",
                                        }}
                                        type={
                                          passwordShown ? "text" : "password"
                                        }
                                        name="password"
                                        validate={validatepass}
                                        placeholder="eg. XXXXX"
                                      />

                                      {passwordShown ? (
                                        <VisibilityIcon
                                          fontSize="small"
                                          onClick={togglePassword}
                                        />
                                      ) : (
                                        <VisibilityOffIcon
                                          fontSize="small"
                                          onClick={togglePassword}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {errors.password && touched.password && (
                                    <p className="error pt-2 m-0">
                                      {errors.password}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div>
                                <ReactTooltip
                                  className="tooltip   shadow"
                                  backgroundColor="white"
                                  textColor="black"
                                  id="registerTip"
                                  place="bottom"
                                  effect="solid"
                                >
                                  <p className="notepass">
                                    Password should meet following Requirements
                                  </p>

                                  <div className="mt-2 d-flex   justify-content-start align-items-center">
                                    <CircleIcon
                                      className="me-1"
                                      style={{ width: "15px" }}
                                    />

                                    {/* <div className="listiconroundcurrect me-2">
                                      <CheckIcon style={{ width: "15px" }} />
                                    </div> */}

                                    <p className="characters">
                                      At least 8 characters
                                    </p>
                                  </div>

                                  <div className="mt-1 d-flex justify-content-start align-items-center ">
                                    <CircleIcon
                                      className="me-1"
                                      style={{ width: "15px" }}
                                    />

                                    <p className="characters">
                                      A mixture of both uppercase and lowercase
                                      letters.
                                    </p>
                                  </div>

                                  <div className="mt-1 d-flex justify-content-start align-items-center">
                                    <CircleIcon
                                      className="me-1"
                                      style={{ width: "15px" }}
                                    />

                                    <p className="characters">
                                      A mixture of letters and numbers.
                                    </p>
                                  </div>

                                  <div className="mt-1 d-flex justify-content-start align-items-center">
                                    <CircleIcon
                                      className="me-1"
                                      style={{ width: "15px" }}
                                    />

                                    <p className="characters">
                                      Inclusion of at least one special
                                      character, e.g., ! @ # ?
                                    </p>
                                  </div>
                                </ReactTooltip>
                              </div>
                            </div>
                            <div className="mt-2">
                              <button
                                className="btn_add_user mt-3 text-center"
                                type="submit"
                                style={{ width: "100%" }}
                              >
                                {BtnLoader === true ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : null}
                                Add User
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
        {/* add */}

        <>
          {singleUse === "alluser" && (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="dashboardtitle m-0">Manage Users</p>
                  <p className="m-0 usertitle">{totlePage} Users</p>
                </div>

                <div className="d-flex  align-items-center">
                  <div className="adduserbtn">
                    <div
                      className="userbtn"
                      data-bs-toggle="modal"
                      data-bs-target="#adduser"
                    >
                      <img src={Plus} alt="" />
                      Add User
                    </div>
                    {counter > 0 && (
                      <Button
                        data-bs-toggle="modal"
                        data-bs-target="#deleteuser"
                        style={{
                          textTransform: "none",
                          border: " 1px solid #FE3E3E",
                          color: "#FE3E3E",
                        }}
                        className="fontmatrialbtn"
                        variant="outlined"
                        startIcon={
                          <DeleteIcon
                            fontSize="small"
                            style={{ color: "#FE3E3E" }}
                          />
                        }
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              {/* search & filter */}
              <div className="d-flex mt-3">
                <div className="searchrequst d-flex justify-content-between">
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: "92%" }}
                  >
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setsearch(e.target.value)}
                      className="w-100 inputsearch"
                      placeholder="Search by id, name, user type"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          getusers();
                        }
                      }}
                    />
                    {search.length > 0 && (
                      <CloseIcon
                        className="mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          getusers1();
                          setsearch("");
                        }}
                      />
                    )}
                    <button
                      className="serchbuttonrequest"
                      onClick={() => getusers()}
                      disabled={search.length === 0}
                    >
                      <img src={Search} alt="" />
                      <span>Search</span>
                    </button>
                  </div>
                  <div
                    className="filterrequest"
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    <img src={filtersImg} alt="" className="ms-1" />
                    <span style={{ color: "#909090" }}>Filter</span>
                  </div>
                </div>
              </div>
              {isLoding ? (
                <div
                  className="App d-flex justify-content-center align-items-center"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    height: "100vh",
                    background: "white",
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <>
                  {userData.length === 0 ? (
                    <div className=" mt-3 NoRecordFound">
                      <img src={NoRecordFound} />
                    </div>
                  ) : (
                    <div
                      className="mt-3"
                      style={{
                        background: "#F8F8F8",
                      }}
                    >
                      <div
                        className="inner_table_div pt-0"
                        style={{ height: "calc(100vh - 230px)" }}
                      >
                        <table className="table leaderboard_table">
                          <tbody>
                            <tr className="headers_tag">
                              <th className="text-center">
                                {/* <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setCounter((counter) => counter + 1);
                                    } else {
                                      setCounter((counter) => counter - 1);
                                    }
                                    let value = e.target.checked;
                                    setUserData(
                                      userData.map((de) => {
                                        de.select = value;
                                        return de;
                                      })
                                    );
                                  }}
                                /> */}
                              </th>
                              <th className="text-center">Sl.No</th>

                              <th className="text-center">User Name</th>
                              <th className="text-center">User Type</th>
                              <th className="text-center">Status</th>
                              <th className="text-center">User Id</th>

                              <th className="text-center"></th>
                            </tr>

                            <>
                              {userData?.map((val, index) => (
                                <tr
                                  className="vals"
                                  key={index}
                                  onClick={(e) => {
                                    let getCurrentCellIndex =
                                      e.target.cellIndex;
                                    let getLastCellIndex =
                                      document.querySelector(
                                        "table tr:last-child td:nth-child(1)"
                                      ).cellIndex;
                                    if (
                                      getCurrentCellIndex !==
                                        getLastCellIndex &&
                                      getCurrentCellIndex !== undefined
                                    ) {
                                      setUserId(val._id);
                                      setSingleUse("singleuser");
                                    }
                                  }}
                                >
                                  <td className="text-center">
                                    {/* <input
                                      type="checkbox"
                                      // checked={des.select}
                                      checked={val.select}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setCounter((counter) => counter + 1);
                                        } else {
                                          setCounter((counter) => counter - 1);
                                        }

                                        setUserData(
                                          userData.map((sd) => {
                                            if (sd._id === val._id) {
                                              sd.select = e.target.checked;
                                            }
                                            return sd;
                                          })
                                        );
                                      }}
                                    /> */}
                                  </td>
                                  <td className="text-center">
                                    {(currentpage - 1) * perPage + index + 1}
                                  </td>

                                  <td className="text-center">{val.name}</td>
                                  <td className="text-center">
                                    {val.user_type}
                                  </td>
                                  <td className="text-center">
                                    {val.user_status === "active" ? (
                                      <button className="user_active">
                                        Active
                                      </button>
                                    ) : val.user_status === "inactive" ? (
                                      <button className="user_inactive">
                                        Inactive
                                      </button>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {val.user_name}
                                  </td>

                                  <td className="text-center">
                                    <CgArrowRightR
                                      className="partner_back_btn"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setUserId(val._id);
                                        setSingleUse("singleuser");
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="w-100 d-flex justify-content-end align-items-center">
                <div className="flotpagination">
                  <PaginationComponent
                    handleChangePage={handleChangePage}
                    perPage={perPage}
                    totlePage={totlePage}
                    currentpage={currentpage}
                  />
                </div>
              </div>
            </>
          )}
        </>
        <>
          {singleUse === "singleuser" && (
            <>
              <ManageUserDetails
                Id={userId}
                singleUser={setSingleUse}
                getusers={getusers}
              />
            </>
          )}
        </>
      </div>
    </>
  );
};

export default ManageUsers;
