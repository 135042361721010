import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

const DropQr = ({ data, setdata, newfileName }) => {
  const [fileName, setfileName] = useState("");
  const handleImage = (acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles[acceptedFiles.length - 1]);
    setfileName(acceptedFiles[acceptedFiles.length - 1].path);
    setdata(acceptedFiles[acceptedFiles.length - 1]);
    // setBulkFile(acceptedFiles);
  };
  useEffect(() => {
    if (newfileName) {
      setfileName(newfileName);
    }
    console.log(newfileName);
  }, []);
  return (
    <Dropzone onDrop={handleImage}>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            className: "AddvendorInput mt-2",
          })}
          style={{ width: "300px", height: "47px", position: "relative" }}
        >
          {fileName ? <p>{fileName}</p> : <p>Choose a file</p>}
          <input name="bulkdata " multiple={false} {...getInputProps()} />
          <div className="up_button  ">
            <DriveFolderUploadIcon />
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default DropQr;
