import React from "react";
import { ErrorMessage, useField } from "formik";

export const NumberField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      <label className="pb-2 label_textfields" htmlFor={field.name}>
        {label}
      </label>
      <div className="d-flex align-items-center">
        <div className="numer_91">+91</div>
        <div>
          <input
            className={`form-control bluck_input shadow-none  ${
              meta.touched && meta.error && "is-invalid"
            }`}
            {...field}
            {...props}
            autoComplete="off"
            maxLength={10}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </div>
      <ErrorMessage component="div" name={field.name} className="error pt-2" />
    </div>
  );
};
