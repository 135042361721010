import React, { useState } from "react";
import { DateRangePicker } from "rsuite";
import { startOfDay, endOfDay, subMonths, subYears } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { Ranges } from "../../../ReusableComponent/Validation";

const InvoiceFilter = ({
  handleClose,
  getinvoice,
  onChange,
  setStartDate,
  setEndDate,
  setStartDate1,
  setEndDate1,
  getinvoice1,
  holdfilter,
}) => {
  return (
    <div className="p-3 ">
      <div className="d-flex justify-content-between align-items-center">
        <p className="m-0 Filter_text">Apply Filter</p>
        <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
      </div>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div className="w-100 ">
          <p className=" Package_title">Uploaded On</p>
          <DateRangePicker
            placement={"bottom"}
            style={{ fontFamily: "Gm" }}
            name="start"
            placeholder="Select Date Range"
            format="dd/MM/yyyy"
            character="-"
            onChange={onChange}
            ranges={Ranges}
            defaultValue={holdfilter}
            cleanable={true}
          />
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center mt-4"
        style={{ gap: "10px" }}
      >
        <div className="w-50 ResetAll">
          <p
            className="m-0"
            onClick={() => {
              setStartDate("");
              setEndDate("");
              setStartDate1("");
              setEndDate1("");
              handleClose();
              getinvoice1();
            }}
          >
            Reset All
          </p>
        </div>
        <div
          className="w-50 Allfilter"
          onClick={() => {
            getinvoice();
            handleClose();
          }}
        >
          <p className="m-0">Apply Filter</p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceFilter;
