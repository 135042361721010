import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./Component/Login/Login";
// import Dashboard from "./Component/ProcurementDept/Dashboard/Dashboard";
import AdminDash from "./Component/Admin/AdminDash";
import ProjectManagerDashboard from "./Component/ProjectManager/PMDash";
import ProcDeptDash from "./Component/ProcurementDept/PdDash";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import Error from "./ReusableComponent/Error";

import { useEffect } from "react";
import io from "socket.io-client";
import AutoLogout from "./ReusableComponent/AutoLogout";
// const socket = io(process.env.REACT_APP_SERVER_URL, {
//   path: `/${process.env.REACT_APP_SOCKET}`,
//   transports: ["websocket"],
//   query: {
//     _id: localStorage.getItem("_id"),
//     user_type: localStorage.getItem("role"),
//   },
// });

function App() {
  // socket Call
  const SocketCall = (event, SiteEngId, ProjectManagerID, status) => {
    console.log("socket OFF");
    // socket.emit(event, {
    //   status,
    //   site_engineer: SiteEngId,
    //   project_manager: ProjectManagerID,
    // });
  };
  const SocketCallData = (event, RequestID, status) => {
    console.log("socket OFF");
    // socket.emit(event, {
    //   status,
    //   request_id: RequestID,
    // });
  };

  return (
    <div className="App">
      <AutoLogout>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route
            path="/adminallreq"
            element={
              <AdminDash
              // socket={socket}
              // SocketCall={SocketCall}
              // SocketCallData={SocketCallData}
              />
            }
          />
          <Route
            path="/pmdashboard"
            element={
              <ProjectManagerDashboard
              // socket={socket}
              // SocketCall={SocketCall}
              />
            }
          />
          <Route
            path="/pddashboard"
            element={
              <ProcDeptDash
              //  socket={socket} SocketCall={SocketCall}
              />
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>

        <ToastContainer />
      </AutoLogout>
    </div>
  );
}

export default App;
