import React from "react";
import { useFormik } from "formik";
import EditIcons from "../../../../Assets/Img/EditIcons.svg";
import * as yup from "yup";
import { useState } from "react";
import PaymentTermsradioBTN from "./PaymentTermsradioBTN";
import { useEffect } from "react";
import axiosClientInterceptors from "../../../../ReusableComponent/axiosClentInterceptors";
import { toast } from "react-toastify";
const PaymentTerm = ({
  Payment_Term,
  setPaymentTerm,
  paymentTermsdata,
  getVEndordata,
  vendordetailsID,
  dontShowEdit,
}) => {
  const [Radiobtn_Value, setRadiobtn_Value] = useState("");
  const [inputs, setInputs] = useState([
    { persentage: "", no_of_days: "", description: "" },
  ]);
  const [errors, setErrors] = useState([]);
  const [Day, setDay] = useState(paymentTermsdata?.single_payment_day);
  const [Day1, setDay1] = useState(paymentTermsdata?.advance_persentage);

  const validationSchema = yup.object().shape({
    PaymentType: yup.string().required("required field"),
    Description: yup.string().required("required field"),
  });
  const formik = useFormik({
    initialValues: {
      PaymentType: "",
      Description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPaymentTerm(false);
      alert(JSON.stringify(values, null, 2));
    },
  });

  function validateArrayOfObjects(arr) {
    let isValid = true;

    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];

      if (!obj.persentage || !obj.no_of_days || !obj.description) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }
  const handleFormSubmit = (e) => {
    // validate inputs
    const newErrors = inputs.map((input) => {
      return {
        persentage: validateInput(input.persentage),
        no_of_days: validateInput(input.no_of_days),
        description: validateInput(input.description),
      };
    });
    setErrors(newErrors);

    // submit form data if all inputs are valid
    const allInputsValid = newErrors.every((error) => {
      return (
        error.persentage === "" &&
        error.no_of_days === "" &&
        error.description === ""
      );
    });
    if (allInputsValid) {
      // submit form data to server
    }
  };
  const validateInput = (value) => {
    if (value.trim() === "") {
      return "This field is required.";
    }
    return "";
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };
  const handleRemoveInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
    setErrors([]);
  };
  const handleAddInput = () => {
    setInputs((prev) => {
      if (prev)
        return [...inputs, { persentage: "", no_of_days: "", description: "" }];
      return [{ persentage: "", no_of_days: "", description: "" }];
    });
    setErrors([]);
  };
  useEffect(() => {
    setRadiobtn_Value(paymentTermsdata?.payment_terms);
  }, []);
  const AddPaymentTerms = () => {
    if (Radiobtn_Value === "Single Payment Term") {
      let data = {
        payment_terms_id: paymentTermsdata?._id,
        payment_terms: "Single Payment Term",
        single_payment_day: Day,
      };
      axiosClientInterceptors
        .post(`/updatevendor?_id=${vendordetailsID}`, data)
        .then(function (response) {
          if (response.data.status === "success") {
            toast.success(`${response.data.msg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setPaymentTerm(false);
            getVEndordata();
            setRadiobtn_Value("single_payment");
          } else {
            toast.error(`${response.data.msg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } else if (Radiobtn_Value === "Installment") {
      let data = {
        payment_terms_id: paymentTermsdata?._id || "",
        payment_terms: "Installment",
        payment_details: inputs,
      };
      if (!validateArrayOfObjects(inputs)) {
        toast.info(`Please fill The ${Radiobtn_Value} `, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        axiosClientInterceptors
          .post(`/updatevendor?_id=${vendordetailsID}`, data)
          .then(function (response) {
            if (response.data.status === "success") {
              toast.success(`${response.data.msg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setPaymentTerm(false);
              getVEndordata();
              setRadiobtn_Value("single_payment");
            } else {
              toast.error(`${response.data.msg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
      }
    } else if (Radiobtn_Value === "Advance") {
      let data = {
        payment_terms_id: paymentTermsdata?._id,
        payment_terms: "Advance",
        payment_details: inputs,
        advance_persentage: Day1,
      };
      if (!validateArrayOfObjects(inputs)) {
        toast.info(`Please fill The ${Radiobtn_Value} `, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        axiosClientInterceptors
          .post(`/updatevendor?_id=${vendordetailsID}`, data)
          .then(function (response) {
            if (response.data.status === "success") {
              toast.success(`${response.data.msg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              setPaymentTerm(false);
              getVEndordata();
              setRadiobtn_Value("single_payment");
            } else {
              toast.error(`${response.data.msg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
      }
    }
  };
  useEffect(() => {
    axiosClientInterceptors
      .get(
        `/get-payment-terms?vendor_id=${vendordetailsID}&type=${Radiobtn_Value}`
      )
      .then((response) => {
        setInputs(response.data.result?.payment_details);
        setDay(response.data.result?.single_payment_day);
        setDay1(response.data.result?.advance_persentage);
      });
  }, [Radiobtn_Value]);

  return (
    <form onSubmit={formik.handleSubmit} className="PersonalInfo mt-4">
      <div className="d-flex justify-content-between align-items-center">
        {" "}
        <p className="PersonalInfo_title">Payment terms</p>
        {Payment_Term ? (
          <button
            type="submit"
            className=""
            style={{
              color: "#fcc200",
              background: "transparent",
              fontFamily: "Gm",
            }}
            onClick={() => AddPaymentTerms()}
          >
            Save
          </button>
        ) : (
          <>
            {dontShowEdit === false && (
              <img
                src={EditIcons}
                className="new_edit"
                onClick={() => {
                  setRadiobtn_Value(paymentTermsdata?.payment_terms);
                  setPaymentTerm(true);
                  setDay1(paymentTermsdata?.advance_persentage);
                  setDay(paymentTermsdata?.single_payment_day);
                  setInputs(paymentTermsdata?.payment_details);
                }}
              />
            )}
          </>
        )}
      </div>
      <hr />
      {Payment_Term ? (
        <PaymentTermsradioBTN
          setInputs={setInputs}
          paymentTermsdata={paymentTermsdata}
          Day={Day}
          setDay={setDay}
          Day1={Day1}
          setDay1={setDay1}
          handleChange={handleChange}
          handleFormSubmit={handleFormSubmit}
          errors={errors}
          Radiobtn_Value={Radiobtn_Value}
          setRadiobtn_Value={setRadiobtn_Value}
          inputs={inputs}
          handleRemoveInput={handleRemoveInput}
          handleAddInput={handleAddInput}
        />
      ) : (
        <>
          <>
            {paymentTermsdata ? (
              <>
                {paymentTermsdata.payment_terms === "Installment" ? (
                  ""
                ) : (
                  <div class="row mt-4">
                    <div class="col-2">
                      {" "}
                      <p className="parentPersonalInfo1_text">Payment Type</p>
                      <p
                        className="parentPersonalInfo1_subtext"
                        style={{ textTransform: "capitalize" }}
                      >
                        {paymentTermsdata?.payment_terms}
                      </p>
                    </div>
                    <div class="col-md-3">
                      {" "}
                      <p className="parentPersonalInfo1_text">
                        {paymentTermsdata.payment_terms === "Advance"
                          ? "Percentage"
                          : "Description"}
                      </p>
                      <p className="parentPersonalInfo1_subtext">
                        {paymentTermsdata.payment_terms === "Advance" &&
                        paymentTermsdata.advance_persentage
                          ? `${paymentTermsdata.advance_persentage} % should be paid before date of invoice`
                          : `${paymentTermsdata.single_payment_day} days from the date of invoice`}
                      </p>
                    </div>
                  </div>
                )}
                {paymentTermsdata.payment_details &&
                Array.isArray(paymentTermsdata.payment_details) &&
                paymentTermsdata.payment_details.length > 0
                  ? paymentTermsdata.payment_details.map((val, i) => (
                      <div class="row mt-4">
                        <div class="col-md-2">
                          {" "}
                          <p className="parentPersonalInfo1_text">
                            Payment Type
                          </p>
                          <p className="parentPersonalInfo1_subtext">
                            Installment
                          </p>
                        </div>
                        <div class="col-md-2">
                          {" "}
                          <p className="parentPersonalInfo1_text">Percentage</p>
                          <p className="parentPersonalInfo1_subtext">
                            {val?.persentage}%
                          </p>
                        </div>
                        <div class="col-md-2">
                          {" "}
                          <p className="parentPersonalInfo1_text">
                            No. of days
                          </p>
                          <p className="parentPersonalInfo1_subtext">
                            {val?.no_of_days} days
                          </p>
                        </div>
                        <div class="col-md-6">
                          {" "}
                          <p className="parentPersonalInfo1_text">
                            Description
                          </p>
                          <p className="parentPersonalInfo1_subtext">
                            {val.description}
                          </p>
                        </div>
                      </div>
                    ))
                  : ""}
              </>
            ) : (
              ""
            )}
          </>
        </>
      )}
    </form>
  );
};

export default PaymentTerm;
