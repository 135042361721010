import React, { useRef } from "react";
import SideBar from "../../ReusableComponent/SideBar";
import {
  selectactivetab,
  setnotificationCount,
} from "../../features/counter/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import Requests from "../Admin/AdminRequests/Requests";
import AllProjectsAdmin from "../Admin/AllProject/AllProjectsAdmin";
import Logout from "../Login/Logout";
import DashboardAdmin from "../Admin/DashboardAdmin/DashboardAdmin";
import { useState } from "react";
import Notification from "../Admin/Notification/Notification";
const PMDash = ({ socket, SocketCall }) => {
  const activetabsection = useSelector(selectactivetab);
  const [requestdata, setRequestData] = useState("listing");

  return (
    <div className="d-flex">
      <div className="sidlebarcontainer">
        <SideBar
        // socket={socket} setRequestData={setRequestData} 
        />
      </div>
      {activetabsection === "Dashboard" && (
        <div className="dashboardtabsection">
          <DashboardAdmin SocketCall={SocketCall} />
        </div>
      )}
      {activetabsection === "Requests" && (
        <div className="dashboardtabsection">
          <Requests
            setRequestData={setRequestData}
            requestdata={requestdata}
            socket={socket}
            SocketCall={SocketCall}
          />
        </div>
      )}
      {activetabsection === "Notifications" && (
        <div className="dashboardtabsection">
          <Notification setRequestData={setRequestData} />
        </div>
      )}
      {activetabsection === "All Projects" && (
        <div className="dashboardtabsection">
          <AllProjectsAdmin />
        </div>
      )}
      {activetabsection === "Contractors" && (
        <div className="dashboardtabsection">Contractors</div>
      )}
      {activetabsection === "Logout" && (
        <div className="dashboardtabsection">
          <Logout />
        </div>
      )}
    </div>
  );
};

export default PMDash;
