import { Modal } from "@mui/material";
import React from "react";
import { useState } from "react";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CloseIcon from "@mui/icons-material/Close";
import DateRangePicker from "rsuite/DateRangePicker";
import Search from "../../../Assets/Img/svg/search.svg";
import {
  setallprojectpagination,
  selectallprojectpagination,
  selectfilterAllrequest,
  setfilterAllrequest,
} from "../../../features/pagination/paginationSlice";
import {
  setAllRequestDateRange,
  selectAllRequestDateRange,
  setAllRequestLastActivity,
  selectAllRequestLastActivity,
} from "../../../features/DateRangeSlice/DateRangeSlice";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import PaginationComponent from "../../../ReusableComponent/PaginationComponent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Loader from "../../../ReusableComponent/Loader";
import NoRecordFound from "../../../Assets/Img/NoRecordFound.svg";
import {
  selectallProjects,
  selectAllTheSiteEng,
  selectrequestIdFRomProject,
  setNotificationStatusScreen,
  setrequestID,
} from "../../../features/counter/counterSlice";
import filtersImg from "../../../Assets/Img/svg/filter.svg";
import { Ranges } from "../../../ReusableComponent/Validation";
import { setscreen } from "../../../features/Screen/screen";
import { flushSync } from "react-dom";

const AllRequestTab = ({
  setRequestData,
  Setrequestidentity,
  getRequestDetails,
  // getTotlerequestcount,
}) => {
  const SiteEngineer = useSelector(selectAllTheSiteEng);
  const projectName = useSelector(selectallProjects);
  const requestIDfromPRoject = useSelector(selectrequestIdFRomProject);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [allrequestdata, setAllRequestData] = useState([]);
  const [search, setsearch] = useState("");
  const [perPage, setPerPge] = useState(10);
  const [totlePage, setTotalPage] = useState("");
  const allprojectpagination = useSelector(selectallprojectpagination);
  const filter = useSelector(selectfilterAllrequest);
  const dateRange = useSelector(selectAllRequestDateRange);
  const dateRangeactivity = useSelector(selectAllRequestLastActivity);
  const [MaterialCategory, setMaterialCategory] = useState(
    filter.material_category
  );
  const [priorityval, Setpriorityval] = useState(filter.priority);
  const [siteenggval, Setsiteenggval] = useState(filter.site_engineer);
  const [reqstat, Setreqstat] = useState(filter.request_status);
  const [ProjectStage, SetProjectStage] = useState(filter.project_stage);
  const [SiteAddress, setSiteAddress] = useState(filter.project_location);
  const [dropdowncategories, setdropdowncategories] = useState([]);
  const [isLoding, setLoading] = useState(false);

  const onChange = (dates) => {
    dispatch(setAllRequestDateRange(dates));
  };
  const onChange1 = (dates) => {
    dispatch(setAllRequestLastActivity(dates));
  };

  const getAllRequests = (searchstatus) => {
    setLoading(true);
    let activityFrom;
    let activityTo;
    let rangedatestart = dateRange[1]; //moment().format("YYYY-MM-DD");
    let rangedateend = dateRange[0]; //moment().format("YYYY-MM-DD");
    if (rangedatestart > rangedateend) {
      activityFrom = rangedateend;
      activityTo = rangedatestart;
    } else {
      activityFrom = rangedatestart;
      activityTo = rangedateend;
    }
    let activityFrom1;
    let activityTo1;
    let rangedatestart1 = dateRangeactivity[1]; // moment().format("YYYY-MM-DD");
    let rangedateend1 = dateRangeactivity[0]; // moment().format("YYYY-MM-DD");
    if (rangedatestart1 > rangedateend1) {
      activityFrom1 = rangedateend1;
      activityTo1 = rangedatestart1;
    } else {
      activityFrom1 = rangedatestart1;
      activityTo1 = rangedateend1;
    }
    const filterrequest = {
      to_date: activityTo,
      from_date: activityFrom,
      activity_from_date: activityFrom1,
      activity_to_date: activityTo1,
      material_category: MaterialCategory,
      priority: priorityval,
      site_engineer: siteenggval,
      request_status: reqstat,
      project_name: ProjectStage,
      project_location: SiteAddress,
    };
    dispatch(setfilterAllrequest(filterrequest));

    axiosClientInterceptors
      .post(
        `/getrequests?project_id=${requestIDfromPRoject && requestIDfromPRoject
        }&page=${allprojectpagination}&limit=${perPage}&search=${searchstatus ? search : ""
        }`,
        filterrequest
      )
      .then((resp) => {
        setAllRequestData(resp.data.result.listOfRequest);
        dispatch(setallprojectpagination(resp.data.result.currentPage));
        setTotalPage(resp.data.result.totalRequest);
        // getTotlerequestcount(resp.data.result.totalRequest);
        setLoading(false);
      });
  };
  const getAllRequests2 = (reset) => {
    setLoading(true);
    setMaterialCategory("");
    Setpriorityval("");
    Setsiteenggval("");
    Setreqstat("");
    SetProjectStage("");
    setSiteAddress("");
    let activityFrom;
    let activityTo;
    let rangedatestart = dateRange[1]; //moment().format("YYYY-MM-DD");
    let rangedateend = dateRange[0]; //moment().format("YYYY-MM-DD");
    if (rangedatestart > rangedateend) {
      activityFrom = rangedateend;
      activityTo = rangedatestart;
    } else {
      activityFrom = rangedatestart;
      activityTo = rangedateend;
    }
    let activityFrom1;
    let activityTo1;
    let rangedatestart1 = dateRangeactivity[1]; // moment().format("YYYY-MM-DD");
    let rangedateend1 = dateRangeactivity[0]; // moment().format("YYYY-MM-DD");
    if (rangedatestart1 > rangedateend1) {
      activityFrom1 = rangedateend1;
      activityTo1 = rangedatestart1;
    } else {
      activityFrom1 = rangedatestart1;
      activityTo1 = rangedateend1;
    }
    const filterrequest = {
      to_date: reset ? "" : activityTo,
      from_date: reset ? "" : activityFrom,
      activity_from_date: reset ? "" : activityFrom1,
      activity_to_date: reset ? "" : activityTo1,
      material_category: reset ? "" : MaterialCategory,
      priority: reset ? "" : priorityval,
      site_engineer: reset ? "" : siteenggval,
      request_status: reset ? "" : reqstat,
      project_name: reset ? "" : ProjectStage,
      project_location: reset ? "" : SiteAddress,
    };

    axiosClientInterceptors
      .post(
        `/getrequests?project_id=${requestIDfromPRoject && requestIDfromPRoject
        }&page=1&limit=${perPage}&search=`,
        filterrequest
      )
      .then((resp) => {
        setAllRequestData(resp.data.result.listOfRequest);
        dispatch(setallprojectpagination(resp.data.result.currentPage));
        setTotalPage(resp.data.result.totalRequest);
        // getTotlerequestcount(resp.data.result.totalRequest);
        setLoading(false);
      });
  };

  const getcategories = () => {
    axiosClientInterceptors
      .get(`/getcategories`)
      .then((response) => {
        setdropdowncategories(response.data.Category);
      })
      .catch((error) => console.log("error", error));
  };

  const handleChangePage = (e, pageSize) => {
    dispatch(setallprojectpagination(e));
    setPerPge(pageSize);
  };

  useEffect(() => {
    getAllRequests();
  }, [allprojectpagination, perPage]);

  useEffect(() => {
    getcategories();
  }, []);

  return (
    <>
      <Modal
        className="d-flex justify-content-center align-items-center flex-column "
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className=" allProjectFilter ">
          <div className="d-flex justify-content-between mb-5 align-items-center">
            <p className="m-0 Filter_text ">Apply Filter</p>
            <CloseIcon onClick={handleClose} />
          </div>

          <div>
            <div className="row ">
              <div className="col-6" style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Creation Date Range</label>
                <br />

                <DateRangePicker
                  id="filter_range"
                  name="start"
                  placeholder="Choose date range"
                  format="dd/MM/yyyy"
                  character="-"
                  style={{ zIndex: "999" }}
                  onChange={onChange}
                  ranges={Ranges}
                  defaultValue={dateRange}
                  cleanable={true}
                />
              </div>
              <div className="col-6" style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">
                  Last Activity Date Range
                </label>
                <br />
                <DateRangePicker
                  id="filter_range"
                  name="start"
                  placeholder="Choose date range"
                  format="dd/MM/yyyy"
                  character="-"
                  style={{ zIndex: "999" }}
                  onChange={onChange1}
                  ranges={Ranges}
                  defaultValue={dateRangeactivity}
                  cleanable={true}
                />
              </div>
              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2 ">Material Category</label>
                <br />
                <select
                  className="filter_usertype"
                  name="material_category"
                  style={{ border: "none" }}
                  value={MaterialCategory || filter.material_category}
                  onChange={(e) => {
                    setMaterialCategory(e.target.value);
                  }}
                >
                  <option selected value="" disabled>
                    Select Category
                  </option>
                  {dropdowncategories?.map((val, index) => (
                    <option key={index} value={val._id}>
                      {val.category_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Request Priority</label>
                <br />
                <select
                  className="filter_usertype"
                  name="priority"
                  style={{ border: "none" }}
                  value={priorityval || filter.priority}
                  onChange={(e) => {
                    Setpriorityval(e.target.value);
                  }}
                >
                  <option selected disabled value="">
                    Choose Priority
                  </option>
                  <option value="High">High Priority</option>
                  <option value="Medium">Medium Priority</option>
                  <option value="Low">Low Priority</option>
                </select>
              </div>

              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Site Engineer</label>
                <br />
                <select
                  className="filter_usertype"
                  name="site_engineer"
                  style={{ border: "none" }}
                  value={siteenggval || filter.site_engineer}
                  onChange={(e) => {
                    Setsiteenggval(e.target.value);
                  }}
                >
                  <option selected value="" disabled>
                    Select Site Engineer
                  </option>
                  {SiteEngineer?.map((op, index) => {
                    return (
                      <option key={op._id} value={op._id}>
                        {op.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Request Status</label>
                <br />
                <select
                  className="filter_usertype"
                  name="status"
                  style={{ border: "none" }}
                  value={reqstat || filter.request_status}
                  onChange={(e) => {
                    Setreqstat(e.target.value);
                  }}
                >
                  <option selected disabled value="">
                    Choose Status
                  </option>
                  {localStorage.getItem("role") === "Procurement Department" ? (
                    ""
                  ) : (
                    <option value="New Request">New Request</option>
                  )}
                  <option value="Approved">Approved</option>
                  <option value="RFQ Sent to Vendor">
                    RFQ Sent to Vendors
                  </option>
                  <option value="PO Created">PO Created</option>
                  <option value="PO Sent to Vendor">PO Sent to Vendor</option>
                  <option value="Payment Initiated">Payment Initiated</option>
                  <option value="Advance Paid">Advance Paid</option>
                  <option value="Post Advance Installment">
                    Post Advance Installment
                  </option>
                  <option value="Installment">Installment</option>
                  <option value="Single Payment Term">
                    Single Payment Term
                  </option>
                  <option value="Partially Received">Partially Received</option>
                  <option value="Material Received">Material Received</option>
                  <option value="Payment Done">Payment Done</option>
                  <option value="PO cancelled">PO Cancelled</option>
                  <option value="Closed">Closed</option>
                  {localStorage.getItem("role") === "Procurement Department" ? (
                    ""
                  ) : (
                    <option value="Rejected">Rejected</option>
                  )}
                </select>
              </div>

              <div className="col-6 col-md-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Project </label>
                <select
                  className="filter_usertype"
                  name="project_stage"
                  style={{ border: "none" }}
                  value={ProjectStage || filter.project_stage}
                  onChange={(e) => {
                    SetProjectStage(e.target.value);
                  }}
                >
                  <option selected value="" disabled>
                    Choose Project
                  </option>
                  {projectName?.map((ki, index) => {
                    return (
                      <option key={index} value={ki.project_name}>
                        {ki.project_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-6 col-md-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Site Address</label>
                <input
                  type="text"
                  style={{ border: "none" }}
                  className="filter_usertype p-1"
                  name="project_stage"
                  value={SiteAddress || filter.project_location}
                  onChange={(e) => setSiteAddress(e.target.value)}
                  placeholder="eg. Jp nagar, BTM, HSR layout"
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-6">
                  <div
                    className="filter_op_btns mx-auto"
                    onClick={() => {
                      dispatch(setAllRequestDateRange(""));
                      getAllRequests2(true);
                      Setpriorityval("");
                      dispatch(setAllRequestLastActivity(""));
                      handleClose();
                      dispatch(setfilterAllrequest(""));
                    }}
                  >
                    Reset All
                  </div>
                </div>
                <div className="col-6">
                  <button
                    onClick={() => {
                      getAllRequests();
                      handleClose();
                    }}
                    className="filter_op_btns1"
                  >
                    Apply Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* search & filter */}
      <div className="d-flex mt-3">
        <div className="searchrequst d-flex me-2">
          <input
            type="text"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            className="w-100 inputsearch"
            placeholder="Search by id, name, status"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                getAllRequests(true);
              }
            }}
          />
          {search.length > 0 && (
            <CloseIcon
              className="mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                getAllRequests(false);

                setsearch("");
              }}
            />
          )}
          <button
            className="serchbuttonrequest"
            disabled={search.length === 0}
            onClick={() => getAllRequests(true)}
          >
            <img src={Search} alt="" />
            <span>Search</span>
          </button>
        </div>
        <div
          className="filterrequest"
          onClick={() => {
            handleOpen();
          }}
        >
          <img src={filtersImg} alt="" className="me-1" />
          <span style={{ color: "#909090" }}>Filter</span>
        </div>
      </div>
      {isLoding ? (
        <div
          className="App d-flex justify-content-center align-items-center"
          style={{
            width: "100%",
            textAlign: "center",
            height: "100vh",
            background: "white",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {allrequestdata.length === 0 ? (
            <div className=" mt-3 NoRecordFound">
              <img src={NoRecordFound} />
            </div>
          ) : (
            <div className="mt-3 mr-2">
              <div className="inner_table_div">
                <table
                  className="table leaderboard_table"
                  style={{ width: "110%", overflowX: "scroll" }}
                >
                  <tbody>
                    <tr className="headers_tag">
                      <th className="text-center">Request ID</th>
                      <th className="text-center">Project Name</th>
                      <th className="text-center">Material Category</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Created By</th>
                      <th className="text-center">Due Date</th>
                      <th className="text-center">Last Activity Date & Time</th>
                    </tr>

                    {allrequestdata !== "" ? (
                      allrequestdata?.map((des, index) => (
                        <tr
                          key={des._id}
                          className="vals"
                          onClick={() => (
                            dispatch(setrequestID(des?._id)),
                            localStorage.setItem("REqustID", des?._id),
                            dispatch(setscreen("singledata")),
                            getRequestDetails(des?._id),
                            Setrequestidentity(des?._id)
                          )}
                        >
                          <td
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                              dispatch(
                                setNotificationStatusScreen("singledata")
                              );
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              {des?.priority == "High" && (
                                <div className="active_dot"></div>
                              )}
                              {des?.priority == "Medium" && (
                                <div className="med_pri"></div>
                              )}
                              {des?.priority == "Low" && (
                                <div className="low_pri"></div>
                              )}
                              {des?.request_id}
                            </div>
                          </td>
                          <td
                            className="text-center"
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des?.project_name}
                          </td>
                          <td
                            className="text-center"
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des?.material_category}
                          </td>
                          <td
                            className="text-center"
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des?.request_status}
                          </td>
                          <td
                            className="text-center"
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des?.requested_by}
                          </td>
                          <td
                            className="text-center"
                            onClick={() => {
                              dispatch(setrequestID(des?._id));
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des?.due_date}
                          </td>

                          <td
                            className="text-center"
                            onClick={() => {
                              dispatch(setrequestID(des?._id));
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des.updationdate}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div>
                        <div className="d-flex" style={{ gap: "10px" }}>
                          <WarningRoundedIcon className="no_records_found" />
                          <h3 className="pl-3" style={{ fontFamily: "gsb" }}>
                            No records found
                          </h3>
                        </div>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="w-100 d-flex justify-content-end align-items-center">
                <div className="flotpagination">
                  <PaginationComponent
                    handleChangePage={handleChangePage}
                    perPage={perPage}
                    totlePage={totlePage}
                    currentpage={allprojectpagination}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AllRequestTab;
