import React from "react";
import { useState } from "react";
import Search from "../../../Assets/Img/svg/search.svg";
import CloseIcon from "@mui/icons-material/Close";
import PaginationComponent from "../../../ReusableComponent/PaginationComponent";
import POfilter from "./POfilter";
import { useDispatch, useSelector } from "react-redux";
import NoRecordFound from "../../../Assets/Img/NoRecordFound.svg";

import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import {
  setFinalpoData,
  setPoID,
  setPOswitch,
  setPOvendor,
  setTermsncondition,
} from "../../../features/counter/counterSlice";
import {
  selectPOpagination,
  setPOpagination,
  selectPOfilterData,
  setPOfilterData,
} from "../../../features/pagination/paginationSlice";
import { useEffect } from "react";
import {
  selecPOfilter,
  setPOfilter,
} from "../../../features/DateRangeSlice/DateRangeSlice";
import newAxiosClientInterceptors from "../../../ReusableComponent/newAxiosClientInterceptors";
import Loader from "../../../ReusableComponent/Loader";
import {
  selectProjectScreen,
  setProjectScreen,
} from "../../../features/Screen/screen";
import moment from "moment";
const PurchageOrder = ({ singleViewId }) => {
  const projectScreen = useSelector(selectProjectScreen);
  const [projectdata, Setprojectdata] = useState([]);
  const filter = useSelector(selectPOfilterData);
  const [isLoding, setLoading] = useState(false);
  const [search, setsearch] = useState("");
  const [Project_Name, setProject_Name] = useState(filter.project_name);
  const [vendor, setVendor] = useState(filter.vendor_name)
  const [purchaseOrderStat, setPurchaseOrderStat] = useState(filter.PO_status);
  const dispatch = useDispatch();
  const [perPage, setPerPge] = useState(10);
  const [totlePage, setTotalPage] = useState("");
  const currentpage = useSelector(selectPOpagination);
  const dateRange = useSelector(selecPOfilter);


  // pagination handlar
  const handleChangePage = (e, pageSize) => {
    dispatch(setPOpagination(e));
    setPerPge(pageSize);
  };
  const onChange = (dates) => {
    dispatch(setPOfilter(dates));
  };

  // get vendor list for filter dropdown
  const getAllVendor = () => {
    newAxiosClientInterceptors
      .get(`/get-all-vendors-list`)
      .then((res) => {
        dispatch(setPOvendor(res.data?.result))
      })
      .catch((err) => {
        console.log(err);
      })
  }

  // to list out all projects
  const POlisting = () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
    };
    let activityFrom;
    let activityTo;
    let rangedatestart = moment(dateRange[1]).add(0, 'days');
    let rangedateend = dateRange[0];
    if (rangedatestart > rangedateend) {
      activityFrom = rangedateend;
      activityTo = rangedatestart;
    } else {
      activityFrom = rangedatestart;
      activityTo = rangedateend;
    }

    var filterrequest = {
      from_date: activityFrom,
      to_date: activityTo,
      project_name: Project_Name,
      PO_status: purchaseOrderStat,
      vendor_name: vendor
    };
    dispatch(setPOfilterData(filterrequest));
    axiosClientInterceptors
      .post(
        `/get-all-po?_id=${singleViewId || undefined
        }&page=${currentpage}&limit=${perPage}&search=${search}`,
        filterrequest,
        {
          headers,
        }
      )
      .then((resultr) => {
        dispatch(setPOpagination(resultr.data.result.currentPage));
        Setprojectdata(resultr.data.result.listOfPO);
        setTotalPage(resultr.data.result.totalPurchase);
        setLoading(false);
      });
  };
  const POlisting2 = () => {
    setLoading(true);
    setProject_Name("");
    setPurchaseOrderStat("");
    setVendor("");
    const headers = {
      "Content-Type": "application/json",
    };

    var filterrequest = {
      from_date: "",
      to_date: "",
      project_name: "",
      PO_status: "",
      vendor_name: "",
    };
    dispatch(setPOfilterData(""));
    axiosClientInterceptors
      .post(`/get-all-po?page=1&limit=${perPage}&search=`, filterrequest, {
        headers,
      })
      .then((resultr) => {
        dispatch(setPOpagination(resultr.data.result.currentPage));
        Setprojectdata(resultr.data.result.listOfPO);
        setTotalPage(resultr.data.result.totalPurchase);
        setProject_Name("");
        setVendor("");
        setPurchaseOrderStat("");
        setLoading(false);
      });
  };
  const click = (id) => {
    axiosClientInterceptors
      .get(`/getpurchasebyid?_id=${id}`)
      .then((responsedata) => {
        dispatch(setFinalpoData(responsedata.data.Purchase_Orders[0]));
        dispatch(setTermsncondition(responsedata.data.termsandcondition));
        console.log(
          responsedata.data.Purchase_Orders[0],
          "responsedata.data.Purchase_Orders[0]"
        );
      });
  };

  useEffect(() => {
    getAllVendor();
  }, [])

  useEffect(() => {
    POlisting();
  }, [currentpage, perPage]);
  return (
    <div>
      {projectScreen === "addminAllpo" && (
        <>
          <p className="m-0">
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setProjectScreen("allusers"));
                // setsingleView("allusers");
              }}
            >
              All Projects{" "}
            </span>
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setProjectScreen("singleuser"));
                // setsingleView("singleuser");
              }}
            >
              Project Details{" "}
            </span>
            /
            <span
              className="fontdocument_active"
              onClick={() => {
                // dispatch(setProjectScreen("AllInvoice"));
                // setsingleView("AllInvoice");
              }}
            >
              {" "}
              All Purchase Orders
            </span>
          </p>
        </>
      )}
      <div className="d-flex mt-3 ">
        <div className="searchrequst d-flex me-2">
          <input
            type="text"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            className="w-100 inputsearch"
            placeholder="Search by PO number"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                POlisting();
              }
            }}
          />
          {search.length > 0 && (
            <CloseIcon
              className="mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setsearch("");
                POlisting2();
              }}
            />
          )}
          <button
            className="serchbuttonrequest"
            onClick={() => POlisting()}
            disabled={search.length === 0}
          >
            <img src={Search} alt="" />
            <span>Search</span>
          </button>
        </div>
        <POfilter
          Project_Name={Project_Name}
          vendor={vendor}
          POlisting={POlisting}
          setProject_Name={setProject_Name}
          setVendor={setVendor}
          purchaseOrderStat={purchaseOrderStat}
          setPurchaseOrderStat={setPurchaseOrderStat}
          onChange={onChange}
          dateRange={dateRange}
          POlisting2={POlisting2}
          filters={filter}
        />
      </div>

      {isLoding ? (
        <div
          className="App d-flex justify-content-center align-items-center"
          style={{
            width: "100%",
            textAlign: "center",
            height: "100vh",
            background: "white",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {projectdata?.length === 0 ? (
            <div className=" mt-3 NoRecordFound">
              <img src={NoRecordFound} />
            </div>
          ) : (
            <div className="admin_inner_table_div">
              <div className="inner_table_div">
                <table className="table leaderboard_table">
                  <tbody>
                    <tr className="headers_tag">
                      <th className="text-center">Sl. No.</th>
                      <th className="text-center">PO Number</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Purchase Date</th>
                      <th className="text-center">Due Date</th>
                    </tr>
                    {projectdata?.map((val, i) => (
                      <tr
                        key={i}
                        className="vals "
                        onClick={() => {
                          singleViewId
                            ? dispatch(setProjectScreen("singlepo"))
                            : dispatch(setPOswitch("PurchaseOrderDOc"));
                          dispatch(setPoID(val._id));
                          click(val._id);
                        }}
                      >
                        <td className="text-center py-3">
                          {(currentpage - 1) * perPage + i + 1}
                        </td>
                        <td className="text-center py-3">
                          {val.purchase_order_no}
                        </td>
                        <td className="text-center d-flex justify-content-center">
                          <div
                            className={
                              val.PO_status === "PO cancelled"
                                ? "canceled_po"
                                : "new_po"
                            }
                          >
                            {val.PO_status ? val.PO_status : "New PO"}
                          </div>
                        </td>
                        <td className="text-center py-3">
                          {val.purchase_order_date}
                        </td>
                        <td className="text-center py-3">{val.due_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
      <div className="w-100 d-flex align-items-center justify-content-end">
        <div className="flotpagination">
          <PaginationComponent
            handleChangePage={handleChangePage}
            perPage={perPage}
            totlePage={totlePage}
            currentpage={currentpage}
          />
        </div>
      </div>
    </div>
  );
};

export default PurchageOrder;
