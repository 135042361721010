import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import Search from "../../../Assets/Img/svg/search.svg";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import "rsuite/styles/index.less"; // less
import "rsuite/dist/rsuite.min.css"; // or css
import "rsuite/dist/rsuite-rtl.min.css"; // or rtl css
// import FilterUsers from "./FilterUsers";
import InvoiceFilter from "./InvoiceFilter";
import AddInvoice from "./AddInvoice";
import { useDispatch, useSelector } from "react-redux";
import NoRecordFound from "../../../Assets/Img/NoRecordFound.svg";
import { CgArrowRightR } from "react-icons/cg";
import {
  setinvoiceCurrentpage,
  selectinvoiceCurrentpage,
} from "../../../features/pagination/paginationSlice";
import { useEffect } from "react";
import upload from "../../../Assets/Img/svg/upload.svg";
import filter from "../../../Assets/Img/svg/filter.svg";
import PaginationComponent from "../../../ReusableComponent/PaginationComponent";
import Loader from "../../../ReusableComponent/Loader";
import { setallInvoiceImg } from "../../../features/counter/counterSlice";
import { setProjectScreen } from "../../../features/Screen/screen";

const AllInvoice = ({ setsingleView, singleViewId, Project_id }) => {
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [search, setsearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate1, setStartDate1] = useState("");
  const [endDate1, setEndDate1] = useState("");
  const [POdropdownList, setPOdropdownList] = useState([]);
  const [projectdata, Setprojectdata] = useState([]);
  const [holdfilter, setHoldFilter] = useState("");
  const [holdfilter1, setHoldFilter1] = useState("");
  const dispatch = useDispatch();
  const currentpage = useSelector(selectinvoiceCurrentpage);
  const [Isloding, setIsloding] = useState(false);

  // pagination
  const [perPage, setPerPge] = useState(10);
  const [totlePage, setTotalPage] = useState("");

  //  get  Purchase Order Number
  const getPurchageOrderNumber = () => {
    axiosClientInterceptors
      .get(`/get-purchase-order?project_id=${Project_id}`)
      .then((resp) => {
        setPOdropdownList(resp.data.result);
      });
  };

  // invoice get
  const getinvoice = () => {
    setIsloding(true);
    const headers = {
      "Content-Type": "application/json",
    };
    let activityFrom;
    let activityTo;
    let rangedatestart = startDate;
    let rangedateend = endDate;
    if (rangedatestart > rangedateend) {
      activityFrom = rangedateend;
      activityTo = rangedatestart;
    } else {
      activityFrom = rangedatestart;
      activityTo = rangedateend;
    }
    let activityFrom1;
    let activityTo1;
    let rangedatestart1 = startDate1;
    let rangedateend1 = endDate1;
    if (rangedatestart1 > rangedateend1) {
      activityFrom1 = rangedateend1;
      activityTo1 = rangedatestart1;
    } else {
      activityFrom1 = rangedatestart1;
      activityTo1 = rangedateend1;
    }
    var raw = {
      to_date: activityFrom,
      from_date: activityTo,
      due_from_date: activityFrom1,
      due_to_date: activityTo1,
    };

    axiosClientInterceptors
      .post(
        `/getinvoices?page=${currentpage}&limit=${perPage}&search=${search}&project_id=${singleViewId}`,
        raw,
        {
          headers,
        }
      )
      .then((resultr) => {
        dispatch(setinvoiceCurrentpage(resultr.data.result.currentPage));
        Setprojectdata(resultr.data.result.listOfInvoice);
        setTotalPage(resultr.data.result.totalInvoice);
        setIsloding(false);
      });
  };

  const getinvoice1 = () => {
    setIsloding(true);
    const headers = {
      "Content-Type": "application/json",
    };

    var raw = {
      from_date: "",
      to_date: "",
      due_from_date: "",
      due_to_date: "",
    };

    axiosClientInterceptors
      .post(
        `/getinvoices?page=1&limit=${perPage}&search=&project_id=${singleViewId}`,
        raw,
        {
          headers,
        }
      )
      .then((resultr) => {
        setHoldFilter("");
        setHoldFilter1("");
        dispatch(setinvoiceCurrentpage(resultr.data.result.currentPage));
        Setprojectdata(resultr.data.result.listOfInvoice);
        setTotalPage(resultr.data.result.totalInvoice);
        setIsloding(false);
      });
  };
  // pagination handler
  const handleChangePage = (e, pageSize) => {
    dispatch(setinvoiceCurrentpage(e));
    setPerPge(pageSize);
  };
  useEffect(() => {
    getinvoice();
  }, [currentpage, perPage]);

  // filter daterange
  const onChange = (dates) => {
    setHoldFilter(dates);
    if (dates) {
      var start = dates[0];
      var end = dates[1];
      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");
      if (start < end) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(end);
        setEndDate(start);
      }
    }
  };
  // filter daterange
  const onChange1 = (dates) => {
    setHoldFilter1(dates);
    if (dates) {
      var start = dates[0];
      var end = dates[1];

      start = start.toLocaleDateString("en-CA");
      end = end.toLocaleDateString("en-CA");

      if (start < end) {
        setStartDate1(start);
        setEndDate1(end);
      } else {
        setStartDate1(end);
        setEndDate1(start);
      }
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="d-flex   justify-content-between align-items-center ">
          <p className="m-0">
            <span
              className="fontdocument_dim"
              onClick={() => {
                setsingleView("allusers");
                dispatch(setProjectScreen("allusers"));
              }}
            >
              All Projects
            </span>
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                setsingleView("singleuser");
                dispatch(setProjectScreen("singleuser"));
              }}
            >
              Project Details{" "}
            </span>
            / <span className="fontdocument_active">All Invoices</span>
          </p>

          <div
            className="modal-title d-flex align-items-center uploaddoc_btn"
            style={{ gap: "10px" }}
            onClick={() => {
              handleOpen1();
              getPurchageOrderNumber();
            }}
          >
            <img src={upload} alt="" />
            <span>Upload Invoice</span>
          </div>

          <Modal
            open={open1}
            style={{ background: "rgba(34, 30, 31, 0.9)" }}
            className="d-flex justify-content-center align-items-center flex-column "
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="filter_container">
              <AddInvoice
                getinvoice={getinvoice}
                POdropdownList={POdropdownList}
                handleClose1={handleClose1}
              />
            </div>
          </Modal>
        </div>
        <hr />

        <div>
          <div className="d-flex mt-3">
            <div className="searchrequst d-flex me-2 ">
              <input
                type="text"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
                className="w-100 inputsearch"
                placeholder="Search by Invoice number, po number"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    getinvoice();
                  }
                }}
              />
              {search.length > 0 && (
                <CloseIcon
                  className="mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    getinvoice1();
                    setsearch("");
                  }}
                />
              )}
              <button
                className="serchbuttonrequest"
                disabled={search.length === 0}
                onClick={() => getinvoice()}
              >
                <img src={Search} alt="" />
                <span>Search</span>
              </button>
            </div>
            <div className="filterusers">
              <div className="filterbutton" onClick={handleOpen}>
                <img src={filter} alt="" />
                <p className="m-0 Filter">Filter</p>
              </div>
              {/* filter popup */}
              <Modal
                className="d-flex justify-content-center align-items-center flex-column "
                open={open}
                style={{ background: "rgba(34, 30, 31, 0.9)" }}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div
                  className="filter_container "
                  style={{ marginBottom: "11%" }}
                >
                  <InvoiceFilter
                    getinvoice={getinvoice}
                    getinvoice1={getinvoice1}
                    startDate={startDate}
                    endDate={endDate}
                    startDate1={startDate1}
                    endDate1={endDate1}
                    onChange={onChange}
                    onChange1={onChange1}
                    handleClose={handleClose}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setEndDate1={setEndDate1}
                    setStartDate1={setStartDate1}
                    holdfilter={holdfilter}
                    setHoldFilter={setHoldFilter}
                    holdfilter1={holdfilter1}
                    setHoldFilter1={setHoldFilter1}
                  />
                </div>
              </Modal>
            </div>
          </div>
        </div>

        {Isloding ? (
          <div
            className="App d-flex justify-content-center align-items-center"
            style={{
              width: "100%",
              textAlign: "center",
              height: "100vh",
              background: "white",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {projectdata.length === 0 ? (
              <div className=" mt-3 NoRecordFound">
                <img src={NoRecordFound} />
              </div>
            ) : (
              <div className="inner_table_div pt-0 mt-4">
                <table className="table leaderboard_table">
                  <tbody>
                    <tr class="headers_tag">
                      <th className="text-center">Invoice Number</th>
                      <th className="text-center">Total Amount</th>
                      <th className="text-center">PO Numbers</th>
                      <th className="text-center">Action</th>
                    </tr>

                    <>
                      {projectdata?.map((val, index) => {
                        return (
                          <tr
                            className="vals"
                            style={{ backgroundColor: "#F5F5F5" }}
                            key={index}
                            onClick={() => {
                              setsingleView("invoiceView");
                              dispatch(setProjectScreen("invoiceView"));
                              dispatch(setallInvoiceImg(val.invoice));
                            }}
                          >
                            <td className="text-center">
                              {val.invoice_number}
                            </td>
                            <td className="text-center">₹{val.total_amt}/-</td>
                            <td className="text-center">
                              {val.purchase_ord_number}
                            </td>

                            <td
                              className="text-center d-flex align-items-center justify-content-center"
                              style={{ gap: "10px" }}
                            >
                              <CgArrowRightR
                                onClick={() => {
                                  setsingleView("invoiceView");
                                  dispatch(setProjectScreen("invoiceView"));
                                  dispatch(setallInvoiceImg(val.invoice));
                                }}
                                className="partner_back_btn"
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
        <div className="w-100">
          <div className="flotpagination">
            <PaginationComponent
              handleChangePage={handleChangePage}
              perPage={perPage}
              totlePage={totlePage}
              currentpage={currentpage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllInvoice;
