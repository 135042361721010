import React from "react";
import { useState } from "react";
import ProgressBar from "./ProgressBar";
export default function ProgressBarTest({ indivisual }) {
  const [value, setValue] = useState(0);
  return (
    <div className="m-3">
      <div width="100%">
        <ProgressBar
          color={"#ffe185"}
          value={indivisual?.project_progress}
          max={100}
          width="100%"
        />
      </div>
      <div
        className="d-flex percentage_val justify-content-around"
        style={{ width: "100%" }}
      >
        <div>Not Started</div>
        <div style={{ marginLeft: "4%" }}>10%</div>
        <div style={{ marginLeft: "7%" }}>20%</div>
        <div style={{ marginLeft: "8%" }}>30%</div>
        <div style={{ marginLeft: "8%" }}>40%</div>
        <div style={{ marginLeft: "7%" }}>50%</div>
        <div style={{ marginLeft: "7%" }}>60%</div>
        <div style={{ marginLeft: "8%" }}>70%</div>
        <div style={{ marginLeft: "8%" }}>80%</div>
        <div style={{ marginLeft: "7%" }}>90%</div>
        <div style={{ marginLeft: "45px" }}>Completed</div>
      </div>
    </div>
  );
}
