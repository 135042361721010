import React from "react";
import { useState } from "react";
import bulkFileUI from "../../../Assets/Img/bulkFileUI.png";

import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  fileNameSplit,
  fileNameSplitQR,
} from "../../../ReusableComponent/Validation";
import DeleteIcon from "@mui/icons-material/Delete";
import saveAs from "file-saver";

const VendorDocCard = ({ val, filedelet, delet }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div>
        <Modal
          open={open}
          style={{ background: "rgba(34, 30, 31, 0.9)" }}
          className="d-flex justify-content-center align-items-center flex-column "
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            className="filter_container "
            style={{ position: "relative", zIndex: 1, width: "80%" }}
          >
            <div className="m-3 ">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="m-0 Filter_text ">View Document</p>
                <CloseIcon onClick={handleClose} />
              </div>
              {/* <div className="loderimg">
                <CircularProgress />
              </div> */}
              <iframe
                style={{
                  position: "relative",
                  zIndex: 3,
                  height: "400px",
                  width: "100%",
                }}
                // src={invoice}
                src={`${process.env.REACT_APP_IMG_URL}/${val}`}
              ></iframe>
              {/* <DocumentViewer
              style={{ height: "400px", width: "100%" }}
              queryParams="hl=[english]"
              url={invoice}
            ></DocumentViewer> */}
              {/* <FileViewer
              fileType="png"
              filePath={invoice}
              // errorComponent={CustomErrorComponent}
              // onError={this.onError}
            /> */}

              <div
                className="downloadme mt-2"
                onClick={() =>
                  saveAs(
                    `${process.env.REACT_APP_IMG_URL}/${val}`,
                    `${fileNameSplitQR(val)}`
                  )
                }
              >
                <div className="d-flex align-items-center">
                  <SaveAltIcon fontSize="small" className="me-2 text-black" />
                  <p className="m-0 text-black">Download</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="d-flex  align-items-center justify-content-between">
        <div className="d-flex  align-items-center ">
          <img src={bulkFileUI} alt="..." className="me-2" />
          <div>
            <p
              className="GSTcrertificate"
              onClick={() => {
                console.log("val", val);
              }}
            >
              {fileNameSplitQR(val)}
            </p>
            <p className="Download">
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  saveAs(
                    `${process.env.REACT_APP_IMG_URL}/${val}`,
                    `${fileNameSplitQR(val)}`
                  )
                }
              >
                {" "}
                Download{" "}
              </span>{" "}
              |{" "}
              <span style={{ cursor: "pointer" }} onClick={() => handleOpen()}>
                View
              </span>
            </p>
          </div>
        </div>

        {delet && (
          <DeleteIcon
            onClick={() => {
              if (delet) {
                filedelet(val);
              } else {
                alert("are you sure You   want to delet");
              }
            }}
            className="delete_icon_admin"
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    </>
  );
};

export default VendorDocCard;
