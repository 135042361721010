import React from "react";
import BulkMaterial from "./BulkMaterial";
import BulkVendor from "./BulkVendor";
import HelpScreenDoc from "./HelpScreenDoc";

export default function DocConfig() {
  return (
    <div>
      <div
        className="d-flex row MatrialCatalogTab_con p-3"
        style={{ gap: "20px" }}
      >
        <div className="col-6 others_container">
          <HelpScreenDoc />
        </div>
        <div className="row others_container col-6">
          <BulkVendor />
        </div>
        <div className="others_container w-50">
          <BulkMaterial />
        </div>
      </div>
      {/* <p class="version" style={{ position: "absolute", bottom: "0" }}>
        Version : 0.2.3
      </p> */}
    </div>
  );
}
