import React, { useState } from "react";
import view from "../../../../Assets/Img/Arrow - Right Square.svg";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Upload from "../../../../Assets/Img/Upload.svg";
import { saveAs } from "file-saver";
import Modal from "@mui/material/Modal";
import File_logo from "../../../../Assets/Img/File_logo.svg";
import NoRecordFound from "../../../../Assets/Img/NoRecordFound.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  selectmatrialPagination,
  setmatrialPagination,
} from "../../../../features/pagination/paginationSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosClientInterceptors from "../../../../ReusableComponent/axiosClentInterceptors";
import PaginationComponent from "../../../../ReusableComponent/PaginationComponent";
import { useEffect } from "react";
import MatrialPopUp from "./MatrialPopUp";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { CircularProgress } from "@mui/material";
import { fileNameSplit } from "../../../../ReusableComponent/Validation";
import moment from "moment";

const MatrialDetailsTable = ({ categoryname }) => {
  const [showEditView, setshowEditView] = useState("add");
  const [totalPage, setTotalPage] = useState("");
  const currentpage = useSelector(selectmatrialPagination);
  const [perPage, setPerPge1] = useState(10);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loader, setloader] = useState(false);
  const [bulkFile, setBulkFile] = useState("");
  const [fileName, setfileName] = useState("");

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    setfileName();
  };

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setOpen2(false);
    setfileName();
  };

  const handleImage = (acceptedFiles) => {
    setfileName(acceptedFiles[0]);
  };

  const [materialCategoryValue, setMaterialCategoryValue] = useState([]);
  const [EditData, setEditData] = useState("");
  const [ListingUOMPrice, setListingUOMPrice] = useState([]);
  const [ListingUOMMatrial, setListingUOMMatrial] = useState([]);
  //

  const [bulkUploadMessage, setBulkUploadMessage] = useState("");
  const [duplicateData, setDuplicateData] = useState("");
  const getMaterialBulkFile = () => {
    axiosClientInterceptors
      .get(`/get-cofig-document?document_type=Bulk Material`)
      .then((response) => {
        setBulkFile(response.data.result);
      });
  };

  const downloadFormat3 = () => {
    saveAs(
      `${process.env.REACT_APP_IMG_URL}/${bulkFile[0].document}`,
      `${fileNameSplit(bulkFile[0].document)}`
    );
  };
  const setCat = () => {
    axiosClientInterceptors
      .post(
        `/getmaterials?page=${currentpage}&limit=${perPage}&search=${searchTerm}`,
        {
          category: categoryname,
        }
      )
      .then((res) => {
        dispatch(setmatrialPagination(res.data.result.currentPage));
        setTotalPage(res.data.result.totalMaterials);
        setMaterialCategoryValue(res.data.result.listOfMaterials);
      });
  };
  const setCat1 = () => {
    axiosClientInterceptors
      .post(`/getmaterials?page=1&limit=${perPage}&search=`, {
        category: categoryname,
      })
      .then((res) => {
        dispatch(setmatrialPagination(res.data.result.currentPage));
        setTotalPage(res.data.result.totalMaterials);
        setMaterialCategoryValue(res.data.result.listOfMaterials);
      });
  };
  const GetmatrialByID = (MatrialID) => {
    axiosClientInterceptors
      .get(`/getmaterialsbyid?_id=${MatrialID}`)
      .then((response) => {
        setEditData(response.data.result);
      });
  };

  const handleChangePage = (e, pageSize) => {
    dispatch(setmatrialPagination(e));
    setPerPge1(pageSize);
  };

  const submitBulkMaterial = () => {
    if (fileName.length === 0) {
      alert("Please upload the file");
    } else {
      var formdata = new FormData();
      formdata.append("file", fileName);
      const headers = {
        "content-type": "multipart/form-data",
      };
      setloader(true);
      axiosClientInterceptors
        .post(`/bulk-upload-materials`, formdata, {
          headers,
        })
        .then((response) => {
          if (response.data.status === "success") {
            toast.success("Material data Uploaded Successfully!!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setBulkUploadMessage(response.data.msg);
            setDuplicateData(response.data.duplicateData);
            handleClose1();
            handleOpen2();
            setloader(false);
            setCat1();
          } else {
            toast.error(response.data.msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleClose1();
            setfileName("");
            setloader(false);
          }
        })
        .catch((error) =>
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
    }
  };

  useEffect(() => {
    setCat();
    getMaterialBulkFile();
    axiosClientInterceptors.get(`/get-uom`).then((res) => {
      setListingUOMPrice(res.data.result);
    });
    axiosClientInterceptors.get(`/get-specs-uom`).then((res) => {
      setListingUOMMatrial(
        res.data.result.map((value, i) => ({
          ["value"]: value.specification,
          ["label"]: value.specification,
          _id: value._id,
        }))
      );
    });
  }, [currentpage, perPage, categoryname]);
  return (
    <>
      {/* edit and update popup start */}
      <MatrialPopUp
        categoryname={categoryname}
        GetmatrialByID={GetmatrialByID}
        setCat={setCat}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        setshowEditView={setshowEditView}
        showEditView={showEditView}
        EditData={EditData}
        ListingUOMPrice={ListingUOMPrice}
        ListingUOMMatrial={ListingUOMMatrial}
      />

      {/* edit and update popup end */}
      <div className="material_table mt-3">
        <span className="text-dark catalogue_heading">Material Details</span>
        <div className="d-flex justify-content-between mt-3">
          <div className="" style={{ width: "100%" }}>
            <div className="d-flex search_mat_parent">
              <input
                type="text"
                value={searchTerm}
                class="w-100 inputsearch_mat"
                placeholder="Search by material name"
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setCat();
                  }
                }}
              />
              {searchTerm.length > 0 && (
                <CloseIcon
                  className="mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCat1();
                    setSearchTerm("");
                  }}
                />
              )}
              <div className="serchbuttonrequest" onClick={() => setCat()}>
                <SearchRoundedIcon fontSize="small" />
                <span>Search</span>
              </div>
            </div>
          </div>
          <div className=" ms-4" style={{ width: "43%" }}>
            <div
              className="d-flex justify-content-between"
              style={{ gap: "8px" }}
            >
              <button className="upload_bulk_btn" onClick={() => handleOpen1()}>
                <img src={Upload} className="ms-2" /> Upload Bulk Materials
              </button>

              {categoryname.length > 1 ? (
                <button
                  className="add_material"
                  onClick={() => {
                    setshowEditView("add");
                    handleOpen();
                  }}
                >
                  <AddIcon style={{ width: "20px", color: "#000" }} />
                  Add Material
                </button>
              ) : (
                <button
                  className="add_material"
                  onClick={() => {
                    toast.info(`Please Select Category`, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }}
                >
                  <AddIcon style={{ width: "20px", color: "#000" }} />
                  Add Material
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="matrialtable" style={{ marginBottom: "20px" }}>
          {" "}
          {materialCategoryValue.length === 0 ? (
            <div className=" mt-3 NoRecordFound">
              <img src={NoRecordFound} />
            </div>
          ) : (
            <div className="matrialTable">
              <table className="table table-responsive-sm table_material_catalog">
                <thead className="headers_tag">
                  <tr>
                    <th>Sl.No.</th>
                    <th>Material Name</th>
                    <th>Creation Date</th> <th>Last Activity Date</th>
                    <th className=" text-center">Material Description</th>
                  </tr>
                </thead>

                <tbody>
                  {materialCategoryValue?.map((value, index) => (
                    <tr
                      className="vals"
                      style={{ background: "#F8F8F8" }}
                      onClick={() => {
                        handleOpen();
                        setshowEditView("edit");
                        setEditData(value);
                        GetmatrialByID(value._id);
                      }}
                    >
                      <td> {(currentpage - 1) * perPage + index + 1}.</td>
                      <td> {value?.material_name}</td>
                      <td>
                        {moment(value.creationdate, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>
                        {moment(value.updationdate, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>
                        {value?.description.length > 45 ? (
                          <span>{value?.description?.slice(0, 45)}...</span>
                        ) : (
                          value?.description
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <Modal
        className="d-flex justify-content-center align-items-center flex-column"
        open={open1}
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="filter_container" style={{ width: "40%" }}>
          <div className="m-3  " style={{ padding: "13px 0px 16px 0px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 Filter_text ">Upload Document</p>
              <CloseIcon onClick={handleClose1} />
            </div>
            {/* body */}
            <Dropzone onDrop={handleImage}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    className: "uplode_file_dot_border rounded w-100",
                  })}
                >
                  <div className="mb-3">
                    <img className="mx-auto" src={File_logo} alt="..." />
                  </div>
                  <input name="bulkdata" multiple {...getInputProps()} />

                  <p className="uplode_file_text">Upload your file here</p>

                  <p className="Fileformat">File format : Only .xlsx(Excel)</p>

                  <p>{fileName?.name}</p>

                  <div className="uplode_file_btn_container ">
                    <button
                      className=" mx-auto uplode_file_btn px-4 py-2  rounded"
                      type="button"
                    >
                      Browse File
                    </button>
                  </div>
                </div>
              )}
            </Dropzone>
            <p className="accept_files">
              Accepted File Format :{" "}
              <span
                className="Download_link text-dark"
                onClick={() => downloadFormat3()}
              >
                <b>click here to download</b>
              </span>
            </p>
            <button
              // disabled={fileName?.length === ""}
              className="w-100 me-3  Upload_Document "
              onClick={() => {
                submitBulkMaterial();
              }}
            >
              <p className="m-0">
                {loader ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  "Upload Materials"
                )}
              </p>
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={open2}
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        className="d-flex justify-content-center align-items-center flex-column"
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="filter_container ">
          <div className="m-3 text-center">
            <CheckCircleIcon className="tick_mark" />
            <div className="text-center success_msg">
              {bulkUploadMessage}
              <br />
              {duplicateData} Duplicate Data
            </div>
          </div>
        </div>
      </Modal>
      <div className="flotpagination  d-flex justify-content-end align-items-center">
        <PaginationComponent
          handleChangePage={handleChangePage}
          perPage={perPage}
          totlePage={totalPage}
          currentpage={currentpage}
        />
      </div>
    </>
  );
};

export default MatrialDetailsTable;
