import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";

const MultiSelect = ({ placeholder, handlechange, options, defaultValue }) => {
  const animatedComponents = makeAnimated();
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#F8F8F8",
      borderRadius: "2px",

      borderColor: state.isFocused ? "#F8F8F8" : "#F8F8F8",
      "&:hover": { borderColor: state.isFocused ? "#F8F8F8" : "#F8F8F8" },
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f2f2f2" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": { background: "#f2f2f2", color: "white" },
    }),
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label style={{ color: "black" }}>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  return (
    <Select
      styles={customStyles}
      isRequired
      classNamePrefix="custom-select"
      className="w-100 selecttag"
      closeMenuOnSelect={false}
      placeholder={placeholder}
      defaultValue={defaultValue}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: "#FFFFFF",
        },
      })}
      hideSelectedOptions={false}
      components={{
        Option,
        MultiValue,
        animatedComponents,
      }}
      onChange={handlechange}
      //   defaultValue={matuomexist}
      isMulti
      options={options}
    />
  );
};

export default MultiSelect;
