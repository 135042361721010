import React from "react";
import PaginationComponent from "../../../ReusableComponent/PaginationComponent";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { useState } from "react";
import { Modal } from "@mui/material";
import Search from "../../../Assets/Img/svg/search.svg";
import NoRecordFound from "../../../Assets/Img/NoRecordFound.svg";
import { Ranges } from "../../../ReusableComponent/Validation";

import {
  setReceivedpagination,
  selectReceivedpagination,
  selectfilterReceived,
  setfilterReceived,
} from "../../../features/pagination/paginationSlice";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import DateRangePicker from "rsuite/DateRangePicker";

import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { useEffect } from "react";
import {
  setreceivedFilter,
  selectreceivedFilter,
} from "../../../features/DateRangeSlice/DateRangeSlice";
import Loader from "../../../ReusableComponent/Loader";
import {
  selectallProjects,
  selectAllTheSiteEng,
  selectrequestIdFRomProject,
  setNotificationStatusScreen,
  setrequestID,
} from "../../../features/counter/counterSlice";
import filtersImg from "../../../Assets/Img/svg/filter.svg";
import { setscreen } from "../../../features/Screen/screen";

const ReceivedTab = ({
  getRequestDetails,
  setRequestData,
  // getTotlerecivedcount,
  Setrequestidentity,
}) => {
  const allReceivedpagination = useSelector(selectReceivedpagination);
  const siteEngineer = useSelector(selectAllTheSiteEng);
  const projectName = useSelector(selectallProjects);
  const requestIDfromPRoject = useSelector(selectrequestIdFRomProject);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [recievedrequestdata, setRecievedRequestData] = useState([]);
  const [MaterialCatalog, setMaterialCatalog] = useState([]);
  //  filter
  const filter = useSelector(selectfilterReceived);
  const [isLoding, setLoading] = useState(false);
  const [MaterialCategor, setMaterialCategor] = useState(
    filter.material_category
  );
  const [RequestPriority, setRequestPriority] = useState(filter.priority);
  const [SiteEngineer, setSiteEngineer] = useState(filter.site_engineer);
  const [RequestStatus, setRequestStatus] = useState(filter.request_status);
  const [ProjectStage, setProjectStage] = useState(filter.project_stage);
  const [SiteAddress, setSiteAddress] = useState(filter.project_location);
  const [Discrepency, setDiscrepency] = useState(filter.discrepency);
  const daterangestore = useSelector(selectreceivedFilter);
  const [search, setsearch] = useState("");

  // pagination recieve
  const [perPage, setPerPge] = useState(10);
  const [totlePage, setTotalPage] = useState("");

  const onChange = (dates) => {
    dispatch(setreceivedFilter(dates));
  };
  const getMaterialRequests = () => {
    setLoading(true);
    let activityFrom;
    let activityTo;
    let rangedatestart = daterangestore[1];
    let rangedateend = daterangestore[0];
    if (rangedatestart > rangedateend) {
      activityFrom = rangedateend;
      activityTo = rangedatestart;
    } else {
      activityFrom = rangedatestart;
      activityTo = rangedateend;
    }
    const filterrequest = {
      activity_from_date: activityFrom,
      activity_to_date: activityTo,
      material_category: MaterialCategor,
      priority: RequestPriority,
      site_engineer: SiteEngineer,
      request_status: RequestStatus,
      project_name: ProjectStage,
      project_location: SiteAddress,
      discrepency: Discrepency,
    };
    dispatch(setfilterReceived(filterrequest));
    axiosClientInterceptors
      .post(
        `/getReceivedMatRequests?project_id=${
          requestIDfromPRoject && requestIDfromPRoject
        }&page=${allReceivedpagination}&limit=${perPage}&search=${search}`,
        filterrequest
      )
      .then((resp) => {
        dispatch(setReceivedpagination(resp.data.result.currentPage));
        setTotalPage(resp.data.result.totalRequest);
        setRecievedRequestData(resp.data.result.listOfRequest);
        // getTotlerecivedcount(resp.data.result.totalRequest);
        setLoading(false);
      });
  };
  const getMaterialRequests2 = (reset) => {
    setMaterialCategor("");
    setRequestPriority("");
    setSiteEngineer("");
    setProjectStage("");
    setSiteAddress("");
    setDiscrepency("");
    setLoading(true);
    let activityFrom;
    let activityTo;
    let rangedatestart = daterangestore[1];
    let rangedateend = daterangestore[0];
    if (rangedatestart > rangedateend) {
      activityFrom = rangedateend;
      activityTo = rangedatestart;
    } else {
      activityFrom = rangedatestart;
      activityTo = rangedateend;
    }
    const filterrequest = {
      activity_from_date: reset ? "" : activityFrom,
      activity_to_date: reset ? "" : activityTo,
      material_category: reset ? "" : MaterialCategor,
      priority: reset ? "" : RequestPriority,
      site_engineer: reset ? "" : SiteEngineer,
      request_status: reset ? "" : RequestStatus,
      project_name: reset ? "" : ProjectStage,
      project_location: reset ? "" : SiteAddress,
      discrepency: reset ? "" : Discrepency,
    };
    axiosClientInterceptors
      .post(
        `/getReceivedMatRequests?project_id=${
          requestIDfromPRoject && requestIDfromPRoject
        }&page=1&limit=${perPage}&search=`,
        filterrequest
      )
      .then((resp) => {
        dispatch(setReceivedpagination(resp.data.result.currentPage));
        setTotalPage(resp.data.result.totalRequest);
        setRecievedRequestData(resp.data.result.listOfRequest);
        // getTotlerecivedcount(resp.data.result.totalRequest);
        setLoading(false);
      });
  };
  const getAllMaterialCategory = () => {
    axiosClientInterceptors.get(`/getcategories`).then((rso) => {
      setMaterialCatalog(rso.data.Category);
    });
  };

  useEffect(() => {
    getAllMaterialCategory();
  }, []);

  useEffect(() => {
    getMaterialRequests();
  }, [allReceivedpagination, perPage]);

  const handleChangePage = (e, pageSize) => {
    dispatch(setReceivedpagination(e));
    setPerPge(pageSize);
  };

  return (
    <>
      <Modal
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className=" allProjectFilter ">
          <div className="d-flex justify-content-between mb-5 align-items-center">
            <p className="m-0 Filter_text ">Apply Filter</p>
            <CloseIcon onClick={handleClose} />
          </div>

          <div>
            <div className="row">
              <div className="col-6" style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">
                  Last Activity Date Range{" "}
                </label>
                <br />
                <DateRangePicker
                  placement={"bottomEnd"}
                  cleanable={true}
                  id="filter_range"
                  placeholder="Select Date Range"
                  style={{ border: "none", zIndex: "999" }}
                  format="dd/MM/yyyy"
                  character="-"
                  onChange={onChange}
                  defaultValue={daterangestore}
                  ranges={Ranges}
                />
              </div>
              <div className="col-6" style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Material Category</label>
                <br />
                <select
                  className="filter_usertype "
                  name="material_category"
                  defaultValue={"select"}
                  style={{ border: "none" }}
                  onChange={(e) => {
                    setMaterialCategor(e.target.value);
                  }}
                  value={MaterialCategor || filter.material_category}
                >
                  <option selected value="">
                    Select Category
                  </option>
                  {MaterialCatalog?.map((val, index) => (
                    <option key={val._id} value={val._id}>
                      {val.category_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Request Priority</label>
                <br />
                <select
                  className="filter_usertype mb-1"
                  name="priority"
                  value={RequestPriority || filter.priority}
                  style={{ border: "none" }}
                  onChange={(e) => {
                    setRequestPriority(e.target.value);
                  }}
                >
                  <option selected value="">
                    Choose Priority
                  </option>
                  <option value="High">High Priority</option>
                  <option value="Medium">Medium Priority</option>
                  <option value="Low">Low Priority</option>
                </select>
              </div>
              <div className="col-6" style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Site Engineer</label>
                <br />
                <select
                  className="filter_usertype mb-1"
                  name="site_engineer"
                  value={SiteEngineer || filter.site_engineer}
                  style={{ border: "none" }}
                  onChange={(e) => {
                    setSiteEngineer(e.target.value);
                  }}
                >
                  <option selected value="">
                    Select Site Engineer
                  </option>
                  {siteEngineer?.map((op, index) => {
                    return (
                      <option key={op._id} value={op._id}>
                        {op.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Discrepency</label>
                <br />
                <select
                  className="filter_usertype mb-1"
                  name="discrepency"
                  value={Discrepency || filter.discrepency}
                  style={{ border: "none" }}
                  onChange={(e) => {
                    setDiscrepency(e.target.value);
                  }}
                >
                  <option selected value="" disabled>
                    Select
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Project </label>
                <br />
                <select
                  className="filter_usertype mb-1"
                  name="project_stage"
                  value={ProjectStage || filter.project_stage}
                  style={{ border: "none" }}
                  onChange={(e) => {
                    setProjectStage(e.target.value);
                  }}
                >
                  <option selected value="" disabled>
                    Choose Project
                  </option>
                  {projectName?.map((ki, index) => {
                    return (
                      <option key={index} value={ki.project_name}>
                        {ki.project_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div
                className="col-12 col-md-12 "
                style={{ marginBottom: "16px" }}
              >
                <label className="select_label mb-2">Site Address</label>
                <input
                  type="text"
                  style={{ border: "none" }}
                  value={SiteAddress || filter.project_location}
                  onChange={(e) => {
                    setSiteAddress(e.target.value);
                  }}
                  placeholder="eg. Jp nagar, BTM, HSR layout"
                  className="filter_usertype p-1 mb-1"
                  name="project_location"
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-6">
                  <div
                    className="filter_op_btns"
                    onClick={() => {
                      dispatch(setreceivedFilter(""));
                      dispatch(setfilterReceived(""));
                      handleClose();
                      getMaterialRequests2(true);
                    }}
                  >
                    Reset All
                  </div>
                </div>
                <div className="col-6">
                  <button
                    onClick={() => {
                      getMaterialRequests();
                      handleClose();
                    }}
                    type="submit"
                    className="filter_op_btns1"
                  >
                    Apply Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* search & filter */}
      <div className="d-flex mt-3">
        <div className="searchrequst d-flex me-2">
          <input
            type="text"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            className="w-100 inputsearch"
            placeholder="Search by id, name, status"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                getMaterialRequests();
              }
            }}
          />
          {search.length > 0 && (
            <CloseIcon
              className="mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                getMaterialRequests2();
                setsearch("");
              }}
            />
          )}
          <button
            className="serchbuttonrequest"
            disabled={search.length === 0}
            onClick={() => getMaterialRequests()}
          >
            <img src={Search} alt="" />
            <span>Search</span>
          </button>
        </div>
        <div
          className="filterrequest"
          onClick={() => {
            handleOpen();
          }}
        >
          <img src={filtersImg} alt="" className="me-1" />
          <span style={{ color: "#909090" }}>Filter</span>
        </div>
      </div>
      <div className="mt-3 mr-2">
        {isLoding ? (
          <div
            className="App d-flex justify-content-center align-items-center"
            style={{
              width: "100%",
              textAlign: "center",
              height: "100vh",
              background: "white",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {recievedrequestdata.length === 0 ? (
              <div className=" mt-3 NoRecordFound">
                <img src={NoRecordFound} />
              </div>
            ) : (
              <div className="inner_table_div">
                <table className="table leaderboard_table">
                  <tbody>
                    <tr className="headers_tag">
                      <th className="text-center">Request ID</th>
                      <th className="text-center">Project Name</th>
                      <th className="text-center">Material Category</th>
                      <th className="text-center">Discrepency</th>
                      <th className="text-center">PO Amount</th>
                      <th className="text-center">Invoice Amount</th>
                      <th className="text-center">Created By</th>
                      <th className="text-center">Site Engineer</th>
                    </tr>
                    {recievedrequestdata !== "" ? (
                      recievedrequestdata.map((des, index) => {
                        return (
                          <tr
                            key={des._id}
                            className="vals"
                            onClick={() => {
                              dispatch(setrequestID(des._id));
                              localStorage.setItem("REqustID", des._id);
                              dispatch(setscreen("singledata"));
                              getRequestDetails(des._id);
                              Setrequestidentity(des._id);
                            }}
                          >
                            <td
                              onClick={() => {
                                setRequestData("singledata");
                                dispatch(setscreen("singledata"));
                                dispatch(
                                  setNotificationStatusScreen("singledata")
                                );
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                {des.priority == "High" ? (
                                  <div className="active_dot"></div>
                                ) : (
                                  ""
                                )}
                                {des.priority == "Medium" ? (
                                  <div className="med_pri"></div>
                                ) : (
                                  ""
                                )}
                                {des.priority == "Low" ? (
                                  <div className="low_pri"></div>
                                ) : (
                                  ""
                                )}
                                {des.request_id}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              onClick={() => {
                                setRequestData("singledata");
                                dispatch(setscreen("singledata"));
                              }}
                            >
                              {des.project_name}
                            </td>
                            <td
                              className="text-center"
                              onClick={() => {
                                setRequestData("singledata");
                                dispatch(setscreen("singledata"));
                              }}
                            >
                              {des.material_category}
                            </td>
                            {des.discrepency === "No" ? (
                              <td
                                className="text-center"
                                style={{ color: " green" }}
                                onClick={() => {
                                  setRequestData("singledata");
                                  dispatch(setscreen("singledata"));
                                }}
                              >
                                {des.discrepency}
                              </td>
                            ) : (
                              <td
                                style={{ color: "red" }}
                                className="text-center"
                                onClick={() => {
                                  setRequestData("singledata");
                                  dispatch(setscreen("singledata"));
                                }}
                              >
                                {des.discrepency}
                              </td>
                            )}
                            <td
                              className="text-center"
                              onClick={() => {
                                setRequestData("singledata");
                                dispatch(setscreen("singledata"));
                              }}
                            >
                              ₹{Number(des.po_amount).toFixed(2)}
                            </td>
                            <td
                              className="text-center"
                              onClick={() => {
                                setRequestData("singledata");
                                dispatch(setscreen("singledata"));
                              }}
                            >
                              ₹{des.invoice_amount ? Number(des.invoice_amount).toFixed(2) : '0000.00'}
                            </td>
                            <td
                              className="text-center"
                              onClick={() => {
                                setRequestData("singledata");
                                dispatch(setscreen("singledata"));
                              }}
                            >
                              {des.requested_by}
                            </td>
                            <td
                              className="text-center"
                              onClick={() => {
                                setRequestData("singledata");
                                dispatch(setscreen("singledata"));
                              }}
                            >
                              {des.site_engineer}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div>
                        <div className="d-flex" style={{ gap: "10px" }}>
                          <WarningRoundedIcon className="no_records_found" />
                          <h3 className="pl-3" style={{ fontFamily: "gsb" }}>
                            No records found
                          </h3>
                        </div>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
        <div className="w-100 d-flex justify-content-end align-items-center">
          <div className="flotpagination">
            <PaginationComponent
              handleChangePage={handleChangePage}
              perPage={perPage}
              totlePage={totlePage}
              currentpage={allReceivedpagination}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceivedTab;
