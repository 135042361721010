import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const DynamicRowVendor = ({
  input,
  handleChange,
  index,
  errors,
  handleRemoveInput,
}) => {
  return (
    <div className="row p-4 ">
      <div className="col-md-1  ">
        {index === 0 && <p className="m-0 titleInput">Sl.No.</p>}
        <p className="m-0 mt-4 titleInput ">{index + 1}.</p>
      </div>
      <div className="col-md-2">
        {index === 0 && <p className="titleInput">Percentage</p>}
        <div className=" d-flex align-items-center mt-1">
          <input
            type="text"
            name="persentage"
            placeholder="eg. 30/45"
            className="AddvendorInput"
            value={input.persentage}
            onChange={(e) => handleChange(e, index)}
            // onKeyDown={(event) => {
            //   const value = parseFloat(event.target.value);
            //   if (value >= 100 && event.keyCode !== 8 ) {
            //     event.preventDefault();
            //   } else if (value < 0) {
            //     event.preventDefault();
            //   }
            // }}
            onKeyDown={(event) => {
              const pattern = /^\d{1,3}(\.\d{0,3})?$/;
  
              // Get the current input value
              const currentValue = event.target.value;
              if(event.keyCode === 8 || event.keyCode ===37 || event.keyCode===39){
                return
              }
              // Get the key that the user is trying to input
              const key = event.key;
  
              // Construct the new value by appending the key to the current value
              const newValue = currentValue + key;
              // Check if the new value matches the pattern
              if (!pattern.test(newValue)) {
                event.preventDefault(); // Prevent the invalid input
              }
            }}
          />
          <p className="PluseNO">%</p>
        </div>
        {errors[index] && (
          <span className="eroorMSG">{errors[index].persentage}</span>
        )}
      </div>
      <div className="col-md-2">
        {index === 0 && <p className="titleInput">No. Of Days</p>}
        <div className=" d-flex align-items-center mt-1 ">
          <input
            type="number"
            name="no_of_days"
            placeholder="eg. 30/45"
            className="AddvendorInput "
            value={input.no_of_days}
            onInput={(e) => (e.target.value = parseInt(e.target.value))}
            onChange={(e) => handleChange(e, index)}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(event) => {
              const value = event.target.value;
              if (value.length >= 3 && event.keyCode !== 8) {
                event.preventDefault();
              }
            }}
          />

          <p className="PluseNO">days</p>
        </div>
        {errors[index] && (
          <span className="eroorMSG">{errors[index].no_of_days}</span>
        )}
      </div>
      <div className="col-md-6">
        {index === 0 && <p className="titleInput">Description</p>}
        <div className=" d-flex align-items-center mt-1 ">
          <input
            type="text"
            name="description"
            value={input.description}
            onChange={(e) => handleChange(e, index)}
            placeholder="eg. on Delivery of Material to Site"
            className="AddvendorInput "
            maxlength="100"
          // pattern="\d{100}" 
          />
        </div>
        {errors[index] && (
          <span className="eroorMSG">{errors[index].description}</span>
        )}
      </div>
      <div className="col-md-1">
        <div
          className={`closeRow ${index === 0 ? "mt-4" : "mt-2"} `}
          // className="closeRow mt-4"
          onClick={() => handleRemoveInput(index)}
        >
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default DynamicRowVendor;
