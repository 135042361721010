import React, { useEffect } from "react";
import PaginationComponent from "../../../ReusableComponent/PaginationComponent";
import Search from "../../../Assets/Img/svg/search.svg";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import NoRecordFound from "../../../Assets/Img/NoRecordFound.svg";
import { Ranges } from "../../../ReusableComponent/Validation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectclosedTabPagination,
  selectcloseTabFilter,
  setclosedTabPagination,
  setcloseTabFilter,
} from "../../../features/pagination/paginationSlice";
import {
  seleccloseLastActivityDate,
  selecclosedCreationDate,
  setclosedCreationDate,
  setcloseLastActivityDate,
} from "../../../features/DateRangeSlice/DateRangeSlice";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DateRangePicker from "rsuite/DateRangePicker";
import {
  selectallPD,
  selectallProjects,
  selectAllTheSiteEng,
  selectrequestIdFRomProject,
  setNotificationStatusScreen,
  setrequestID,
} from "../../../features/counter/counterSlice";
import Loader from "../../../ReusableComponent/Loader";
import filtersImg from "../../../Assets/Img/svg/filter.svg";
import moment from "moment";
import { setscreen } from "../../../features/Screen/screen";

const ClosedTab = ({
  singleViewId,
  getRequestDetails,
  setRequestData,
  // getcloseed,
  Setrequestidentity,
}) => {
  const [perPage, setPerPge] = useState(10);
  const [isLoding, setLoading] = useState(false);
  const [search, setsearch] = useState("");
  const filter = useSelector(selectcloseTabFilter);
  const dispatch = useDispatch();
  const [totlePage, setTotalPage] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const currentpage = useSelector(selectclosedTabPagination);
  const dateRange = useSelector(selecclosedCreationDate);
  const SiteEngineer = useSelector(selectAllTheSiteEng);
  const projectName = useSelector(selectallProjects);

  const dateRangeactivity = useSelector(seleccloseLastActivityDate);
  const [allrequestdata, setAllRequestData] = useState([]);
  const [MaterialCategory, setMaterialCategory] = useState(
    filter.material_category
  );
  const [priorityval, Setpriorityval] = useState(filter.priority);
  const [siteenggval, Setsiteenggval] = useState(filter.site_engineer);
  const [projectNamedata, setprojectNamedata] = useState(filter.project_name);
  const [SiteAddress, setSiteAddress] = useState(filter.project_location);
  const [dropdowncategories, setdropdowncategories] = useState([]);

  const handleChangePage = (e, pageSize) => {
    dispatch(setclosedTabPagination(e));
    setPerPge(pageSize);
  };
  const onChange = (dates) => {
    dispatch(setclosedCreationDate(dates));
  };
  const onChange1 = (dates) => {
    dispatch(setcloseLastActivityDate(dates));
  };
  const getAllRequests = () => {
    setLoading(true);
    let activityFrom;
    let activityTo;
    let rangedatestart = dateRange[1];
    let rangedateend = dateRange[0];
    if (rangedatestart > rangedateend) {
      activityFrom = rangedateend;
      activityTo = rangedatestart;
    } else {
      activityFrom = rangedatestart;
      activityTo = rangedateend;
    }
    let activityFrom1;
    let activityTo1;
    let rangedatestart1 = dateRangeactivity[1];
    let rangedateend1 = dateRangeactivity[0];
    if (rangedatestart1 > rangedateend1) {
      activityFrom1 = rangedateend1;
      activityTo1 = rangedatestart1;
    } else {
      activityFrom1 = rangedatestart1;
      activityTo1 = rangedateend1;
    }
    const filterrequest = {
      to_date: activityTo,
      from_date: activityFrom,
      activity_from_date: activityFrom1,
      activity_to_date: activityTo1,
      material_category: MaterialCategory,
      priority: priorityval,
      site_engineer: siteenggval,
      project_name: projectNamedata,

      project_location: SiteAddress,
    };
    dispatch(setcloseTabFilter(filterrequest));

    axiosClientInterceptors
      .post(
        `/get-closed-request?page=${currentpage}&limit=${perPage}&search=${search}&project_id=${singleViewId}`,
        filterrequest
      )
      .then((resp) => {
        setAllRequestData(resp.data.result.listOfRequest);
        dispatch(setclosedTabPagination(resp.data.result.currentPage));
        setTotalPage(resp.data.result.totalRequest);
        // getcloseed(resp.data.result.totalRequest);
        setLoading(false);
      });
  };
  const getAllRequests2 = (reset) => {
    setLoading(true);

    setMaterialCategory("");
    Setpriorityval("");
    Setsiteenggval("");
    setprojectNamedata("");
    setSiteAddress("");
    let activityFrom;
    let activityTo;
    let rangedatestart = dateRange[1];
    let rangedateend = dateRange[0];
    if (rangedatestart > rangedateend) {
      activityFrom = rangedateend;
      activityTo = rangedatestart;
    } else {
      activityFrom = rangedatestart;
      activityTo = rangedateend;
    }
    let activityFrom1;
    let activityTo1;
    let rangedatestart1 = dateRangeactivity[1];
    let rangedateend1 = dateRangeactivity[0];
    if (rangedatestart1 > rangedateend1) {
      activityFrom1 = rangedateend1;
      activityTo1 = rangedatestart1;
    } else {
      activityFrom1 = rangedatestart1;
      activityTo1 = rangedateend1;
    }
    const filterrequest = {
      to_date: reset ? "" : activityTo,
      from_date: reset ? "" : activityFrom,
      activity_from_date: reset ? "" : activityFrom1,
      activity_to_date: reset ? "" : activityTo1,
      material_category: reset ? "" : MaterialCategory,
      priority: reset ? "" : priorityval,
      site_engineer: reset ? "" : siteenggval,
      project_name: reset ? "" : projectNamedata,
      project_location: reset ? "" : SiteAddress,
    };

    axiosClientInterceptors
      .post(
        `/get-closed-request?page=1&limit=${perPage}&search=&project_id=${singleViewId}`,
        filterrequest
      )
      .then((resp) => {
        setAllRequestData(resp.data.result.listOfRequest);
        dispatch(setclosedTabPagination(resp.data.result.currentPage));
        setTotalPage(resp.data.result.totalRequest);
        // getcloseed(resp.data.result.totalRequest);
        setLoading(false);
      });
  };

  const getcategories = () => {
    axiosClientInterceptors
      .get(`/getcategories`)
      .then((response) => {
        setdropdowncategories(response.data.Category);
      })
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getcategories();
  }, []);
  useEffect(() => {
    getAllRequests();
  }, [currentpage, perPage]);

  return (
    <>
      <Modal
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className=" allProjectFilter ">
          <div className="d-flex justify-content-between mb-5 align-items-center">
            <p className="m-0 Filter_text ">Apply Filter</p>
            <CloseIcon onClick={handleClose} />
          </div>

          <div>
            <div className="row">
              <div className="col-6" style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Creation Date Range</label>
                <br />
                <DateRangePicker
                  cleanable={true}
                  id="filter_range"
                  placeholder="Select Date Range"
                  format="dd/MM/yyyy"
                  character="-"
                  style={{ zIndex: "999", border: "none" }}
                  onChange={onChange}
                  defaultValue={dateRange}
                  ranges={Ranges}
                />
              </div>
              <div className="col-6" style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">
                  Last Activity Date Range
                </label>
                <br />
                <DateRangePicker
                  cleanable={true}
                  id="filter_range"
                  placeholder="Select Date Range"
                  format="dd/MM/yyyy"
                  character="-"
                  style={{ zIndex: "999", border: "none" }}
                  onChange={onChange1}
                  ranges={Ranges}
                  defaultValue={dateRangeactivity}
                />
              </div>
              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Request Priority</label>
                <br />
                <select
                  className="filter_usertype mb-1"
                  name="priority"
                  value={priorityval || filter.priority}
                  style={{ border: "none" }}
                  onChange={(e) => {
                    Setpriorityval(e.target.value);
                  }}
                >
                  <option selected value="">
                    Select Priority
                  </option>
                  <option value="High">High Priority</option>
                  <option value="Medium">Medium Priority</option>
                  <option value="Low">Low Priority</option>
                </select>
              </div>
              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Material Category</label>
                <br />
                <select
                  className="filter_usertype mb-1"
                  name="material_category"
                  value={MaterialCategory || filter.material_category}
                  style={{ border: "none" }}
                  defaultValue={filter.material_category}
                  onChange={(e) => {
                    setMaterialCategory(e.target.value);
                  }}
                >
                  <option selected value="" disabled>
                    Select Category
                  </option>
                  {dropdowncategories?.map((val, index) => (
                    <option key={index} value={val._id}>
                      {val.category_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Site Engineer</label>
                <br />
                <select
                  className="filter_usertype mb-1"
                  name="site_engineer"
                  value={siteenggval || filter.site_engineer}
                  style={{ border: "none" }}
                  onChange={(e) => {
                    Setsiteenggval(e.target.value);
                  }}
                >
                  <option selected value="" disabled>
                    Select Site Engineer
                  </option>
                  {SiteEngineer?.map((op, index) => {
                    return (
                      <option key={op._id} value={op._id}>
                        {op.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-6 " style={{ marginBottom: "16px" }}>
                <label className="select_label mb-2">Project </label>
                <br />
                <select
                  className="filter_usertype mb-1"
                  name="project_stage"
                  value={projectNamedata || filter.project_name}
                  style={{ border: "none" }}
                  onChange={(e) => {
                    setprojectNamedata(e.target.value);
                  }}
                >
                  <option selected value="" disabled>
                    Choose Project
                  </option>
                  {projectName?.map((ki, index) => {
                    return (
                      <option key={index} value={ki.project_name}>
                        {ki.project_name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div
                className="col-12 col-md-12 "
                style={{ marginBottom: "16px" }}
              >
                <label className="select_label mb-2">Site Address</label>
                <input
                  type="text"
                  value={SiteAddress || filter.project_location}
                  style={{ border: "none" }}
                  onChange={(e) => setSiteAddress(e.target.value)}
                  placeholder="eg. Jp nagar, BTM, HSR layout"
                  className="filter_usertype p-1"
                  name="project_location"
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-6">
                  <div
                    className="filter_op_btns"
                    onClick={() => {
                      dispatch(setclosedCreationDate(""));
                      getAllRequests2(true);
                      dispatch(setcloseLastActivityDate(""));
                      handleClose();
                      dispatch(setcloseTabFilter(""));
                    }}
                  >
                    Reset All
                  </div>
                </div>
                <div className="col-6">
                  <button
                    onClick={() => {
                      getAllRequests();
                      handleClose();
                    }}
                    type="submit"
                    className="filter_op_btns1"
                  >
                    Apply Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* search & filter */}
      <div className="d-flex mt-3">
        <div className="searchrequst d-flex me-2 ">
          <input
            type="text"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            className="w-100 inputsearch"
            placeholder="Search by id, name, status"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                getAllRequests();
              }
            }}
          />
          {search.length > 0 && (
            <CloseIcon
              className="mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                getAllRequests2();
                setsearch("");
              }}
            />
          )}
          <button
            className="serchbuttonrequest"
            onClick={() => getAllRequests()}
            disabled={search.length === 0}
          >
            <img src={Search} alt="" />
            <span>Search</span>
          </button>
        </div>
        <div
          className="filterrequest"
          onClick={() => {
            handleOpen();
          }}
        >
          <img src={filtersImg} alt="" className="me-1" />
          <span style={{ color: "#909090" }}>Filter</span>
        </div>
      </div>
      {isLoding ? (
        <div
          className="App d-flex justify-content-center align-items-center"
          style={{
            width: "100%",
            textAlign: "center",
            height: "100vh",
            background: "white",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {allrequestdata.length === 0 ? (
            <div className=" mt-3 NoRecordFound">
              <img src={NoRecordFound} />
            </div>
          ) : (
            <div className="mt-3 mr-2">
              <div className="inner_table_div">
                <table className="table leaderboard_table">
                  <tbody>
                    <tr className="headers_tag">
                      <th className="text-center">Request Id</th>
                      <th className="text-center">Project Name</th>
                      <th className="text-center">Material Category</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Created By</th>
                      <th className="text-center">Due Date</th>
                      <th className="text-center">Last Activity Date & Time</th>
                    </tr>

                    {allrequestdata.map((des, index) => {
                      return (
                        <tr
                          key={des._id}
                          className="vals"
                          onClick={() => (
                            dispatch(setrequestID(des._id)),
                            localStorage.setItem("REqustID", des._id),
                            getRequestDetails(des._id),
                            Setrequestidentity(des._id),
                            dispatch(setscreen("singledata"))
                          )}
                        >
                          <td
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              {des.priority == "High" && (
                                <div className="active_dot"></div>
                              )}
                              {des.priority == "Medium" && (
                                <div className="med_pri"></div>
                              )}
                              {des.priority == "Low" && (
                                <div className="low_pri"></div>
                              )}
                              {des.request_id}
                            </div>
                          </td>
                          <td
                            className="text-center"
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des.project_name}
                          </td>
                          <td
                            className="text-center"
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des.material_category}
                          </td>
                          <td
                            className="text-center"
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des.request_status}
                          </td>
                          <td
                            className="text-center"
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des.requested_by}
                          </td>
                          <td
                            className="text-center"
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                              dispatch(
                                setNotificationStatusScreen("singledata")
                              );
                            }}
                          >
                            {moment(des.due_date, "YYYY-MM-DD").format(
                              "DD/MM/YYYY"
                            )}
                          </td>

                          <td
                            className="text-center"
                            onClick={() => {
                              setRequestData("singledata");
                              dispatch(setscreen("singledata"));
                            }}
                          >
                            {des.updationdate}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
      <div className="w-100 d-flex justify-content-end align-items-center">
        <div className="flotpagination">
          <PaginationComponent
            handleChangePage={handleChangePage}
            perPage={perPage}
            totlePage={totlePage}
            currentpage={currentpage}
          />
        </div>
      </div>
    </>
  );
};

export default ClosedTab;
