import React, { useEffect, useState } from 'react'
import searchIcon from "../../../Assets/Img/svg/search.svg"
import filter from "../../../Assets/Img/svg/filter.svg"
import { useDropzone } from 'react-dropzone'
import {
    selectAddVendorScreen,
    setAddVendorScreen
} from '../../../features/Screen/screen'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { DateRangePicker } from "rsuite";
import Select from "react-select";
import { LastActivityDate, Ranges, lastActivity } from '../../../ReusableComponent/Validation'
import axiosClientInterceptors from '../../../ReusableComponent/axiosClentInterceptors'
import {
    selectPOswitch,
    selectallProjects,
    selectsetPOprojectName,
    selectvendorCompanyName,
    setFinalpoData,
    setPOprojectName,
    setPOswitch,
    setPoID,
    setTermsncondition
} from '../../../features/counter/counterSlice'
import NewMultiSelect from '../../../ReusableComponent/NewMultiSelect'
import newAxiosClientInterceptors from '../../../ReusableComponent/newAxiosClientInterceptors'
import {
    selectPOPage,
    selectPOPerPage,
    selectPOTotalCount,
    selectPOpagination,
    setPOPage,
    setPOPerPage,
    setPOTotalCount,
    setPOpagination
} from '../../../features/pagination/paginationSlice'
import PaginationComponent from '../../../ReusableComponent/PaginationComponent'
import moment from 'moment'
import { useFormik } from 'formik'
import Loader from '../../../ReusableComponent/Loader'
import PurchaseOrderDOc from '../PoAdmin/PurchaseOrderDOc'
import NoRecordFound from "../../../Assets/Img/NoRecordFound.svg"
import { useRef } from 'react'
// import {
//     selectPOdateRange,
//     selectPOendDate,
//     selectPOprojectName,
//     selectPOstartDate,
//     selectPOstatus,
//     setPOdateRange,
//     setPOendDate,
//     setPOprojectName,
//     setPOstartDate,
//     setPOstatus
// } from '../../../features/idvPurchaseOrderSlice/purchaseOrderSlice'

const ViewPO = ({
    getvendorListiing,
    materialCatalog,
    vendordetailsID,
    SocketCall,
    SocketCallData
}) => {

    const { afterToday } = DateRangePicker;

    const screenvendor = useSelector(selectPOswitch);
    const Projects = useSelector(selectallProjects);
    const dispatch = useDispatch();
    const comapanyName = useSelector(selectvendorCompanyName)
    // const perPage = useSelector(selectPOPerPage)
    // const currentpage = useSelector(selectPOPage)
    // const totalCount = useSelector(selectPOTotalCount)
    const currentpage = useSelector(selectPOpagination);
    const pojectNames = useSelector(selectsetPOprojectName);
    const [perPage, setPerPge] = useState(10);
    const [totlePage, setTotalPage] = useState("");

    // const defaultPOstartDate = useSelector(selectPOstartDate);
    // const defaultPOendDate = useSelector(selectPOendDate);
    // const defaultPOdateRange = useSelector(selectPOdateRange);
    // const defaultPOprojectName = useSelector(selectPOprojectName);
    // const defaultPOstatus = useSelector(selectPOstatus);

    const [openFilter, setOpenFilter] = useState(false);
    const [allProjectData, setAllProjectData] = useState([]);
    const [isLoding, setLoading] = useState(false);
    const [POList, setPOList] = useState([]);
    const [dateRange, setDateRange] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [search, setSearch] = useState("");
    const clearSearch = useRef(null);

    const formik = useFormik({
        initialValues: {
            POStatus: ""
        },
        onSubmit: () => {
            getPOList();
        }
    })

    //handle multiselect options
    const handleAllProjects = Object.assign(
        [],
        Projects?.map((x) => {
            return { value: x.project_name, label: x.project_name };
        })
    );

    // let data = []
    const handleProjectOptions = (value) => {
        // data = [...value]
        setAllProjectData(value);
    }

    const handleDateRange = (date) => {
        console.log(date);
        if (date) {
            setDateRange(date);
            const [dateFrom, dateTo] = date;
            setStartDate(moment(dateFrom).format("YYYY-MM-DD"));
            setEndDate(moment(dateTo).format("YYYY-MM-DD"));
        }
    }

    const click = (id) => {
        axiosClientInterceptors
            .get(`/getpurchasebyid?_id=${id}`)
            .then((responsedata) => {
                dispatch(setFinalpoData(responsedata.data.Purchase_Orders[0]));
                dispatch(setTermsncondition(responsedata.data.termsandcondition));
                console.log(
                    responsedata.data.Purchase_Orders[0],
                    "responsedata.data.Purchase_Orders[0]"
                );
            });
    };

    const getPOList = (reset) => {
        setLoading(true);
        // dispatch(setPOstartDate(startDate || defaultPOstartDate))
        // dispatch(setPOendDate(endDate || defaultPOendDate))
        // dispatch(setPOdateRange(dateRange || defaultPOdateRange))
        // dispatch(setPOprojectName(allProjectData || defaultPOprojectName))
        // dispatch(setPOstatus(formik.values.POStatus || defaultPOstatus))

        // dispatch(setPOprojectName(allProjectData))

        axiosClientInterceptors
            .post(`/po-by-vendor-id?page=${currentpage}&limit=${perPage}&search=${reset === "resetsearch" ? "" : search}`, {
                vendor_id: vendordetailsID,
                po_from_date: reset === "reset" ? null : startDate,
                po_to_date: reset === "reset" ? null : endDate,
                project_name: reset === "reset" ? [] : allProjectData?.map((item) => item?.value),
                status: reset === "reset" ? "" : formik.values.POStatus
            })
            .then((res) => {
                dispatch(setPOpagination(res.data.result.currentPage));
                console.log(res.data?.result?.totalPO);
                setPOList(res.data?.result.listOfPO)
                setTotalPage(res.data?.result?.totalPO)
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // handle change function for pagination
    const handleChangePage = (e, pageSize) => {
        dispatch(setPOpagination(e));
        setPerPge(pageSize)
    }

    useEffect(() => {
        getPOList();
    }, [currentpage, perPage])

    // useEffect(() => {
    //     if (screenvendor !== "viewPO" && allProjectData.length > 0) {
    //         dispatch(setPOprojectName([]));
    //         setAllProjectData([]);
    //     }
    // }, [allProjectData])

    useEffect(() => {
        console.log(startDate, endDate);
    })

    return (
        <div className="p-4">
            <div className="d-flex align-items-center">
                <p className="m-0">
                    <span
                        className="fontdocument_dim"
                        onClick={() => {
                            dispatch(setAddVendorScreen("listing"));
                            getvendorListiing();
                        }}
                    >
                        All Vendors{" "}
                    </span>
                    /{" "}
                    <span
                        className="fontdocument_dim"
                        onClick={() => {
                            dispatch(setAddVendorScreen("vendorDetails"));
                            vendordetailsID();
                            materialCatalog();
                        }}
                    >
                        Vendor Details{" "}
                    </span>
                    / <span className="fontdocument_active">
                        Purchase Orders For {comapanyName}
                    </span>
                </p>
                <div className="PO_border" />
            </div>
            <div className="mt-3 d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    <div className="d-flex">
                        <input
                            ref={clearSearch}
                            type="text"
                            defaultValue={search}
                            className="searchbar"
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    getPOList();
                                }
                            }}
                            placeholder="Search by PO Number"
                        />
                        {
                            search.length > 0 &&
                            <CloseIcon sx={{ marginTop: "5px" }}
                                onClick={() => {
                                    clearSearch.current.value = "";
                                    setSearch("");
                                    getPOList("resetsearch")
                                }}
                            />
                        }

                    </div>
                    <div className="searchBtn"
                        style={{ position: "relative" }}
                        onClick={() => {
                            getPOList();
                        }}
                    >
                        <img
                            className="searchIcon1"
                            src={searchIcon} alt="" />
                        <p>Search</p>
                    </div>
                </div>
                <div
                    className="filter_btn"
                    onClick={() => {
                        setOpenFilter(true);
                    }}
                >
                    <img
                        style={{ paddingRight: "6px" }}
                        src={filter} alt="" />
                    Filter
                </div>
            </div>
            {
                isLoding ? (
                    <div
                        className="App d-flex justify-content-center align-items-center"
                        style={{
                            width: "100%",
                            textAlign: "center",
                            height: "100vh",
                            background: "white",
                        }}
                    >
                        <Loader />
                    </div>
                ) : (

                    POList.length > 0 ? (
                        <div>
                            <div className="admin_inner_table_div">
                                <div className="mt-4 inner_table_div">
                                    <table className="table leaderboard_table">
                                        {/* <thead className="">
                                    <tr>
                                        <th>SL. No.</th>
                                        <th>PO Number</th>
                                        <th>Status</th>
                                        <th>Purchase Date</th>
                                        <th>Due Date</th>
                                    </tr>
                                </thead> */}
                                        <tbody>
                                            <tr className="headers_tag">
                                                <th className="text-center">SL. No.</th>
                                                <th className="text-center">PO Number</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Purchase Date</th>
                                                <th className="text-center">Due Date</th>
                                            </tr>
                                            {
                                                POList?.map((item, i) => {
                                                    return (
                                                        <tr
                                                            className="vals"
                                                            onClick={() => {
                                                                dispatch(setAddVendorScreen("IdvPurchaseOrderDOc"));
                                                                // dispatch(setPoID(item?._id));
                                                                click(item?._id);
                                                            }}
                                                        >
                                                            <td className="table_row">{(currentpage - 1) * perPage + i + 1}</td>
                                                            <td className="table_row">{item?.purchase_order_no || "-"}</td>
                                                            <td
                                                                style={{ borderBottom: "0px" }}
                                                                className="d-flex justify-content-center align-items-center">
                                                                <div className={
                                                                    item?.PO_status === "" ? "newPO" : "PO_cancelled"
                                                                }>

                                                                    {item?.PO_status || "New PO"}
                                                                </div>
                                                            </td>
                                                            <td className="table_row">{item?.po_date ?
                                                                moment(item?.po_date).format("DD/MM/YYYY") : "-"}</td>
                                                            <td className="table_row">{item?.due_date || "-"}</td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="w-100 d-flex justify-content-end align-items-center">
                                <div className="flotpagination">
                                    <PaginationComponent
                                        handleChangePage={handleChangePage}
                                        perPage={perPage}
                                        totlePage={totlePage}
                                        currentpage={currentpage}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className=" mt-3 NoRecordFound">
                            <h1>No Records Found!</h1>
                        </div>
                    )

                )
            }

            {/* filter pop up starts here */}
            <Dialog
                open={openFilter}
                onClose={() => {
                    setOpenFilter(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "384px",
                            height: "416px"
                        },
                    },
                }}
            >
                <div className="p-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="filter_title">Apply FIlters</p>
                        <CloseIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                setOpenFilter(false);
                            }}
                        />
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mt-4 datePicker">
                            <p className="filter_heading">Date Range</p>
                            <DateRangePicker
                                className="mt-2"
                                // style={{ border: "1px solid black" }}
                                placement={"bottom"}
                                style={{
                                    fontFamily: "Gm",
                                    border: "1px solid hsl(0, 0%, 70%)",
                                    backgroundColor: "white !important",
                                    height: "45px"
                                }}
                                name="start"
                                placeholder="Select Date Range"
                                format="dd/MM/yyyy"
                                character="-"
                                onChange={handleDateRange}
                                ranges={LastActivityDate}
                                defaultValue={dateRange}
                                cleanable={false}
                                disabledDate={afterToday()}
                            />
                        </div>
                        <div className="mt-3">
                            <p className="filter_heading mb-2"
                                onClick={() => {
                                    console.log(allProjectData);
                                }}
                            >Project Name</p>
                            {/* <NewMultiSelect
                                myPlaceHolder={"Select User"}
                                myOption={handleAllProjects}
                                getmedata={handleProjectOptions}
                                myDefalutValue={pojectNames}
                            /> */}
                            <Select
                                options={handleAllProjects}
                                defaultValue={allProjectData}
                                // name="Material_Category"
                                // value={multiselect}
                                onChange={handleProjectOptions}
                                isMulti
                                className="poProjects"
                            />
                        </div>
                        <div className="mt-3">
                            <p className="filter_heading">Status</p>
                            <select
                                name="POStatus"
                                className="dropdown_fields mt-2"
                                defaultValue={formik.values.POStatus}
                                onChange={formik.handleChange}
                            >
                                <option value="" hidden>New Purchase Order</option>
                                <option value="New PO">New PO</option>
                                <option value="PO Cancelled">PO Cancelled</option>

                            </select>
                        </div>
                        <div className="mt-4 d-flex justify-content-between align-item-center">
                            <button
                                type="reset"
                                className="resetAll_btn"
                                onClick={() => {
                                    getPOList("reset");
                                    setOpenFilter(false);
                                    formik.resetForm({});
                                    setStartDate(null);
                                    setEndDate(null)
                                    setDateRange(null)
                                    setAllProjectData([]);
                                }}
                            >
                                Reset All
                            </button>
                            <button
                                type="submit"
                                onClick={() => {
                                    setOpenFilter(false);
                                }}
                                className="apply_btn">
                                Apply Filter
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            {/* filter pop up ends here */}

        </div>
    )
}

export default ViewPO
