import React from "react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import documentcopy from "../../../Assets/Img/copy_btn.svg";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setCancelPoDataFromlisting,
  setnewRequestID,
  setPOswitch,
  setRequestDataFromRFQID,
} from "../../../features/counter/counterSlice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilterResults from "react-filter-search";

const CopyPO = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [search, setsearch] = useState("");
  const [radioValue, setradioValue] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [cancelPoDropdownList, setcancelPoDropdownList] = useState([]);
  const getcancelPo = () => {
    axiosClientInterceptors.get(`/cancelledPO`).then((res) => {
      setcancelPoDropdownList(res.data?.cancelledPO);
    });
  };
  useEffect(() => {
    getcancelPo();
  }, []);

  const createrevisedPo = () => {
    axiosClientInterceptors
      .post(`/copypurchaseorder`, { po_no: radioValue })
      .then((rsp) => {
        dispatch(setRequestDataFromRFQID(rsp.data.result));
        console.log("rsp.data.result", rsp.data.result);
        dispatch(setnewRequestID(rsp.data.result.purchase_order_no));
        dispatch(setPOswitch("Request detail"));
      });
  };
  const handleChange = (event) => {
    console.log(event.target.value);
    setradioValue(event.target.value);
  };

  return (
    <>
      {" "}
      <div className="CopyPurchaseOrder me-3" onClick={handleOpen}>
        <img src={documentcopy} alt="" className="me-2" />
        <p>Copy Purchase Order</p>
      </div>
      <Modal
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        open={open}
        className="d-flex justify-content-center align-items-center flex-column "
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="filter_container">
          <div className="d-flex justify-content-between align-items-center p-3">
            <p className="m-0 Filter_text ">Copy Purchase Order</p>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <div className="mx-3">
            <div className="search_container">
              <div class="search">
                <input
                  type="text"
                  value={search}
                  class="search__input"
                  onChange={(e) => setsearch(e.target.value)}
                  placeholder="Search by purchase order number"
                />
                <button class="search__button">
                  <svg
                    class="search__icon"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div
              className="mt-3"
              style={{ height: "300px", overflowY: "scroll" }}
            >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={cancelPoDropdownList[1]?.purchase_order_no}
                value={radioValue}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                {" "}
                <FilterResults
                  value={search}
                  data={cancelPoDropdownList}
                  renderResults={(results) => (
                    <>
                      {results.length === 0 ? (
                        <p className="nodata w-100 text-center">
                          NO DATA Found
                        </p>
                      ) : (
                        <>
                          {" "}
                          {results.map((val, i) => (
                            <FormControlLabel
                              value={val.purchase_order_no}
                              control={<Radio />}
                              label={val.purchase_order_no}
                            />
                          ))}
                        </>
                      )}
                    </>
                  )}
                />
              </RadioGroup>
            </div>
            <div
              onClick={() => createrevisedPo()}
              className="mb-2 d-flex justify-content-between align-items-center w-100"
            >
              <button className="PurchageOrder_add w-100" type="submit">
                <p className="m-0">Create Purchase Order</p>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CopyPO;
