import React, { useEffect, useState } from "react";
import "./PO.css";
import Delete from "../../../Assets/Img/svg/Delete.svg";
import Documenticon from "../../../Assets/Img/svg/Document.svg";
import { useDispatch, useSelector } from "react-redux";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Popup from "reactjs-popup";
import {
  selectactivetab,
  selectCancelPoDataFromlisting,
  selectCopyRequestID,
  selectnewRequestID,
  selectPOswitch,
  selectRFQID,
  setCancelPoDataFromlisting,
  setFinalpoData,
  setPOswitch,
  setTermsncondition,
} from "../../../features/counter/counterSlice";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";

import ExistingRow from "./ExistingRow";
import { setProjectScreen, setscreen } from "../../../features/Screen/screen";
import CircularProgress from "@mui/material/CircularProgress";

const RequestDetailPO = ({
  editable,
  vendorID,
  individualPOcreateID,
  // SocketCallData,
  setRequestData,
  // SocketCall,
  requestAllDetails_id,
}) => {
  const revisedPoData = useSelector(selectCancelPoDataFromlisting);
  const RFQID = useSelector(selectRFQID);
  const [poDetails, setPoDetails] = useState(revisedPoData);

  var MYdata = poDetails?.materials?.map((v, ind) => ({
    ...v,
    writable: true,
  }));
  const [dataM, setDataM] = useState(MYdata);
  const [TotalInvoice, setTotalInvoice] = useState(0);
  const [submitdisabe, setsubmitdisabe] = useState(true);
  const [loaderPO, setloaderPO] = useState(false);

  // ================================================

  const activetabsection = useSelector(selectactivetab);
  const [companyPAN, setCompanyPAN] = useState("");

  const POswitch = useSelector(selectPOswitch);
  const [Vendor, setVendor] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [vendorDetails, setVendorDetails] = useState("");
  const [transportCharges, setTransportCharges] = useState(
    Number(poDetails.transportation_charges) || 0
  );
  const [loadingUnloadingCharges, setLoadingUnloadingCharges] = useState(
    Number(poDetails.load_unload_charges) || 0
  );
  const [uomList, setUomList] = useState([]);
  const [transcheckbox, Settranscheckbox] = useState(false);
  const [loadingcheckbox, Setloadingcheckbox] = useState(false);
  const [discountcheckbox, Setdiscountcheckbox] = useState(false);
  const [terms, Setterms] = useState("");
  const [tandcaray, Settandcaray] = useState(
    poDetails.special_instruction || []
  );

  const [discountvalue, setDiscount] = useState(
    Number(poDetails.discount) || 0
  );
  const [counterval, setCounterVal] = useState(0);
  const [vendorSelected, setVendorSelected] = useState(true);

  const dispach = useDispatch();

  let PURCHASEORDER_DATA = [];
  // terms & condition state
  const [myArray, setMyArray] = useState([]);
  // Function to delete an item from the array
  const handleDelete = (indexToDelete) => {
    const newArray = [...myArray];
    newArray.splice(indexToDelete, 1);
    setMyArray(newArray);
  };

  useEffect(() => {
    // To get Prerequiste values

    axiosClientInterceptors
      .get(`/purchaseorder?rfq_id=${individualPOcreateID}`)
      .then((resul) => {
        // setloaderPO(true);
        setDataM(
          resul.data.result[0].materials.map((v, ind) => ({
            ...v,
            writable: true,
          }))
        );

        setPoDetails(resul.data.result[0]);
        setCompanyPAN(resul.data.result[0].company_pan);
        getVendorsOnCategory(resul.data.result[0].material_category);
      });
  }, []);

  useEffect(() => {
    if (revisedPoData?.material_category) {
      axiosClientInterceptors
        .get(`/get-vendor-list?category=${revisedPoData?.material_category}`)
        .then((resul) => {
          setVendorList(resul.data.result);
        });
    }
    setMyArray(poDetails.special_instruction || []);
  }, []);

  const getVendorsOnCategory = (vendortype) => {
    axiosClientInterceptors
      .get(`/get-vendor-list?category=${vendortype}`)
      .then((resul) => {
        setVendorList(resul.data.result);
        setVendorSelected(false);
      });
  };

  const closemodalpopup = () => {
    document.getElementById("closematerialpopup").click();
  };

  const postAllData = () => {
    setloaderPO(true);
    PURCHASEORDER_DATA.push({
      project_id: poDetails.project_id,
      purchase_order_no: poDetails.purchaseorder_number,
      purchase_order_date: poDetails.purchaseorder_date,
      due_date: poDetails.due_date,
      materials: dataM,
      material_category: poDetails.material_category,
      vendor_company_name: vendorDetails?.company_name,
      vendor_name: vendorDetails?.vendor_name,
      vendor_phone: vendorDetails?.contact_no,
      vendor_email: vendorDetails?.email,
      vendor_address: vendorDetails?.address,
      billing_address: poDetails.billing_address,
      shipping_address: poDetails.shipping_address,
      site_eng_name: poDetails.site_eng_name,
      site_eng_no: poDetails.site_eng_no,
      company_gst: poDetails.company_gst,
      company_pan: poDetails.company_pan ? poDetails.company_pan : companyPAN,
      vendor_gst: vendorDetails?.gst_number,
      vandor_pan: vendorDetails?.vandor_pan,
      grand_total: poDetails.total,
      sub_total: calculateSubTotle(),
      discount: poDetails.discount,
      transportation_charges: poDetails.transportation_charges,
      special_instruction: myArray,
      request_id: poDetails.request_id,
      // gst: gstcharges,
      load_unload_charges: poDetails.load_unload_charges,
      RFQ_id: RFQID,
      vendor_id: vendorID,
    });

    axiosClientInterceptors
      .post(`/addpurchaseorder`, PURCHASEORDER_DATA[0])
      .then((aldata) => {
        // console.log("Alldatta,", aldata);

        dispach(setFinalpoData(aldata.data.Purchase_Orders[0]));

        axiosClientInterceptors
          .get(`/getpurchasebyid?_id=${aldata.data.Purchase_Orders[0]._id}`)
          .then((responsedata) => {
            axiosClientInterceptors
              .get(`/generate-po?_id=${aldata.data.Purchase_Orders[0]._id}`)
              .then((res) => {
                console.log("dayaayaya", res);
              });
            dispach(setPOswitch("PurchaseOrderDOc"));
            // SocketCall("admin-notification-count", null, null, "PO Created");
            // SocketCall("pm-notification-count", null, null, "PO Created");
            // SocketCall("pd-notification-count", null, null, "PO Created");
            // SocketCallData(
            //   "admin-get-notification",
            //   requestAllDetails_id,
            //   "PO Created"
            // );
            // SocketCallData(
            //   "pm-get-notification",
            //   requestAllDetails_id,
            //   "PO Created"
            // );
            // SocketCallData(
            //   "pd-get-notification",
            //   requestAllDetails_id,
            //   "PO Created"
            // );

            dispach(setFinalpoData(responsedata.data.Purchase_Orders[0]));
            console.log("from ", responsedata.data.Purchase_Orders[0]);
            dispach(setTermsncondition(responsedata.data.termsandcondition));
            setloaderPO(false);
            // dispach(setCancelPoDataFromlisting([]));
            // dispach(setCancelPoDataFromlisting([]));
          });
      });
  };

  const addtandc = (terms) => {
    window.$("#enterterms").modal("hide");
    // handleUpdate;
    setMyArray([...myArray, terms]);
    Settandcaray([...tandcaray, terms]);
    Setterms("");
  };
  // console.log("T&C", tandcaray);

  useEffect(() => {
    axiosClientInterceptors
      .get(`/getvendorbyid?_id=${vendorID}`)
      .then((res) => {
        setVendorDetails(res.data.Vendor[0]);
        // setVendorCompanyName(res.data.Vendor[0]?.company_name);
        // setVendorPAN(res.data.Vendor[0]?.vandor_pan);
      });
  }, [Vendor]);

  useEffect(() => {
    if (poDetails?.discount > 0) {
      Setdiscountcheckbox(true);
    }
  }, []);

  // new  code =====================================
  const updateFieldValue = (index, fieldName, value) => {
    calculateSubTotle();
    setDataM((prevData) => {
      let newData = [...prevData];
      newData[index][fieldName] = value;
      return newData;
    });
  };
  const calculateSubTotle = () => {
    let subtotal = 0;
    for (let i = 0; i < dataM?.length; i++) {
      subtotal += dataM[i].amount;
    }

    return subtotal;
  };
  // TransportationCharges
  const TransportationCharges = () => {
    let SubTotle_data = calculateSubTotle();
    if (SubTotle_data === 0) {
      return 0;
    } else {
      return Number(transportCharges);
    }
  };
  // Loading & Unloading Charges
  const LoadingUnloadingCharges = () => {
    let SubTotle_data = calculateSubTotle();
    if (SubTotle_data === 0) {
      return 0;
    } else {
      return Number(loadingUnloadingCharges);
    }
  };

  // Discount;
  const Discount = () => {
    return discountvalue;
  };

  // Total Invoice Value
  const TotalInvoiceValue = () => {
    setsubmitdisabe(false);
    let SubTotle_data = calculateSubTotle();
    let transpotationCharge = TransportationCharges();
    let loadingCharge = LoadingUnloadingCharges();
    let dicount = Discount();

    let InvoiceTotle =
      Number(SubTotle_data) +
      Number(transpotationCharge) +
      Number(loadingCharge);
    setTotalInvoice(InvoiceTotle - dicount);
  };

  useEffect(() => {
    TransportationCharges();
    LoadingUnloadingCharges();
  }, [transcheckbox, loadingcheckbox]);

  return (
    <>
      {" "}
      <div className="p-3 create_po_page">
        <div className="d-flex justify-content-between align-items-center">
          {POswitch === "Request detail  from request" ? (
            <>
              {activetabsection === "All Projects" ? (
                <p className="m-0">
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      dispach(setProjectScreen("allusers"));
                      dispach(setscreen("listing"));
                      dispach(setPOswitch(""));
                    }}
                  >
                    All Projects
                  </span>{" "}
                  /{" "}
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      dispach(setProjectScreen("singleuser"));
                      dispach(setscreen("listing"));
                    }}
                  >
                    Project Details{" "}
                  </span>
                  /{" "}
                  <span
                    // className="fontdocument_active"
                    className="fontdocument_dim"
                    onClick={() => {
                      dispach(setscreen("listing"));
                    }}
                  >
                    All Requests{" "}
                  </span>
                  /{" "}
                  <span
                    // className="fontdocument_active"
                    className="fontdocument_dim"
                    onClick={() => {
                      dispach(setscreen("singledata"));
                      dispach(setPOswitch(""));
                    }}
                  >
                    Request Details
                  </span>
                  / <span className="fontdocument_active"> Purchase Order</span>
                </p>
              ) : (
                <p className="m-0">
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      setRequestData("listing");
                      dispach(setscreen("listing"));
                      dispach(setPOswitch("Request detail  from requests"));
                    }}
                  // onClick={() => setsingleView("allusers")}
                  >
                    Material Requests
                  </span>
                  /{" "}
                  <span
                    className="fontdocument_dim"
                    onClick={() => {
                      setRequestData("singledata");
                      dispach(setscreen("singledata"));
                      dispach(setPOswitch("Request detail  from requests"));
                    }}

                  //   onClick={() => dispach(setPOswitch("maintable"))}
                  >
                    Request Details{" "}
                  </span>
                  /{" "}
                  <span
                    className="fontdocument_active"
                  // onClick={() => setsingleView("allusers")}
                  >
                    Purchase Order
                  </span>
                </p>
              )}
            </>
          ) : (
            <p className="m-0">
              <span
                className="fontdocument_dim"
                onClick={() => {
                  dispach(setPOswitch("maintable"));
                  dispach(setCancelPoDataFromlisting([]));
                }}
              >
                Purchase Order list
              </span>
              /{" "}
              <span
                className="fontdocument_active"
              // onClick={() => setsingleView("allusers")}
              >
                Purchase Order
              </span>
            </p>
          )}
        </div>
        <hr />
        {/* 1st */}
        <div className="PurchaseOrderDetails p-3">
          <p
            className="PurchaseOrderDetails_text"
            onClick={() => {
              TransportationCharges();
              LoadingUnloadingCharges();
            }}
          >
            Purchase Order Details
          </p>
          <hr />
          <div className="row p-2">
            <div className="col-2">
              <p
                className=" PurchaseOrderDetails_subtext"
                style={{ marginBottom: "10px" }}
              >
                Purchase Order Date
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {poDetails?.purchaseorder_date}
              </p>
            </div>
            <div className="col-1">
              <p
                className=" PurchaseOrderDetails_subtext  "
                style={{ marginBottom: "10px" }}
              >
                Project ID
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {poDetails?.project_id}
              </p>
            </div>
            <div className="col-2">
              <p
                className=" PurchaseOrderDetails_subtext  "
                style={{ marginBottom: "10px" }}
              >
                PAN
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {poDetails?.company_pan}
              </p>
            </div>
            <div className="col-2">
              <p
                className=" PurchaseOrderDetails_subtext"
                style={{ marginBottom: "10px" }}
              >
                GST
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {poDetails?.company_gst}
              </p>
            </div>
            <div className="col-3">
              <p
                className=" PurchaseOrderDetails_subtext"
                style={{ marginBottom: "10px" }}
              >
                Purchase Order Number
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {poDetails?.purchaseorder_number}
              </p>
            </div>
            <div className="col-2">
              <p
                className=" PurchaseOrderDetails_subtext"
                style={{ marginBottom: "10px" }}
              >
                Due Date
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {poDetails?.due_date}
                {/* {poDetails.due_date} */}
              </p>
            </div>
          </div>
        </div>
        <div className="PurchaseOrderDetails p-3 mt-4">
          <p className="PurchaseOrderDetails_text">Vendor Details</p>
          <hr />
          <div className="row p-2">
            <div className="col-2">
              <p
                className=" PurchaseOrderDetails_subtext "
                style={{ marginBottom: "10px" }}
              >
                Company Name
              </p>
              {editable ? (
                <select
                  class="form-select "
                  aria-label="Default select example"
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    fontFamily: "Gm",
                    fontSize: "14px",
                    color: "#969696",
                  }}
                  onChange={(e) => setVendor(e.target.value)}
                >
                  <option selected disabled>
                    Choose Company
                  </option>
                  {vendorList?.map((re, index) => {
                    return <option value={re._id}>{re?.company_name}</option>;
                  })}
                </select>
              ) : (
                <p className="m-0 PurchaseOrderDetails_subtext1">
                  {vendorDetails?.company_name || "-"}
                </p>
              )}
            </div>
            <div className="col-2">
              <p
                className=" PurchaseOrderDetails_subtext"
                style={{ marginBottom: "10px" }}
              >
                Full Name
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {vendorDetails?.vendor_name || "-"}
              </p>
            </div>
            <div className="col-2">
              <p
                className=" PurchaseOrderDetails_subtext"
                style={{ marginBottom: "10px" }}
              >
                PAN
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {/* {vendorDetails?.company_pan || "-"} */}
                {vendorDetails?.vandor_pan || vendorDetails?.vendor_pan || "-"}
              </p>
            </div>
            <div className="col-2">
              <p
                className=" PurchaseOrderDetails_subtext"
                style={{ marginBottom: "10px" }}
              >
                GST
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {vendorDetails?.gst_number || "-"}
              </p>
            </div>
            <div className="col-3">
              <p
                className=" PurchaseOrderDetails_subtext"
                style={{ marginBottom: "10px" }}
              >
                Email ID
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {vendorDetails?.email || "-"}
              </p>
            </div>
            <div className="col-2">
              <p
                className=" PurchaseOrderDetails_subtext"
                style={{ marginBottom: "10px" }}
              >
                Phone Number
              </p>
              <p className="m-0 PurchaseOrderDetails_subtext1">
                {vendorDetails?.contact_no
                  ? `+91${vendorDetails?.contact_no}`
                  : "-"}
              </p>
            </div>
          </div>
        </div>
        <div className=" mt-4 d-flex justify-content-between align-items-start">
          <div className="boxOne p-3">
            <p className="PurchaseOrderDetails_text">Site Engineer Details</p>
            <hr />
            <div className="py-2">
              <p className=" FullName" style={{ marginBottom: "6px" }}>
                Full Name
              </p>
              <p className=" posubtitle">{poDetails?.site_eng_name}</p>
            </div>
            <div className="py-2">
              <p className="  FullName" style={{ marginBottom: "6px" }}>
                Phone Number
              </p>
              <p className=" posubtitle">
                {poDetails?.site_eng_no ? poDetails?.site_eng_no : "-"}
              </p>
            </div>
          </div>
          <div className="boxTwo  p-3">
            <p className="PurchaseOrderDetails_text">Address</p>
            <hr />
            <div className="row">
              <div className="col-4">
                <p className="FullName" style={{ marginBottom: "10px" }}>
                  Shipping Address
                </p>
                <div className="pocontent_container">
                  <p>{poDetails?.shipping_address}</p>
                </div>
              </div>
              <div className="col-4">
                <p className="  FullName" style={{ marginBottom: "10px" }}>
                  Billing Address
                </p>
                <div className="pocontent_container">
                  <p>{poDetails?.billing_address}</p>
                </div>
              </div>
              <div className="col-4">
                <p className="  FullName" style={{ marginBottom: "10px" }}>
                  Vendor Address
                </p>
                <div className="pocontent_container">
                  <p>{vendorDetails?.address}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* table PO */}
        <div className="PurchaseOrderDetails p-3 mt-4">
          <p className="PurchaseOrderDetails_text">Material Details</p>
          <div className="mt-3">
            <table className="table leaderboard_table">
              <tbody>
                <tr style={{ backgroundColor: "#FAC40F", color: "black" }}>
                  <th
                    className="text-center"
                    style={{ backgroundColor: "#FAC40F", color: "black" }}
                  >
                    Sl.No
                  </th>
                  <th
                    style={{ backgroundColor: "#FAC40F", color: "black" }}
                    className="text-center"
                  >
                    Material Name
                  </th>
                  <th
                    style={{ backgroundColor: "#FAC40F", color: "black" }}
                    className="text-center"
                  >
                    HSN/SAC
                  </th>
                  <th
                    style={{ backgroundColor: "#FAC40F", color: "black" }}
                    className="text-center"
                  >
                    UOM
                  </th>
                  <th
                    style={{ backgroundColor: "#FAC40F", color: "black" }}
                    className="text-center"
                  >
                    Quantity
                  </th>
                  <th
                    style={{ backgroundColor: "#FAC40F", color: "black" }}
                    className="text-center"
                  >
                    Rate
                  </th>
                  <th
                    style={{ backgroundColor: "#FAC40F", color: "black" }}
                    className="text-center"
                  >
                    CGST
                  </th>
                  <th
                    style={{ backgroundColor: "#FAC40F", color: "black" }}
                    className="text-center"
                  >
                    SGST
                  </th>
                  <th
                    style={{ backgroundColor: "#FAC40F", color: "black" }}
                    className="text-center"
                  >
                    Total Amount
                  </th>
                </tr>
                {dataM?.map((value, index, arr) => {
                  return (
                    <>
                      <ExistingRow
                        value={value}
                        updateFieldValue={updateFieldValue}
                        index={index}
                      />
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-end align-items-center mt-2">
            {/* <div
              className="d-flex align-items-center"
              style={{ padding: "10px 10px 10px 0px" }}
            >
              <input
                type="checkbox"
                className="me-2"
                // defaultChecked={poDetails.transportation_charges === 0}
                defaultChecked={poDetails.transportation_charges === 0}
                onChange={(e) => {
                  Settranscheckbox(e.target.checked);
                  e.target.checked && setTransportCharges(0);
                }}
              />
              <p className="UnloadingChargestext">Transportation Charges</p>
            </div> */}
            <div
              className="d-flex align-items-center"
            // style={{ marginRight: "9.5%" }}
            >
              <p className="my-0 UnloadingChargestext">Sub Total : </p>
              <p className="my-0 mx-2 UnloadingChargestext">
                {/* {Number.isNaN(calculateSubTotle())
                  ? 0
                  : calculateSubTotle().toFixed(2)} */}
                {poDetails.sub_total?.toFixed(2)}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {/* <div className="d-flex align-items-center">
              <input
                type="checkbox"
                className="me-2"
                defaultChecked={poDetails.load_unload_charges === 0}
                onChange={(e) => {
                  Setloadingcheckbox(e.target.checked);
                  e.target.checked && setLoadingUnloadingCharges(0);
                }}
              />
              <p className="UnloadingChargestext">
                Loading & Unloading Charges
              </p>
            </div> */}
            <div className="d-flex align-items-center">
              <p className="UnloadingChargestext">Transportation Charges :</p>
              <div className="Included mx-1">
                {transcheckbox ? (
                  <div className="input_fields_tags">Included</div>
                ) : (
                  <input
                    disabled
                    type="number"
                    value={poDetails.transportation_charges}
                    // defaultValue={poDetails.transportation_charges}
                    className="input_fields_tags"
                    onChange={(e) => {
                      setCounterVal(counterval + 1);
                      setTransportCharges(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-2">
            {/* <div
              className="d-flex align-items-center"
              style={{ marginTop: "-16px" }}
            >
              <input
                type="checkbox"
                className="me-2"
                defaultChecked={poDetails?.discount > 0}
                // checked={poDetails.load_unload_charges === 0}
                onChange={(e) => {
                  Setdiscountcheckbox(e.target.checked);
                  !e.target.checked && setDiscount(0);
                }}
              />
              <p className="UnloadingChargestext">Discount</p>
            </div> */}
            <div className="d-flex align-items-center">
              <p className="UnloadingChargestext">
                Loading & Unloading Charges :
              </p>
              <div className="Included mx-1">
                {loadingcheckbox ? (
                  <div className="input_fields_tags">Included</div>
                ) : (
                  <input
                    disabled
                    type="number"
                    className="input_fields_tags"
                    value={poDetails.load_unload_charges}
                    onChange={(e) => {
                      setCounterVal(counterval + 1);
                      setLoadingUnloadingCharges(e.target.value);
                    }}
                  />
                )}

                {/* </p> */}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center mt-1">
            <div className="d-flex align-items-center">
              <p className="UnloadingChargestext">Discount :</p>
              <div className="Included mx-1">
                <input
                  disabled
                  type="number"
                  className="input_fields_tags"
                  defaultValue={poDetails.discount}
                  value={poDetails.discount}
                  onChange={(e) => {
                    setCounterVal(counterval + 1);
                    setDiscount(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-self-center mt-2">
            <div className="d-flex align-items-center">
              {/* <input
                type="checkbox"
                className="me-2"
                onChange={(e) => Setloadingcheckbox(e.target.checked)}
              /> */}
              {/* <p className="UnloadingChargestext">IGST</p> */}
            </div>
            {/* <button
              className="d-flex align-items-center  CalculateGrandTotal"
              onClick={() => {
                TotalInvoiceValue();
              }}
            >
              Calculate Grand Total
            </button> */}
          </div>
          <div className="d-flex justify-content-end align-items-center mt-4">
            <div className="d-flex align-items-center">
              <p
                className="my-0 UnloadingChargestext"
                style={{ fontWeight: "600" }}
              >
                Total Invoice Value :
              </p>
              <p
                className="my-0 mx-1 UnloadingChargestext"
                style={{ fontWeight: "600" }}
              >
                ₹ {poDetails.total?.toFixed(2)} /-
              </p>
            </div>
          </div>
        </div>
        <div className="PurchaseOrderDetails p-3 mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <p
              className="PurchaseOrderDetails_text"
              onClick={() => {
                console.log("dataM", dataM);
              }}
            >
              Payment Terms
            </p>
            <div
              className="UnloadingChargestext"
              data-bs-toggle="modal"
              data-bs-target="#enterterms"
            >
              <span style={{ color: "#FCC200", cursor: "pointer" }}>
                + Add New Term
              </span>
            </div>
          </div>
          <hr />

          {/* {tandcaray.map((in,index)=>{
             return (<p>{in}</p>)
            })} */}
          {myArray?.map((k, index) => {
            return (
              <div className="d-flex justify-content-between align-items-center notecontainer mt-2">
                <div className="w-100 ">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <p key={index} className="mb-0 p-2">
                      {k}
                    </p>
                    <div
                      className="d-flex justify-content-center align-items-center Delete_bg"
                      onClick={() => handleDelete(index)}
                    >
                      <img src={Delete} className="me-2" />
                      <p>Delete</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Popup
          modal
          className="poup_scroll_imp fade popup-overlaypo "
          trigger={
            <button
              className="create_purchase_order_btn d-flex align-items-center mb-3 mt-3"
              disabled={myArray.length === 0}
            >
              <img src={Documenticon} className="me-2" />
              <p>Save & View Pdf</p>
            </button>
          }
          style={{
            background: "rgb(0,0,0,0.5)",
            width: "25% !important",
          }}
        >
          {(close) => (
            <div>
              <button
                id="closematerialpopup"
                className="close_btn_popup_edit"
                onClick={close}
                style={{
                  background: "none",
                  border: "none",
                  borderRadius: "50%",
                  marginRight: "10px",

                  fontSize: "24px",
                }}
              >
                &times;
              </button>
              <div>
                <div className="p-4">
                  <div className="popup_icon_center">
                    <InfoRoundedIcon
                      className="PO_cancel_icon "
                      style={{
                        color:
                          "linear-gradient(90.11deg, #FFDB6C -9.01%, #F7B314 127.45%)",
                        transform: "rotate(180deg)",
                      }}
                    />
                  </div>
                  <div className="popup_text_center">
                    <h4 className="popup_addbankdetails_text">
                      Are you sure want to Create this Purchase Order..?
                    </h4>
                  </div>
                  {loaderPO ? (
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      <CircularProgress size={20} color="inherit" />
                    </div>
                  ) : (
                    <div className="pt-3 d-flex">
                      <div style={{ width: "50%", textAlign: "center" }}>
                        <button
                          type="button"
                          className="create_purchase_order_btn"
                          onClick={postAllData}
                          style={{
                            width: "80%",
                            fontSize: "14px",
                            background:
                              "162.29deg, #FFE185 -6.85%, #F7BA00 100.08%",
                          }}
                        >
                          Create
                        </button>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          textAlign: "center",
                          color: "#000",
                        }}
                      >
                        <button
                          type="button"
                          className="btn popup_btn_close"
                          data-bs-dismiss="modal"
                          style={{
                            color: "#000",
                            background: "#f4f4f4",
                          }}
                          onClick={closemodalpopup}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Popup>
        {/* popup to add terms and condtions */}
        <div
          className="modal fade"
          id="enterterms"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: "none" }}>
                <h5 className="modal-title text-dark" id="exampleModalLabel">
                  Add Payment Terms
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body pt-0">
                <div className="form_invoice_upload">
                  <form>
                    <div className="row">
                      <div className="col-12">
                        <textarea
                          type="text"
                          required
                          className="invoice_upload_form_input p-3"
                          placeholder="Enter Payment Terms"
                          name="terms_conditions"
                          value={terms}
                          onChange={(e) => {
                            Setterms(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      {/* <Link to="/purchaseorder" className=" "> */}
                      <button
                        className="all_invoice mt-3 text-center w-100"
                        onClick={() => addtandc(terms)}
                        type="submit"
                        disabled={terms === ""}
                      >
                        Submit
                      </button>
                      {/* </Link> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDetailPO;
