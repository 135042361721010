import React from "react";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { fileNameSplit } from "../../../ReusableComponent/Validation";

const ViewDocuments = ({ invoice, title, saveAs, file }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div
        className="d-flex align-items-center uploaddoc_btn2"
        onClick={handleOpen}
        style={{ gap: "12px" }}
      >
        <p className="m-0 fontdocument">View</p>
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.05957 7.49974C5.05957 8.83308 6.15103 9.91792 7.49859 9.91792C8.84006 9.91792 9.93152 8.83308 9.93152 7.49974C9.93152 6.16035 8.84006 5.0755 7.49859 5.0755C6.15103 5.0755 5.05957 6.16035 5.05957 7.49974ZM11.0834 3.77854C12.1505 4.60279 13.059 5.80885 13.7115 7.31794C13.7602 7.43309 13.7602 7.56642 13.7115 7.67551C12.4066 10.6937 10.0834 12.4998 7.49805 12.4998H7.49195C4.91268 12.4998 2.58951 10.6937 1.28463 7.67551C1.23585 7.56642 1.23585 7.43309 1.28463 7.31794C2.58951 4.29976 4.91268 2.49976 7.49195 2.49976H7.49805C8.79073 2.49976 10.0163 2.94824 11.0834 3.77854ZM7.49998 9.00764C8.33534 9.00764 9.01827 8.32885 9.01827 7.49855C9.01827 6.66219 8.33534 5.9834 7.49998 5.9834C7.42681 5.9834 7.35363 5.98946 7.28656 6.00158C7.26217 6.66825 6.71339 7.20158 6.03656 7.20158H6.00607C5.98778 7.29855 5.97559 7.39552 5.97559 7.49855C5.97559 8.32885 6.65851 9.00764 7.49998 9.00764Z"
            fill="#FCC200"
          />
        </svg>
      </div>
      <Modal
        open={open}
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        className="d-flex justify-content-center align-items-center flex-column "
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="filter_container "
          style={{ position: "relative", zIndex: 1, width: "80%" }}
        >
          <div className="m-3 ">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0 Filter_text ">{title}</p>
              <CloseIcon onClick={handleClose} />
            </div>
            <div className="loderimg">
              <CircularProgress />
            </div>
            <iframe
              style={{
                position: "relative",
                zIndex: 3,
                height: "400px",
                width: "100%",
              }}
              src={invoice}
            ></iframe>

            <div
              className="downloadme mt-2"
              onClick={() =>
                saveAs(
                  `${process.env.REACT_APP_IMG_URL}/${file}`,
                  `${fileNameSplit(file)}`
                )
              }
            >
              <div className="d-flex align-items-center">
                <SaveAltIcon fontSize="small" className="me-2 text-black" />
                <p className="m-0 text-black">Download</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewDocuments;
