import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import filter from "../../../Assets/Img/svg/filter.svg";
import { useState } from "react";
import Dialog from '@mui/material/Dialog';
import Modal from "@mui/material/Modal";
import { DateRangePicker } from "rsuite";
import { startOfDay, endOfDay, subMonths, subYears } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  selecPOfilter,
  setPOfilter,
} from "../../../features/DateRangeSlice/DateRangeSlice";
import {
  selectPOfilterData,
  setPOfilterData,
} from "../../../features/pagination/paginationSlice";
import { selectPOvendor, selectallProjects } from "../../../features/counter/counterSlice";
import { lastActivity, LastActivityDate, Ranges } from "../../../ReusableComponent/Validation";
const POfilter = ({
  Project_Name,
  POlisting,
  setProject_Name,
  vendor,
  setVendor,
  onChange,
  dateRange,
  POlisting2,
  purchaseOrderStat,
  setPurchaseOrderStat,
}) => {
  const filters = useSelector(selectPOfilterData);
  const Projects = useSelector(selectallProjects);
  const vendorList = useSelector(selectPOvendor)
  const dispatch = useDispatch();
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  
  return (
    <>
      {" "}
      <div className="filterbutton" onClick={() => {
        setOpen(true);
      }}>
        <img src={filter} alt="" />
        <p className="m-0 Filter">Filter</p>
      </div>
      {/* <Modal
        open={open}
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        className="d-flex justify-content-center align-items-center flex-column "
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="filter_container  p-4 ">
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0 Filter_text ">Apply Filter</p>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <div className="mt-3 row">
            <div className="col-12 mb-3">
              <p className="RequestId mb-2">Date Range</p>
              <DateRangePicker
                style={{ fontFamily: "Gm" }}
                name="start"
                placeholder=" Choose Date Range"
                format="dd/MM/yyyy"
                character="-"
                placement="bottomEnd"
                onChange={onChange}
                ranges={Ranges}
                defaultValue={dateRange}
                cleanable={true}
              />
            </div>
            <div className="col-6">
              <p className="RequestId mb-2">Project Name</p>
              <select
                class="form-select "
                aria-label="Default select example"
                value={Project_Name}
                defaultValue={filters.project_name}
                style={{
                  backgroundColor: "#f8f8f8",
                  border: "none",
                  padding: "8px 32px 14px 13px",
                  fontFamily: "Gm",
                  color: "gray",
                }}
                onChange={(e) => {
                  setProject_Name(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Choose Project
                </option>
                {Projects.map((val, i) => (
                  <option value={val.project_name}>{val.project_name}</option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <p className="RequestId mb-2">PO Status</p>
              <select
                class="form-select "
                aria-label="Default select example"
                value={purchaseOrderStat}
                defaultValue={filters.PO_status}
                style={{
                  backgroundColor: "#f8f8f8",
                  border: "none",
                  padding: "8px 32px 14px 13px",
                  fontFamily: "Gm",
                  color: "gray",
                }}
                onChange={(e) => {
                  setPurchaseOrderStat(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Choose PO Status
                </option>
                <option value="New PO">New PO</option>
                <option value="PO Cancelled">PO Cancelled</option>
              </select>
            </div>
          </div>
          <div className="mt-4">
            <div className="row">
              <div className="col-6">
                <div
                  className="filter_op_btns mx-auto"
                  onClick={() => {
                    dispatch(setPOfilterData(""));
                    POlisting2();
                    dispatch(setPOfilter(""));
                    handleClose();
                  }}
                >
                  Reset All
                </div>
              </div>
              <div className="col-6">
                <button
                  onClick={() => {
                    POlisting();
                    handleClose();
                  }}
                  className="filter_op_btns"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "384px",
              height: "490px"
            },
          },
        }}
      >
        <div className="p-4">
          <div className="d-flex justify-content-between align-items-center">
            <p className="poFilter_title">Apply Filter</p>
            <CloseIcon
              onClick={() => {
                setOpen(false)
              }}
            />
          </div>
          <div className="mt-4">
            <p className="poFilter_text mb-2">Date Range</p>
            <DateRangePicker
              style={{ fontFamily: "Gm" }}
              name="start"
              placeholder=" Choose Date Range"
              format="dd/MM/yyyy"
              character="-"
              placement="bottomEnd"
              onChange={onChange}
              ranges={LastActivityDate}
              defaultValue={dateRange}
              cleanable={true}
            />
          </div>
          <div className="mt-3">
            <p className="poFilter_text mb-2">Project Name</p>
            <select
              class="form-select "
              aria-label="Default select example"
              value={Project_Name}
              defaultValue={filters.project_name}
              style={{
                backgroundColor: "#f8f8f8",
                border: "none",
                padding: "8px 32px 14px 13px",
                fontFamily: "Gm",
                color: "gray",
              }}
              onChange={(e) => {
                setProject_Name(e.target.value);
              }}
            >
              <option value="" selected disabled>
                Choose Project
              </option>
              {Projects.map((val, i) => (
                <option
                  key={i}
                  value={val.project_name}>{val.project_name}</option>
              ))}
            </select>
          </div>
          <div className="mt-3">
            <p className="poFilter_text mb-2">Status</p>
            <select
              class="form-select"
              aria-label="Default select example"
              value={purchaseOrderStat}
              defaultValue={filters.PO_status}
              style={{
                backgroundColor: "#f8f8f8",
                border: "none",
                padding: "8px 32px 14px 13px",
                fontFamily: "Gm",
                color: "gray",
              }}
              onChange={(e) => {
                setPurchaseOrderStat(e.target.value);
              }}
            >
              <option value="" selected disabled>
                Choose PO Status
              </option>
              <option value="New PO">New PO</option>
              <option value="PO Cancelled">PO Cancelled</option>
            </select>
          </div>
          <div className="mt-3">
            <p className="poFilter_text mb-2">Vendor</p>
            <select
              class="form-select"
              defaultValue={filter.vendor_name}
              value={vendor}
              aria-label="Default select example"
              style={{
                backgroundColor: "#f8f8f8",
                border: "none",
                padding: "8px 32px 14px 13px",
                fontFamily: "Gm",
                color: "gray",
              }}
              onChange={(e) => {
                setVendor(e.target.value);
              }}
            >
              <option value="">Select Vendor</option>
              {vendorList.map((val, i) => (
                <option
                  key={i}
                  value={val.vendor_name}>{val.vendor_name}</option>
              ))}
            </select>
          </div>
          <div className="mt-4 d-flex justify-content-between align-items-center">
            <button
              className="poResetall_btn"
              onClick={() => {
                dispatch(setPOfilterData(""));
                POlisting2();
                dispatch(setPOfilter(""));
                setOpen(false);
              }}
            >
              Reset All
            </button>
            <button
              className="poApply_btn"
              onClick={() => {
                POlisting();
                setOpen(false);
              }}
            >
              Apply Filter
            </button>
          </div>
        </div>
      </Dialog>

    </>
  );
};

export default POfilter;
