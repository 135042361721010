import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Plus from "../../../Assets/Img/svg/plus.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  setCancelPoDataFromlisting,
  setCopyRequestID,
  setPOswitch,
  setRequestDataFromRFQID,
} from "../../../features/counter/counterSlice";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, useField } from "formik";
import { toast } from "react-toastify";

import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { setscreen } from "../../../features/Screen/screen";

const CreatePOpopUp = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [reqId, setReqId] = useState("");

  const createPo = () => {
    axiosClientInterceptors
      .get(`/purchaseorder-by-request_id?request_id=${reqId}`)
      .then((result) => {
        if (result.data.status === "success") {
          dispatch(setPOswitch("Request detail"));
          dispatch(setCopyRequestID(reqId));
          dispatch(setRequestDataFromRFQID(result.data.result[0]));
        } else {
          toast.info(`${result.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <>
      {" "}
      <div className="CreatePurchaseOrder" onClick={handleOpen}>
        <img src={Plus} alt="" className="me-2" />
        <p>Create Purchase Order</p>
      </div>
      <Modal
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        open={open}
        className="d-flex justify-content-center align-items-center flex-column "
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="filter_container">
          <div className="d-flex justify-content-between align-items-center p-3">
            <p className="m-0 Filter_text ">Create Purchase Order</p>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <div className="m-3  ">
            <div className="row">
              <div className="col-12">
                <p className=" Package_title mt-2">Request ID</p>
                <input
                  type="text"
                  className="form-input w-100"
                  placeholder="eg. req1, req2, req3..."
                  style={{
                    backgroundColor: "#f8f8f8",
                    border: "none",
                    padding: "15px 32px 14px 13px",
                    fontFamily: "gm",
                  }}
                  onChange={(e) => setReqId(e.target.value.toUpperCase())}
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              onClick={createPo}
            >
              <button className="PurchageOrder_add w-100">
                <p className="m-0">Create Purchase Order</p>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreatePOpopUp;
