import { useFormik } from "formik";
import React, { useCallback } from "react";
import EditIcons from "../../../../Assets/Img/EditIcons.svg";
import * as yup from "yup";
import { useState } from "react";
import axiosClientInterceptors from "../../../../ReusableComponent/axiosClentInterceptors";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ImageCard from "../ImageCard";
import VendorDocCard from "../VendorDocCard";
import DropQr from "../DropQr";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  fileNameSplit,
  fileNameSplitQR,
} from "../../../../ReusableComponent/Validation";

const BankInfo = ({
  bankinfoEdit,
  setbankinfoEdit,
  vendorData,
  getVEndordata,
  vendordetailsID,
  filedelet,
}) => {
  const [bankDetailsbranch_name, setbankDetailsbranch_name] = useState("");
  const [bankDetailsbank_name, setbankDetailsbank_name] = useState("");
  const [initialIFSC, setinitialIFSC] = useState(vendorData.ifsc_code);
  // const [InEditUpi_Switch, setInEditUpi_Switch] = useState(
  //   vendorData.account_number ? "Account Number" : "UPI"
  // );
  const [InEditUpi_Switch, setInEditUpi_Switch] = useState("Account Number");
  const [qrcodeDoc, setqrcodeDoc] = useState("");

  // formik validation
  const validationSchema = yup.object().shape({
    ifsc_code: yup
      .string()
      .test("ifscCodeCondition", "IFSC Code is required", function (value) {
        if (
          InEditUpi_Switch === "Account Number" ||
          InEditUpi_Switch === "Account Number + UPI"
        ) {
          return yup.string().required("required field").isValidSync(value);
        }
        return true;
      })
      .matches(/^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/, "Please enter valid IFSC"),
    account_number: yup
      .string()
      .test(
        "ifscCodeCondition",
        "Account Number is required",
        function (value) {
          if (
            InEditUpi_Switch === "Account Number" ||
            InEditUpi_Switch === "Account Number + UPI"
          ) {
            return yup.string().required("required field").isValidSync(value);
          }
          return true;
        }
      )
      .matches(/[0-9]{9,18}$/, "Please enter valid Account Number")
      .nullable(),
    vandor_pan: yup
      .string()
      .test("ifscCodeCondition", "Vendor PAN required", function (value) {
        if (
          InEditUpi_Switch === "Account Number" ||
          InEditUpi_Switch === "Account Number + UPI"
        ) {
          return yup.string().required("required field").isValidSync(value);
        }
        return true;
      })
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Please enter valid PAN"),

    bank_address: yup
      .string()
      .test("ifscCodeCondition", "Bank adderess is required", function (value) {
        if (
          InEditUpi_Switch === "Account Number" ||
          InEditUpi_Switch === "Account Number + UPI"
        ) {
          return yup.string().required("required field").isValidSync(value);
        }
        return true;
      }),
    upi_id: yup
      .string()
      .test("ifscCodeCondition", "UPI deatils are required", function (value) {
        if (
          (InEditUpi_Switch === "UPI" && qrcodeDoc === "") ||
          (InEditUpi_Switch === "Account Number + UPI" && qrcodeDoc === "")
        ) {
          return yup.string().required("  required field").isValidSync(value);
        }
        return true;
      })
      .matches(/^[\w.-]+@[\w.-]+$/, "invalid UPI-ID"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ifsc_code: initialIFSC || vendorData.ifsc_code || "",
      account_number: vendorData.account_number || "",
      vandor_pan: vendorData.vandor_pan || "",
      bank_address: vendorData.bank_address || "",
      bank_name: bankDetailsbank_name || "",
      branch_name: bankDetailsbranch_name || "",
      upi_id: vendorData.upi_id || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (
        InEditUpi_Switch === "Account Number" ||
        InEditUpi_Switch === "Account Number + UPI"
      ) {
        const data = {
          ifsc_code: initialIFSC || values.ifsc_code,
          account_number: values.account_number,
          vandor_pan: values.vandor_pan,
          bank_address: values.bank_address,
          bank_name: bankDetailsbank_name,
          branch_name: bankDetailsbranch_name,
        };
        // alert(JSON.stringify(values, null, 2));
        axiosClientInterceptors
          .post(`/updatevendor?_id=${vendordetailsID}`, data)
          .then(function (response) {
            if (response.data.status === "success") {
              toast.success(`${response.data.msg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // dispatch(setAddVendorScreen("listing"));
              setbankinfoEdit(false);
              getVEndordata();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (
        InEditUpi_Switch === "UPI" ||
        InEditUpi_Switch === "Account Number + UPI"
      ) {
        const formData = new FormData();
        // v_id:652e3cdba753535d96bf3fd7
        // upi_id:9082653821@paytm
        // alert(JSON.stringify(values, null, 2));
        formData.append("v_id", vendordetailsID);
        formData.append("upi_id", values.upi_id);
        formData.append("qr_code", qrcodeDoc);

        axiosClientInterceptors
          .put(`/update-payment-details`, formData)
          .then(function (response) {
            if (response.data.status === "success") {
              {
                InEditUpi_Switch !== "Account Number + UPI" &&
                  toast.success(`${response.data.msg}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
              }
              // dispatch(setAddVendorScreen("listing"));
              setbankinfoEdit(false);
              getVEndordata();
              setInEditUpi_Switch("Account Number");
            } else {
              toast.info(`${response.data.msg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    if (formik.values.ifsc_code?.length === 11) {
      axiosClientInterceptors
        .post(`/get-bank-details`, {
          IFSC: formik.values.ifsc_code,
        })
        .then((response) => {
          if (response.data.status === "success") {
            setbankDetailsbranch_name(response.data.result.BRANCH);
            setbankDetailsbank_name(response.data.result.BANK);
            setinitialIFSC(formik.values.ifsc_code);
          } else {
            toast.error(`${response.data.msg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {});
    }
    setbankDetailsbranch_name(bankDetailsbranch_name);
    setbankDetailsbank_name(bankDetailsbank_name);
  }, [formik.values.ifsc_code]);

  useEffect(() => {
    if (vendorData.account_number) {
      setInEditUpi_Switch("Account Number");
    }
    // if (vendorData.account_number == undefined) {
    //   setInEditUpi_Switch("UPI");
    // }
  }, [vendorData.account_number]);

  return (
    <form onSubmit={formik.handleSubmit} className="PersonalInfo mt-4">
      <div className="d-flex justify-content-between align-items-center">
        {" "}
        <p className="PersonalInfo_title">Bank Details</p>
        {bankinfoEdit ? (
          <>
            <button
              type="submit"
              className=""
              style={{
                color: "#fcc200",
                background: "transparent",
                fontFamily: "Gm",
              }}
            >
              Save
            </button>
          </>
        ) : (
          <img
            src={EditIcons}
            className="new_edit"
            onClick={() => setbankinfoEdit(true)}
          />
        )}{" "}
      </div>
      <hr />
      {bankinfoEdit && (
        <div className="mt-2 ">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue={vendorData.account_number ? "Account Number" : "UPI"}
            value={InEditUpi_Switch}
            onChange={(e) => {
              setInEditUpi_Switch(e.target.value);
            }}
          >
            <FormControlLabel
              value="Account Number"
              control={<Radio style={{ color: "black" }} />}
              label="Account Number"
            />
            <FormControlLabel
              value="UPI"
              control={<Radio style={{ color: "black" }} />}
              label="UPI"
            />
            <FormControlLabel
              value="Account Number + UPI"
              control={<Radio style={{ color: "black" }} />}
              label="Account Number + UPI"
            />
          </RadioGroup>
        </div>
      )}
      <>
        {InEditUpi_Switch === "Account Number" && (
          <>
            <div class="parentPersonalInfo">
              <div class="parentPersonalInfo1">
                <p className="parentPersonalInfo1_text">Bank Name</p>

                <p className="parentPersonalInfo1_subtext">
                  {bankDetailsbank_name || vendorData.bank_name || "-"}
                </p>
              </div>
              <div class="parentPersonalInfo2">
                {" "}
                <p className="parentPersonalInfo1_text">IFSC Code</p>
                {bankinfoEdit ? (
                  <input
                    type="text"
                    placeholder="eg. CNRB0000582"
                    name="ifsc_code"
                    className="mt-2 AddvendorInput"
                    defaultValue={vendorData.ifsc_code}
                    value={formik.values.ifsc_code}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <p className="parentPersonalInfo1_subtext">
                    {vendorData.ifsc_code || "-"}
                  </p>
                )}
                {formik.touched.ifsc_code && formik.errors.ifsc_code && (
                  <p className="errorRequest mt-1">
                    {formik.errors.ifsc_code}{" "}
                  </p>
                )}
              </div>
              <div class="parentPersonalInfo3">
                {" "}
                <p className="parentPersonalInfo1_text">Branch Name</p>
                <p className="parentPersonalInfo1_subtext">
                  {bankDetailsbranch_name || vendorData.branch_name || "-"}
                </p>
              </div>
              <div class="parentPersonalInfo4">
                {" "}
                <p className="parentPersonalInfo1_text">Account Number</p>
                {bankinfoEdit ? (
                  <input
                    type="number"
                    placeholder="eg. 123456XXXX"
                    name="account_number"
                    className="mt-2 AddvendorInput"
                    value={formik.values.account_number}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <p className="parentPersonalInfo1_subtext">
                    {vendorData.account_number || "-"}
                  </p>
                )}
                {formik.touched.account_number &&
                  formik.errors.account_number && (
                    <p className="errorRequest mt-1">
                      {formik.errors.account_number}
                    </p>
                  )}
              </div>
              <div class="parentPersonalInfo5">
                {" "}
                <p className="parentPersonalInfo1_text">PAN</p>
                {bankinfoEdit ? (
                  <input
                    type="text"
                    placeholder="eg. FDJXXXXXXX"
                    name="vandor_pan"
                    className="mt-2 AddvendorInput"
                    value={formik.values.vandor_pan}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <p className="parentPersonalInfo1_subtext">
                    {vendorData.vandor_pan || "-"}
                  </p>
                )}
                {formik.touched.vandor_pan && formik.errors.vandor_pan && (
                  <p className="errorRequest mt-1">
                    {formik.errors.vandor_pan}
                  </p>
                )}
              </div>
            </div>
            {!bankinfoEdit && (
              <div class="parentPersonalInfo mt-3">
                <div class="parentPersonalInfo1">
                  {" "}
                  <p className="parentPersonalInfo1_text">UPI ID</p>
                  <p className="parentPersonalInfo1_subtext">
                    {vendorData.upi_id || "-"}
                  </p>
                </div>
                <div class="parentPersonalInfo21">
                  {" "}
                  <p className="parentPersonalInfo1_text">QR Code</p>
                  <>
                    {vendorData.qr_code ? (
                      <div class="col-md-6 g-3 mt-3">
                        <VendorDocCard
                          val={vendorData.qr_code}
                          filedelet={filedelet}
                          delet={false}
                        />
                      </div>
                    ) : (
                      <p>-</p>
                    )}
                  </>
                </div>
              </div>
            )}
            {/*  */}
            <div class="parentPersonalInfosecond11 mt-4">
              <div class="parentPersonalInfosecond11">
                {" "}
                <p className="parentPersonalInfo1_text">Bank Address</p>
                {bankinfoEdit ? (
                  <input
                    type="text"
                    placeholder="eg.  #54, old #28, new sri durgbhavani arcade,2nd floor 5th main MKK road maruthi extension bangalore"
                    name="bank_address"
                    className="mt-2 AddvendorInput"
                    value={formik.values.bank_address}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <p className="parentPersonalInfo1_subtext">
                    {vendorData.bank_address || "-"}
                  </p>
                )}
                {formik.touched.bank_address && formik.errors.bank_address && (
                  <p className="errorRequest mt-1">
                    {formik.errors.bank_address}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
        {InEditUpi_Switch === "UPI" && (
          <div class="parentPersonalInfo">
            <div class="parentPersonalInfo1">
              {" "}
              <p className="parentPersonalInfo1_text">UPI ID</p>
              <input
                type="text"
                placeholder="eg. CNRB0000582"
                name="upi_id"
                className="mt-2 AddvendorInput"
                defaultValue={vendorData.upi_id}
                value={formik.values.upi_id}
                onChange={formik.handleChange}
              />
              {formik.touched.upi_id && formik.errors.upi_id && (
                <p className="errorRequest mt-1">{formik.errors.upi_id} </p>
              )}
            </div>
            <div class="parentPersonalInfo2">
              {" "}
              <p className="parentPersonalInfo1_text">QR Code</p>
              <DropQr
                data={qrcodeDoc}
                setdata={setqrcodeDoc}
                newfileName={fileNameSplitQR(vendorData.qr_code)}
              />
            </div>
          </div>
        )}

        {InEditUpi_Switch === "Account Number + UPI" && (
          <>
            <div class="parentPersonalInfo">
              <div class="parentPersonalInfo1">
                <p className="parentPersonalInfo1_text">Bank Name</p>

                <p className="parentPersonalInfo1_subtext">
                  {bankDetailsbank_name || vendorData.bank_name || "-"}
                </p>
              </div>
              <div class="parentPersonalInfo2">
                {" "}
                <p className="parentPersonalInfo1_text">IFSC Code</p>
                {bankinfoEdit ? (
                  <input
                    type="text"
                    placeholder="eg. CNRB0000582"
                    name="ifsc_code"
                    className="mt-2 AddvendorInput"
                    defaultValue={vendorData.ifsc_code}
                    value={formik.values.ifsc_code}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <p className="parentPersonalInfo1_subtext">
                    {vendorData.ifsc_code || "-"}
                  </p>
                )}
                {formik.touched.ifsc_code && formik.errors.ifsc_code && (
                  <p className="errorRequest mt-1">
                    {formik.errors.ifsc_code}{" "}
                  </p>
                )}
              </div>
              <div class="parentPersonalInfo3">
                {" "}
                <p className="parentPersonalInfo1_text">Branch Name</p>
                <p className="parentPersonalInfo1_subtext">
                  {bankDetailsbranch_name || vendorData.branch_name || "-"}
                </p>
              </div>
              <div class="parentPersonalInfo4">
                {" "}
                <p className="parentPersonalInfo1_text">Account Number</p>
                {bankinfoEdit ? (
                  <input
                    type="number"
                    placeholder="eg. 123456XXXX"
                    name="account_number"
                    className="mt-2 AddvendorInput"
                    value={formik.values.account_number}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <p className="parentPersonalInfo1_subtext">
                    {vendorData.account_number || "-"}
                  </p>
                )}
                {formik.touched.account_number &&
                  formik.errors.account_number && (
                    <p className="errorRequest mt-1">
                      {formik.errors.account_number}
                    </p>
                  )}
              </div>
              <div class="parentPersonalInfo5">
                {" "}
                <p className="parentPersonalInfo1_text">PAN</p>
                {bankinfoEdit ? (
                  <input
                    type="text"
                    placeholder="eg. FDJXXXXXXX"
                    name="vandor_pan"
                    className="mt-2 AddvendorInput"
                    value={formik.values.vandor_pan}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <p className="parentPersonalInfo1_subtext">
                    {vendorData.vandor_pan || "-"}
                  </p>
                )}
                {formik.touched.vandor_pan && formik.errors.vandor_pan && (
                  <p className="errorRequest mt-1">
                    {formik.errors.vandor_pan}
                  </p>
                )}
              </div>
            </div>
            {!bankinfoEdit && (
              <div class="parentPersonalInfo mt-3">
                <div class="parentPersonalInfo1">
                  {" "}
                  <p className="parentPersonalInfo1_text">UPI ID</p>
                  <p className="parentPersonalInfo1_subtext">
                    {vendorData.upi_id || "-"}
                  </p>
                </div>
                <div class="parentPersonalInfo2">
                  {" "}
                  <p className="parentPersonalInfo1_text">QR Code</p>
                  <>
                    {vendorData.qr_code ? (
                      <div class="col-md-6 g-3 mt-3">
                        <VendorDocCard
                          val={vendorData.qr_code}
                          filedelet={filedelet}
                          delet={false}
                        />
                      </div>
                    ) : (
                      <p>-</p>
                    )}
                  </>
                </div>
              </div>
            )}
            {/*  */}
            <div class="parentPersonalInfosecond11 mt-4">
              <div class="parentPersonalInfosecond11">
                {" "}
                <p className="parentPersonalInfo1_text">Bank Address</p>
                {bankinfoEdit ? (
                  <input
                    type="text"
                    placeholder="eg.  #54, old #28, new sri durgbhavani arcade,2nd floor 5th main MKK road maruthi extension bangalore"
                    name="bank_address"
                    className="mt-2 AddvendorInput"
                    value={formik.values.bank_address}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <p className="parentPersonalInfo1_subtext">
                    {vendorData.bank_address || "-"}
                  </p>
                )}
                {formik.touched.bank_address && formik.errors.bank_address && (
                  <p className="errorRequest mt-1">
                    {formik.errors.bank_address}
                  </p>
                )}
              </div>
            </div>
            <div class="parentPersonalInfo mt-3">
              <div class="parentPersonalInfo1">
                {" "}
                <p className="parentPersonalInfo1_text">UPI ID</p>
                <input
                  type="text"
                  placeholder="eg. CNRB0000582"
                  name="upi_id"
                  className="mt-2 AddvendorInput"
                  defaultValue={vendorData.upi_id}
                  value={formik.values.upi_id}
                  onChange={formik.handleChange}
                />
                {formik.touched.upi_id && formik.errors.upi_id && (
                  <p className="errorRequest mt-1">{formik.errors.upi_id} </p>
                )}
              </div>
              <div class="parentPersonalInfo2">
                {" "}
                <p className="parentPersonalInfo1_text">QR Code</p>
                <DropQr
                  data={qrcodeDoc}
                  setdata={setqrcodeDoc}
                  newfileName={fileNameSplitQR(vendorData.qr_code)}
                />
              </div>
            </div>
          </>
        )}
      </>
    </form>
  );
};

export default BankInfo;
