import React from "react";
import LoaderImage from "../Assets/Img/Double.gif";

const Loader = () => {
  return (
    <div>
      <h3 className="text-dark d-flex flex-column">
        <div>
          <img src={LoaderImage} width="50%" />
        </div>
        <div>Loading...</div>
      </h3>
    </div>
  );
};

export default Loader;
