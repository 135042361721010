import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import * as yup from "yup";
import EditNewRow from "../EditNewRow";
import axiosClientInterceptors from "../../../../ReusableComponent/axiosClentInterceptors";
import { toast } from "react-toastify";
import MultiSelect from "../../../../ReusableComponent/MultiSelect";

const EditView = ({
  setshowEditView,
  GetmatrialByID,
  setCat,
  ListingUOMMatrial,
  ListingUOMPrice,
  EditData,
}) => {
  const output = [];

  const [rows, setRows] = useState(EditData.material_specification);
  const [mainErrorOFrowedit, setmainErrorOFrowedit] = useState(false);
  const [AddSelectMAtrialUOM, setAddSelectMAtrialUOM] = useState([]);
  const [addSelectPriceUOM, setaddSelectPriceUOM] = useState([]);

  const ConvertAPIData = (UOM) => {
    const dataArray = UOM.split(",");
    const convertedmatrialUOMdata = dataArray.map((value) => ({
      value: value,
      label: value,
    }));

    return convertedmatrialUOMdata;
  };

  const handlechangeMatrialADDuom = (data) => {
    setAddSelectMAtrialUOM(data);
  };
  const handlechangepriceADDuom = (data) => {
    setaddSelectPriceUOM(data);
  };
  const handleAddRow = () => {
    const newRow = {
      specification_name: "",
      specification_uom: "",
      specification_value: "",
    };
    setRows([...rows, newRow]);
  };
  const convertMYarray = () => {
    const convertedArray = rows.map((item) => {
      if (typeof item.specification_value === "string") {
        item.specification_value = item.specification_value.split(",");
      }
      return item;
    });
    return convertedArray;
  };

  const validationSchema = yup.object({
    material_name: yup.string().required(),
    description: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      material_name: EditData.material_name,
      description: EditData.description,
    },

    validationSchema: validationSchema,

    onSubmit: (values, { resetForm }) => {
      let data = {
        category: EditData.category_id,
        material_name: values.material_name,
        description: values.description,
        material_specification: convertMYarray(),
        material_uom:
          AddSelectMAtrialUOM.length > 0
            ? AddSelectMAtrialUOM.map((obj) => obj.label).join(",")
            : EditData.material_uom,
        price_uom:
          addSelectPriceUOM.length > 0
            ? addSelectPriceUOM.map((obj) => obj.label).join(",")
            : EditData.price_uom,
      };
      console.log("mainErrorOFrowedit", mainErrorOFrowedit);

      if (mainErrorOFrowedit) {
        toast.success(`Please Fill Specification`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setmainErrorOFrowedit(false);
      } else {
        axiosClientInterceptors
          .post(`/updatematerial?_id=${EditData._id}`, data)
          .then((result) => {
            if (result.data.status === "success") {
              GetmatrialByID(EditData._id);
              toast.success(`${result.data.msg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setshowEditView("edit");
              setCat();
            }
          });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row add_material_main">
        <div
          className="col-6 col-md-5"
          style={{
            borderRight: "1px solid #E2E2E2",
          }}
        >
          <div>
            <div className="form-group">
              <label htmlFor="companyname" className="label_txt_mc">
                Material Name
              </label>
              <input
                type="text"
                name="material_name"
                value={formik.values.material_name}
                onChange={formik.handleChange}
                className="p-3 add_material_inputxt"
                placeholder="Enter Material Name"
                id="companyname"
                autoComplete="off"
              />
            </div>
            {formik.touched.material_name && formik.errors.material_name && (
              <p className="errorRequest mt-1">Material Name required</p>
            )}
            <hr />
            <div>
              {rows?.map((row, index) => (
                <>
                  {index == 0 && (
                    <div className="row Specification_title ">
                      <p className="m-0 col-5">Specification Name</p>
                      <p className="m-0 col-3">UOM</p>
                    </div>
                  )}

                  <div key={index} className="my-2">
                    <EditNewRow
                      setmainErrorOFrow={setmainErrorOFrowedit}
                      setRows={setRows}
                      rows={rows}
                      index={index}
                      row={row}
                      ListingUOMMatrial={ListingUOMMatrial}
                    />
                  </div>
                </>
              ))}
            </div>
            <div>
              <div className="add_specif_btn" onClick={handleAddRow}>
                <AddIcon className="plus_icons" /> Add Specifications
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-7">
          <>
            <div>
              <div className="form-group">
                <label htmlFor="companyname" className="label_txt_mc">
                  Material UOM
                </label>
                <div className="checkboxcallstatus">
                  <MultiSelect
                    placeholder="eg. nos, bundel..."
                    defaultValue={ConvertAPIData(EditData.material_uom)}
                    handlechange={handlechangeMatrialADDuom}
                    options={ListingUOMPrice}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="companyname" className="label_txt_mc">
                Price UOM
              </label>
              <div className="checkboxcallstatus">
                <MultiSelect
                  placeholder="eg. ks, quintel..."
                  defaultValue={ConvertAPIData(EditData.price_uom)}
                  handlechange={handlechangepriceADDuom}
                  options={ListingUOMPrice}
                />
              </div>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="companyname" className="label_txt_mc">
                Description
              </label>
              <textarea
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                className="p-3 add_material_inputxt"
                placeholder="Enter Description"
                id="companyname"
                autoComplete="off"
              />
              {formik.touched.description && formik.errors.description && (
                <p className="errorRequest mt-1">Description required</p>
              )}
            </div>
          </>
        </div>
      </div>
      <button type="submit" className="add_material_btn_long mt-3">
        Save Changes
      </button>
    </form>
  );
};

export default EditView;
