import React from "react";
import documentcopy from "../../../Assets/Img/svg/documentcopy.svg";
import "./PO.css";
import PurchageOrder from "./PurchageOrder";
import CreatePOpopUp from "./CreatePOpopUp";
import CopyPO from "./CopyPO";
import { useState } from "react";
import {
  selectPOswitch,
  setPOswitch,
} from "../../../features/counter/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import PurchaseOrderDOc from "./PurchaseOrderDOc";
import RequestDetailPO from "./RequestDetailPO";
import { ToWords } from "to-words";
import TempletPObyReqID from "./TempletPObyReqID";

const PoAdmin = ({ SocketCall, SocketCallData }) => {
  const POtab = useSelector(selectPOswitch);
  const [requestId, setrequestId] = useState("");
  const dispach = useDispatch();

  return (
    <>
      {POtab === "maintable" && (
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center">
            <p className="dashboardtitle m-0">Purchase Orders</p>
            <div className="d-flex align-items-center ">
              <CopyPO />
              <CreatePOpopUp
                setrequestId={setrequestId}
                requestId={requestId}
              />
            </div>
          </div>
          <hr />
          <PurchageOrder />
        </div>
      )}
      {POtab === "PurchaseOrderDOc" && (
        <>
          <PurchaseOrderDOc
            SocketCall={SocketCall}
            SocketCallData={SocketCallData}
          />
        </>
      )}
      {POtab === "Request detail" && (
        <>
          <TempletPObyReqID
            editable={true}
            requestId={requestId}
            SocketCall={SocketCall}
            SocketCallData={SocketCallData}
          />
        </>
      )}
    </>
  );
};

export default PoAdmin;
