import React from "react";
import DynamicRowVendor from "../DynamicRowVendor";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import VendorDays from "../VendorDays";
import { useEffect } from "react";

const PaymentTermsradioBTN = ({
  Day1,
  setDay1,
  inputs,
  setInputs,
  Radiobtn_Value,
  setRadiobtn_Value,
  Day,
  setDay,
  handleChange,
  handleFormSubmit,
  errors,
  handleRemoveInput,
  handleAddInput,
  paymentTermsdata,
}) => {
  useEffect(() => {
    setRadiobtn_Value(Radiobtn_Value);
  }, []);
  return (
    <div>
      {" "}
      <div className="mt-2 ">
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={Radiobtn_Value}
          value={Radiobtn_Value}
          onChange={(e) => {
            setRadiobtn_Value(e.target.value);
          }}
        >
          <FormControlLabel
            value="Single Payment Term"
            control={<Radio style={{ color: "black" }} />}
            label="Single Payment"
          />
          <FormControlLabel
            value="Installment"
            control={<Radio style={{ color: "black" }} />}
            label="Installment"
          />
          <FormControlLabel
            value="Advance"
            control={<Radio style={{ color: "black" }} />}
            label="Advance"
          />
        </RadioGroup>
      </div>
      {/* days */}
      {Radiobtn_Value === "Single Payment Term" && (
        <VendorDays
          days={true}
          setDay={setDay}
          Day={Day}
          paymentTermsdata={paymentTermsdata}
        />
      )}
      {Radiobtn_Value === "Installment" && (
        <div className="rowContainer">
          <div>
            {inputs?.map((input, index) => {
              return (
                <DynamicRowVendor
                  handleFormSubmit={handleFormSubmit}
                  handleChange={handleChange}
                  input={input}
                  index={index}
                  errors={errors}
                  handleRemoveInput={handleRemoveInput}
                />
              );
            })}

            <hr />
            <div className="d-flex  align-items-center px-4 pb-4">
              <div className="AddRow me-2" onClick={handleAddInput}>
                + Add Row
              </div>
            </div>
          </div>
        </div>
      )}
      {Radiobtn_Value === "Advance" && (
        <>
          {" "}
          <VendorDays setDay={setDay1} Day={Day1} />{" "}
          <div className="rowContainer mt-2">
            <div>
              {inputs?.map((input, index) => {
                return (
                  <DynamicRowVendor
                    handleFormSubmit={handleFormSubmit}
                    handleChange={handleChange}
                    input={input}
                    index={index}
                    errors={errors}
                    handleRemoveInput={handleRemoveInput}
                  />
                );
              })}

              <hr />
              <div className="d-flex  align-items-center px-4 pb-4">
                <div className="AddRow me-2" onClick={handleAddInput}>
                  + Add Row
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentTermsradioBTN;
