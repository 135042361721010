import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import MultiSelect from "../../../../ReusableComponent/MultiSelect";
import EditNewRow from "../EditNewRow";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import axiosClientInterceptors from "../../../../ReusableComponent/axiosClentInterceptors";
import EditView from "./EditView";
import moment from "moment";

const MatrialPopUp = ({
  categoryname,
  GetmatrialByID,
  setCat,
  open,
  handleClose,
  setshowEditView,
  showEditView,
  EditData,
  ListingUOMPrice,
  ListingUOMMatrial,
}) => {
  // add STate===================================================
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [rows, setRows] = useState([
    { specification_name: "", specification_uom: "", specification_value: "" },
  ]);
  const [mainErrorOFrow, setmainErrorOFrow] = useState(false);
  const [AddSelectMAtrialUOM, setAddSelectMAtrialUOM] = useState([]);
  const [addSelectPriceUOM, setaddSelectPriceUOM] = useState([]);
  const output = [];

  // To make currect response
  const responseCurrect = () => {
    const groupedSpecs = {};
    for (const spec of rows) {
      const key = `${spec.specification_name}:${spec.specification_uom}`;
      if (!groupedSpecs[key]) {
        groupedSpecs[key] = {
          specification_name: spec.specification_name,
          specification_uom: spec.specification_uom,
          specification_value: [],
        };
      }
      groupedSpecs[key].specification_value.push(
        spec.specification_value.split(",")
      );
    }

    // Convert grouped specifications to desired format
    for (const key in groupedSpecs) {
      const spec = groupedSpecs[key];
      output.push({
        specification_name: spec.specification_name,
        specification_uom: spec.specification_uom,
        specification_value: spec.specification_value.flat(),
      });
    }
  };

  // add STate===================================================

  const handlechangeMatrialADDuom = (data) => {
    setAddSelectMAtrialUOM(data);
  };

  const handlechangepriceADDuom = (data) => {
    setaddSelectPriceUOM(data);
  };

  const handleAddRow = () => {
    const newRow = {
      specification_name: "",
      specification_uom: "",
      specification_value: "",
    };
    setRows([...rows, newRow]);
  };

  const validationSchema = yup.object({
    material_name: yup.string().required(),
    description: yup.string().required(),
  });
  const formik = useFormik({
    initialValues: {
      material_name: "",
      description: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { resetForm }) => {
      if (mainErrorOFrow) {
        toast.success(`Please Fill Specification`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setmainErrorOFrow(false);
      } else if (AddSelectMAtrialUOM.length === 0) {
        toast.success(`Please Fill UOM`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (addSelectPriceUOM.length === 0) {
        toast.success(`Please Fill UOM`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        responseCurrect();
        let data = {
          category: categoryname,
          material_name: values.material_name,
          description: values.description,
          material_specification: output,
          material_uom: AddSelectMAtrialUOM.map((obj) => obj.label).join(","),
          price_uom: addSelectPriceUOM.map((obj) => obj.label).join(","),
        };

        axiosClientInterceptors.post(`/addmaterial`, data).then((response) => {
          if (response.data.status === "success") {
            setCat();
            handleClose();
            setRows([
              {
                specification_name: "",
                specification_uom: "",
                specification_value: "",
              },
            ]);
            resetForm({});
            setAddSelectMAtrialUOM([]);
            setaddSelectPriceUOM([]);
            window.$("#material_added").modal("show");
            // toast.success(`FRWW`, {
            //   position: "top-right",
            //   autoClose: 5000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          } else {
            toast.success(`${response.data.msg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      }
    },
  });
  const del = () => {
    axiosClientInterceptors
      .delete(`/deletematerial?_id=${EditData._id}`)
      .then((respon) => {
        let success_res = respon.data.status;
        if (success_res === "success") {
          setCat();
          handleClose1();
          handleClose();
        }
      });
  };

  return (
    <>
      {" "}
      <Modal
        style={{
          background: "rgba(34, 30, 31, 0.9)",
        }}
        className="d-flex justify-content-center align-items-center flex-column"
        open={open1}
      >
        <div className="form_invoice_upload bg-light p-4">
          <div className="text-center">
            <InfoRoundedIcon className="popup_delete_proj_icon" />
            <div className="mt-3">
              <h4>
                Are you sure <br /> to delete this material ?
              </h4>
            </div>
          </div>
          <div className="pt-3 d-flex justify-content-between">
            <div style={{ width: "48%", textAlign: "center" }}>
              <button
                type="button"
                className="popup_btn_close"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleClose1();
                }}
                style={{ background: "#F4F4F4" }}
              >
                Go Back
              </button>
            </div>
            <div style={{ width: "48%", textAlign: "center" }} className="me-2">
              <button
                type="button"
                className="popup_btn_unlinkaccount"
                onClick={() => del()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{
          background: "rgba(34, 30, 31, 0.9)",
        }}
        className="d-flex justify-content-center align-items-center flex-column"
        open={open}
      >
        <div className=" allProjectFilter2">
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0 Filter_text ">
              {showEditView === "add"
                ? " Add Material"
                : "Edit Material Details"}
            </p>
            <CloseIcon
              onClick={() => {
                handleClose();
                setshowEditView("add");
              }}
            />
          </div>

          <div className="mt-3">
            {showEditView === "edit" && (
              <div className="form_invoice_upload">
                <div className="mt-3">
                  <div className="row add_material_main">
                    <div
                      className="col-6 col-md-6"
                      style={{
                        borderRight: "1px solid #E2E2E2",
                      }}
                    >
                      <div className="me-2">
                        <div>
                          <div className="form-group">
                            <label
                              htmlFor="companyname"
                              className="label_txt_mat_details"
                              onClick={() => {}}
                            >
                              Material Name
                            </label>
                            <div className="material_details_txt">
                              {EditData?.material_name}
                            </div>
                          </div>
                        </div>
                        <div className="pt-3">
                          {EditData?.material_specification?.map(
                            (val, index) => {
                              if (val?.specification_value[0] !== "") {
                                return (
                                  <>
                                    {index === 0 && (
                                      <>
                                        {" "}
                                        {val?.specification_name.length > 2 && (
                                          <div className="material_spec_table_header">
                                            Specifications
                                          </div>
                                        )}
                                      </>
                                    )}

                                    {val?.specification_value.length > 0 && (
                                      <div className="mat_spec_body">
                                        <div className="p-3">
                                          {val?.specification_name} :{" "}
                                          {val?.specification_value?.map(
                                            (vals, index) => (
                                              <>
                                                {vals}
                                                {val?.specification_uom},
                                              </>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="ms-2">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label
                                htmlFor="companyname"
                                className="label_txt_mat_details"
                              >
                                Material Updated Date
                              </label>
                              <div className="material_details_txt">
                                {moment(
                                  EditData?.updationdate,
                                  "YYYY-MM-DD"
                                ).format("DD/MM/YYYY")}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label
                                htmlFor="companyname"
                                className="label_txt_mat_details"
                              >
                                Material Added Date
                              </label>
                              <div className="material_details_txt">
                                {moment(
                                  EditData?.creationdate,
                                  "YYYY-MM-DD"
                                ).format("DD/MM/YYYY")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-6">
                            <div className="form-group ">
                              <label
                                htmlFor="companyname"
                                className="label_txt_mat_details"
                              >
                                Material UOM
                              </label>
                              <div className="material_details_txt">
                                {EditData?.material_uom}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label
                                htmlFor="companyname"
                                className="label_txt_mat_details"
                              >
                                Price UOM
                              </label>
                              <div className="material_details_txt">
                                {EditData?.price_uom}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mt-3">
                          <label
                            htmlFor="companyname"
                            className="label_txt_mat_details"
                          >
                            Description
                          </label>
                          <div className="material_details_txt mt-1">
                            {EditData?.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <div>
                      <button
                        className="delete_vendor_btn"
                        onClick={() => handleOpen1()}
                      >
                        <DeleteIcon style={{ fontSize: "20px" }} />
                        Delete
                      </button>
                    </div>
                    <div
                      className="all_invoice text-center"
                      onClick={() => {
                        setshowEditView("update");
                      }}
                    >
                      <BorderColorIcon className="edt_mat_icon" /> Edit
                    </div>
                  </div>
                </div>
              </div>
            )}{" "}
            {showEditView === "update" && (
              <>
                <EditView
                  setmainErrorOFrow={setmainErrorOFrow}
                  categoryname={categoryname}
                  setshowEditView={setshowEditView}
                  GetmatrialByID={GetmatrialByID}
                  setCat={setCat}
                  ListingUOMMatrial={ListingUOMMatrial}
                  ListingUOMPrice={ListingUOMPrice}
                  EditData={EditData}
                />
              </>
            )}
            {showEditView === "add" && (
              <form onSubmit={formik.handleSubmit}>
                <div className="row add_material_main">
                  <div
                    className="col-6 col-md-6"
                    style={{
                      borderRight: "1px solid #E2E2E2",
                    }}
                  >
                    <div className="me-2">
                      <div className="form-group">
                        <label htmlFor="companyname" className="label_txt_mc">
                          Material Name
                        </label>
                        <input
                          type="text"
                          name="material_name"
                          value={formik.values.material_name}
                          onChange={formik.handleChange}
                          className="p-3 add_material_inputxt"
                          placeholder="Enter Material Name"
                          id="companyname"
                          autoComplete="off"
                        />
                      </div>
                      {formik.touched.material_name &&
                        formik.errors.material_name && (
                          <p className="errorRequest mt-1">
                            Material Name required
                          </p>
                        )}
                      <hr />

                      {rows.map((row, index) => (
                        <>
                          {index == 0 && (
                            <div className="row Specification_title ">
                              <p className="m-0 col-5">Specification Name</p>
                              <p className="m-0 col-3">UOM</p>
                              <p className="m-0 col-3">Value</p>
                            </div>
                          )}

                          <div key={index} className="my-2">
                            <EditNewRow
                              setmainErrorOFrow={setmainErrorOFrow}
                              setRows={setRows}
                              rows={rows}
                              index={index}
                              row={row}
                              ListingUOMMatrial={ListingUOMMatrial}
                            />
                          </div>
                        </>
                      ))}
                      <div className="col-6 col-md-7">
                        <div>
                          <div
                            className="add_specif_btn"
                            onClick={handleAddRow}
                          >
                            <AddIcon className="plus_icons" /> Add
                            Specifications
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="ms-2">
                      <div className="form-group">
                        <label htmlFor="companyname" className="label_txt_mc">
                          Material UOM
                        </label>
                        <div className="checkboxcallstatus">
                          <MultiSelect
                            placeholder="eg. nos, bundel..."
                            handlechange={handlechangeMatrialADDuom}
                            options={ListingUOMPrice}
                          />
                        </div>
                      </div>

                      <div className="form-group mt-3">
                        <label htmlFor="companyname" className="label_txt_mc">
                          Price UOM
                        </label>
                        <div className="checkboxcallstatus">
                          <MultiSelect
                            placeholder="eg. ks, quintel..."
                            handlechange={handlechangepriceADDuom}
                            options={ListingUOMPrice}
                          />
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="companyname" className="label_txt_mc">
                          Description
                        </label>
                        <textarea
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          className="p-3 add_material_inputxt"
                          placeholder="Enter Description"
                          id="companyname"
                          autoComplete="off"
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <p className="errorRequest mt-1">
                              Description required
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="add_material_btn_long mt-3">
                  <AddIcon /> Add Material
                </button>
              </form>
            )}
          </div>
        </div>
      </Modal>
      <div
        className="modal fade"
        id="material_added"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <div className="modal-body pt-0">
              <div className="form_invoice_upload1">
                <div className="text-center">
                  <CheckCircleIcon className="tick_mark" />
                  <div className="mt-3">
                    <h4>Material Added Successfully</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatrialPopUp;
