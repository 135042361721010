import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Popup from "reactjs-popup";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import MatrialDetailsTable from "./MatrialDetails/MatrialDetailsTable";
import { toast } from "react-toastify";
import Select from "react-select";

function AdminMaterialCatlog() {
  const [Categoryname, setCategoryname] = useState("");
  const [matcatname, Setmatcatname] = useState("");
  const [materialCatalog, setMaterialCatalog] = useState([]);
  // const [selectedOption, setSelectedOption] = useState("none");
  const [showPage, setShowPage] = useState("showlisting");

  // Function to add material category
  const addmatcategory = () => {
    if (matcatname != "") {
      axiosClientInterceptors
        .post(`/addcategory`, {
          category_name: matcatname,
        })
        .then((res) => {
          document.getElementById("closematerialpopup").click();
          if (res.data.status === "success") {
            toast.success(`${res.data.msg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(`${res.data.msg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          getAllMaterialCategory();
        });
    }
  };

  const handleTypeSelect = (e) => {
    // setSelectedOption(e.value);
    setCategoryname(e.value);
  };

  useEffect(() => {
    getAllMaterialCategory();
  }, [Categoryname, showPage]);

  // to get list of categories existing
  const getAllMaterialCategory = () => {
    axiosClientInterceptors.get(`/getcategories`).then((res) => {
      setMaterialCatalog(
        res.data.Category.map((val, index) => {
          return {
            ...val,
            ["value"]: val._id,
            ["label"]: val.category_name,
          };
        })
      );
      console.log("##@@", materialCatalog);
    });
  };

  return (
    <>
      <div style={{}}>
        {showPage === "showlisting" ? (
          <div
            className=""
            style={{
              padding: "25px",
              height: "calc(90vh - -58px)",
            }}
          >
            <div className="">
              <div className="d-flex align-items-center justify-content-between">
                <p className="dashboardtitle m-0">Material Catalogue</p>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <div
                    style={{
                      width: "220px",
                      border: "1px solid #c7c4c4",
                    }}
                  >
                    {/* <select
                      className="select_category_materialcatlog"
                      id="SelectCategory"
                      name="SelectCategory"
                      onChange={(e) => {
                        setCategoryname(e.target.value);
                      }}
                    >
                      <option value="" selected disabled>
                        Select Category
                      </option>
                      {materialCatalog?.map((cu, index) => {
                        return (
                          <option value={cu._id}>{cu.category_name}</option>
                        );
                      })}
                    </select> */}
                    <Select
                      placeholder="Select Category"
                      options={materialCatalog}
                      onChange={handleTypeSelect}
                      value={materialCatalog.filter(function (option) {
                        return option.value === Categoryname;
                      })}
                      style={{ fontFamily: "Gsb" }}
                      label="Single select"
                    />
                  </div>

                  <Popup
                    modal
                    className="poup_scroll_imp fade popup-overlay2 "
                    trigger={
                      <div className="select_category_btn">
                        <AddIcon style={{ width: "20px" }} /> Add Category
                      </div>
                    }
                    style={{
                      background: "rgb(0,0,0,0.5)",
                    }}
                  >
                    {(close) => (
                      <div>
                        <span
                          id="closematerialpopup"
                          className="close_btn_popup_edit"
                          onClick={close}
                          style={{
                            background: "none",
                            border: "none",
                            borderRadius: "50%",
                            marginRight: "10px",
                            cursor: "pointer",
                            fontSize: "28px",
                          }}
                        >
                          &times;
                        </span>
                        <div className="form_invoice_upload p-4">
                          <h5
                            className="modal-title_add_cat"
                            id="exampleModalLabel"
                          >
                            Add New Category
                          </h5>
                          <div className="row mt-4">
                            <div className="col-12">
                              <label
                                htmlFor="vendorname"
                                className="label_txt_mc"
                              >
                                Category Name
                              </label>
                              <input
                                required
                                type="text"
                                className="invoice_upload_form_input p-3"
                                placeholder="eg. plumbing, electrical"
                                name="request_id"
                                onChange={(e) => {
                                  Setmatcatname(e.target.value);
                                }}
                              />
                            </div>

                            <div className="mt-3">
                              <button
                                type="submit"
                                className="all_invoice mt-3 text-center w-100"
                                onClick={addmatcategory}
                              >
                                <AddIcon /> Add Category
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
            </div>
            <hr />

            <MatrialDetailsTable categoryname={Categoryname} />
          </div>
        ) : (
          ""
        )}
        {showPage === "vendordetails" && (
          <p>vendor</p>
          // <VendorDetails
          //   vendorDetailsData={vendorDetailsData}
          //   setShowPage={setShowPage}
          //   materialCatalog={materialCatalog}
          //   vendorId={vendorId}
          //   toggleShowedit={toggleShowedit}
          //   showedit={showedit}
          //   getVendorDetails={getVendorDetails}
          //   vendorIdentity={vendorIdentity}
          //   getvendorsFunc={getvendorsFunc}
          // />
        )}
      </div>
    </>
  );
}

export default AdminMaterialCatlog;
