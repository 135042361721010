import React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { useState } from "react";

const AddUserGroups = ({ getAllUserGroups }) => {
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [userGroups, setUserGroups] = useState("");

  const addUserGrp = () => {
    let data = {
      user_group: userGroups,
    };

    axiosClientInterceptors
      .post(`/add-user-group`, data)
      .then((response) => {
        getAllUserGroups();
        if (response.data.status === "success") {
          setUserGroups("");
          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <div className="MatrialCatalogTab_sub">
        <button
          className="AddProject text-dark"
          style={{ width: "fit-content" }}
          onClick={() => handleOpen1()}
        >
          <AddIcon className="add_icon" />
          Create User Group
        </button>
      </div>
      <Modal
        open={open1}
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        className="d-flex justify-content-center align-items-center flex-column"
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="filter_container ">
          <div className="m-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 Filter_text">Add User Group</p>
              <CloseIcon onClick={handleClose1} style={{ cursor: "pointer" }} />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div className="w-100 ">
                <p className=" Package_title">User Group Name</p>
                <input
                  type="text"
                  rows="6"
                  value={userGroups}
                  onChange={(e) => setUserGroups(e.target.value)}
                  name="invoice_number"
                  className="w-100  InvoiceNumber_input"
                  placeholder="eg: Enter User Group Name"
                />
              </div>
            </div>
            <button
              className="AddProject w-100 mt-4 "
              style={{ height: "50px" }}
              disabled={userGroups.length === 0}
              onClick={() => {
                addUserGrp();
                handleClose1();
              }}
            >
              <AddIcon className="add_icon" style={{ pointerEvents: "none" }} />
              Add User Group
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddUserGroups;
