import React, { useEffect } from "react";
import { useState } from "react";
import EditIcons from "../../../Assets/Img/EditIcons.svg";
import AddTerms from "./AddTerms";
import { CgArrowRightR } from "react-icons/cg";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field } from "formik";
import Delete from "../../../Assets/Img/svg/Delete.svg";
import Edit from "../../../Assets/Img/svg/Edit.svg";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import {
  validategst,
  validateLocation,
  validatePan,
} from "../../../ReusableComponent/Validation";

import { toast } from "react-toastify";
import { setTermsncondition } from "../../../features/counter/counterSlice";
import { useDispatch } from "react-redux";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
export default function POConfig() {
  const [showedit, toggleShowedit] = useState(false);
  const [terms, setTerms] = useState([]);
  const dispatch = useDispatch();
  const [compDetails, setCompDetails] = useState("");
  const [termAndConditions, setTermAndConditions] = useState([]);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [toggleEdit, settoggleEdit] = useState(false);
  const [editDescription, setEditDescription] = useState(
    termAndConditions.description
  );

  const [opendel, setOpendel] = useState(false);
  const handleOpendel = () => setOpendel(true);
  const handleClosedel = () => setOpendel(false);

  const edituom = () => {
    let data = {
      description: editDescription,
    };

    axiosClientInterceptors
      .post(`/update-terms-and-conditions?_id=${termAndConditions._id}`, data)
      .then((response) => {
        if (response.data.status === "success") {
          getTermsandCond();
          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) =>
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
  };

  const deleteTerms = () => {
    axiosClientInterceptors
      .delete(`/delete-terms-and-conditions?_id=${termAndConditions._id}`)
      .then((response) => {
        if (response.data.status === "success") {
          handleClosedel();
          getTermsandCond();

          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) =>
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
  };

  const getTermsandCond = () => {
    const headers = {
      "content-type": "multipart/form-data",
    };

    axiosClientInterceptors
      .get(`/get-all-terms-and-conditions`, {
        headers,
      })
      .then((response) => {
        setTerms(response.data.result);
        dispatch(setTermsncondition(response.data.result));
      })
      .catch((error) => console.log("Error", error));
  };

  const getCompanyDetails = () => {
    const headers = {
      "content-type": "multipart/form-data",
    };

    axiosClientInterceptors
      .get(`/get-company-details`, {
        headers,
      })
      .then((response) => {
        setCompDetails(response.data.result[0]);
      })
      .catch((error) => console.log("Error", error));
  };

  useEffect(() => {
    getTermsandCond();
    getCompanyDetails();
  }, []);
  return (
    <>
      <div style={{}}>
        <div className="MatrialCatalogTab_con p-3">
          <Formik
            enableReinitialize={true}
            initialValues={{
              pan: compDetails?.pan,
              gst: compDetails?.gst,
              address: compDetails?.address,
            }}
            onSubmit={(values) => {
              const headers = {
                "Content-Type": "application/json",
              };
              const data = {
                pan: values.pan,
                gst: values.gst,
                address: values.address,
              };
              axiosClientInterceptors
                .post(`/add-company-details`, data, {
                  headers,
                })
                .then((resultfse) => {
                  let Status = resultfse.data.status;
                  if (Status === "success") {
                    toggleShowedit(false);
                    getCompanyDetails();
                    toast.success("Company Details Updated Sucessfully!!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (Status === "failed") {
                    toast.error("Internal Error try after some time!!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                })
                .catch((err) => {
                  alert("Some Internal Error", err);
                });
            }}
          >
            {({ errors, touched, handleSubmit, values }) => (
              <Form>
                <div className="details_area p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="MaterialSpecification mb-0">
                      Company Details
                    </p>

                    <div
                      className="edit_icon"
                      onClick={() => toggleShowedit(!showedit)}
                    >
                      {!showedit && (
                        <img src={EditIcons} className="new_edit" />
                      )}
                      {showedit && (
                        <div>
                          <button
                            type="submit"
                            className=""
                            style={{
                              color: "#fcc200",
                              background: "transparent",
                              fontFamily: "Gm",
                            }}
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className="mt-2 mb-2" />
                  {showedit ? (
                    <div className="row mt-3">
                      <div className="col-3 col-md-3">
                        <p className="m-0 cardtitle">GST</p>
                        <Field
                          type="email"
                          className="vendor_details_edit"
                          id="user_name"
                          name="gst"
                          validate={validategst}
                          placeholder="Enter GST"
                          // value={values.user_name}
                          // validate={validateuserid}
                        />
                        {errors.gst && touched.gst && (
                          <p className="error">{errors.gst}</p>
                        )}
                      </div>
                      <div className="col-3 col-md-3">
                        <p className="m-0 cardtitle">PAN</p>
                        <Field
                          type="email"
                          className="vendor_details_edit"
                          id="user_name"
                          name="pan"
                          placeholder="Enter PAN"
                          // value={values.user_name}
                          validate={validatePan}
                        />
                        {errors.pan && touched.pan && (
                          <p className="error">{errors.pan}</p>
                        )}
                      </div>
                      <div className="col-6 col-md-6">
                        <p className="m-0 cardtitle">Billing Address</p>
                        <Field
                          type="textarea"
                          className="vendor_details_edit"
                          id="address"
                          name="address"
                          placeholder="Enter Billing Address"
                          validate={validateLocation}
                          maxlength="250"
                        />
                        {errors.address && touched.address && (
                          <p className="error">{errors.address}</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-3">
                      <div className="col-3">
                        <div class="details">
                          <span>GST</span>
                          <p class="mb-0">{compDetails?.gst}</p>
                        </div>
                      </div>
                      <div className="col-3">
                        <div class="details">
                          <span>PAN</span>
                          <p class="mb-0">{compDetails?.pan}</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div class="details">
                          <span>Billing Address</span>
                          <p class="mb-0">{compDetails?.address}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="config_terms_ mt-3 p-3">
          <AddTerms getTermsandCond={getTermsandCond} />
          <div className="mt-3 mb-4">
            <table className="table table-bordered table-responsive-sm table_material_catalog">
              <thead>
                <tr>
                  <th>Sl.No.</th>
                  <th className="text-start">Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {terms
                  ? terms?.map((po, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={(e) => {
                            handleOpen2();
                            setTermAndConditions(po);
                            // setShowPage("vendordetails");
                            // setVendorId(po._id);
                            // getVendorDetails(po._id);
                          }}
                        >
                          <td>{index + 1}.</td>
                          <td className="text-start">{po.description}</td>
                          <td>
                            <CgArrowRightR
                              className="partner_back_btn"
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  : "none"}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          open={open2}
          style={{ background: "rgba(34, 30, 31, 0.9)" }}
          className="d-flex justify-content-center align-items-center flex-column "
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="filter_container ">
            <div className="m-3">
              <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 Filter_text">T&C’s Details </p>
                <CloseIcon
                  onClick={() => {
                    //   getinvoice();
                    settoggleEdit(false);
                    handleClose2();
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="row">
                <div className="col-md-12 mt-4">
                  <div className="w-100 ">
                    <p className=" Package_title">Description</p>
                    {!toggleEdit ? (
                      <div
                        className="w-100  InvoiceNumber_input"
                        style={{ height: "120px" }}
                        //   validate={validateInvoiceNumber}
                      >
                        {termAndConditions?.description}
                      </div>
                    ) : (
                      <textarea
                        type="textarea"
                        //   cols="20"
                        rows="6"
                        defaultValue={termAndConditions.description}
                        onChange={(e) => setEditDescription(e.target.value)}
                        name="invoice_number"
                        className="w-100  InvoiceNumber_input"
                        placeholder="eg: Enter Terms Description"
                        //   validate={validateInvoiceNumber}
                      />
                    )}{" "}
                  </div>
                </div>
              </div>

              {toggleEdit ? (
                <button
                  disabled={editDescription === ""}
                  className="w-100 configEditDetails mt-4 "
                  onClick={() => {
                    //   getinvoice();
                    settoggleEdit(false);
                    handleClose2();
                    edituom();
                  }}
                >
                  <p className="m-0">Save Changes</p>
                </button>
              ) : (
                <div
                  className="d-flex justify-content-between align-items-center mt-4"
                  style={{ gap: "20px" }}
                >
                  <div
                    className="w-50 configDelete"
                    onClick={() => {
                      handleClose2();
                      // deleteTerms();
                      handleOpendel();
                    }}
                  >
                    <img
                      src={Delete}
                      alt="..."
                      className="me-1"
                      style={{ width: "7%" }}
                    />
                    <p className="m-0">Delete</p>
                  </div>
                  <div
                    className="w-50 configEditDetails "
                    onClick={() => {
                      settoggleEdit(true);
                    }}
                  >
                    <img
                      src={Edit}
                      alt="..."
                      style={{ width: "9%" }}
                      className="me-1"
                    />

                    <p className="m-0">Edit Details</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
        {/* Are you sure wanna delete popup */}
        <Modal
          open={opendel}
          style={{ background: "rgba(34, 30, 31, 0.9)" }}
          className="d-flex justify-content-center align-items-center flex-column "
          onClose={handleClosedel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="filter_container_config p-3">
            <div>
              <div className="popup_icon_center">
                <InfoRoundedIcon className="popup_delete_proj_icon" />
              </div>
              <div className="popup_text_center">
                <span className="popup_addbankdetails_text">
                  <b> Are you sure want to Delete this Condition ?</b>
                </span>
              </div>
              <div className="pt-3 d-flex">
                <div
                  style={{
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  <button
                    type="button"
                    className="popup_btn_unlinkaccount"
                    onClick={() => {
                      deleteTerms();
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div
                  style={{
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-secondary popup_btn_close"
                    onClick={handleClosedel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
