import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestScreen: "listing",
  ProjectScreen: "allusers",
  AddVendorScreen: "listing",
};

export const screenSlice = createSlice({
  name: "screenSlice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setscreen: (state, action) => {
      state.requestScreen = action.payload;
    },
    setProjectScreen: (state, action) => {
      state.ProjectScreen = action.payload;
    },
    setAddVendorScreen: (state, action) => {
      state.AddVendorScreen = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});
// dispatch(setscreen());

export const {
  setscreen,
  setProjectScreen,
  setAddVendorScreen
} = screenSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectscreen = (state) => state.screenSlice.requestScreen;
export const selectProjectScreen = (state) => state.screenSlice.ProjectScreen;
export const selectAddVendorScreen = (state) => state.screenSlice.AddVendorScreen;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default screenSlice.reducer;
