import React, { useState } from "react";
import AllDocuments from "../../Admin/AllProject/AllDocuments";

const ProjectsDocument = ({ setsingleView, singleViewId }) => {
  return (
    <div className="p-4">
      <AllDocuments singleViewId={singleViewId} setsingleView={setsingleView} />
    </div>
  );
};

export default ProjectsDocument;
