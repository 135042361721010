import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectactivetab,
  setPOswitch,
} from "../../../../features/counter/counterSlice";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  setProjectScreen,
  setscreen,
} from "../../../../features/Screen/screen";
import ArticleIcon from "@mui/icons-material/Article";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect } from "react";
import axiosClientInterceptors from "../../../../ReusableComponent/axiosClentInterceptors";
import { useState } from "react";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

const RFQ = ({
  matarialID,
  requestIDforVEndor,
  getRequestDetails,
  // SocketCallData,
  // SocketCall,
}) => {
  const activetabsection = useSelector(selectactivetab);
  const dispatch = useDispatch();
  const [listOfvendor, setlistOfvendor] = useState([]);
  const [btnLoader, setbtnLoader] = useState(false);
  let checkedIds = [];

  function handleCheckboxChange(event) {
    const checkboxId = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the ID of the checked checkbox to the array
      checkedIds.push(checkboxId);
    } else {
      // Remove the ID of the unchecked checkbox from the array
      checkedIds = checkedIds.filter((id) => id !== checkboxId);
    }
  }

  const sendEmailToVendor = () => {
    setbtnLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    let data = {
      vendor_id: checkedIds,
      request_id: requestIDforVEndor,
    };
    axiosClientInterceptors
      .post(`/send-mail-to-vendor`, data, {
        headers,
      })
      .then((resulta) => {
        console.log(resulta.data);
        if (resulta.data.status === "succes") {
          toast.success(`${resulta.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getRequestDetails();
          console.log("RequestIdentity", requestIDforVEndor);
          dispatch(setscreen("singledata"));
          dispatch(setPOswitch(""));
          // SocketCall("admin-notification-count", null, null, "RFQ sent");
          // SocketCall("pm-notification-count", null, null, "RFQ sent");
          // SocketCall("pd-notification-count", null, null, "RFQ sent");
          // SocketCallData(
          //   "admin-get-notification",
          //   requestIDforVEndor,
          //   "RFQ sent"
          // );
          // SocketCallData("pm-get-notification", requestIDforVEndor, "RFQ sent");
          // SocketCallData("pd-get-notification", requestIDforVEndor, "RFQ sent");
        } else {
          toast.error(`${resulta.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setbtnLoader(false);
      });
  };

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };
    axiosClientInterceptors
      .post(
        `/rfq-for-vendor`,
        { category_id: new Array(matarialID) },
        {
          headers,
        }
      )
      .then((resulta) => {
        // SocketCall("admin-notification-count", null, null, "PO Created");
        // SocketCall("pm-notification-count", null, null, "PO Created");
        // SocketCall("pd-notification-count", null, null, "PO Created");
        // SocketCallData(
        //   "admin-get-notification",
        //   requestAllDetails_id,
        //   "PO Created"
        // );
        // SocketCallData(
        //   "pm-get-notification",
        //   requestAllDetails_id,
        //   "PO Created"
        // );
        // SocketCallData(
        //   "pd-get-notification",
        //   requestAllDetails_id,
        //   "PO Created"
        // );
        setlistOfvendor(resulta.data.result);
      });
  }, []);

  return (
    <div>
      <div className="w-100 p-3">
        {activetabsection === "All Projects" ? (
          <>
            <p className="m-0">
              <span
                className="fontdocument_dim"
                onClick={() => {
                  dispatch(setProjectScreen("allusers"));
                  dispatch(setscreen("listing"));
                  dispatch(setPOswitch(""));
                }}
              >
                All Projects
              </span>{" "}
              /{" "}
              <span
                className="fontdocument_dim"
                onClick={() => {
                  dispatch(setProjectScreen("singleuser"));
                  dispatch(setscreen("listing"));
                  dispatch(setPOswitch(""));
                }}
              >
                Project Details{" "}
              </span>
              /{" "}
              <span
                className="fontdocument_dim"
                onClick={() => {
                  dispatch(setscreen("listing"));
                  dispatch(setPOswitch(""));
                }}
              >
                All Requests
              </span>{" "}
              /{" "}
              <span
                className="fontdocument_dim"
                onClick={() => {
                  dispatch(setscreen("singledata"));
                  dispatch(setPOswitch(""));
                }}
              >
                Requests Details{" "}
              </span>
              / <span className="fontdocument_active">RFQ</span>
            </p>
          </>
        ) : (
          <p className="m-0">
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setscreen("listing"));
                dispatch(setPOswitch(""));
              }}
            >
              All Requests
            </span>{" "}
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setscreen("singledata"));
                dispatch(setPOswitch(""));
              }}
            >
              Requests Details{" "}
            </span>
            / <span className="fontdocument_active">RFQ</span>
          </p>
        )}
        <hr />
        <p className="pt-1 pb-2 rfqtittle">
          Choose Vendors to Send Request For Quotation
        </p>
        <div className="Rfqcontainer">
          <div class="row">
            {listOfvendor.map((val, i) => (
              <div class="col-md-4" key={i}>
                <div className="d-flex  align-items-center my-2">
                  {" "}
                  <input
                    type="checkbox"
                    className="me-2"
                    value={val._id}
                    onChange={handleCheckboxChange}
                  />
                  <p className="vendorNameRFQ">{val.company_name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex" style={{ gap: "10px" }}>
          <button
            className=" d-flex align-items-center ml-2 purchase_order_btn mt-3"
            style={{ width: "fit-content" }}
            onClick={() => {
              if (checkedIds.length === 0) {
                toast.info(`Please select the vendor`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                sendEmailToVendor();
              }
            }}
          >
            {btnLoader ? (
              <CircularProgress size={30} color="inherit" />
            ) : (
              <>
                {" "}
                <ArticleIcon
                  className="side_nav_icons"
                  style={{
                    color: "#000",
                    marginRight: "5px",
                  }}
                />
                <p className="mb-0 pl-3">Send Email to Vendors</p>{" "}
              </>
            )}
          </button>
          {/* <button
            className=" d-flex align-items-center ml-2 purchase_order_btn mt-3"
            style={{ width: "fit-content" }}
            onClick={() => {
              if (checkedIds.length === 0) {
                toast.info(`Please select the vendor`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                sendEmailToVendor();
              }
            }}
          >
            {btnLoader ? (
              <CircularProgress size={30} color="inherit" />
            ) : (
            <>
              {" "}
              <WhatsAppIcon
                className="side_nav_icons"
                style={{
                  color: "#000",
                  marginRight: "5px",
                }}
              />
              <p className="mb-0 pl-3">Send via Whatsapp</p>{" "}
            </>
             )} 
          </button> */}
          <div
            className="modal fade"
            id="mail_sent_success"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div
                className="modal-content"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <div className="modal-body pt-0">
                  <div className="form_invoice_upload1">
                    <div className="text-center">
                      <CheckCircleIcon className="tick_mark" />
                      <div className="mt-3">
                        <h4>Email Sent Successfully</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RFQ;
