import React, { useEffect, useRef } from "react";
import { useState } from "react";

const CheckBoxstage = ({ val, checkedui, checkeddata, projectlisting }) => {
  const ref = useRef();
  const [check, setCheck] = useState(checkeddata);
  useEffect(() => {
    projectlisting();
  }, [check]);

  return (
    <>
      <div className="row mt-2 d-flex align-items-center">
        <div className="" style={{ width: "0%" }}>
          <p className="d-flex"> </p>
        </div>
        <div className="" style={{ width: "10%" }}>
          <input
            className="check"
            type="checkbox"
            ref={ref}
            style={{
              marginRight: "8px",
              marginLeft: "8px",
            }}
            value={check}
            name="tr"
            onClick={() => {
              setCheck(!check);
              checkedui(val, !check);
            }}
            checked={check}
          />
        </div>
        <div className="" style={{ width: "70%" }}>
          <span>{val}</span>
        </div>
      </div>
    </>
  );
};

export default CheckBoxstage;
