import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAddVendorScreen,
  setAddVendorScreen,
} from "../../../features/Screen/screen";
import EditIcons from "../../../Assets/Img/Add_doc.svg";
import VendorDocCard from "./VendorDocCard";
import { useState } from "react";
import Select from "react-select";
import PersonalInfo from "./VendorEditcards/PersonalInfo";
import BankInfo from "./VendorEditcards/BankInfo";
import PaymentTerm from "./VendorEditcards/PaymentTerm";
import { useEffect } from "react";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import File_logo from "../../../Assets/Img/File_logo.svg";
import ImageCard from "./ImageCard";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import ViewPO from "./ViewPO";
import { setvendorCompanyName } from "../../../features/counter/counterSlice";

const VendorDetailsPage = ({
  materialCatalog,
  vendordetailsID,
  getvendorListiing,
}) => {
  const dispatch = useDispatch();
  const screenvendor = useSelector(selectAddVendorScreen);
  const [personalinfoEdit, setpersonalinfoEdit] = useState(false);
  const [bankinfoEdit, setbankinfoEdit] = useState(false);
  const [documentsEdit, setdocumentsEdit] = useState(false);
  const [Payment_Term, setPaymentTerm] = useState(false);
  const [vendorData, setVendordata] = useState("");
  const [registered_Vendor, setregistered_Vendor] = useState();
  const [BulkFile, setBulkFile] = useState([]);
  const [paymentTermsdata, setpaymentTermsdata] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [loader, setloader] = useState(false);
  const [viewPOPage, setViewPOPage] = useState(false);

  const getVEndordata = () => {
    axiosClientInterceptors
      .get(`/getvendorbyid?_id=${vendordetailsID}`)
      .then((response) => {
        setregistered_Vendor(response.data?.registered_Vendor);
        setpaymentTermsdata(response.data?.paymentTerms);
        setVendordata(response.data.Vendor[0]);
        setUserStatus(response.data.Vendor[0].status);
        dispatch(setvendorCompanyName(response.data?.Vendor[0].company_name));
      })
      .catch((error) => {});
  };

  const activateuserPopUp = () => {
    window.$("#activateuser").modal("show");
  };
  const deactivateuserPopUp = () => {
    window.$("#deactivateuser").modal("show");
  };
  const removeUser = () => {
    window.$("#deleteUser").modal("show");
  };

  useEffect(() => {
    getVEndordata();
  }, []);

  const deletAPI = () => {
    axiosClientInterceptors
      .delete(`/deletevendor?_id=${vendordetailsID}`)
      .then((response) => {
        // dispatch(setTermsncondition(response.data.result));
        if (response.data.status === "success") {
          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleClose();
          getvendorListiing();
          dispatch(setAddVendorScreen("listing"));
        }
      })
      .catch((error) => {});
  };
  const handleImage = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setdocumentsEdit(true);
    } else {
      setdocumentsEdit(false);
    }
    setBulkFile(acceptedFiles);
  };
  const RemoveFile = (i) => {
    const newBulk = [...BulkFile];
    newBulk.splice(i, 1);
    setBulkFile(newBulk);
    if (newBulk.length === 0) {
      setdocumentsEdit(false);
    }
  };
  const postDocuments = () => {
    setloader(true);
    const formData = new FormData();
    BulkFile?.forEach((image) => {
      formData.append("documents", image);
    });
    axiosClientInterceptors
      .post(`/add-document?_id=${vendordetailsID}`, formData)
      .then(function (response) {
        if (response.data.status === "success") {
          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setdocumentsEdit(false);
          handleClose();
          getVEndordata();
          setloader(false);
          // dispatch(setAddVendorScreen("listing"));
        }
      });
  };
  const filedelet = (valfile) => {
    let data = {
      filePath: valfile,
    };
    axiosClientInterceptors
      .delete(`/remove-document?_id=${vendordetailsID}&filePath=${valfile}`)
      .then((response) => {
        // dispatch(setTermsncondition(response.data.result));

        if (response.data.status === "success") {
          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getVEndordata();

          // dispatch(setAddVendorScreen("listing"));
        }
      })
      .catch((error) => {});
  };

  const activateUsers = () => {
    axiosClientInterceptors
      .post(`/update-status?_id=${vendordetailsID}&status=active`)
      .then((res) => {
        window.$("#activateuser").modal("hide");
        getVEndordata();
      });
  };

  const deactivateUsers = () => {
    axiosClientInterceptors
      .post(`/update-status?_id=${vendordetailsID}&status=inactive`)
      .then((res) => {
        window.$("#deactivateuser").modal("hide");
        getVEndordata();
      });
  };
  return (
    <>
      <Modal
        open={open1}
        onClose={handleClose1}
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
        aria-labelledby="modal-modal-title"
        className="d-flex justify-content-center align-items-center flex-column "
        aria-describedby="modal-modal-description"
      >
        <div className="popupcontainer_body">
          <div className="popup_icon_center">
            <ErrorRoundedIcon className="popup_unlinkbankdetails_icon" />
          </div>
          <div className="pt-2 popup_text_center">
            <span className="popup_addbankdetails_text">
              Are you sure want to Delete this Vendor ?
            </span>
          </div>
          <div className="pt-3 d-flex" style={{ gap: "10px" }}>
            <button
              type="button"
              className="popup_btn_unlinkaccount"
              onClick={() => deletAPI()}
            >
              Delete
            </button>

            <button
              onClick={() => handleClose1()}
              className="popup_btn_close"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <div className="p-4">
        <div className="d-flex justify-content-between align-items-center ">
          <p className="m-0">
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setAddVendorScreen("listing"));
                getvendorListiing();
              }}
            >
              All Vendors{" "}
            </span>
            / <span className="fontdocument_active">Vendor Details</span>
          </p>
          {/* <button
            className="admin_pro_delete mt-3"
            onClick={() => {
              handleOpen1();
            }}
          >
            <DeleteIcon className="delete_icon_admin" />
            Delete
          </button> */}
          <div className="d-flex justify-content-between align-items-center gap-4">
            <button
              className="viewPO_btn"
              onClick={() => {
                dispatch(setAddVendorScreen("viewPO"));
              }}
            >
              View PO's
            </button>
            <div className="dropdown action">
              <button
                className="btn dropdown-toggle dropdown_action"
                type="button"
                id="dropdownMenu2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Action
              </button>
              {userStatus === "" ? (
                ""
              ) : userStatus === "active" ? (
                <div
                  className="dropdown-menu w-100"
                  aria-labelledby="dropdownMenu2"
                >
                  <button
                    className="dropdown-item"
                    onClick={deactivateuserPopUp}
                  >
                    Deactivate User
                  </button>
                </div>
              ) : (
                <div
                  className="dropdown-menu w-100"
                  aria-labelledby="dropdownMenu2"
                >
                  <button className="dropdown-item" onClick={activateuserPopUp}>
                    Activate User
                  </button>
                  <div className="dropdown-divider"></div>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      handleOpen1();
                    }}
                  >
                    Remove User
                  </button>
                </div>
              )}
            </div>
          </div>
          {/*  */}
        </div>
        <hr />
        <div className="scroolView">
          {/* personal info */}
          <PersonalInfo
            registered_Vendor={registered_Vendor}
            userStatus={userStatus}
            getVEndordata={getVEndordata}
            vendordetailsID={vendordetailsID}
            vendorData={vendorData}
            materialCatalog={materialCatalog}
            personalinfoEdit={personalinfoEdit}
            setpersonalinfoEdit={setpersonalinfoEdit}
            dontShowEdit={false}
          />
          {/* bank info */}

          <BankInfo
            vendordetailsID={vendordetailsID}
            getVEndordata={getVEndordata}
            vendorData={vendorData}
            setbankinfoEdit={setbankinfoEdit}
            bankinfoEdit={bankinfoEdit}
            filedelet={filedelet}
          />

          {/* Payment Term */}
          <PaymentTerm
            getVEndordata={getVEndordata}
            paymentTermsdata={paymentTermsdata}
            vendordetailsID={vendordetailsID}
            Payment_Term={Payment_Term}
            setPaymentTerm={setPaymentTerm}
            dontShowEdit={false}
          />
          {/* file editz */}
          <div className="PersonalInfo mt-4">
            <Modal
              open={open}
              style={{ background: "rgba(34, 30, 31, 0.9)" }}
              className="d-flex justify-content-center align-items-center flex-column "
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div
                className="filter_container "
                style={{ position: "relative", zIndex: 1, width: "40%" }}
              >
                <div className="m-3 ">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="m-0 Filter_text ">Documents</p>
                    <CloseIcon onClick={handleClose} />
                  </div>
                  {documentsEdit ? (
                    <div className="p-4 image_scrool ">
                      {BulkFile.map((val, index) => (
                        <div className="mt-2">
                          {" "}
                          <ImageCard
                            path={val.path}
                            index={index}
                            RemoveFile={RemoveFile}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Dropzone onDrop={handleImage}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className: "uplode_file_dot_border rounded w-100",
                          })}
                        >
                          <div className="mb-3">
                            <img
                              className="mx-auto"
                              src={File_logo}
                              alt="..."
                            />
                          </div>
                          <input
                            name="bulkdata"
                            multiple
                            {...getInputProps()}
                          />

                          <p className="uplode_file_text">
                            Drag & Drop Your File here
                          </p>

                          {/* <p className="Fileformat">
                  File format : Only .csv, .xls , .xlsx
                </p> */}
                          <div className="uplode_file_btn_container ">
                            <button
                              className=" mx-auto uplode_file_btn px-4 py-2  rounded"
                              type="button"
                            >
                              Browse File
                            </button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {documentsEdit && (
                    <div
                      onClick={() => postDocuments()}
                      className="downloadme mt-2  w-100"
                      // onClick={() =>
                      //   saveAs(
                      //     // `${invoice}`,
                      //     // `${downlod.split("/").at(-1).split(".").at(-2)}.pdf`
                      //     `${process.env.REACT_APP_IMG_URL}/${val}`,
                      //     `${fileNameSplit(val)}`
                      //   )
                      // }
                    >
                      <div className="d-flex align-items-center upload">
                        {/* <SaveAltIcon
                        fontSize="small"
                        className="me-2 text-black"
                      /> */}
                        {loader ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <p className="m-0 text-black">Upload</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Modal>
            <div className="d-flex justify-content-between align-items-center">
              {" "}
              <p className="PersonalInfo_title">Documents </p>
              <img
                src={EditIcons}
                className="new_edit"
                onClick={() => handleOpen()}
              />
            </div>
            <hr />
            <div class="row">
              {vendorData.documents?.map((val, i) => (
                <div class="col-md-6 g-3" key={i}>
                  <VendorDocCard val={val} filedelet={filedelet} delet={true} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr />
      </div>

      {/* Activate User */}
      <div
        id="activateuser"
        className="modal fade partner_details_delete"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ border: "none" }}
          >
            <div className="head_modal" style={{ background: "#3ADD5E" }}></div>
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <ErrorRoundedIcon className="popup_linkbankdetails_icon" />
                </div>
                <div className="pt-2 popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Activate this User ?
                  </span>
                </div>
                <div className="pt-3 d-flex" style={{ gap: "10px" }}>
                  <button
                    type="button"
                    className="popup_btn_linkaccount"
                    onClick={activateUsers}
                  >
                    Activate
                  </button>

                  <button className="popup_btn_close" data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Deactivate User */}
      <div
        id="deactivateuser"
        className="modal fade partner_details_delete"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ border: "none" }}
          >
            <div className="head_modal"></div>
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <ErrorRoundedIcon className="popup_unlinkbankdetails_icon" />
                </div>
                <div className="pt-2 popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Deactivate this Vendor ?
                  </span>
                </div>
                <div className="pt-3 d-flex" style={{ gap: "10px" }}>
                  <button
                    type="button"
                    className="popup_btn_unlinkaccount"
                    onClick={deactivateUsers}
                  >
                    Deactivate
                  </button>

                  <button className="popup_btn_close" data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorDetailsPage;
