import React, { useEffect, useRef } from "react";
import cenlogo from "../Assets/Img/cenlogo.png";
import { useSelector, useDispatch } from "react-redux";
import {
  selectactivetab,
  setsidebaractivetab,
  setAllTheSiteEng,
  setAllListmanagers,
  selectPOswitch,
  setPOswitch,
  setallprojectStage,
  selectallprojectStage,
  setallPD,
  setallProjects,
  setallProjectIndivusal,
  setallMaterialCategory,
  setTermsncondition,
  selectnotificationCount,
  setnotificationCount,
  setNotificationData,
  selectNotificationData,
  setrequestIdFRomProject,
} from "../features/counter/counterSlice";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import $ from "jquery";
import axiosClientInterceptors from "../ReusableComponent/axiosClentInterceptors";
import { useNavigate } from "react-router";
import NotificationAlert from "./NotificationAlert";
import { useState } from "react";
import {
  setAddVendorScreen,
  setProjectScreen,
  setscreen,
} from "../features/Screen/screen";
import {
  setallprojectpagination,
  setclosedTabPagination,
  setcloseTabFilter,
  setcurentpage,
  setfilterAllrequest,
  Setfilterdata,
  setfilterReceived,
  setinvoiceCurrentpage,
  setmaterials,
  setmaterialuompagination,
  setmatrialPagination,
  setPOfilterData,
  setPOpagination,
  setReceivedpagination,
  setvendorpagination,
  setVendorsDetailsPagination,
} from "../features/pagination/paginationSlice";
import {
  setallCloseCreationDate,
  setallCloseLastActivityDate,
  setAllprojectDaterange,
  setAllRequestDateRange,
  setAllRequestLastActivity,
  setclosedCreationDate,
  setcloseLastActivityDate,
  setPOfilter,
  setreceivedFilter,
} from "../features/DateRangeSlice/DateRangeSlice";

const SideBarProcDept = (
  {
    //  socket, setRequestData
  }
) => {
  const activetabsection = useSelector(selectactivetab);
  const countNotification = useSelector(selectnotificationCount);
  const NotificationDataSlice = useSelector(selectNotificationData);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const audioPlayer = useRef(null);
  function playAudio() {
    audioPlayer.current.play();
  }

  // get list of site
  const getSiteEngineer = () => {
    axiosClientInterceptors.get(`/getsiteengineer`).then((resp) => {
      dispatch(setAllTheSiteEng(resp.data?.Site_Engineers));
    });
  };

  const getmanagers = () => {
    axiosClientInterceptors.get(`/getmanagernames`).then((result) => {
      dispatch(setAllListmanagers(result.data?.Project_Managers));
    });
  };
  const getpakage = () => {
    axiosClientInterceptors.get(`/get-packages`).then((result) => {
      dispatch(setallprojectStage(result?.data.result));
    });
  };
  const getprojectName = () => {
    axiosClientInterceptors.get(`/get-all-project`).then((result) => {
      dispatch(setallProjects(result.data?.result));
    });
  };
  const getcategories = () => {
    axiosClientInterceptors
      .get(`/getcategories`)
      .then((response) => {
        dispatch(setallMaterialCategory(response.data.Category));
      })
      .catch((error) => console.log("error", error));
  };
  const getPD = () => {
    axiosClientInterceptors
      .get(`/get-user-name?user_type=Procurement Department`)
      .then((result) => {
        dispatch(setallPD(result.data?.result));
      });
  };
  const getTermsandCond = () => {
    const headers = {
      "content-type": "multipart/form-data",
    };

    axiosClientInterceptors
      .get(`/get-all-terms-and-conditions`, {
        headers,
      })
      .then((response) => {
        dispatch(setTermsncondition(response.data.result));
      })
      .catch((error) => console.log("Error", error));
  };
  const getcount = () => {
    axiosClientInterceptors
      .get(`/get-activity-notification-count`)
      .then((result) => {
        dispatch(setnotificationCount(result?.data.result[0].count));
        console.log("data", result?.data.result[0].count);
      });
  };
  useEffect(() => {
    getTermsandCond();
    getSiteEngineer();
    getmanagers();
    getpakage();
    getPD();
    getprojectName();
    getcategories();
    getcount();
  }, []);
  useEffect(() => {
    document.title = "Procurement";
    getcount();
  }, [activetabsection]);
  const tabsArray = [
    // {
    //   icone: (
    //     <svg
    //       width="19"
    //       height="19"
    //       viewBox="0 0 19 19"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M9.75791 1.74948C9.88434 1.636 10.0516 1.57652 10.2227 1.58415C13.8413 1.69247 16.8655 4.31934 17.4136 7.83024C17.4171 7.85121 17.4171 7.87259 17.4136 7.89355C17.4254 8.05967 17.3692 8.22356 17.2576 8.34896C17.1459 8.47436 16.9879 8.55095 16.8185 8.56177L10.7389 8.9627C10.5379 8.98053 10.3385 8.91437 10.1897 8.78048C10.041 8.64658 9.95666 8.45737 9.95748 8.25931L9.54883 2.28754V2.18906C9.55627 2.02111 9.63149 1.86296 9.75791 1.74948ZM9.34107 10.5241L14.5898 10.2005H14.6464C14.8727 10.2042 15.0882 10.296 15.2455 10.4556C15.4028 10.6152 15.489 10.8296 15.4852 11.0516C15.2775 14.0774 13.0591 16.6056 10.0402 17.2569C7.02126 17.9083 3.92648 16.5264 2.44417 13.8652C2.0083 13.0987 1.73287 12.2545 1.63403 11.3822C1.5957 11.1237 1.57892 10.8626 1.58385 10.6014C1.59384 7.38393 3.8846 4.60676 7.0899 3.92628C7.47792 3.85365 7.86696 4.04738 8.03625 4.39755C8.11988 4.52365 8.14156 4.67804 8.15141 4.82904C8.2069 5.67935 8.26411 6.5226 8.32109 7.36259C8.3701 8.08509 8.41895 8.80517 8.46641 9.52525C8.46396 9.69488 8.49062 9.86371 8.54527 10.0247C8.67392 10.3413 8.99409 10.5423 9.34107 10.5241Z"
    //         fill="#FCC200"
    //       />
    //     </svg>
    //   ),
    //   name: "Dashboard",
    // },
    {
      icone: (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2479 8.34374C17.1407 8.44787 16.9952 8.50737 16.8421 8.50737C16.2755 8.50737 15.8162 8.95364 15.8162 9.49661C15.8162 10.0433 16.2702 10.4873 16.8314 10.4933C17.1476 10.4963 17.4163 10.7105 17.4163 11.0176V12.9255C17.4163 14.5313 16.0765 15.8337 14.4227 15.8337H11.9267C11.6687 15.8337 11.4597 15.6306 11.4597 15.38V13.7734C11.4597 13.461 11.207 13.2155 10.8855 13.2155C10.5716 13.2155 10.3112 13.461 10.3112 13.7734V15.38C10.3112 15.6306 10.1022 15.8337 9.84498 15.8337H4.57664C2.93053 15.8337 1.58301 14.532 1.58301 12.9255V11.0176C1.58301 10.7105 1.85175 10.4963 2.16795 10.4933C2.72993 10.4873 3.18319 10.0433 3.18319 9.49661C3.18319 8.96852 2.73912 8.56687 2.15723 8.56687C2.00411 8.56687 1.85864 8.50737 1.75145 8.40324C1.64426 8.29911 1.58301 8.15779 1.58301 8.00904V6.08263C1.58301 4.47903 2.93359 3.16699 4.5843 3.16699H9.84498C10.1022 3.16699 10.3112 3.37005 10.3112 3.6207V5.52479C10.3112 5.82974 10.5716 6.08263 10.8855 6.08263C11.207 6.08263 11.4597 5.82974 11.4597 5.52479V3.6207C11.4597 3.37005 11.6687 3.16699 11.9267 3.16699H14.4227C16.0765 3.16699 17.4163 4.46862 17.4163 6.07519V7.94953C17.4163 8.09829 17.3551 8.23961 17.2479 8.34374ZM10.8855 11.7725C11.2071 11.7725 11.4597 11.5196 11.4597 11.2146V8.23948C11.4597 7.93453 11.2071 7.68164 10.8855 7.68164C10.5716 7.68164 10.3113 7.93453 10.3113 8.23948V11.2146C10.3113 11.5196 10.5716 11.7725 10.8855 11.7725Z"
            fill="#909090"
          />
        </svg>
      ),
      name: "Requests",
    },

    // {
    //   icone: (
    //     <svg
    //       width="19"
    //       height="20"
    //       viewBox="0 0 19 20"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fill-rule="evenodd"
    //         clip-rule="evenodd"
    //         d="M14.8098 7.27288C14.8098 8.31135 15.0726 8.92344 15.6509 9.6288C16.0892 10.1484 16.2292 10.8155 16.2292 11.5392C16.2292 12.262 16.0018 12.9482 15.5463 13.5054C14.9499 14.1732 14.1088 14.5996 13.2504 14.6737C12.0064 14.7845 10.7617 14.8778 9.50043 14.8778C8.2384 14.8778 6.99445 14.822 5.75051 14.6737C4.89132 14.5996 4.05022 14.1732 3.45461 13.5054C2.99905 12.9482 2.77087 12.262 2.77087 11.5392C2.77087 10.8155 2.91171 10.1484 3.34918 9.6288C3.94558 8.92344 4.19106 8.31135 4.19106 7.27288V6.92061C4.19106 5.52988 4.52309 4.62049 5.20683 3.73026C6.22338 2.43196 7.85286 1.65332 9.46503 1.65332H9.53584C11.1826 1.65332 12.8648 2.46944 13.8641 3.82353C14.5124 4.69544 14.8098 5.56652 14.8098 6.92061V7.27288ZM7.18337 16.587C7.18337 16.1706 7.54923 15.9799 7.88756 15.8983C8.28332 15.8108 10.6949 15.8108 11.0906 15.8983C11.429 15.9799 11.7948 16.1706 11.7948 16.587C11.7752 16.9834 11.5525 17.3348 11.2449 17.558C10.846 17.8828 10.3778 18.0884 9.88841 18.1626C9.61775 18.1992 9.35181 18.2 9.09059 18.1626C8.60041 18.0884 8.13226 17.8828 7.73413 17.5571C7.4257 17.3348 7.20304 16.9834 7.18337 16.587Z"
    //         fill="#909090"
    //       />
    //     </svg>
    //   ),
    //   name: "Notifications",
    // },
    {
      icone: (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.18292 1.5835H12.8179C15.2633 1.5835 16.625 2.99266 16.625 5.40725V13.5852C16.625 16.0393 15.2633 17.4168 12.8179 17.4168H6.18292C3.77625 17.4168 2.375 16.0393 2.375 13.5852V5.40725C2.375 2.99266 3.77625 1.5835 6.18292 1.5835ZM6.39631 5.27293V5.26501H8.7626C9.10381 5.26501 9.38089 5.5421 9.38089 5.88172C9.38089 6.23085 9.10381 6.50793 8.7626 6.50793H6.39631C6.0551 6.50793 5.77881 6.23085 5.77881 5.89043C5.77881 5.55001 6.0551 5.27293 6.39631 5.27293ZM6.39631 10.086H12.603C12.9434 10.086 13.2205 9.80893 13.2205 9.46851C13.2205 9.12809 12.9434 8.85022 12.603 8.85022H6.39631C6.0551 8.85022 5.77881 9.12809 5.77881 9.46851C5.77881 9.80893 6.0551 10.086 6.39631 10.086ZM6.39651 13.7042H12.6032C12.9191 13.6726 13.1573 13.4026 13.1573 13.0867C13.1573 12.7621 12.9191 12.493 12.6032 12.4613H6.39651C6.15901 12.4376 5.92943 12.5484 5.80276 12.7542C5.6761 12.9521 5.6761 13.2134 5.80276 13.4192C5.92943 13.6171 6.15901 13.7359 6.39651 13.7042Z"
            fill="#909090"
          />
        </svg>
      ),
      name: "All Projects",
    },
    {
      icone: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          width="19"
          height="19"
        >
          <path
            fill-rule="evenodd"
            d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
            clip-rule="evenodd"
            fill="#909090"
          />
          <path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
        </svg>
      ),
      name: "All Vendors",
    },
    {
      icone: (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1793 8.89295H13.3706C13.7028 8.89295 13.9638 8.61826 13.9638 8.28702C13.9638 7.9477 13.7028 7.68109 13.3706 7.68109H11.1793C10.8471 7.68109 10.5861 7.9477 10.5861 8.28702C10.5861 8.61826 10.8471 8.89295 11.1793 8.89295ZM15.973 4.69252C16.4556 4.69252 16.772 4.86218 17.0884 5.23381C17.4048 5.60545 17.4602 6.13866 17.389 6.6226L16.6375 11.9224C16.4951 12.9412 15.6408 13.6917 14.6362 13.6917H6.00581C4.95371 13.6917 4.08356 12.8685 3.99654 11.8021L3.26878 2.99511L2.07429 2.78506C1.75787 2.72851 1.53638 2.41342 1.59175 2.09026C1.64712 1.75983 1.95563 1.54089 2.27996 1.59017L4.16662 1.88021C4.43557 1.92949 4.63334 2.15489 4.65707 2.42958L4.80737 4.23928C4.8311 4.49862 5.03677 4.69252 5.28991 4.69252H15.973ZM5.87909 14.9687C5.21461 14.9687 4.6767 15.5181 4.6767 16.1967C4.6767 16.8673 5.21461 17.4166 5.87909 17.4166C6.53566 17.4166 7.07358 16.8673 7.07358 16.1967C7.07358 15.5181 6.53566 14.9687 5.87909 14.9687ZM14.7784 14.9687C14.1139 14.9687 13.576 15.5181 13.576 16.1967C13.576 16.8673 14.1139 17.4166 14.7784 17.4166C15.435 17.4166 15.9729 16.8673 15.9729 16.1967C15.9729 15.5181 15.435 14.9687 14.7784 14.9687Z"
            fill="#909090"
          />
        </svg>
      ),
      name: "Material Catalogue",
    },
    {
      icone: (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.06614 12.9754H11.3302C11.6515 12.9754 11.918 12.7063 11.918 12.3817C11.918 12.0571 11.6515 11.7958 11.3302 11.7958H7.06614C6.74477 11.7958 6.47827 12.0571 6.47827 12.3817C6.47827 12.7063 6.74477 12.9754 7.06614 12.9754ZM9.71548 7.83746H7.06614C6.74477 7.83746 6.47827 8.10663 6.47827 8.43121C6.47827 8.7558 6.74477 9.01705 7.06614 9.01705H9.71548C10.0369 9.01705 10.3034 8.7558 10.3034 8.43121C10.3034 8.10663 10.0369 7.83746 9.71548 7.83746ZM15.3092 7.14519C15.4935 7.14307 15.6941 7.14075 15.8764 7.14075C16.0723 7.14075 16.2291 7.29909 16.2291 7.497V13.862C16.2291 15.8253 14.6536 17.4166 12.7097 17.4166H6.47042C4.43247 17.4166 2.77075 15.7462 2.77075 13.6878V5.15367C2.77075 3.19034 4.35409 1.58325 6.30582 1.58325H10.4915C10.6953 1.58325 10.852 1.7495 10.852 1.94742V4.49659C10.852 5.94534 12.0356 7.13284 13.47 7.14075C13.8051 7.14075 14.1004 7.14326 14.3589 7.14545C14.56 7.14715 14.7388 7.14867 14.8966 7.14867C15.0082 7.14867 15.1528 7.147 15.3092 7.14519ZM15.5253 5.98981C14.881 5.99218 14.1215 5.98981 13.5751 5.98427C12.7082 5.98427 11.9941 5.26306 11.9941 4.38747V2.30064C11.9941 1.95943 12.4041 1.79002 12.6384 2.03622C13.0624 2.48149 13.645 3.09346 14.225 3.70273C14.8041 4.31104 15.3806 4.91665 15.7941 5.35093C16.023 5.59081 15.8553 5.98902 15.5253 5.98981Z"
            fill="#909090"
          />
        </svg>
      ),
      name: "Purchase Orders",
    },
    {
      icone: (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.1512 10.751C16.4344 10.9014 16.6528 11.1389 16.8065 11.3764C17.1059 11.8672 17.0816 12.4689 16.7904 12.9993L16.224 13.9493C15.9247 14.456 15.3664 14.7727 14.792 14.7727C14.5088 14.7727 14.1933 14.6935 13.9344 14.5352C13.7241 14.4006 13.4813 14.3531 13.2224 14.3531C12.4215 14.3531 11.75 15.0102 11.7257 15.7939C11.7257 16.7043 10.9814 17.4168 10.051 17.4168H8.95066C8.01217 17.4168 7.26784 16.7043 7.26784 15.7939C7.25166 15.0102 6.58015 14.3531 5.77919 14.3531C5.5122 14.3531 5.26949 14.4006 5.06723 14.5352C4.80833 14.6935 4.48471 14.7727 4.20963 14.7727C3.62712 14.7727 3.06887 14.456 2.76953 13.9493L2.21128 12.9993C1.91193 12.4847 1.89575 11.8672 2.1951 11.3764C2.32455 11.1389 2.56726 10.9014 2.84234 10.751C3.06887 10.6402 3.2145 10.4581 3.35204 10.2443C3.75657 9.5635 3.51385 8.66891 2.82616 8.26516C2.0252 7.81391 1.7663 6.8085 2.22746 6.02475L2.76953 5.09058C3.23877 4.30683 4.242 4.02975 5.05105 4.48891C5.75492 4.86891 6.66914 4.61558 7.08176 3.94266C7.21121 3.721 7.28402 3.4835 7.26784 3.246C7.25166 2.93725 7.34066 2.64433 7.49438 2.40683C7.79372 1.916 8.33579 1.59933 8.92639 1.5835H10.0672C10.6658 1.5835 11.2079 1.916 11.5073 2.40683C11.6529 2.64433 11.75 2.93725 11.7257 3.246C11.7095 3.4835 11.7823 3.721 11.9118 3.94266C12.3244 4.61558 13.2386 4.86891 13.9506 4.48891C14.7516 4.02975 15.7629 4.30683 16.224 5.09058L16.7661 6.02475C17.2353 6.8085 16.9764 7.81391 16.1674 8.26516C15.4797 8.66891 15.237 9.5635 15.6496 10.2443C15.779 10.4581 15.9247 10.6402 16.1512 10.751ZM7.21121 9.50808C7.21121 10.751 8.2387 11.7406 9.50891 11.7406C10.7791 11.7406 11.7823 10.751 11.7823 9.50808C11.7823 8.26516 10.7791 7.26766 9.50891 7.26766C8.2387 7.26766 7.21121 8.26516 7.21121 9.50808Z"
            fill="#909090"
          />
        </svg>
      ),
      name: "Configurations",
    },
  ];
  useEffect(() => {
    if (localStorage.getItem("role") === "Procurement Department") {
    } else {
      history("/");
      localStorage.clear();
    }
  }, []);
  var userName = localStorage.getItem("name");
  var role = localStorage.getItem("role");

  const ClearSlice = () => {
    dispatch(setAllRequestDateRange(""));
    dispatch(setAllRequestLastActivity(""));
    dispatch(setreceivedFilter(""));
    dispatch(setallCloseCreationDate(""));
    dispatch(setallCloseLastActivityDate(""));
    dispatch(setAllprojectDaterange(""));
    dispatch(setPOfilter(""));
    dispatch(setclosedCreationDate(""));
    // dispatch(setcloseLastActivityDate(""));==>
    dispatch(setcurentpage(1));
    dispatch(setmaterialuompagination(1));
    dispatch(setinvoiceCurrentpage(1));
    dispatch(Setfilterdata(""));
    dispatch(setallprojectpagination(1));
    dispatch(setReceivedpagination(1));
    dispatch(setfilterAllrequest(""));
    dispatch(setfilterReceived(""));
    dispatch(setmaterials(1));
    dispatch(setVendorsDetailsPagination(1));
    dispatch(setclosedTabPagination(1));
    dispatch(setPOpagination(1));
    dispatch(setPOfilterData(""));
    dispatch(setcloseTabFilter(""));
    dispatch(setmatrialPagination(1));
    dispatch(setvendorpagination(1));
    dispatch(setscreen("listing"));
  };

  // Logout Function
  const Logout = () => {
    dispatch(setsidebaractivetab("Requests"));
    localStorage.clear("username");
    localStorage.removeItem("username");
    dispatch(setsidebaractivetab("Requests"));
    $(".modal-backdrop").remove();
    ClearSlice();
    history("/");
  };
  // useEffect(() => {
  //   socket.on("pd-notification-count", (data) => {
  //     dispatch(setnotificationCount(data[0]?.count));
  //     handleOpen();
  //     playAudio();
  //     setTimeout(() => {
  //       handleClose();
  //     }, 10000);
  //   });
  //   socket.on("pd-get-notification", (data) => {
  //     dispatch(setNotificationData(data));
  //     console.log("data", data);
  //   });
  // }, []);
  return (
    <div style={{ padding: "26px" }}>
      {/* notification start */}
      {/* <NotificationAlert
        setRequestData={setRequestData}
        NotificationDataSlice={NotificationDataSlice}
        handleOpen={handleOpen}
        handleClose={handleClose}
        audioPlayer={audioPlayer}
        playAudio={playAudio}
        open={open}
      /> */}
      {/* notification end */}
      <div className="d-flex align-items-center" style={{ gap: "12px" }}>
        <img className="cendrolImg" alt="..." src={cenlogo} />
        <div>
          <p className="procurementUser m-0">{userName ? userName : ""}</p>
          <p className="ProcurementHeadtxt m-0">{role ? role : ""}</p>
        </div>
      </div>
      <div style={{ marginTop: "29%" }}>
        {tabsArray.map((val, index) => (
          <div
            className={` ${
              activetabsection === val.name
                ? "dashboardtabactive"
                : "dashboardtab"
            } mt-3`}
            key={index}
            onClick={() => {
              dispatch(setsidebaractivetab(val.name));
              dispatch(setPOswitch("maintable"));
              dispatch(setallProjectIndivusal(""));
              dispatch(setrequestIdFRomProject(""));
              dispatch(setscreen("listing"));
              dispatch(setProjectScreen("allusers"));
              dispatch(setAddVendorScreen("listing"));
              // dispatch(setAddVendorScreen(""));
              dispatch(setfilterAllrequest(""));
            }}
          >
            {val.icone}

            <p className="m-0 dashbordtablable" style={{ color: "#000000" }}>
              {val.name}{" "}
              {val.name === "Notifications" && (
                <span
                  className={`${
                    activetabsection === val.name
                      ? "notificationCount"
                      : "notificationCount1"
                  }`}
                >
                  {countNotification > 100 ? "99+" : countNotification}
                </span>
              )}
            </p>
          </div>
        ))}
        <div className="mt-3">
          <button
            className="dashboardtab text-start w-100"
            data-bs-toggle="modal"
            data-bs-target="#logout"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.83349 8.89058C7.48714 8.89058 7.21328 9.15975 7.21328 9.50016C7.21328 9.83266 7.48714 10.1097 7.83349 10.1097H12.6663V13.8939C12.6663 15.8335 11.0634 17.4168 9.08198 17.4168H5.15932C3.1859 17.4168 1.58301 15.8414 1.58301 13.9018V5.10641C1.58301 3.15891 3.19396 1.5835 5.16737 1.5835H9.09809C11.0634 1.5835 12.6663 3.15891 12.6663 5.0985V8.89058H7.83349ZM15.5405 6.76127L17.8521 9.06502C17.9709 9.18377 18.0342 9.33418 18.0342 9.50043C18.0342 9.65877 17.9709 9.8171 17.8521 9.92793L15.5405 12.2317C15.4217 12.3504 15.2634 12.4138 15.113 12.4138C14.9546 12.4138 14.7963 12.3504 14.6776 12.2317C14.4401 11.9942 14.4401 11.6063 14.6776 11.3688L15.9442 10.11H12.6667V8.89085H15.9442L14.6776 7.6321C14.4401 7.3946 14.4401 7.00668 14.6776 6.76918C14.9151 6.52377 15.303 6.52377 15.5405 6.76127Z"
                fill="#909090"
              />
            </svg>
            <p className="m-0 dashbordtablable " style={{ color: "#000000" }}>
              Logout
            </p>
          </button>
        </div>
      </div>
      {/* Logout popup  */}
      <div
        id="logout"
        className="modal fade "
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered ">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <InfoRoundedIcon className="popup_delete_proj_icon" />
                </div>
                <div className="popup_text_center">
                  <h4 className="popup_addbankdetails_text">
                    Are you sure want to Logout.?
                  </h4>
                </div>
                <div className="pt-3 d-flex justify-content-between">
                  <div
                    style={{
                      width: "48%",
                      textAlign: "center",
                    }}
                  >
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      onClick={() => Logout()}
                    >
                      Yes
                    </button>
                  </div>
                  <div
                    style={{
                      width: "48%",
                      textAlign: "center",
                    }}
                  >
                    <button
                      type="button"
                      className="btn popup_btn_close"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <p className="version mt-3">Version:0.2.4</p> */}
    </div>
  );
};

export default SideBarProcDept;
