import React, { useState } from "react";
import { Formik, Form, Field, useField } from "formik";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Dropzone from "react-dropzone";
import File from "../../../Assets/Img/File.png";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ProjectUploadDoc = ({ singleViewId, handleClose, getdata2 }) => {
  const [fileNames, setFileNames] = useState([]);

  // accepting file from dropzone and storing it to state
  const handleDrop = (acceptedFiles) => setFileNames(acceptedFiles[0]);

  // uploading image on click of upload button
  const updatecsvfile = (e) => {
    if (fileNames.length === 0) {
      alert("Please upload the file");
    } else {
      e.preventDefault();
      const formdata = new FormData();
      formdata.append("document", fileNames);

      // posting to api by formdata
      axiosClientInterceptors
        .post(`/uploadDocument?_id=${singleViewId}`, formdata)
        .then((res) => {
          if (res.data.status === "success") {
            handleClose();
            getdata2();
          } else {
            alert("Details are already Exists");
          }
        });
    }
  };
  return (
    <>
      <div className="modal-body pt-0 ">
        <div className="d-flex justify-content-between align-items-center my-3">
          <p className="m-0 Filter_text ">Upload Document</p>
          <CloseIcon onClick={handleClose} />
        </div>
        <Formik
          initialValues={{
            fileNames: "",
          }}
          onSubmit={(data) => {}}
        >
          <div className="App">
            <Form>
              <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <div>
                      <div className="mb-3">
                        <img src={File} width="30%" />
                      </div>
                      <input name="fileNames" {...getInputProps()} />
                      <p className="vendor_bulk_upload_text">
                        Drag & Drop Your File here <br /> or
                      </p>
                    </div>
                    <div>
                      <button className="browse_bulk_vendor">
                        Browse Files
                      </button>
                    </div>
                  </div>
                )}
              </Dropzone>
              {fileNames.name && (
                <div className="file_upload_filename">
                  <strong>File:</strong>
                  <ul>
                    <li>{fileNames.name}</li>
                  </ul>
                </div>
              )}
              <div className="mb-3">
                {/* <span className="mt-3 accepted_file">
                  Accepted File Types : .csv only
                </span> */}
              </div>
              <button
                className="upload_vendor_bulk"
                type="submit"
                onClick={updatecsvfile}
                width="100%"
              >
                Upload Document
              </button>
            </Form>
          </div>
        </Formik>
      </div>
    </>
  );
};

export default ProjectUploadDoc;
