import React, { useEffect } from "react";
import { useState } from "react";
import Popup from "reactjs-popup";
import Delete from "../../../Assets/Img/svg/Delete.svg";
import Documenticon from "../../../Assets/Img/svg/Document.svg";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRequestDataFromRFQID,
  setCancelPoDataFromlisting,
  setFinalpoData,
  setPOswitch,
  setTermsncondition,
} from "../../../features/counter/counterSlice";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import ExistingRow from "./ExistingRow";
import { toast } from "react-toastify";

const TempletPObyReqID = ({ requestId, SocketCall, SocketCallData }) => {
  const dispach = useDispatch();
  const POdata = useSelector(selectRequestDataFromRFQID);
  var MYdata = POdata?.materials?.map((v, ind) => ({
    ...v,
    writable: true,
  }));
  const [dataM, setDataM] = useState(MYdata);
  const [transcheckbox, Settranscheckbox] = useState(false);
  const [loadingcheckbox, Setloadingcheckbox] = useState(false);
  const [discountcheckbox, Setdiscountcheckbox] = useState(false);
  const [transportCharges, setTransportCharges] = useState(
    POdata.transportation_charges
  );
  const [loadingUnloadingCharges, setLoadingUnloadingCharges] = useState(
    POdata.load_unload_charges
  );
  const [discount, setDiscount] = useState(POdata.discount);
  const [loaderPO, setloaderPO] = useState(false);
  const [VendorList, setVendorList] = useState([]);
  const [counterval, setCounterVal] = useState(0);
  const [myArray, setMyArray] = useState(POdata.special_instruction || []);
  const [TotalInvoice, setTotalInvoice] = useState(0);
  const [terms, Setterms] = useState("");
  const [tandcaray, Settandcaray] = useState(POdata.special_instruction || []);
  const [Gtotl, setGtotl] = useState(false);
  const [Vendor, setVendor] = useState("");
  const [vendorDetails, setVendorDetails] = useState("");
  let PURCHASEORDER_DATA = [];

  // Scroll Wheel in input type text
  var inputTypeNumbers = document.querySelectorAll("input[type=number]");
  for (var a = 0; a < inputTypeNumbers.length; a++) {
    inputTypeNumbers[a].onwheel = function (event) {
      event.target.blur();
    };
  }

  const updateFieldValue = (index, fieldName, value) => {
    calculateSubTotle();
    setDataM((prevData) => {
      let newData = [...prevData];
      newData[index][fieldName] = value;

      return newData;
    });
  };
  const calculateSubTotle = () => {
    let subtotal = 0;
    for (let i = 0; i < dataM?.length; i++) {
      subtotal += dataM[i].amount;
    }

    return subtotal;
  };

  // TransportationCharges
  const TransportationCharges = () => {
    let SubTotle_data = calculateSubTotle();
    if (SubTotle_data === 0) {
      return 0;
    } else {
      return Number(transportCharges);
    }
  };
  // Loading & Unloading Charges
  const LoadingUnloadingCharges = () => {
    let SubTotle_data = calculateSubTotle();
    if (SubTotle_data === 0) {
      return 0;
    } else {
      return Number(loadingUnloadingCharges);
    }
  };

  // Discount;
  const Discount = () => {
    console.log(discount);
    return Number(discount);
  };
  // Total Invoice Value
  const TotalInvoiceValue = () => {
    let SubTotle_data = calculateSubTotle();
    let transpotationCharge = TransportationCharges();
    let loadingCharge = LoadingUnloadingCharges();
    let dicount = Discount();

    let InvoiceTotle =
      Number(SubTotle_data || 0) +
      Number(transpotationCharge || 0) +
      Number(loadingCharge || 0);
    setTotalInvoice(InvoiceTotle - Number(dicount || 0));
    console.log(
      InvoiceTotle,
      Number(SubTotle_data || 0),
      Number(transpotationCharge || 0),
      Number(loadingCharge || 0),
      "InvoiceTotle - Number(dicount)"
    );
  };
  const handleDelete = (indexToDelete) => {
    const newArray = [...myArray];
    newArray.splice(indexToDelete, 1);
    setMyArray(newArray);
  };
  const addtandc = (terms) => {
    window.$("#enterterms").modal("hide");
    // handleUpdate;
    setMyArray([...myArray, terms]);
    Settandcaray([...tandcaray, terms]);
    Setterms("");
  };

  useEffect(() => {
    console.log("material_category", POdata?.material_category);
    if (POdata?.material_category) {
      axiosClientInterceptors
        .get(`/get-vendor-list?category=${POdata?.material_category}`)
        .then((resul) => {
          setVendorList(resul.data.result);
          console.log(resul.data.result, "vendor");
        });
    }
    //  setMyArray(poDetails.special_instruction || []);
  }, []);
  function validateArrayOfObjects(arr) {
    let isValid = true;

    for (let i = 0; i < arr?.length; i++) {
      const obj = arr[i];

      if (!obj.hsn_sac || !obj.rate || !obj.sgst || !obj.cgst) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  const postAllData = () => {
    if (!validateArrayOfObjects(dataM)) {
      toast.info(`Please Fill The Details`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (Gtotl == false) {
      toast.info(`Please Click on Grand Total`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("PURCHASEORDER_DATA", PURCHASEORDER_DATA);
      PURCHASEORDER_DATA.push({
        project_id: POdata.project_id,
        purchase_order_no: POdata.purchaseorder_number,
        purchase_order_date: POdata.purchaseorder_date,
        due_date: POdata.due_date,
        materials: dataM,
        material_category: POdata.material_category,
        vendor_company_name: vendorDetails?.company_name,
        vendor_name: vendorDetails?.vendor_name,
        vendor_phone: vendorDetails?.contact_no,
        vendor_email: vendorDetails?.email,
        vendor_address: vendorDetails?.address,
        billing_address: POdata.billing_address,
        shipping_address: POdata.shipping_address,
        site_eng_name: POdata.site_eng_name,
        site_eng_no: POdata.site_eng_no,
        company_gst: POdata.company_gst,
        company_pan: POdata.company_pan,
        vendor_gst: vendorDetails?.gst_number,
        vandor_pan: vendorDetails?.vandor_pan,
        grand_total: TotalInvoice,
        sub_total: calculateSubTotle(),
        discount: discount,
        transportation_charges: transportCharges,
        special_instruction: myArray,
        request_id: POdata.request_id,
        // gst: gstcharges,
        load_unload_charges: loadingUnloadingCharges,
        RFQ_id: "",
        vendor_id: Vendor,
      });
      setloaderPO(true);
      axiosClientInterceptors
        .post(`/addpurchaseorder`, PURCHASEORDER_DATA[0])
        .then((aldata) => {
          if (aldata.data.msg === "PO already exiist!") {
            setloaderPO(false);
            toast.info(`PO already exiist!`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            dispach(setFinalpoData(aldata.data.Purchase_Orders[0]));

            axiosClientInterceptors
              .get(`/getpurchasebyid?_id=${aldata.data.Purchase_Orders[0]._id}`)
              .then((responsedata) => {
                axiosClientInterceptors
                  .get(`/generate-po?_id=${aldata.data.Purchase_Orders[0]._id}`)
                  .then(() => { });
                dispach(setPOswitch("PurchaseOrderDOc"));
                SocketCall(
                  "admin-notification-count",
                  null,
                  null,
                  "PO Created"
                );
                SocketCall("pm-notification-count", null, null, "PO Created");
                SocketCall("pd-notification-count", null, null, "PO Created");
                SocketCallData(
                  "admin-get-notification",
                  responsedata.data.Purchase_Orders[0].req_id,
                  "PO Created"
                );
                SocketCallData(
                  "pm-get-notification",
                  responsedata.data.Purchase_Orders[0].req_id,
                  "PO Created"
                );
                SocketCallData(
                  "pd-get-notification",
                  responsedata.data.Purchase_Orders[0].req_id,
                  "PO Created"
                );

                dispach(setFinalpoData(responsedata.data.Purchase_Orders[0]));
                console.log(
                  "from ",
                  responsedata.data.Purchase_Orders[0].req_id
                );
                dispach(
                  setTermsncondition(responsedata.data.termsandcondition)
                );
                setloaderPO(false);
              });
          }
        });
    }
  };
  useEffect(() => {
    console.log("VendorID", Vendor);
    axiosClientInterceptors.get(`/getvendorbyid?_id=${Vendor}`).then((res) => {
      setVendorDetails(res.data.Vendor[0]);
      console.log(res.data.Vendor[0], "res.data.Vendor[0]");
      // setVendorCompanyName(res.data.Vendor[0]?.company_name);
      // setVendorPAN(res.data.Vendor[0]?.vandor_pan);
    });
  }, [Vendor]);
  useEffect(() => {
    if (POdata.discount > 0) {
      Setdiscountcheckbox(true);
    }
  }, []);
  useEffect(() => {
    TransportationCharges();
    LoadingUnloadingCharges();
  }, [transcheckbox, loadingcheckbox]);

  return (
    <div className="p-3 create_po_page">
      <div className="d-flex justify-content-between align-items-center">
        <p className="m-0">
          <span
            className="fontdocument_dim"
            onClick={() => {
              dispach(setPOswitch("maintable"));
              dispach(setCancelPoDataFromlisting([]));
            }}
          >
            Purchase Order list
          </span>
          / <span className="fontdocument_active">Purchase Order</span>
        </p>
      </div>
      <hr />
      {/* 1st */}
      <div className="PurchaseOrderDetails p-3">
        <p
          className="PurchaseOrderDetails_text"
          onClick={() => console.log(POdata)}
        >
          Purchase Order Details
        </p>
        <hr />
        <div className="row p-2">
          <div className="col-2">
            <p
              className=" PurchaseOrderDetails_subtext"
              style={{ marginBottom: "10px" }}
            >
              Purchase Order Date
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {POdata?.purchaseorder_date}
            </p>
          </div>
          <div className="col-1">
            <p
              className=" PurchaseOrderDetails_subtext  "
              style={{ marginBottom: "10px" }}
            >
              Project ID
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {POdata?.project_id}
            </p>
          </div>
          <div className="col-2">
            <p
              className=" PurchaseOrderDetails_subtext  "
              style={{ marginBottom: "10px" }}
            >
              PAN
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {POdata?.company_pan}
            </p>
          </div>
          <div className="col-2">
            <p
              className=" PurchaseOrderDetails_subtext"
              style={{ marginBottom: "10px" }}
            >
              GST
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {POdata?.company_gst}
            </p>
          </div>
          <div className="col-3">
            <p
              className=" PurchaseOrderDetails_subtext"
              style={{ marginBottom: "10px" }}
            >
              Purchase Order Number
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {POdata?.purchaseorder_number}
            </p>
          </div>
          <div className="col-2">
            <p
              className=" PurchaseOrderDetails_subtext"
              style={{ marginBottom: "10px" }}
            >
              Due Date
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {POdata.due_date}
            </p>
          </div>
        </div>
      </div>
      <div className="PurchaseOrderDetails p-3 mt-4">
        <p className="PurchaseOrderDetails_text">Vendor Details</p>
        <hr />
        <div className="row p-2">
          <div className="col-2">
            <p
              className=" PurchaseOrderDetails_subtext "
              style={{ marginBottom: "10px" }}
            >
              Company Name
            </p>

            <select
              class="form-select "
              aria-label="Default select example"
              style={{
                backgroundColor: "white",
                border: "none",
                fontFamily: "Gm",
                fontSize: "14px",
                color: "#969696",
              }}
              onChange={(e) => setVendor(e.target.value)}
            >
              <option selected disabled>
                Choose Company
              </option>
              {VendorList?.map((re, index) => {
                return <option value={re._id}>{re?.company_name}</option>;
              })}
            </select>
          </div>
          <div className="col-2">
            <p
              className=" PurchaseOrderDetails_subtext"
              style={{ marginBottom: "10px" }}
            >
              Full Name
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {vendorDetails?.vendor_name || "-"}
            </p>
          </div>
          <div className="col-2">
            <p
              className=" PurchaseOrderDetails_subtext"
              style={{ marginBottom: "10px" }}
            >
              PAN
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {vendorDetails?.vandor_pan || "-"}
            </p>
          </div>
          <div className="col-2">
            <p
              className=" PurchaseOrderDetails_subtext"
              style={{ marginBottom: "10px" }}
            >
              GST
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {vendorDetails?.gst_number || "-"}
            </p>
          </div>
          <div className="col-2">
            <p
              className=" PurchaseOrderDetails_subtext"
              style={{ marginBottom: "10px" }}
            >
              Email ID
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {vendorDetails?.email || "-"}
            </p>
          </div>
          <div className="col-2">
            <p
              className=" PurchaseOrderDetails_subtext"
              style={{ marginBottom: "10px" }}
            >
              Phone Number
            </p>
            <p className="m-0 PurchaseOrderDetails_subtext1">
              {vendorDetails?.contact_no
                ? `+91${vendorDetails?.contact_no}`
                : "-"}
            </p>
          </div>
        </div>
      </div>
      <div className=" mt-4 d-flex justify-content-between align-items-start">
        <div className="boxOne p-3">
          <p className="PurchaseOrderDetails_text">Site Engineer Details</p>
          <hr />
          <div className="py-2">
            <p className=" FullName" style={{ marginBottom: "6px" }}>
              Full Name
            </p>
            <p className=" posubtitle">{POdata?.site_eng_name}</p>
          </div>
          <div className="py-2">
            <p className="  FullName" style={{ marginBottom: "6px" }}>
              Phone Number
            </p>
            <p className=" posubtitle">{POdata?.site_eng_no}</p>
          </div>
        </div>
        <div className="boxTwo  p-3">
          <p className="PurchaseOrderDetails_text">Address</p>
          <hr />
          <div className="row">
            <div className="col-4">
              <p className="FullName" style={{ marginBottom: "10px" }}>
                Shipping Address
              </p>
              <div className="pocontent_container">
                <p>{POdata?.shipping_address}</p>
              </div>
            </div>
            <div className="col-4">
              <p className="  FullName" style={{ marginBottom: "10px" }}>
                Billing Address
              </p>
              <div className="pocontent_container">
                <p>{POdata?.billing_address}</p>
              </div>
            </div>
            <div className="col-4">
              <p className="  FullName" style={{ marginBottom: "10px" }}>
                Vendor Address
              </p>
              <div className="pocontent_container">
                <p>{vendorDetails?.address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* table PO */}
      <div className="PurchaseOrderDetails p-3 mt-4">
        <p className="PurchaseOrderDetails_text">Material Details</p>
        <div className="mt-3">
          <table className="table leaderboard_table">
            <tbody>
              <tr style={{ backgroundColor: "#FAC40F", color: "black" }}>
                <th
                  className="text-center"
                  style={{ backgroundColor: "#FAC40F", color: "black" }}
                >
                  Sl.No
                </th>
                <th
                  style={{ backgroundColor: "#FAC40F", color: "black" }}
                  className="text-center"
                >
                  Material Name
                </th>
                <th
                  style={{ backgroundColor: "#FAC40F", color: "black" }}
                  className="text-center"
                >
                  HSN/SAC
                </th>
                <th
                  style={{ backgroundColor: "#FAC40F", color: "black" }}
                  className="text-center"
                >
                  UOM
                </th>
                <th
                  style={{ backgroundColor: "#FAC40F", color: "black" }}
                  className="text-center"
                >
                  Quantity
                </th>
                <th
                  style={{ backgroundColor: "#FAC40F", color: "black" }}
                  className="text-center"
                >
                  Rate
                </th>
                <th
                  style={{ backgroundColor: "#FAC40F", color: "black" }}
                  className="text-center"
                >
                  CGST
                </th>
                <th
                  style={{ backgroundColor: "#FAC40F", color: "black" }}
                  className="text-center"
                >
                  SGST
                </th>
                <th
                  style={{ backgroundColor: "#FAC40F", color: "black" }}
                  className="text-center"
                >
                  Total Amount
                </th>
              </tr>
              {dataM?.map((value, index, arr) => {
                return (
                  <>
                    <ExistingRow
                      editable={true}
                      value={value}
                      updateFieldValue={updateFieldValue}
                      index={index}
                    />
                  </>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-end align-items-center mt-2">
          <div
            className="d-flex align-items-center"
            style={{
              marginRight: `${calculateSubTotle() > 0 ? "6.5%" : "9.5%"}`,
              padding: "7px 0px",
            }}
          >
            <p className="my-0 UnloadingChargestext ">Sub Total : </p>
            <p className="my-0 mx-2 UnloadingChargestext">
              {Number.isNaN(calculateSubTotle())
                ? 0
                : calculateSubTotle().toFixed(2)}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="d-flex align-items-center"
            style={{ padding: "10px 10px 10px 0px" }}
          >
            <input
              type="checkbox"
              className="me-2"
              // defaultChecked={poDetails.transportation_charges === 0}
              //   defaultChecked={poDetails.transportation_charges === 0}
              onChange={(e) => {
                Settranscheckbox(e.target.checked);
                e.target.checked && setTransportCharges(0);
              }}
            />
            <p className="UnloadingChargestext">Transportation Charges</p>
          </div>

          <div className="d-flex align-items-center">
            <p className="UnloadingChargestext">Transportation Charges :</p>
            <div className="Included mx-1">
              {transcheckbox ? (
                <div className="input_fields_tags">Included</div>
              ) : (
                <input
                  type="text"
                  //   value={poDetails.transportation_charges}
                  defaultValue={Number(POdata.transportation_charges) || 0}
                  className="input_fields_tags"
                  onChange={(e) => {
                    setCounterVal(counterval + 1);
                    setTransportCharges(Number(e.target.value));
                  }}
                  onKeyDown={(event) => {
                    const pattern = /^\d{0,10}(\.\d{0,10})?$/;
                    const currentValue = event.target.value;
                
                    if(event.keyCode === 8 || event.keyCode ===37 || event.keyCode===39 ){
                      return
                    }

                    const key = event.key;
                    const newValue = currentValue + key;
                    if (!pattern.test(newValue)) {
                      event.preventDefault(); 
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className="me-2"
              //   defaultChecked={poDetails.load_unload_charges === 0}
              onChange={(e) => {
                Setloadingcheckbox(e.target.checked);
                e.target.checked && setLoadingUnloadingCharges(0);
              }}
            />
            <p className="UnloadingChargestext">Loading & Unloading Charges</p>
          </div>

          <div className="d-flex align-items-center">
            <p className="UnloadingChargestext">
              Loading & Unloading Charges :
            </p>
            <div className="Included mx-1">
              {loadingcheckbox ? (
                <div className="input_fields_tags">Included</div>
              ) : (
                <input
                  defaultValue={Number(POdata.load_unload_charges) || 0}
                  onChange={(e) => {
                    setLoadingUnloadingCharges(Number(e.target.value));
                  }}
                  type="text"
                  className="input_fields_tags"
                  onKeyDown={(event) => {
                    const pattern = /^\d{0,10}(\.\d{0,10})?$/;
        
                    // Get the current input value
                    const currentValue = event.target.value;
                    if(event.keyCode === 8 || event.keyCode ===37 || event.keyCode===39 ){
                      return
                    }
                    // Get the key that the user is trying to input
                    const key = event.key;
                    // Construct the new value by appending the key to the current value
                    const newValue = currentValue + key;
                    // Check if the new value matches the pattern
                    if (!pattern.test(newValue)) {
                      event.preventDefault(); // Prevent the invalid input
                    }
                  }}
                />
              )}

              {/* </p> */}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-2">
          <div
            className="d-flex align-items-center"
            style={{ marginTop: `${discountcheckbox ? "6px" : "9px"}` }}
          >
            <input
              type="checkbox"
              className="me-2"
              //   defaultChecked={poDetails?.discount > 0}
              // checked={poDetails.load_unload_charges === 0}
              onChange={(e) => {
                Setdiscountcheckbox(e.target.checked);
                !e.target.checked && setDiscount(0);
              }}
            />
            <p className="UnloadingChargestext">Discount</p>
          </div>
          {discountcheckbox && (
            <div className="d-flex align-items-center">
              <p className="UnloadingChargestext">Discount :</p>
              <div className="Included mx-1">
                <input
                  defaultValue={Number(POdata.discount) || 0}
                  onChange={(e) => {
                    setDiscount(Number(e.target.value));
                  }}
                  type="text"
                  className="input_fields_tags"
                  onKeyDown={(event) => {
                    const pattern = /^\d{0,10}(\.\d{0,10})?$/;
        
                    // Get the current input value
                    const currentValue = event.target.value;
                    if(event.keyCode === 8 || event.keyCode ===37 || event.keyCode===39 ){
                      return
                    }
                    // Get the key that the user is trying to input
                    const key = event.key;
                    // Construct the new value by appending the key to the current value
                    const newValue = currentValue + key;
                    // Check if the new value matches the pattern
                    if (!pattern.test(newValue)) {
                      event.preventDefault(); // Prevent the invalid input
                    }
                  }}
                />

                {/* </p> */}
              </div>
            </div>
            //
          )}
        </div>

        <div className="d-flex justify-content-between align-self-center mt-2">
          <div className="d-flex align-items-center">
            {/* <input
              type="checkbox"
              className="me-2"
              onChange={(e) => Setloadingcheckbox(e.target.checked)}
            /> */}
            {/* <p className="UnloadingChargestext">IGST</p> */}
          </div>
          <button
            disabled={!validateArrayOfObjects(dataM)}
            className="d-flex align-items-center  CalculateGrandTotal"
            onClick={() => {
              if (!validateArrayOfObjects(dataM)) {
              } else {
                setGtotl(true);
                TotalInvoiceValue();
              }
            }}
          >
            Calculate Grand Total
          </button>
        </div>
        <div className="d-flex justify-content-end align-items-center mt-4">
          <div className="d-flex align-items-center">
            <p
              className="my-0 UnloadingChargestext"
              style={{ fontWeight: "600" }}
            >
              Total Invoice Value :
            </p>
            <p
              className="my-0 mx-1 UnloadingChargestext"
              style={{ fontWeight: "600" }}
            >
              ₹ {TotalInvoice?.toFixed(2)} /-
            </p>
          </div>
        </div>
      </div>
      <div className="PurchaseOrderDetails p-3 mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <p className="PurchaseOrderDetails_text">Payment Terms</p>
          <div
            className="UnloadingChargestext"
            data-bs-toggle="modal"
            data-bs-target="#enterterms"
          >
            <span style={{ color: "#FCC200", cursor: "pointer" }}>
              + Add New Term
            </span>
          </div>
        </div>
        <hr />

        {/* {tandcaray.map((in,index)=>{
             return (<p>{in}</p>)
            })} */}

        {myArray?.map((k, index) => {
          return (
            <div className="d-flex justify-content-between align-items-center notecontainer mt-2">
              <div className="w-100 ">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <p key={index} className="mb-0 p-2">
                    {k}
                  </p>
                  <div
                    className="d-flex justify-content-center align-items-center Delete_bg"
                    onClick={() => handleDelete(index)}
                  >
                    <img src={Delete} className="me-2" />
                    <p>Delete</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Popup
        modal
        className="poup_scroll_imp fade popup-overlaypo "
        trigger={
          <button
            className="create_purchase_order_btn d-flex align-items-center mb-3 mt-3"
            disabled={Vendor === ""}
          >
            <img src={Documenticon} className="me-2" />
            <p>Save & View Pdf</p>
          </button>
        }
        style={{
          background: "rgb(0,0,0,0.5)",
          width: "25% !important",
        }}
      >
        {(close) => (
          <div>
            <button
              id="closematerialpopup"
              className="close_btn_popup_edit"
              onClick={close}
              style={{
                background: "none",
                border: "none",
                borderRadius: "50%",
                marginRight: "10px",

                fontSize: "24px",
              }}
            >
              &times;
            </button>
            <div>
              <div className="p-4">
                <div className="popup_icon_center">
                  <InfoRoundedIcon
                    className="PO_cancel_icon "
                    style={{
                      color:
                        "linear-gradient(90.11deg, #FFDB6C -9.01%, #F7B314 127.45%)",
                      transform: "rotate(180deg)",
                    }}
                  />
                </div>
                <div className="popup_text_center">
                  <h4 className="popup_addbankdetails_text">
                    Are you sure want to Create this Purchase Order..?
                  </h4>
                </div>
                {loaderPO ? (
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <CircularProgress size={20} color="inherit" />
                  </div>
                ) : (
                  <div className="pt-3 d-flex">
                    <div style={{ width: "50%", textAlign: "center" }}>
                      <button
                        type="button"
                        className="create_purchase_order_btn"
                        onClick={postAllData}
                        style={{
                          width: "80%",
                          fontSize: "14px",
                          background:
                            "162.29deg, #FFE185 -6.85%, #F7BA00 100.08%",
                        }}
                      >
                        Create
                      </button>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        textAlign: "center",
                        color: "#000",
                      }}
                    >
                      <button
                        type="button"
                        className="btn popup_btn_close"
                        data-bs-dismiss="modal"
                        style={{
                          color: "#000",
                          background: "#f4f4f4",
                        }}
                        onClick={close}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Popup>
      {/* popup to add terms and condtions */}
      <div
        className="modal fade"
        id="enterterms"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header" style={{ borderBottom: "none" }}>
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                Add Payment Terms
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pt-0">
              <div className="form_invoice_upload">
                <form>
                  <div className="row">
                    <div className="col-12">
                      <textarea
                        type="text"
                        required
                        className="invoice_upload_form_input p-3"
                        placeholder="Enter Payment Terms"
                        name="terms_conditions"
                        value={terms}
                        onChange={(e) => {
                          Setterms(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    {/* <Link to="/purchaseorder" className=" "> */}
                    <button
                      className="all_invoice mt-3 text-center w-100"
                      onClick={() => addtandc(terms)}
                      type="submit"
                      disabled={terms === ""}
                    >
                      Submit
                    </button>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TempletPObyReqID;
