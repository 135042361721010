import React from "react";
import SideBarAdmin from "../../ReusableComponent/SideBarAdmin";
import {
  selectactivetab,
} from "../../features/counter/counterSlice";
import { useDispatch, useSelector } from "react-redux";

import Requests from "../../Component/Admin/AdminRequests/Requests";
import AllProjectsAdmin from "../../Component/Admin/AllProject/AllProjectsAdmin";

import Logout from "../Login/Logout";
import PoAdmin from "./PoAdmin/PoAdmin";
import Configurations from "./Configurations/Configurations";
import DashboardAdmin from "./DashboardAdmin/DashboardAdmin";
import Notification from "./Notification/Notification";
import { useState } from "react";
import { useEffect } from "react";
import AdminMaterialCatlog from "./MaterialCatalog/AdminMaterialCatlog";
import Vendor from "./Vendor/Vendor";
import ManageUsers from "./ManageUsers/ManageUsers";

const AdminDash = ({ SocketCall, socket, SocketCallData }) => {
  const dispatch = useDispatch();
  const activetabsection = useSelector(selectactivetab);
  const [requestdata, setRequestData] = useState("listing");

  return (
    <div className="d-flex">
      <div className="sidlebarcontainer">
        <SideBarAdmin
        // socket={socket}
        // SocketCall={SocketCall}
        // setRequestData={setRequestData}
        />
      </div>
      {activetabsection === "Dashboard" && (
        <div className="dashboardtabsection">
          <DashboardAdmin SocketCall={SocketCall} />
        </div>
      )}
      {activetabsection === "Requests" && (
        <div className="dashboardtabsection">
          <Requests
            // SocketCallData={SocketCallData}
            // SocketCall={SocketCall}
            setRequestData={setRequestData}
            requestdata={requestdata}
          // socket={socket}
          />
        </div>
      )}
      {activetabsection === "Notifications" && (
        <div className="dashboardtabsection">
          <Notification setRequestData={setRequestData} />
        </div>
      )}
      {activetabsection === "All Projects" && (
        <div className="dashboardtabsection">
          <AllProjectsAdmin
            setRequestData={setRequestData}
            requestdata={requestdata}
          />
        </div>
      )}
      {activetabsection === "Contractors" && (
        <div className="dashboardtabsection">Contractors</div>
      )}
      {activetabsection === "Material Catalogue" && (
        <div className="dashboardtabsection">
          <AdminMaterialCatlog />
        </div>
      )}

      {activetabsection === "All Vendors" && (
        <div className="dashboardtabsection">
          <Vendor
            SocketCall={SocketCall}
            SocketCallData={SocketCallData}
          />
        </div>
      )}
      {activetabsection === "Purchase Orders" && (
        <div className="dashboardtabsection">
          <PoAdmin
          // SocketCall={SocketCall} SocketCallData={SocketCallData}
          />
        </div>
      )}

      {activetabsection === "Projects" && (
        <div className="dashboardtabsection">Projects</div>
      )}
      {activetabsection === "Manage Users" && (
        <div className="dashboardtabsection">
          <ManageUsers />
        </div>
      )}
      {activetabsection === "Configurations" && (
        <div className="dashboardtabsection">
          <Configurations />
        </div>
      )}
      {activetabsection === "Logout" && (
        <div className="dashboardtabsection">
          <Logout />
        </div>
      )}
    </div>
  );
};

export default AdminDash;
