import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentpage: 1,
  // filter All projects
  invoiceCurrentpage: 1,
  allprojectpagination: 1,
  Receivedpagination: 1,
  materials: 1,
  filterdata: "",
  filterAllrequest: "",
  filterReceived: "",
  VendorsDetailsPagination: 1,
  closedTabPagination: 1,
  POpagination: 1,
  POfilterData: "",
  POPage: 1,
  POPerPage: 10,
  POTotalCount: 0,
  closeTabFilter: "",
  matrialPagination: 1,
  vendorpagination: 1,
  materialuompagination: 1,
  vendorFilter: [],
};

export const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setvendorFilter: (state, action) => {
      state.vendorFilter = action.payload;
    },
    setcurentpage: (state, action) => {
      state.currentpage = action.payload;
    },
    setmaterialuompagination: (state, action) => {
      state.materialuompagination = action.payload;
    },
    setinvoiceCurrentpage: (state, action) => {
      state.invoiceCurrentpage = action.payload;
    },
    Setfilterdata: (state, action) => {
      state.filterdata = action.payload;
    },
    setallprojectpagination: (state, action) => {
      state.allprojectpagination = action.payload;
    },
    setReceivedpagination: (state, action) => {
      state.Receivedpagination = action.payload;
    },
    setfilterAllrequest: (state, action) => {
      state.filterAllrequest = action.payload;
    },
    setfilterReceived: (state, action) => {
      state.filterReceived = action.payload;
    },
    setmaterials: (state, action) => {
      state.materials = action.payload;
    },
    setVendorsDetailsPagination: (state, action) => {
      state.VendorsDetailsPagination = action.payload;
    },
    setclosedTabPagination: (state, action) => {
      state.closedTabPagination = action.payload;
    },
    setPOpagination: (state, action) => {
      state.POpagination = action.payload;
    },
    setPOfilterData: (state, action) => {
      state.POfilterData = action.payload;
    },
    setcloseTabFilter: (state, action) => {
      state.closeTabFilter = action.payload;
    },
    setmatrialPagination: (state, action) => {
      state.matrialPagination = action.payload;
    },
    setvendorpagination: (state, action) => {
      state.vendorpagination = action.payload;
    },
    setPOPage: (state, action) => {
      state.POPage = action.payload
    },
    setPOPerPage: (state, action) => {
      state.POPerPage = action.payload
    },
    setPOTotalCount: (state, action) => {
      state.POTotalCount = action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});

export const {
  setcurentpage,
  Setfilterdata,
  setinvoiceCurrentpage,
  setallprojectpagination,
  setReceivedpagination,
  setfilterAllrequest,
  setmaterials,
  setVendorsDetailsPagination,
  setfilterReceived,
  setclosedTabPagination,
  setPOpagination,
  setPOfilterData,
  setcloseTabFilter,
  setmatrialPagination,
  setvendorpagination,
  setmaterialuompagination,
  setvendorFilter,
  setPOPage,
  setPOPerPage,
  setPOTotalCount
} = paginationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectcurrentpage = (state) => state.pagination.currentpage;
export const selectinvoiceCurrentpage = (state) =>
  state.pagination.invoiceCurrentpage;
export const selectmaterialuompagination = (state) =>
  state.pagination.materialuompagination;
export const selectallprojectpagination = (state) =>
  state.pagination.allprojectpagination;
export const selectfilterdata = (state) => state.pagination.filterdata;
export const selectReceivedpagination = (state) =>
  state.pagination.Receivedpagination;
export const selectfilterAllrequest = (state) =>
  state.pagination.filterAllrequest;
export const selectmaterials = (state) => state.pagination.materials;
export const selectVendorsDetailsPagination = (state) =>
  state.pagination.VendorsDetailsPagination;
export const selectfilterReceived = (state) => state.pagination.filterReceived;
export const selectclosedTabPagination = (state) =>
  state.pagination.closedTabPagination;
export const selectPOfilterData = (state) => state.pagination.POfilterData;

export const selectPOpagination = (state) => state.pagination.POpagination;
export const selectcloseTabFilter = (state) => state.pagination.closeTabFilter;
export const selectmatrialPagination = (state) =>
  state.pagination.matrialPagination;
export const selectvendorpagination = (state) =>
  state.pagination.vendorpagination;
export const selectvendorFilter = (state) => state.pagination.vendorFilter;
export const selectPOPage = (state) => state.pagination.POPage;
export const selectPOPerPage = (state) => state.pagination.POPerPage;
export const selectPOTotalCount = (state) => state.pagination.POTotalCount
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export default paginationSlice.reducer;
