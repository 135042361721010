import React, { useEffect } from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { useNavigate } from "react-router-dom";
import {
  selectactivetab,
  setsidebaractivetab,
} from "../../features/counter/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { setfilterAllrequest } from "../../features/pagination/paginationSlice";

const Logout = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const activetabsection = useSelector(selectactivetab);

  // useEffect(() => {
  //   window.$("#logout").modal("show");
  // }, []);

  function logout() {
    localStorage.clear();
    history("/");
    dispatch(setsidebaractivetab("Requests"));
    dispatch(setfilterAllrequest(""));
  }

  useEffect(() => {
    if (activetabsection === "Logout") {
      window.$("#logout").modal("show");
    }
  }, [activetabsection]);

  return (
    <div>
      <div
        id="logout"
        className="modal fade partner_details_deleter"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered ">
          <div
            className="modal-content modal-sm modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <InfoRoundedIcon className="popup_unlinkbankdetails_icon" />
                </div>
                <div className="popup_text_center">
                  <span className="popup_addbankdetails_text">
                    Are you sure want to Logout?
                  </span>
                </div>
                <div
                  className="pt-3 d-flex justify-content-center"
                  style={{ gap: "10px" }}
                >
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      data-bs-toggle="modal"
                      data-bs-target=".partner-details-deleted-success"
                      data-bs-dismiss="modal"
                      onClick={() => logout()}
                    >
                      Logout
                    </button>
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <button className="popup_btn_close" data-bs-dismiss="modal">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
