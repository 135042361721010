import React from "react";
import "./notification.css";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import NotificationCard from "../../../ReusableComponent/NotificationCard";
import { useEffect } from "react";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectnotificationCount } from "../../../features/counter/counterSlice";

const Notification = ({ setRequestData }) => {
  const [Notification, setNotification] = useState([]);
  const countNotification = useSelector(selectnotificationCount);

  const getAllnotification = () => {
    const headers = {
      "content-type": "multipart/form-data",
    };
    axiosClientInterceptors
      .get(`/get-activity-notifications`, {
        headers,
      })
      .then((response) => {
        setNotification(response.data.result);
      })
      .catch((error) => console.log("Error", error));
  };
  const ViewNotification = (ID) => {
    const headers = {
      "content-type": "multipart/form-data",
    };
    axiosClientInterceptors
      .get(`/set-viewed-activity-notification?notification_id=${ID}`, {
        headers,
      })
      .then((response) => {
        getAllnotification();
      })
      .catch((error) => console.log("Error", error));
  };
  useEffect(() => {
    getAllnotification();
  }, []);
  return (
    <div className="Notification">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="Notification_title">All Notifications</p>
          <p className="Notification_count mt-0">
            {countNotification} unread notifications
          </p>
        </div>
      </div>
      <hr />

      <div className="notification_subContainer">
        {Notification.map((val, i) => (
          <>
            <div className="d-flex justify-content-between align-items-center mt-2">
              {" "}
              <p className="dateFormat">Date : {val.date}</p>
              <p className="dateFormat">{val.count} Notifications</p>
            </div>

            {val?.notification.map((values, i) => (
              <NotificationCard
                values={values}
                setRequestData={setRequestData}
                ViewNotification={ViewNotification}
              />
            ))}
          </>
        ))}
      </div>
    </div>
  );
};

export default Notification;
