import {
  startOfDay,
  endOfDay,
  addMonths,
  addYears,
  subMonths,
  subYears,
} from "date-fns";

export function validateempid(value) {
  let error;
  if (!value) {
    error = "Employee ID is Required";
  } else if (!/^(?=(?:[a-z]*\d){3})(?=(?:\d*[a-z]){3})\w{6}$/i.test(value)) {
    error = "Employee ID It should contain Alphabets and Numbers";
  } else if (value.length > 25) {
    error = "Employee ID Should not more than 6";
  } else if (value.length < 3) {
    error = "Employee ID Should not less than 3 Characters";
  }
  return error;
}

export function validateuserid(value) {
  let error;
  if (!value) {
    error = "User Id is Required";
  } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value)) {
    error = "Invalid UserId";
  }
  return error;
}
export function validateConstructionEnd(value) {
  let error;
  if (!value || value === "Invalid date") {
    error = "Date of Construction is Required";
  }
  return error;
}

// export function validatepass(value) {
//   let error;
//   if (!value) {
//     error = "Password is Required";
//   } else if (!/^.{8}$/.test(value)) {
//     error = "Minimum length should be 8 character";
//   } else if (!/^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z]+$/.test(value)) {
//     error = "Must include Uppercase or Lowercase   character";
//   } else if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/.test(value)) {
//     error = "Must include 1 Number or  character";
//   } else if (!/^(?=.*[@#*$%^&+=!])(?=\S+$).+$/.test(value)) {
//     error = "Must include 1 special character";
//   }
//   return error;
// }
export function validatepass(value) {
  let error;
  if (!value) {
    error = "Password is Required";
  } else if (
    !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#?])[a-zA-Z\d!@#?]{8,100}$/.test(
      value
    )
  ) {
    error = "invalid password";
  }
  return error;
}

export function validateusertype(value) {
  let error;
  if (!value) {
    error = "User type is Required";
  }
  return error;
}

export function validatemob(value) {
  let error;
  if (!value) {
    error = "Mobile Number is Required";
  } else if (value.length > 10) {
    error = "Mobile Number Should not more than 10";
  } else if (value.length < 10) {
    error = "Mobile Number Should not less than 10 ";
  } else if (
    !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
  ) {
    error = "Invalid Mobile Number";
  }
  return error;
}
export function validateCompanyName(value) {
  let error;
  if (!value) {
    error = "Company Name is Required";
  } else if (!/^[A-Za-z0-9!@#$%^&*)(+=._,-\s]+$/i.test(value)) {
    error = "Company Name It should contain only Alphabets";
  } else if (value.length > 100) {
    error = "CompanyName Should not more than 15";
  } else if (value.length < 3) {
    error = "Company Name Should not less than 3 Characters";
  }
  return error;
}
export function ValidatePan(value) {
  let error;
  if (!value) {
    error = "Pan is Required";
  } else if (!/^[A-Za-z0-9]+$/i.test(value)) {
    error = "Enter a Valid Pan";
  } else if (value.length > 10) {
    error = "Pan Should not more than 10";
  } else if (value.length < 10) {
    error = "Pan Should not less than 10 Characters";
  }
  return error;
}

export function validateMaterialName(value) {
  let error;
  if (!value) {
    error = "Material Name is Required";
  } else if (!/^[A-Za-z0-9!@#$%^&*)(+=._,-\s]+$/i.test(value)) {
    error = "Material Name It should contain only Alphabets";
  } else if (value.length > 100) {
    error = "Material Name Should not more than 15";
  } else if (value.length < 3) {
    error = "Material Name Should not less than 3 Characters";
  }
  return error;
}
export function validateDescription(value) {
  let error;
  if (!value) {
    error = "Description is Required";
  } else if (!/^[A-Za-z0-9!@#$%^&*)(+=._,-\s]+$/i.test(value)) {
    error = "Description should contain only Alphabets";
  } else if (value.length < 3) {
    error = "Description Should not less than 3 Characters";
  }
  return error;
}
export function validateVendorName(value) {
  let error;
  if (!value) {
    error = "Vendor name is Required";
  } else if (!/^[^0-9][a-z][A-Z\s.]+$/i.test(value)) {
    error = "Vendor name It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "Vendor name Should not more than 15";
  } else if (value.length < 3) {
    error = "Vendor name Should not less than 3 Characters";
  }
  return error;
}
export function validateTeamLead(value) {
  let error;
  if (!value) {
    error = "Team Lead name is Required";
  } else if (!/^[^0-9][a-z][A-Z\s.]+$/i.test(value)) {
    error = "Team Lead name It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "Team Lead name Should not more than 15";
  } else if (value.length < 3) {
    error = "Team Lead name Should not less than 3 Characters";
  }
  return error;
}
// function validatematerialcategory(value) {
//   let error;
//   if (!value) {
//     error = 'Material Category is Required';
//   }
//   return error;
// }
export function validateMobileNumber(value) {
  let error;
  if (!value) {
    error = "Mobile Number is Required";
  } else if (value.length > 11) {
    error = "Mobile Number Should not more than 10";
  } else if (value.length < 10) {
    error = "Mobile Number Should not less than 10 ";
  } else if (
    !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
  ) {
    error = "Invalid Mobile Number";
  }
  return error;
}
export function validateLocation(value) {
  let error;
  if (!value) {
    error = "Location is Required";
  } else if (!/^[A-Za-z0-9!@#$%^&/*)(+=:"._,-\s]+$/i.test(value)) {
    error = "Enter a Valid Location";
  } else if (value.length > 100) {
    error = "Location Should not more than 100 characters";
  } else if (value.length < 3) {
    error = "Location Should not less than 3 Characters";
  }
  return error;
}

export function validateBankLOcation(value) {
  let error;
  if (!value) {
    error = "Bank Address is Required";
  } else if (!/^[A-Za-z0-9!@#$%^&/*)(+=:"._,-\s]+$/i.test(value)) {
    error = "Enter a Valid Bank Address";
  } else if (value.length > 100) {
    error = "Bank Address Should not more than 100 characters";
  } else if (value.length < 3) {
    error = "Bank Address Should not less than 3 Characters";
  }
  return error;
}
export function validateDescrp(value) {
  let error;
  if (!value) {
    error = "Description is Required";
  } else if (!/^[A-Za-z0-9!@#$%^&*)(+=:"._,-\s]+$/i.test(value)) {
    error = "Enter a Valid Description";
  } else if (value.length > 150) {
    error = "Description Should not more than 150";
  } else if (value.length < 3) {
    error = "Description Should not less than 3 Characters";
  }
  return error;
}

export function validateMaterialCat(value) {
  let error;
  if (!value) {
    error = "Material name is Required";
  } else if (!/^[A-Za-z0-9!@#$%^&*)(+=:"._,-\s]+$/i.test(value)) {
    error = "Enter a Valid Material name";
  } else if (value.length > 30) {
    error = "Material name Should not more than 30 characters";
  } else if (value.length < 3) {
    error = "Material name Should not less than 3 Characters";
  }
  return error;
}

export function validatebankName(value) {
  let error;
  if (!value) {
    error = "Bank Name is Required";
  } else if (!/^[A-Za-z0-9!@#$%^&*)(+=._,-\s]+$/i.test(value)) {
    error = "Bank Name It should contain only Alphabets";
  } else if (value.length > 100) {
    error = "Bank Name Should not more than 15";
  } else if (value.length < 3) {
    error = "Bank Name Should not less than 3 Characters";
  }
  return error;
}

export function validatebranchName(value) {
  let error;
  if (!value) {
    error = "Branch Name is Required";
  } else if (!/^[A-Za-z0-9!@#$%^&*)(+=._-\s]+$/i.test(value)) {
    error = "Branch Name It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "Branch Name Should not more than 15";
  } else if (value.length < 3) {
    error = "Branch Name Should not less than 3 Characters";
  }
  return error;
}

export function validateaccno(value) {
  let error;
  if (!value) {
    error = "Account Number is Required";
  } else if (value.length > 18) {
    error = "Account Number Should not more than 18";
  } else if (value.length < 9) {
    error = "Account Number Should not less than 9 ";
  } else if (!/[0-9]{9,18}$/i.test(value)) {
    error = "Invalid Account Number";
  }
  return error;
}

export function validateifsc(value) {
  let error;
  if (!value) {
    error = "IFSC is Required";
  } else if (!/^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/i.test(value)) {
    error = "Invalid IFSC Code";
  }
  return error;
}
export function validategst(value) {
  let error;
  if (!value) {
    error = "GST is Required";
  } else if (
    !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i.test(value)
  ) {
    error = "Invalid GST Code";
  }
  return error;
}
export function validatePan(value) {
  let error;
  if (!value) {
    error = "PAN is Required";
  } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(value)) {
    error = "Invalid PAN Code";
  }
  return error;
}
export function validateusername(value) {
  let error;
  if (!value) {
    error = "user name is Required";
  } else if (!/^[^0-9][a-z][A-Z\s.]+$/i.test(value)) {
    error = "user name It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "user name Should not more than 15";
  } else if (value.length < 3) {
    error = "user name Should not less than 3 Characters";
  }
  return error;
}
export function validateName(value) {
  let error;
  if (!value) {
    error = "Project Name is Required";
  } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
    error = "Project Name It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "Project Name Should not more than 15";
  } else if (value.length < 3) {
    error = "Project Name Should not less than 3 Characters";
  }
  return error;
}
export function validateVName(value) {
  let error;
  if (!value) {
    error = "Vendor Name is Required";
  } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
    error = "Vendor Name It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "Vendor Name Should not more than 15";
  } else if (value.length < 3) {
    error = "Vendor Name Should not less than 3 Characters";
  }
  return error;
}
export function validateUserName(value) {
  let error;
  if (!value) {
    error = "User Name is Required";
  } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
    error = "User Name It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "User Name Should not more than 15";
  } else if (value.length < 3) {
    error = "User Name Should not less than 3 Characters";
  }
  return error;
}

export function validatematcategory(value) {
  let error;
  if (!value) {
    error = "Material Category is Required";
  }
  return error;
}
export function validateEmail(value) {
  let error;
  if (!value) {
    error = "Email is Required";
  } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}

// karan=====

export function validateProjectId(value) {
  let error;
  if (!value) {
    error = "ProjectId is Required";
  } else if (!/^[0-9a-zA-Z\s]+$/i.test(value)) {
    error = "Enter a Valid ProjectId";
  } else if (value.length > 5) {
    error = "ProjectId Should not more than 5";
  } else if (value.length < 3) {
    error = "validate Should not less than 3 Characters";
  }
  return error;
}

export function validateArchitect(value) {
  let error;
  if (!value) {
    error = "Architect Name is Required";
  } else if (!/^[^0-9][a-z][A-Z\s.]+$/i.test(value)) {
    error = "Architect Name It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "Architect Name Should not more than 25";
  } else if (value.length < 3) {
    error = "Architect Name Should not less than 3 Characters";
  }
  return error;
}
export const fileNameSplit = (str) => {
  return str?.split("/").at(-1).split("-").slice(1).join("-");
};
export const fileNameSplitQR = (str) => {
  return str?.split("/").at(-1);
};

export function validatestyleofhome(value) {
  let error;
  if (!value) {
    error = "Style of Home is Required";
    // } else if (!/^[0-9a-z][0-9A-Z\s]+$/i.test(value)) {
  } else if (!/^[A-Za-z0-9!@#$%^&*)(+=:"._,-\s]+$/i.test(value)) {
    error = "Style of Home It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "Style of Home Should not more than 15";
  } else if (value.length < 3) {
    error = "Style of Home Should not less than 3 Characters";
  }
  return error;
}
export function validatetypeofkitchen(value) {
  let error;
  if (!value) {
    error = "Kitchen Type is Required";
  } else if (!/^[A-Za-z0-9!@#$%^&*)(+=:"._,-\s]+$/i.test(value)) {
    error = "Enter a Valid Kitchen Type It should contain only Aplhabets";
  } else if (value.length < 3) {
    error = "Kitchen Type Should not less than 3 Characters";
  }
  return error;
}
export function validatetypeofhome(value) {
  let error;
  if (!value) {
    error = "Style of Home is Required";
    // } else if (!/^[0-9a-z][0-9A-Z\s]+$/i.test(value)) {
  } else if (!/^[A-Za-z0-9!@#$%^&*)(+=:"._,-\s]+$/i.test(value)) {
    error = "Style of Home It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "Style of Home Should not more than 15";
  } else if (value.length < 3) {
    error = "Style of Home Should not less than 3 Characters";
  }
  return error;
}
export function validatekitchentype(value) {
  let error;
  if (!value) {
    error = "Kitchen Type is Required";
    // } else if (!/^[0-9a-z][0-9A-Z\s]+$/i.test(value)) {
  } else if (!/^[A-Za-z!@#$%^&*)(+=._-\s]+$/i.test(value)) {
    error = "Kitchen Type It should contain only Alphabets";
  } else if (value.length > 25) {
    error = "Kitchen Type Should not more than 15";
  } else if (value.length < 3) {
    error = "Kitchen Type Should not less than 3 Characters";
  }
  return error;
}
export function validateSBUA(value) {
  let error;
  if (!value) {
    error = "Super Built-up Area is Required";
  } else if (!/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/i.test(value)) {
    error = "Super Built-up Area Number";
  }
  return error;
}
export function validateBUA(value) {
  let error;
  if (!value) {
    error = " Built-up Area is Required";
  } else if (!/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/i.test(value)) {
    error = " Built-up Area Number";
  }
  return error;
}

export function validateprojectstage(value) {
  let error;
  if (!value) {
    error = "Project Stage is Required";
  }
  return error;
}

export function validateproject_health(value) {
  let error;
  if (!value) {
    error = "Project Health is Required";
  }
  return error;
}
export function validatepromgr(value) {
  let error;
  if (!value) {
    error = "Project Manager is Required";
  }
  return error;
}

// function validatestyleofhome(value) {
//   let error;
//   if (!value) {
//     error = "Style of home is Required";
//   }
//   return error;
// }
export function validatepackagename(value) {
  let error;
  if (!value) {
    error = "Package is Required";
  }
  return error;
}
export function validatecurrentprojectstage(value) {
  let error;
  if (!value) {
    error = "Current Project Stage is Required";
  }
  return error;
}
export function validatesiteenggname(value) {
  let error;
  if (!value) {
    error = "Site Engineer name is Required";
  }
  return error;
}
export function validatekitchen(value) {
  let error;
  if (!value) {
    error = "Style of Kitchen is Required";
  }
  return error;
}

export function validateConstructionStart(value) {
  let error;
  if (!value) {
    error = "Date of Construction is Required";
  }
  return error;
}

export function validateInvoiceNumber(value) {
  let error;
  if (!value) {
    error = "Invoice Number is Required";
  }
  return error;
}

export function validateDueDate(value) {
  let error;
  if (!value) {
    error = "Due Date is Required";
  }
  return error;
}
export function validateUserNameemail(value) {
  let error;
  if (!value) {
    error = "User Name is Required";
  } else if (!/^[a-zA-Z0-9_.-]*$/.test(value)) {
    error = "User Name It should contain only Alphabets & numbers";
  } else if (value.length > 50) {
    error = "User Name Should not more than 50";
  } else if (value.length < 2) {
    error = "User Name Should not less than 3 Characters";
  }
  return error;
}
export function validateUserNameemailedit(value) {
  let error;
  if (!value) {
    error = "User Name is Required";
  } else if (!/^[a-zA-Z0-9_.-]*$/.test(value)) {
    error = "User Name It should contain only Alphabets and Numbers";
  } else if (value.length > 50) {
    error = "User Name Should not more than 15";
  } else if (value.length < 2) {
    error = "User Name Should not less than 3 Characters";
  }
  return error;
}

export function validateUserID(value) {
  let error;
  if (!value) {
    error = "User ID is Required";
  } else if (!/^[a-zA-Z0-9_.-]*$/.test(value)) {
    error = "User ID It should contain only Alphabets and Numbers";
  } else if (value.length > 50) {
    error = "User ID Should not more than 15";
  } else if (value.length < 2) {
    error = "User ID Should not less than 3 Characters";
  }
  return error;
}
export function validatePurchaseOrderNo(value) {
  let error;
  if (!value) {
    error = "Purchase Order Number is Required";
  }
  return error;
}

export function validateAmount(value) {
  let error;
  if (!value) {
    error = "Total Amount is Required";
  }
  return error;
}
export const reqSlice = (inputString) => {
  const reqCode = inputString.match(/REQ\d+/);
  if (reqCode) {
    console.log(reqCode[0]);
    return reqCode[0];
  }
};

export const Ranges = [
  {
    label: "Today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "Month",
    value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 1))],
  },
  {
    label: "3 Months",
    value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 3))],
  },
  {
    label: "6 Months",
    value: [startOfDay(new Date()), endOfDay(subMonths(new Date(), 6))],
  },
  {
    label: "Year",
    value: [startOfDay(new Date()), endOfDay(subYears(new Date(), 1))],
  },
];

export const LastActivityDate = [
  {
    label: "today",
    value: [endOfDay(new Date()), startOfDay(new Date())],
  },
  {
    label: "Month",
    value: [endOfDay(subMonths(new Date(), 1)), startOfDay(new Date())],
  },
  {
    label: "3 Months",
    value: [endOfDay(subMonths(new Date(), 3)), startOfDay(new Date())],
  },
  {
    label: "6 Months",
    value: [endOfDay(subMonths(new Date(), 6)), startOfDay(new Date())],
  },
  {
    label: "Year",
    value: [endOfDay(subYears(new Date(), 1)), startOfDay(new Date())],
  },
];

export const fuctureDate = [
  {
    label: "today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "Month",
    value: [startOfDay(addMonths(new Date(), 1)), endOfDay(new Date())],
  },
  {
    label: "3 Months",
    value: [startOfDay(addMonths(new Date(), 3)), endOfDay(new Date())],
  },
  {
    label: "6 Months",
    value: [startOfDay(addMonths(new Date(), 6)), endOfDay(new Date())],
  },
  {
    label: "Year",
    value: [startOfDay(addYears(new Date(), 1)), endOfDay(new Date())],
  },
];
