import React from "react";
import "antd/dist/antd.css";
import { Pagination } from "antd";

const PaginationComponent = ({
  handleChangePage,
  perPage,
  totlePage,
  currentpage,
}) => {
  return (
    <Pagination
      onChange={handleChangePage}
      pageSize={perPage}
      total={totlePage}
      showTotal={(total, range) => (
        <p style={{ color: "#909090" }}>
          {" "}
          Showing <span style={{ color: "#0e0e0e" }}>{range[0]}</span> to{" "}
          <span style={{ color: "#0e0e0e" }}>{range[1]}</span> of{" "}
          <span style={{ color: "#0e0e0e" }}>{total}</span> Results
        </p>
      )}
      current={parseInt(currentpage)}
      // defaultCurrent={Page}
      //   showSizeChanger={true}
      pageSizeOptions={[10, 20, 50, 100]}
      showSizeChanger
      // showQuickJumper
    />
  );
};

export default PaginationComponent;
