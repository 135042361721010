import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Double from "../../../Assets/Img/Double.gif";
import HotelIcon from "@mui/icons-material/Hotel";
import BathtubIcon from "@mui/icons-material/Bathtub";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { Formik, Form, Field } from "formik";
import SelectField from "../../../ReusableComponent/SelectField";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {
  validateSBUA,
  validateBUA,
  validateproject_health,
  validatepackagename,
  validatecurrentprojectstage,
  validateLocation,
  validateName,
  validatetypeofkitchen,
  validateConstructionEnd,
  validateConstructionStart,
  validatetypeofhome,
  validateTeamLead,
  validateArchitect,
} from "../../../ReusableComponent/Validation";

import {
  selectAllListmanagers,
  selectAllTheSiteEng,
  setallProjectIndivusal,
  selectallProjectIndivusal,
} from "../../../features/counter/counterSlice";
import moment from "moment";

import CheckBoxstage from "./CheckBoxstage";
import { setProjectScreen } from "../../../features/Screen/screen";
import ProgressBarTest from "../../../ReusableComponent/ProgressBarTest";
import { useCallback } from "react";
export default function ProDetails({
  singleViewId,
  setsingleView,
  projectlisting,
  setProject_id,
}) {
  let history = useNavigate();
  const dispatch = useDispatch();
  const [AllprojectHealth, setAllprojectHealth] = useState([]);
  const TheSiteEng = useSelector(selectAllTheSiteEng);
  const Listmanagers = useSelector(selectAllListmanagers);
  const [indivisual, setindivisual] = useState("");
  const [projectnoofbedrooms, Setprojectnoofbedrooms] = useState("");
  const [projectnoofbathrooms, Setprojectnoofbathrooms] = useState("");
  const [progresListings, setprogresListings] = useState([]);
  const [pylodprogress, setpylodprogress] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [projectprogress, Setprojectprogress] = useState("");
  const [projectstage1, Setprojectstage1] = useState("");
  const [showedit, toggleShowedit] = useState(false);
  const [oldprojectvalue, Setoldprojectvalue] = useState(false);
  const [apiprojectval, Setapiprojectval] = useState(true);
  const [cityNames, setCityNames] = useState([]);
  const [floorChanged, setFloorChanged] = useState(false);
  const [city, setCity] = useState("");
  const [packages, setPackages] = useState([]);
  const [latestFloor, setLatestFloor] = useState(indivisual.no_of_floors || "");

  function Btn({ children, onClick }) {
    return (
      <button
        style={{
          paddingRight: "14px",
          paddingLeft: "14px",
          background: "#FCC200",
          border: "none",
          fontSize: "20px",
          fontFamily: "gb",
        }}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  const getpakage = () => {
    axiosClientInterceptors.get(`/get-packages?city=${city}`).then((result) => {
      setPackages(result.data.result);
    });
  };

  const checkedui = (stage, check) => {
    check ? setpylodprogress(stage) : setpylodprogress("");
  };

  // 3rd Counter for no of Bedrooms
  function handleIncrement2() {
    Setprojectnoofbedrooms(
      (prevprojectnoofbedrooms) => prevprojectnoofbedrooms + 1
    );
  }

  function handleDecrement2(e) {
    e.preventDefault();
    Setprojectnoofbedrooms(
      projectnoofbedrooms - 1 <= 0 ? 1 : projectnoofbedrooms - 1
    );
  }

  // 3rd Counter for no of bathrooms
  function handleIncrement3() {
    Setprojectnoofbathrooms(
      (prevprojectnoofbathrooms) => prevprojectnoofbathrooms + 1
    );
  }

  function handleDecrement3(e) {
    e.preventDefault();

    Setprojectnoofbathrooms(
      projectnoofbathrooms - 1 <= 0 ? 1 : projectnoofbathrooms - 1
    );
  }

  // getSiteProjectHealth
  const getSiteProjectHealth = () => {
    axiosClientInterceptors.get(`/get-project-health`).then((resp) => {
      setAllprojectHealth(resp.data.result);
    });
  };

  // Logout Function
  const Logout = () => {
    localStorage.clear("username");
    localStorage.removeItem("username");
    $(".modal-backdrop").remove();
    history("/");
  };

  const updatedprojectdetails = () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
    };
    axiosClientInterceptors
      .get(`/getprojectbyid?_id=${singleViewId}`, {
        headers,
      })
      .then((res) => {
        setindivisual(res.data.result[0]);
        localStorage.setItem("NOOFFLOORS", res.data.result[0].no_of_floors);
        Setprojectnoofbedrooms(res.data.result[0].no_of_bedrooms);
        Setprojectnoofbathrooms(res.data.result[0].no_of_bathrooms);
        dispatch(setallProjectIndivusal(res.data.result[0]));
        setProject_id(res.data.result[0].project_id);
        Setprojectprogress(res.data.result[0].project_progress);
        setLoading(false);
      });
  };

  // Delete the project
  const deleteproject = () => {
    axiosClientInterceptors
      .delete(`/deleteproject?_id=${singleViewId}`)
      .then((resultuserdeleted) => {
        let Status = resultuserdeleted.data.status;
        if (Status === "success") {
          window.$("#delete_projects").modal("hide");
        } else {
          alert("Error Deleting");
        }
      });
  };

  const getProgress = (floor1, floors2) => {
    if (floor1 == floors2) {
      axiosClientInterceptors
        .get(`/get-project-stages?floor=${floor1}&project_id=${singleViewId}`)
        .then((resp) => {
          setprogresListings(resp.data.result);
          projectlisting();
        });
    } else {
      axiosClientInterceptors
        .get(`/get-project-stages?floor=${floors2}&project_id=${singleViewId}`)
        .then((resp) => {
          setprogresListings(resp.data.result);
          projectlisting();
        });
    }
  };

  const stageedit = (Floors) => {
    let edit = {
      project_stage:
        pylodprogress.length > 2 ? pylodprogress : indivisual.project_stage,
      no_of_floors: Floors,
      // no_of_floors: floorChanged? ,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    axiosClientInterceptors
      .post(`/update-progress?_id=${singleViewId}`, edit, {
        headers,
      })
      .then((res) => {
        if (res.data.status === "success") {
          projectlisting();
          updatedprojectdetails();
          window.$("#filter").modal("hide");
        } else {
          alert("Profile Details are already exists");
        }
      })
      .catch((err) => {
        alert("Some Internal Error", err);
      });
  };

  const getCities = () => {
    axiosClientInterceptors.get(`/get-city`).then((resp) => {
      setCityNames(resp.data.result);
    });
  };

  const ProgressSTage = useCallback(
    ({ val, checkedui, checkeddata, projectlisting }) => {
      return (
        <CheckBoxstage
          projectlisting={projectlisting}
          val={val}
          checkeddata={checkeddata}
          checkedui={checkedui}
        />
      );
    },
    [progresListings]
  );

  useEffect(() => {
    updatedprojectdetails();
    getSiteProjectHealth();
    getCities();
  }, []);

  useEffect(() => {
    getpakage();
  }, [city]);

  return (
    <>
      {isLoading ? (
        <div
          className="App d-flex justify-content-center align-items-center"
          style={{
            width: "100%",
            textAlign: "center",
            height: "100vh",
            background: "#f9f9f9",
          }}
        >
          <div>
            <h3 className="text-dark d-flex flex-column">
              <div>
                <img src={Double} width="50%" />
              </div>
              <div>Loading...</div>
            </h3>
          </div>
        </div>
      ) : (
        <div className="parent-request">
          <div className="inner_body">
            <div className="">
              <div className="">
                <div className="col-md-12">
                  <div>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        project_name: indivisual.project_name,
                        project_progress: indivisual.project_progress,
                        project_stage:
                          // pylodprogress.length > 2
                          //   ? pylodprogress
                          //   : indivisual.project_stage,
                          indivisual.project_stage,
                        package: indivisual.package,
                        location: indivisual.location,
                        sbua: indivisual.sbua,
                        bua: indivisual.bua,
                        style_of_home: indivisual.style_of_home,
                        type_of_kitchen: indivisual.type_of_kitchen,
                        construction_startdate: moment(
                          indivisual.construction_startdate,
                          "DD/MM/YYYY"
                        ).format("YYYY-MM-DD"),
                        construction_enddate: moment(
                          indivisual.construction_enddate,
                          "DD/MM/YYYY"
                        ).format("YYYY-MM-DD"),
                        project_manager: indivisual.project_manager,
                        team_lead: indivisual.team_lead,
                        site_engineer: indivisual.site_engineer,
                        architect: indivisual.architect,
                        no_of_floors: "",
                        no_of_bedrooms: indivisual.no_of_bathrooms,
                        no_of_bathrooms: indivisual.no_of_bedrooms,
                        site_full_address: indivisual.site_full_address,
                        project_health: indivisual.project_health,
                        lead_residence: indivisual.lead_residence,
                      }}
                      onSubmit={(values) => {
                        let edit = {
                          project_name: values.project_name,
                          project_progress: values.project_progress,
                          project_stage: values.project_stage,
                          package:
                            packages.length === 1
                              ? packages[0].package_name
                              : values.package,
                          location: values.location,
                          sbua: values.sbua,
                          bua: values.bua,
                          style_of_home: values.style_of_home,
                          type_of_kitchen: values.type_of_kitchen,
                          construction_startdate: moment(
                            values.construction_startdate,
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY"),
                          construction_enddate: moment(
                            values.construction_enddate,
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY"),
                          project_manager: values.project_manager,
                          team_lead: values.team_lead,
                          site_engineer: values.site_engineer,
                          architect: values.architect,
                          no_of_floors: Number(latestFloor),
                          no_of_bedrooms: projectnoofbedrooms,
                          no_of_bathrooms: projectnoofbathrooms,
                          site_full_address: values.site_full_address,
                          project_health: values.project_health,
                          city: city.length > 0 ? city : indivisual.city_id,
                          lead_residence: values.lead_residence,
                        };
                        const headers = {
                          "Content-Type": "application/json",
                        };
                        axiosClientInterceptors
                          .post(`/updateproject?_id=${singleViewId}`, edit, {
                            headers,
                          })
                          .then((res) => {
                            if (res.data.status === "success") {
                              setsingleView("singleuser");
                              dispatch(setProjectScreen("singleuser"));
                              projectlisting();
                              updatedprojectdetails();
                              toggleShowedit(false);
                            } else {
                              alert("Profile Details are already exists");
                            }
                          })
                          .catch((err) => {
                            alert("Some Internal Error", err);
                          });
                      }}
                    >
                      {({ errors, touched, handleSubmit, values }) => (
                        <Form>
                          <hr className="mt-0 mb-0" />

                          <div className="req_right_div">
                            <div className="req_details_main_sec mb-4">
                              <div
                                className="d-flex justify-content-between"
                                style={{
                                  borderBottom: "1px solid #DBDBDB",
                                  paddingBottom: "13px",
                                }}
                              >
                                <p className="m-0">
                                  <span
                                    className="fontdocument_dim"
                                    onClick={() => {
                                      dispatch(setProjectScreen("allusers"));
                                      setsingleView("allusers");
                                    }}
                                  >
                                    All Projects{" "}
                                  </span>
                                  /{" "}
                                  <span className="fontdocument_active">
                                    Project Details
                                  </span>
                                </p>
                                <div className="crud_button d-flex">
                                  <div className="edit_icon">
                                    {showedit ? (
                                      <div>
                                        <button
                                          type="submit"
                                          className="edit_icon delete_btn"
                                          onClick={() => {
                                            handleSubmit();
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <div
                                        className="delete_btn2 "
                                        onClick={() => toggleShowedit(true)}
                                      >
                                        <ModeEditOutlineIcon
                                          fontSize="small"
                                          className="add_icon "
                                        />
                                        <span> Edit</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {!showedit && (
                                <>
                                  <div
                                    style={{
                                      overflowY: "scroll",
                                      height: "76vh",
                                    }}
                                  >
                                    <div className="progress_barsec mt-3">
                                      <div className="d-flex justify-content-between">
                                        <div className="proj_status">
                                          Project Status (Current Status :{" "}
                                          {indivisual.project_stage
                                            ? indivisual.project_stage
                                            : "-"}
                                          )
                                        </div>
                                        <div>
                                          {indivisual?.project_remaining_days >=
                                          0 ? (
                                            <>
                                              <span
                                                style={{
                                                  color: "#717171",
                                                  fontFamily: "Gm",
                                                }}
                                              >
                                                Days Left{" "}
                                              </span>
                                              :{" "}
                                              <b className="text-dark">
                                                {indivisual?.project_remaining_days ||
                                                  0}
                                              </b>
                                            </>
                                          ) : (
                                            <>
                                              <span
                                                style={{
                                                  color: "#717171",
                                                  fontFamily: "Gm",
                                                }}
                                                className="text-danger"
                                              >
                                                Days Exceeded{" "}
                                              </span>
                                              :{" "}
                                              <b className="text-danger">
                                                {Math.abs(
                                                  indivisual?.project_remaining_days
                                                )}
                                              </b>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <ProgressBarTest
                                        indivisual={indivisual}
                                      />
                                    </div>
                                    <div className="mt-3 admin_project_edit">
                                      <div className="first_row row justify-content-between">
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Project Name</span>
                                            <p className="mb-0">
                                              {indivisual.project_name
                                                ? indivisual.project_name
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Project ID</span>
                                            <p className="mb-0">
                                              {" "}
                                              {indivisual.project_id
                                                ? indivisual.project_id
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Project Health</span>
                                            <p className="mb-0">
                                              {" "}
                                              {indivisual.project_health
                                                ? indivisual.project_health
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Number of Floors</span>
                                            <p className="mb-0">
                                              {" "}
                                              {indivisual.no_of_floors
                                                ? `G+ ${indivisual.no_of_floors}`
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        {/* <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Project Progress</span>
                                            <p className="mb-0">
                                              {" "}
                                              {indivisual.project_progress || 0}
                                              %
                                            </p>
                                          </div>
                                        </div> */}
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Project Progress</span>
                                            <p className="mb-0">
                                              {" "}
                                              {indivisual.project_progress || 0}
                                              %
                                            </p>
                                          </div>
                                        </div>
                                        {/* <div className="col-6 col-md-2">
                                          <div className="details ">
                                            <span>Current Project Stage</span>
                                            <p className="mb-0 ">
                                              {" "}
                                              {indivisual.project_stage
                                                ? indivisual.project_stage
                                                : "no project stage"}
                                            </p>
                                          </div>
                                        </div> */}
                                      </div>
                                      <hr />
                                      <div className="first_row row justify-content-between">
                                        <div className="col-6 col-md-2">
                                          <div className="details ">
                                            <span>Current Project Stage</span>
                                            <p className="mb-0 ">
                                              {" "}
                                              {indivisual.project_stage
                                                ? indivisual.project_stage
                                                : "no project stage"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Package</span>
                                            <p className="mb-0">
                                              {indivisual.package
                                                ? indivisual.package
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Super Built-up Area</span>
                                            <p className="mb-0">
                                              {indivisual.sbua
                                                ? indivisual.sbua
                                                : "-"}{" "}
                                              SQFT
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Built-up Area</span>
                                            <p className="mb-0">
                                              {indivisual.bua
                                                ? indivisual.bua
                                                : "-"}{" "}
                                              SQFT
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Style of Home</span>
                                            <p className="mb-0">
                                              {indivisual.style_of_home
                                                ? indivisual.style_of_home
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        {/* <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Style of Kitchen</span>
                                            <p className="mb-0">
                                              {" "}
                                              {indivisual.type_of_kitchen
                                                ? indivisual.type_of_kitchen
                                                : "-"}
                                            </p>
                                          </div>
                                        </div> */}
                                      </div>
                                      <hr
                                      // style={{
                                      //   borderTop: "1px solid #e5e5ea",
                                      // }}
                                      />
                                      <div className="first_row row justify-content-between">
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Construction Start Date</span>
                                            <p className="mb-0">
                                              {" "}
                                              {indivisual.construction_startdate
                                                ? indivisual.construction_startdate
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Construction End Date</span>
                                            <p className="mb-0">
                                              {indivisual.construction_enddate
                                                ? indivisual.construction_enddate
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Style of Kitchen</span>
                                            <p className="mb-0">
                                              {" "}
                                              {indivisual.type_of_kitchen
                                                ? indivisual.type_of_kitchen
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Project Manager</span>
                                            <p className="mb-0">
                                              {indivisual.project_manager_name
                                                ? indivisual.project_manager_name
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2 ">
                                          <div className="details">
                                            <span>Team Lead</span>
                                            <p className="mb-0">
                                              {indivisual.team_lead
                                                ? indivisual.team_lead
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        {/* <div className="col-6 col-md-2 ">
                                          <div className="details">
                                            <span>Site Engineer</span>
                                            <p className="mb-0">
                                              {indivisual.site_engineer_name
                                                ? indivisual.site_engineer_name
                                                : "-"}
                                            </p>
                                          </div>
                                        </div> */}
                                      </div>
                                      <hr />
                                      <div className="first_row row justify-content-between">
                                        <div className="col-6 col-md-2 ">
                                          <div className="details">
                                            <span>Site Engineer</span>
                                            <p className="mb-0">
                                              {indivisual.site_engineer_name
                                                ? indivisual.site_engineer_name
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2 ">
                                          <div className="details">
                                            <span>Architect</span>
                                            <p className="mb-0">
                                              {" "}
                                              {indivisual.architect
                                                ? indivisual.architect
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        {/* <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Number of Floors</span>
                                            <p className="mb-0">
                                              {" "}
                                              {indivisual.no_of_floors
                                                ? `G+ ${indivisual.no_of_floors}`
                                                : "-"}
                                            </p>
                                          </div>
                                        </div> */}
                                        <div className="col-6 col-md-2">
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ gap: "5px" }}
                                          >
                                            <div>
                                              <BathtubIcon
                                                fontSize="small"
                                                style={{ color: "#FCC200" }}
                                              />
                                            </div>
                                            <div className="details ml-2">
                                              <span>Number of Bathrooms</span>
                                              <p className="mb-0">
                                                {indivisual.no_of_bathrooms}{" "}
                                                Bathrooms
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-6 col-md-2">
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ gap: "5px" }}
                                          >
                                            <div>
                                              <HotelIcon
                                                fontSize="small"
                                                style={{ color: "#FCC200" }}
                                              />
                                            </div>
                                            <div className="details ml-2">
                                              <span>Number of Bedrooms</span>
                                              <p className="mb-0">
                                                {indivisual.no_of_bedrooms}{" "}
                                                Bedrooms
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-2">
                                          <div className="details">
                                            <span>Site Address</span>
                                            <p className="mb-0">
                                              {indivisual.location
                                                ? indivisual.location
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <hr />
                                      <div className="first_row row justify-content-between">
                                        <div className="col-3">
                                          <div className="">
                                            <div className="details">
                                              <span>City</span>
                                              <p className="mb-0">
                                                {indivisual?.city
                                                  ? indivisual?.city
                                                  : "-"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-4">
                                          <div className="">
                                            <div className="details">
                                              <span>
                                                Lead Residence Location
                                              </span>
                                              <p className="mb-0">
                                                {indivisual.lead_residence
                                                  ? indivisual.lead_residence
                                                  : "-"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-5">
                                          <div className="">
                                            <div className="details">
                                              <span>Site Full Location</span>
                                              <p className="mb-0">
                                                {indivisual.site_full_address
                                                  ? indivisual.site_full_address
                                                  : "-"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {showedit && (
                                <div className="admin_project_edit1">
                                  <div className="first_row row justify-content-between">
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Project Name</span>
                                        <br />
                                        <Field
                                          type="text"
                                          name="project_name"
                                          value={values.project_name}
                                          validate={validateName}
                                          className="admin_project_details_input"
                                        />
                                      </div>
                                      {errors.project_name &&
                                        touched.project_name && (
                                          <p className="errors">
                                            {errors.project_name}
                                          </p>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Project ID</span>
                                        <p className="mb-0">
                                          {" "}
                                          {indivisual.project_id}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-2 mb-3">
                                      <div className="details">
                                        <span>Project Health</span>
                                        <br />
                                        <SelectField
                                          className="admin_project_details_input"
                                          validate={validateproject_health}
                                          name="project_health"
                                        >
                                          <option value="" disabled selected>
                                            Select
                                          </option>
                                          {AllprojectHealth.map((val, i) => (
                                            <option
                                              key={i}
                                              value={val.project_health}
                                            >
                                              {val.project_health}
                                            </option>
                                          ))}
                                        </SelectField>
                                      </div>
                                      {errors.project_health &&
                                        touched.project_health && (
                                          <p className="errors">
                                            {errors.project_health}
                                          </p>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="d-flex align-items-center">
                                        <div className="details">
                                          <span>Number of Floor</span>
                                          <div className="d-flex align-items-center">
                                            <p
                                              className="m-0 florG"
                                              style={{ fontSize: "13px" }}
                                            >
                                              G+
                                            </p>
                                            <Field
                                              style={{ outline: "none" }}
                                              name="no_of_floors"
                                              type="tel"
                                              value={
                                                latestFloor ||
                                                indivisual.no_of_floors
                                              }
                                              className="admin_project_details_input w-25"
                                              onChange={(e) => {
                                                console.log(
                                                  "FLOOOR",
                                                  e.target.value
                                                );
                                                setLatestFloor(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Project Progress</span>
                                        <br />
                                        <div
                                          name="project_id"
                                          className="admin_project_details_input d-flex justify-content-between"
                                        >
                                          {apiprojectval && (
                                            <span>
                                              {projectprogress}
                                              {""}%
                                            </span>
                                          )}

                                          {oldprojectvalue && (
                                            <span>
                                              {projectstage1}
                                              {""}%
                                            </span>
                                          )}
                                          <div>
                                            <ModeEditOutlineIcon
                                              data-bs-toggle="modal"
                                              data-bs-target="#filter"
                                              className="add_icon "
                                              style={{ color: "#FCC216" }}
                                              onClick={() => {
                                                getProgress(
                                                  indivisual.no_of_floors,
                                                  latestFloor
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="first_row row  justify-content-between">
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Current Project Stage</span>
                                        <br />
                                        {/* <p className="mb-0">
                                          {" "}
                                          {indivisual.project_stage
                                            ? indivisual.project_stage
                                            : "Not Started"}
                                        </p> */}
                                        <SelectField
                                          className="admin_project_details_input"
                                          validate={validatecurrentprojectstage}
                                          name="project_stage"
                                          value={values.project_stage}
                                        >
                                          <option value={""} disabled selected>
                                            Select
                                          </option>
                                          {progresListings.map((val, i) => (
                                            <option key={i} value={val.stage}>
                                              {val.stage}
                                            </option>
                                          ))}
                                        </SelectField>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Package</span>
                                        <br />
                                        <SelectField
                                          className="admin_project_details_input"
                                          validate={validatepackagename}
                                          name="package"
                                          value={values.package}
                                        >
                                          <option value={""} disabled selected>
                                            Select
                                          </option>
                                          {packages.map((val, i) => (
                                            <option
                                              key={i}
                                              value={val.package_name}
                                            >
                                              {val.package_name}
                                            </option>
                                          ))}
                                        </SelectField>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Super Built-up Area</span>
                                        <br />
                                        <Field
                                          type="number"
                                          name="sbua"
                                          value={values.sbua}
                                          className="admin_project_details_input"
                                          validate={validateSBUA}
                                        />
                                      </div>
                                      {errors.sbua && touched.sbua && (
                                        <p className="errors">{errors.sbua}</p>
                                      )}
                                    </div>

                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Built-up Area</span>
                                        <br />
                                        <Field
                                          type="number"
                                          name="bua"
                                          value={values.bua}
                                          className="admin_project_details_input"
                                          validate={validateBUA}
                                        />
                                      </div>
                                      {errors.bua && touched.bua && (
                                        <p className="errors">{errors.bua}</p>
                                      )}
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Style of Home</span>
                                        <br />
                                        <Field
                                          type="text"
                                          name="style_of_home"
                                          value={values.style_of_home}
                                          className="admin_project_details_input"
                                          validate={validatetypeofhome}
                                        />
                                      </div>
                                      {errors.style_of_home &&
                                        touched.style_of_home && (
                                          <p className="errors">
                                            {errors.style_of_home}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="first_row row justify-content-between">
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Construction Start Date </span>
                                        <br />
                                        <Field
                                          type="date"
                                          name="construction_startdate"
                                          value={values.construction_startdate}
                                          className="admin_project_details_input"
                                          // min={moment().format("YYYY-MM-DD")}
                                          validate={validateConstructionStart}
                                        />
                                      </div>
                                      {errors.construction_startdate &&
                                        touched.construction_startdate && (
                                          <p className="errors">
                                            {errors.construction_startdate}
                                          </p>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Construction End Date</span>
                                        <br />
                                        <Field
                                          type="date"
                                          name="construction_enddate"
                                          value={values.construction_enddate}
                                          min={moment(
                                            values.construction_startdate
                                          ).format("YYYY-MM-DD")}
                                          className="admin_project_details_input"
                                          validate={validateConstructionEnd}
                                        />
                                      </div>
                                      {errors.construction_enddate &&
                                        touched.construction_enddate && (
                                          <p className="errors">
                                            {errors.construction_enddate}
                                          </p>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Style of Kitchen</span>
                                        <br />
                                        <Field
                                          type="text"
                                          value={values.type_of_kitchen}
                                          name="type_of_kitchen"
                                          className="admin_project_details_input"
                                          validate={validatetypeofkitchen}
                                        />
                                      </div>
                                      {errors.type_of_kitchen &&
                                        touched.type_of_kitchen && (
                                          <p className="errors">
                                            {errors.type_of_kitchen}
                                          </p>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Project Manager</span>
                                        <br />
                                        <SelectField
                                          className="admin_project_details_input"
                                          name="project_manager"
                                          value={values.project_manager}
                                        >
                                          <option value="" disabled selected>
                                            Select
                                          </option>
                                          {Listmanagers?.map((val, i) => (
                                            <option key={i} value={val._id}>
                                              {val.name}
                                            </option>
                                          ))}
                                        </SelectField>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Team Lead</span>
                                        <br />
                                        <Field
                                          type="text"
                                          value={values.team_lead}
                                          name="team_lead"
                                          className="admin_project_details_input"
                                          validate={validateTeamLead}
                                        />
                                      </div>
                                      {errors.team_lead &&
                                        touched.team_lead && (
                                          <p className="errors">
                                            {errors.team_lead}
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  <hr />
                                  <div className="row first_row  justify-content-between">
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Site Engineer</span>
                                        <br />

                                        <SelectField
                                          className="admin_project_details_input"
                                          value={values.site_engineer}
                                          name="site_engineer"
                                        >
                                          {TheSiteEng?.map((pmname, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={pmname._id}
                                              >
                                                {pmname.name}
                                              </option>
                                            );
                                          })}
                                        </SelectField>
                                      </div>
                                      {errors.site_engineer &&
                                        touched.site_engineer && (
                                          <p className="errors">
                                            {errors.site_engineer}
                                          </p>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Architect</span>
                                        <br />
                                        <Field
                                          type="text"
                                          name="architect"
                                          value={values.architect}
                                          className="admin_project_details_input"
                                          validate={validateArchitect}
                                        />
                                      </div>
                                      {errors.architect &&
                                        touched.architect && (
                                          <p className="errors">
                                            {errors.architect}
                                          </p>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-2">
                                      <div className="d-flex align-items-center">
                                        <div className="details ml-2">
                                          <span>Number of Bathrooms</span>
                                          <div>
                                            <div
                                              style={{ textAlign: "Center" }}
                                            >
                                              <div className="d-flex align-items-center justify-content-center">
                                                <Btn
                                                  onClick={(e) => {
                                                    handleDecrement3(e);
                                                  }}
                                                >
                                                  -
                                                </Btn>
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                    padding: "0px 12px",
                                                    verticalAlign: "Middle",
                                                  }}
                                                >
                                                  {projectnoofbathrooms}
                                                </span>
                                                <Btn
                                                  onClick={() => {
                                                    handleIncrement3();
                                                  }}
                                                >
                                                  +
                                                </Btn>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="d-flex align-items-center">
                                        <div className="details ml-2">
                                          <span>Number of Bedrooms</span>
                                          <div style={{ textAlign: "Center" }}>
                                            <div className="d-flex align-items-center justify-content-center">
                                              <Btn
                                                onClick={(e) => {
                                                  handleDecrement2(e);
                                                }}
                                              >
                                                -
                                              </Btn>
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                  padding: "0px 12px",
                                                  verticalAlign: "Middle",
                                                }}
                                              >
                                                {projectnoofbedrooms}
                                              </span>
                                              <Btn onClick={handleIncrement2}>
                                                +
                                              </Btn>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-6 col-md-2">
                                      <div className="details">
                                        <span>Site Address</span>
                                        <br />
                                        <Field
                                          type="text"
                                          name="location"
                                          value={values.location}
                                          className="admin_project_details_input"
                                          validate={validateLocation}
                                        />
                                      </div>
                                      {errors.location && touched.location && (
                                        <p className="errors">
                                          {errors.location}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="row first_row  justify-content-between">
                                    <div className="col-3 col-md-3 ">
                                      <div className="details">
                                        <span>City</span>
                                        <SelectField
                                          className="admin_project_details_input"
                                          value={values.city}
                                          name="city"
                                          onChange={(e) => {
                                            setCity(e.target.value);
                                          }}
                                        >
                                          <option value="" disabled selected>
                                            Select
                                          </option>
                                          {cityNames?.map((cit, index) => {
                                            return (
                                              <option
                                                key={index}
                                                selected={
                                                  indivisual.city === cit.city
                                                }
                                                value={cit._id}
                                              >
                                                {cit.city}
                                              </option>
                                            );
                                          })}
                                        </SelectField>
                                      </div>
                                    </div>
                                    <div className="col-4 col-md-4 ">
                                      <div className="details">
                                        <span>Lead Residence Location</span>
                                        <br />
                                        <Field
                                          type="text"
                                          name="lead_residence"
                                          style={{ height: "50px" }}
                                          className="admin_project_details_input w-100"
                                          validate={validateLocation}
                                        />
                                      </div>
                                      {errors.lead_residence &&
                                        touched.lead_residence && (
                                          <p className="errors">
                                            {errors.lead_residence}
                                          </p>
                                        )}
                                    </div>
                                    <div className="col-5 col-md-5 ">
                                      <div className="details">
                                        <span>Site Full Location</span>
                                        <br />
                                        <Field
                                          type="text"
                                          name="site_full_address"
                                          style={{ height: "50px" }}
                                          className="admin_project_details_input w-100"
                                          validate={validateLocation}
                                        />
                                      </div>
                                      {errors.site_full_address &&
                                        touched.site_full_address && (
                                          <p className="errors">
                                            {errors.site_full_address}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {!showedit && (
                                <div className="d-flex align-content-center">
                                  <div
                                    className="Project_Documents me-2"
                                    onClick={() => {
                                      setsingleView("ProjectsDocument");
                                      dispatch(
                                        setProjectScreen("ProjectsDocument")
                                      );
                                    }}
                                  >
                                    Project Documents
                                    <KeyboardArrowRightIcon
                                      fontSize="small"
                                      style={{
                                        color: "#FCC200",
                                        marginRight: "2px",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="Project_Documents me-2"
                                    onClick={() => {
                                      setsingleView("AllInvoice");
                                      dispatch(setProjectScreen("AllInvoice"));
                                    }}
                                  >
                                    All Invoices
                                    <KeyboardArrowRightIcon
                                      fontSize="small"
                                      style={{
                                        color: "#FCC200",
                                        marginRight: "2px",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="Project_Documents me-2"
                                    onClick={() => {
                                      // dispatch(setsidebaractivetab("Requests"));
                                      setsingleView("addminAllrequest");
                                      dispatch(
                                        setProjectScreen("addminAllrequest")
                                      );
                                    }}
                                  >
                                    All Requests
                                    <KeyboardArrowRightIcon
                                      fontSize="small"
                                      style={{
                                        color: "#FCC200",
                                        marginRight: "2px",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="Project_Documents"
                                    onClick={() => {
                                      // dispatch(setsidebaractivetab("Requests"));
                                      setsingleView("addminAllpo");
                                      dispatch(setProjectScreen("addminAllpo"));
                                    }}
                                  >
                                    All Purchase Orders
                                    <KeyboardArrowRightIcon
                                      fontSize="small"
                                      style={{
                                        color: "#FCC200",
                                        marginRight: "2px",
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>

                    <div
                      id="delete_projects"
                      className="modal fade "
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog  modal-dialog-centered ">
                        <div
                          className="modal-content modal_content_whole"
                          style={{ background: "white" }}
                        >
                          <div className="modal-body">
                            <div>
                              <div className="popup_icon_center">
                                <InfoRoundedIcon className="popup_delete_proj_icon" />
                              </div>
                              <div className="popup_text_center">
                                <span className="popup_addbankdetails_text">
                                  Are you sure want to Delete this Project ?
                                </span>
                              </div>
                              <div className="pt-3 d-flex">
                                <div
                                  style={{ width: "50%", textAlign: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="popup_btn_unlinkaccount"
                                    onClick={deleteproject}
                                    data-toggle="modal"
                                    data-target=".partner-details-deleted-success"
                                    data-dismiss="modal"
                                  >
                                    Delete
                                  </button>
                                </div>
                                <div
                                  style={{ width: "50%", textAlign: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-secondary popup_btn_close"
                                    data-bs-dismiss="modal"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Are you ssure want to logout popup */}
                    <div
                      id="logout"
                      className="modal fade "
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog  modal-dialog-centered ">
                        <div
                          className="modal-content modal_content_whole"
                          style={{ background: "white" }}
                        >
                          <div className="modal-body">
                            <div>
                              <div className="popup_icon_center">
                                <InfoRoundedIcon className="popup_delete_proj_icon" />
                              </div>
                              <div className="popup_text_center">
                                <h4 className="popup_addbankdetails_text">
                                  Are you sure want to Logout.?
                                </h4>
                              </div>
                              <div className="pt-3 d-flex justify-content-between">
                                <div
                                  style={{ width: "48%", textAlign: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="popup_btn_unlinkaccount"
                                    onClick={Logout}
                                  >
                                    Yes
                                  </button>
                                </div>
                                <div
                                  style={{ width: "48%", textAlign: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-secondary popup_btn_close"
                                    data-bs-dismiss="modal"
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* popup to select project stage completed */}
                    {/* Filter Popup for reports */}
                    <div
                      className="modal fade"
                      id="filter"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div
                            className="modal-header"
                            style={{
                              borderBottom: "none",
                              paddingLeft: "30px",
                            }}
                          >
                            <h5 className="modal-title" id="exampleModalLabel">
                              Select Project Stage
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div
                            className="modal-body"
                            style={{
                              paddingTop: "0px",
                              paddingLeft: "30px",
                              paddingRight: "30px",
                            }}
                          >
                            <div>
                              <div className="project_details_checkbox">
                                <div id="my">
                                  {progresListings.map((val, i, arr) => (
                                    <div key={i}>
                                      <ProgressSTage
                                        projectlisting={projectlisting}
                                        arr={arr}
                                        checkedui={checkedui}
                                        checkeddata={val.checkedStatus}
                                        val={val.stage}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="">
                                  <div className="">
                                    <button
                                      className="filter_op_btns"
                                      onClick={() =>
                                        stageedit(
                                          latestFloor || indivisual.no_of_floors
                                        )
                                      }
                                    >
                                      Update Project Stage
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
