import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { TextField } from "../../../ReusableComponent/TextField";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import AddIcon from "@mui/icons-material/Add";
import {
  validateName,
  validateArchitect,
  validatestyleofhome,
  validatekitchentype,
  validateSBUA,
  validateBUA,
  validatepackagename,
  validatesiteenggname,
  validateLocation,
  validateConstructionStart,
  validateTeamLead,
  validatepromgr,
} from "../../../ReusableComponent/Validation";
import SelectField from "../../../ReusableComponent/SelectField";
import {
  selectAllListmanagers,
  selectAllTheSiteEng,
} from "../../../features/counter/counterSlice";
import { useSelector } from "react-redux";
import moment from "moment";

export default function AddProjectPopup({ projectlisting }) {
  const manager = useSelector(selectAllListmanagers);
  const siteengineer = useSelector(selectAllTheSiteEng);
  const [counter3, updateCounter3] = useState(1);
  const [counter4, updateCounter4] = useState(1);
  const [projectlist, Setprojectlist] = useState([]);
  const [nooffloors, setNoOfFloors] = useState("1");
  const [cities, setCities] = useState([]);
  const [cityName, setCityName] = useState("");
  const [cityPack, setCityPack] = useState([]);

  const getCities = () => {
    axiosClientInterceptors.get(`/get-city`).then((resp) => {
      setCities(resp.data.result);
    });
  };

  // 3rd Counter for no of Bedrooms
  function handleIncrement2() {
    updateCounter3(counter3 + 1);
  }

  function handleDecrement2(e) {
    e.preventDefault();
    updateCounter3(counter3 - 1 <= 0 ? 1 : counter3 - 1);
  }

  // 3rd Counter for no of bathrooms
  function handleIncrement3() {
    updateCounter4(counter4 + 1);
  }

  function handleDecrement3(e) {
    e.preventDefault();
    updateCounter4(counter4 - 1 <= 0 ? 1 : counter4 - 1);
  }

  // to get project listing based on Floor no
  const getFloorNo = (floor) => {
    axiosClientInterceptors
      .get(`/get-project-stages?floor=${floor}`)
      .then((reslt) => {
        Setprojectlist(reslt.data.result);
      });
  };

  useEffect(() => {
    axiosClientInterceptors
      .get(`/get-packages?city=${cityName}`)
      .then((result) => {
        setCityPack(result.data.result);
      });
  }, [cityName]);

  useEffect(() => {
    getFloorNo(1);
    getCities();
  }, []);

  function Btn({ children, onClick }) {
    return (
      <button
        style={{
          paddingRight: "10px",
          paddingLeft: "10px",
          background: "transparent",
          border: "none",
          fontSize: "25px",
          fontFamily: "gb",
          color: "#FCC200",
        }}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
  return (
    <div>
      <button
        className="AddProject"
        data-bs-toggle="modal"
        data-bs-target="#addproject"
      >
        <AddIcon className="add_icon" />
        Add Project
      </button>
      <div>
        <Formik
          initialValues={{
            project_name: "",
            project_stage: "",
            style_of_home: "",
            type_of_kitchen: "",
            sbua: "",
            bua: "",
            location: "",
            full_location: "",
            team_lead: "",
            project_manager: "",
            site_engineer: "",
            architect: "",
            construction_startdate: "",
            no_of_floors: "",
            no_of_bedrooms: "",
            no_of_bathrooms: "",
            package: "",
            city: "",
            lead_residence: "",
          }}
          onSubmit={(values, { resetForm }) => {
            const addproject = {
              project_name: values.project_name,
              project_id: values.project_id,
              project_stage: values.project_stage,
              location: values.location,
              sbua: values.sbua,
              bua: values.bua,
              style_of_home: values.style_of_home,
              type_of_kitchen: values.type_of_kitchen,
              construction_startdate: moment(
                values.construction_startdate,
                "YYYY-MM-DD"
              ).format("DD/MM/YYYY"),
              project_manager: values.project_manager,
              team_lead: values.team_lead,
              site_engineer: values.site_engineer,
              architect: values.architect,
              no_of_floors: nooffloors,
              no_of_bedrooms: counter3,
              no_of_bathrooms: counter4,
              site_full_address: values.site_full_address,
              package: values.package,
              project_health: values.project_health,
              city: cityName,
              lead_residence: values.lead_residence,
            };

            const headers = {
              "Content-Type": "application/json",
            };
            axiosClientInterceptors
              .post(`/addproject`, addproject, {
                headers,
              })
              .then((res) => {
                if (res.data.status === "success") {
                  document.getElementById("cls").click();
                  window.$("#project_added").modal("show");
                  projectlisting();
                  resetForm({ values: "" });
                  updateCounter4(1);
                  updateCounter3(1);
                  setCityName("");
                } else {
                  alert("Details are already Exists");
                }
              });
          }}
        >
          {(formik, values) => (
            <div>
              <Form>
                <div
                  className="modal fade"
                  id="addproject"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{ borderBottom: "none" }}
                      >
                        <h4
                          className="modal-title text-dark"
                          id="exampleModalLabel"
                        >
                          <b className="addpro_heading">Add Project</b>
                        </h4>
                        <button
                          type="button"
                          id="cls"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body pt-0">
                        <div className="form_invoice_upload">
                          <div className="row">
                            <div className="col-4">
                              <TextField
                                type="text"
                                name="project_name"
                                label="Project Name"
                                className="addproject_input"
                                validate={validateName}
                                placeholder="eg. maple creast"
                              />
                            </div>
                            <div className="col-4">
                              <label className="select_label">
                                Number Of Floors
                              </label>

                              <div className="d-flex align-items-center mt-2">
                                <p className="m-0 florG">G+</p>
                                <input
                                  // margin={true}
                                  onChange={(e) => {
                                    getFloorNo(e.target.value);
                                    setNoOfFloors(e.target.value);
                                  }}
                                  style={{
                                    outline: "none",
                                    background: " #F8F8F8",
                                  }}
                                  value={nooffloors}
                                  name="no_of_floors"
                                  placeholder="eg:1"
                                  type="tel"
                                  className="admin_project_details_input w-100"
                                />
                              </div>

                              <br />
                            </div>
                            <div className="col-4">
                              <label className="select_label mb-2">
                                Project Stage (Optional)
                              </label>
                              <br />
                              <SelectField
                                style={{ borderRadius: 0 }}
                                className="select_project_stage"
                                name="project_stage"
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                {projectlist.map((lo, index) => {
                                  return (
                                    <option key={index} value={lo.stage}>
                                      {lo.stage}
                                    </option>
                                  );
                                })}
                              </SelectField>
                            </div>
                            <div className="col-4">
                              <div
                                className="d-flex align-items-center"
                                style={{ position: "relative" }}
                              >
                                <TextField
                                  type="number"
                                  name="sbua"
                                  style={{ borderRadius: "0px" }}
                                  placeholder="eg. 2000.00"
                                  label="Super Built-up Area"
                                  className="addproject_input"
                                  validate={validateSBUA}
                                />
                                <p className="SQFT">
                                  <span>SQFT.</span>{" "}
                                </p>
                              </div>
                            </div>
                            <div className="col-4 ">
                              <div
                                className="d-flex align-items-center"
                                style={{ position: "relative" }}
                              >
                                <TextField
                                  type="number"
                                  name="bua"
                                  label="Built-Up Area"
                                  className="addproject_input"
                                  style={{ borderRadius: "0px" }}
                                  validate={validateBUA}
                                  placeholder="eg. 2000.00"
                                />

                                <p className="SQFT">
                                  <span>SQFT.</span>{" "}
                                </p>
                              </div>
                            </div>
                            <div className="col-4">
                              <TextField
                                type="text"
                                name="style_of_home"
                                label="Style of Home"
                                placeholder="eg. morden/ villa..."
                                className="addproject_input"
                                validate={validatestyleofhome}
                              />
                            </div>
                            <div className="col-4 ">
                              <TextField
                                type="date"
                                name="construction_startdate"
                                label="Construction Start Date"
                                // min={moment().format("YYYY-MM-DD")}
                                className="addproject_input"
                                validate={validateConstructionStart}
                              />
                            </div>
                            <div className="col-4 ">
                              <TextField
                                type="text"
                                name="type_of_kitchen"
                                label="Kitchen Type"
                                placeholder="eg. L-shaped.."
                                className="addproject_input"
                                validate={validatekitchentype}
                              />
                            </div>
                            <div className="col-4 ">
                              <label className="select_label mb-2">City</label>
                              <br />
                              <SelectField
                                style={{ borderRadius: 0 }}
                                className="select_project_stage"
                                value={cityName}
                                name="city"
                                onChange={(e) => {
                                  setCityName(e.target.value);
                                }}
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                {cities.map((val, i) => (
                                  <option key={i} value={val._id}>
                                    {val.city}
                                  </option>
                                ))}
                              </SelectField>
                            </div>
                            <div className="col-4 ">
                              <label className="select_label mb-2">
                                Package
                              </label>
                              <br />
                              <SelectField
                                style={{ borderRadius: 0 }}
                                className="select_project_stage"
                                validate={validatepackagename}
                                name="package"
                                disabled={cityName === ""}
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                {cityPack?.map((val, i) => (
                                  <option key={i} value={val.package_name}>
                                    {val.package_name}
                                  </option>
                                ))}
                              </SelectField>
                            </div>
                            <div className="col-4 ">
                              <label className="select_label mb-2 ">
                                Project Manager
                              </label>
                              <br />
                              <SelectField
                                style={{ borderRadius: 0 }}
                                className="select_project_stage"
                                validate={validatepromgr}
                                name="project_manager"
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                {manager?.map((pmname, index) => {
                                  return (
                                    <option key={index} value={pmname._id}>
                                      {pmname.name}
                                    </option>
                                  );
                                })}
                              </SelectField>
                            </div>
                            <div className="col-4 ">
                              <TextField
                                type="text"
                                name="team_lead"
                                label="Team Lead"
                                placeholder="eg. praveen/ ramesh"
                                className="addproject_input"
                                validate={validateTeamLead}
                              />
                            </div>
                            <div className="col-4">
                              <TextField
                                type="text"
                                name="architect"
                                placeholder="eg. vayshak"
                                label="Architect"
                                className="addproject_input"
                                validate={validateArchitect}
                              />
                            </div>

                            <div className="col-4 ">
                              <label className="select_label mb-2 ">
                                Site Engineer
                              </label>
                              <SelectField
                                style={{ borderRadius: 0 }}
                                className="select_project_stage"
                                validate={validatesiteenggname}
                                name="site_engineer"
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                {siteengineer?.map((sname, index) => {
                                  return (
                                    <option key={sname._id} value={sname._id}>
                                      {sname.name}
                                    </option>
                                  );
                                })}
                              </SelectField>
                            </div>
                            <div className="col-4 ">
                              <TextField
                                type="text"
                                name="lead_residence"
                                label="Lead Residence Location"
                                placeholder="eg. Jp nagar, BTM, HSR layout"
                                className="addproject_input"
                                validate={validateLocation}
                              />
                            </div>
                            <div className="col-8">
                              <TextField
                                type="text"
                                name="site_full_address"
                                placeholder="eg. site-1342, 17th main, jp nagar"
                                label="Site Full Address"
                                className="addproject_input w-100"
                              />
                            </div>
                            <div className="col-4 ">
                              <TextField
                                type="text"
                                name="location"
                                label="Site Address"
                                placeholder="eg. Jp nagar, BTM, HSR layout"
                                className="addproject_input"
                                validate={validateLocation}
                              />
                            </div>

                            <div className="col-8 mt-2">
                              <div className="row">
                                <div className="col-4">
                                  <label className="select_label">
                                    Number Of Bedrooms
                                  </label>
                                  <div>
                                    <div style={{ textAlign: "Center" }}>
                                      <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{
                                          background: "#F8F8F8",
                                          height: "40px",
                                        }}
                                      >
                                        <Btn
                                          onClick={(e) => {
                                            handleDecrement2(e);
                                          }}
                                        >
                                          -
                                        </Btn>
                                        <span
                                          style={{
                                            fontSize: "20px",
                                            padding: "10px",
                                            verticalAlign: "Middle",
                                          }}
                                        >
                                          {counter3}
                                        </span>
                                        <Btn onClick={handleIncrement2}>+</Btn>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                                <div className="col-4">
                                  <label className="select_label">
                                    Number Of Bathrooms
                                  </label>
                                  <div>
                                    <div style={{ textAlign: "Center" }}>
                                      <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{
                                          background: "#F8F8F8",
                                          height: "40px",
                                        }}
                                      >
                                        <Btn
                                          onClick={(e) => {
                                            handleDecrement3(e);
                                          }}
                                        >
                                          -
                                        </Btn>
                                        <span
                                          style={{
                                            fontSize: "20px",
                                            padding: "10px",
                                            verticalAlign: "Middle",
                                          }}
                                        >
                                          {counter4}
                                        </span>
                                        <Btn onClick={handleIncrement3}>+</Btn>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-2">
                            <button
                              className="add_project mt-3 text-center d-flex justify-content-center align-items-center"
                              type="submit"
                            >
                              <AddIcon className="add_icon" />
                              Add Project
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
        <div
          className="modal fade"
          id="project_added"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <div className="modal-body pt-0">
                <div className="form_invoice_upload1">
                  <div className="text-center">
                    <CheckCircleIcon className="tick_mark" />
                    <div className="mt-3">
                      <h4>Project Added Successfully</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="invoice_exists"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body pt-0">
                <div className="form_invoice_upload">
                  <div className="text-center">
                    <InfoRoundedIcon className="red_alert" />
                    <div className="mt-3">
                      <h3>Invoice Already Exists</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
