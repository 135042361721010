import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  selectcurrentpage,
  setcurentpage,
} from "../../../features/pagination/paginationSlice";
import Modal from "@mui/material/Modal";
import moment from "moment";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import { saveAs } from "file-saver";
import CloseIcon from "@mui/icons-material/Close";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import PaginationComponent from "../../../ReusableComponent/PaginationComponent";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../ReusableComponent/Loader";
import NoRecordFound from "../../../Assets/Img/NoRecordFound.svg";
import ProjectUploadDoc from "./ProjectUploadDoc";
import ViewDocuments from "./ViewDocuments";
import { setProjectScreen } from "../../../features/Screen/screen";
import { fileNameSplit } from "../../../ReusableComponent/Validation";

export default function AllDocuments({ singleViewId, setsingleView }) {
  const [counter, Setcounter] = useState(0);
  let history = useNavigate();
  const currentpage = useSelector(selectcurrentpage);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [search, setsearch] = useState("");
  const [allDocument, setallDocument] = useState([]);
  const [isLoading, setLoading] = useState(true);
  // pagination
  const [perPage, setPerPge] = useState(10);
  const [totlePage, setTotalPage] = useState("");
  const handleChangePage = (e, pageSize) => {
    dispatch(setcurentpage(e));
    setPerPge(pageSize);
  };
  useEffect(() => {
    if (localStorage.getItem("username") === null) {
      history("/");
    } else {
      getdata();
    }
  }, []);

  const getdata = () => {
    setLoading(true);
    axiosClientInterceptors
      .get(
        `/documentlist?_id=${singleViewId}&page=${currentpage}&limit=${perPage}&search=${search}`
      )
      .then((res) => {
        setallDocument(res.data.Documents.listofdocuments);
        dispatch(setcurentpage(res.data.Documents.currentPage));
        setTotalPage(res.data.Documents.totalprojects);
        setLoading(false);
      });
  };
  const getdata2 = () => {
    setLoading(true);
    axiosClientInterceptors
      .get(`/documentlist?_id=${singleViewId}&page=1&limit=${perPage}&search=`)
      .then((res) => {
        setallDocument(res.data.Documents.listofdocuments);
        dispatch(setcurentpage(res.data.Documents.currentPage));
        setTotalPage(res.data.Documents.totalprojects);
        setLoading(false);
      });
  };

  useEffect(() => {
    getdata();
  }, [currentpage, perPage]);

  // Multiple delete
  const deletemultipleprojects = async () => {
    let userids = [];
    await allDocument.forEach((d) => {
      if (d.select) {
        userids.push(d._id);
      }
    });
    axiosClientInterceptors
      .post(`/deletemultipleprojects`, {
        userids,
      })
      .then((resultdeleted) => {
        getdata2();
        Setcounter(0);
      })
      .catch((err) => alert(err));
  };

  return (
    <div>
      <div className="d-flex   justify-content-between align-items-center ">
        <p className="m-0">
          <span
            className="fontdocument_dim"
            onClick={() => {
              dispatch(setProjectScreen("allusers"));
              setsingleView("allusers");
            }}
          >
            All Projects{" "}
          </span>
          /{" "}
          <span
            className="fontdocument_dim"
            onClick={() => {
              setsingleView("singleuser");
              dispatch(setProjectScreen("singleuser"));
            }}
          >
            Project Details
          </span>
          / <span className="fontdocument_active">Project Documents</span>
        </p>
        <div
          style={{ width: "fit-content" }}
          className="modal-title d-flex align-items-center uploaddoc_btn "
          onClick={handleOpen}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.01881 4.55658V2.05094C7.01881 1.78452 7.23131 1.5625 7.50006 1.5625C7.74069 1.5625 7.94455 1.74906 7.97686 1.98536L7.98131 2.05094V4.55658L10.9688 4.55677C12.4563 4.55677 13.6783 5.77486 13.747 7.29401L13.75 7.42881V10.5784C13.75 12.1082 12.5704 13.3639 11.105 13.4344L10.975 13.4375H4.025C2.5375 13.4375 1.32131 12.2255 1.25302 10.7008L1.25 10.5655L1.25 7.42235C1.25 5.89256 2.42369 4.63076 3.88875 4.5599L4.01875 4.55677H7.01875V8.55826L6.01875 7.52562C5.83125 7.332 5.525 7.332 5.3375 7.52562C5.24375 7.62243 5.2 7.75151 5.2 7.88059C5.2 7.97869 5.228 8.08092 5.2872 8.16746L5.3375 8.2291L7.15625 10.1137C7.24375 10.2105 7.36875 10.2621 7.5 10.2621C7.60417 10.2621 7.70833 10.2263 7.7908 10.1583L7.8375 10.1137L9.65625 8.2291C9.84375 8.03548 9.84375 7.71924 9.65625 7.52562C9.4858 7.3496 9.2172 7.3336 9.0289 7.47761L8.975 7.52562L7.98125 8.55826V4.55677L7.01881 4.55658Z"
              fill="#000"
            />
          </svg>
          <p className="m-0 fontdocument w-100 ms-1">Upload Doc</p>
        </div>

        <Modal
          open={open}
          style={{ background: "rgba(34, 30, 31, 0.9)" }}
          className="d-flex justify-content-center align-items-center flex-column"
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="filter_container w-50 mb-0 ">
            <ProjectUploadDoc
              getdata2={getdata2}
              singleViewId={singleViewId}
              handleClose={handleClose}
            />
          </div>
        </Modal>
      </div>
      <hr />
      <div>
        {counter <= 0 && (
          <div className="d-flex mt-3">
            <div className="searchrequst d-flex me-2 ">
              <input
                type="text"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
                className="w-100 inputsearch"
                placeholder="Search by document name"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    getdata();
                  }
                }}
              />
              {search.length > 0 && (
                <CloseIcon
                  className="mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    getdata2();
                    setsearch("");
                  }}
                />
              )}
              <button
                className="serchbuttonrequest"
                disabled={search.length === 0}
                onClick={() => getdata()}
              >
                <SearchRoundedIcon fontSize="small" />
                <span>Search</span>
              </button>
            </div>
          </div>
        )}
        {counter > 0 && (
          <div className="d-flex justify-content-end">
            <div className="d-flex crud_button">
              <button
                className="admin_pro_delete mt-3"
                data-bs-toggle="modal"
                data-bs-target="#deletepro"
              >
                <DeleteIcon className="delete_icon_admin" />
                Delete
              </button>
            </div>
          </div>
        )}
        <div className="parent-request">
          <div className="inner_body">
            <div className="">
              <div className="">
                <div className="">
                  <div className="req_right_div p-0">
                    <div
                      className="d-flex justify-content-between"
                      width="100%"
                    ></div>
                    {isLoading ? (
                      <div
                        className="App d-flex justify-content-center align-items-center"
                        style={{
                          width: "100%",
                          textAlign: "center",
                          height: "100vh",
                          background: "white",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <>
                        {allDocument?.length === 0 ? (
                          <div className=" mt-3 NoRecordFound">
                            <img src={NoRecordFound} />
                          </div>
                        ) : (
                          <div
                            className="mt-3"
                            style={{
                              background: "#F8F8F8",
                            }}
                          >
                            <div className="inner_table_div pt-0">
                              <table className="table leaderboard_table">
                                <tbody>
                                  <tr class="headers_tag">
                                    <th className="text-center">Sl. No.</th>
                                    <th className="text-center">
                                      Document Name
                                    </th>
                                    <th className="text-center">
                                      Date & Time Of Upload
                                    </th>

                                    <th className="text-center">Action</th>
                                  </tr>

                                  <>
                                    {allDocument?.map((val, index) => (
                                      <tr
                                        className="vals"
                                        style={{ backgroundColor: "#F5F5F5" }}
                                        key={index}
                                      >
                                        <td className="text-center">
                                          {index + 1}
                                        </td>
                                        <td className="text-center">
                                          {fileNameSplit(val.document_name)}
                                        </td>
                                        <td className="text-center">
                                          {moment(val.document_date).format(
                                            "DD/MM/YYYY, h:mma"
                                          )}
                                        </td>

                                        <td className="text-center d-flex align-items-center justify-content-center">
                                          <ViewDocuments
                                            saveAs={saveAs}
                                            downlod={val.document_name}
                                            title="Document Details"
                                            invoice={
                                              val.document_name &&
                                              val.document_name
                                                .split(".")
                                                .reverse()[0] === "pdf"
                                                ? `${process.env.REACT_APP_IMG_URL}/${val.document_name}`
                                                : `${process.env.REACT_APP_BASE_URL}/view-file?file=${val.document_name}`
                                            }
                                            file={val.document_name}
                                          />
                                          <div
                                            className="d-flex align-items-center download_invoice ms-1"
                                            onClick={() => {
                                              saveAs(
                                                `${process.env.REACT_APP_IMG_URL}/${val.document_name}`,
                                                `${fileNameSplit(
                                                  val.document_name
                                                )}`
                                              );
                                            }}
                                          >
                                            <p className="m-0 fontdocument  me-1">
                                              Download
                                            </p>
                                            <svg
                                              width="15"
                                              height="15"
                                              viewBox="0 0 15 15"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M7.01881 4.55658V2.05094C7.01881 1.78452 7.23131 1.5625 7.50006 1.5625C7.74069 1.5625 7.94455 1.74906 7.97686 1.98536L7.98131 2.05094V4.55658L10.9688 4.55677C12.4563 4.55677 13.6783 5.77486 13.747 7.29401L13.75 7.42881V10.5784C13.75 12.1082 12.5704 13.3639 11.105 13.4344L10.975 13.4375H4.025C2.5375 13.4375 1.32131 12.2255 1.25302 10.7008L1.25 10.5655L1.25 7.42235C1.25 5.89256 2.42369 4.63076 3.88875 4.5599L4.01875 4.55677H7.01875V8.55826L6.01875 7.52562C5.83125 7.332 5.525 7.332 5.3375 7.52562C5.24375 7.62243 5.2 7.75151 5.2 7.88059C5.2 7.97869 5.228 8.08092 5.2872 8.16746L5.3375 8.2291L7.15625 10.1137C7.24375 10.2105 7.36875 10.2621 7.5 10.2621C7.60417 10.2621 7.70833 10.2263 7.7908 10.1583L7.8375 10.1137L9.65625 8.2291C9.84375 8.03548 9.84375 7.71924 9.65625 7.52562C9.4858 7.3496 9.2172 7.3336 9.0289 7.47761L8.975 7.52562L7.98125 8.55826V4.55677L7.01881 4.55658Z"
                                                fill="#FCC200"
                                              />
                                            </svg>{" "}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                </tbody>
                              </table>
                              <div className="w-100 d-flex justify-content-end">
                                <div className="flotpagination d-flex align-items-end justify-content-between">
                                  <PaginationComponent
                                    handleChangePage={handleChangePage}
                                    perPage={perPage}
                                    totlePage={totlePage}
                                    currentpage={currentpage}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="deletepro"
        className="modal fade "
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered ">
          <div
            className="modal-content modal_content_whole w-75"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div>
                <div className="popup_icon_center">
                  <InfoRoundedIcon className="popup_delete_proj_icon" />
                </div>
                <div className="popup_text_center">
                  <span className="popup_addbankdetails_text">
                    <b> Are you sure want to Delete this Project ?</b>
                  </span>
                </div>
                <div className="pt-3 d-flex">
                  <div
                    style={{
                      width: "50%",
                      textAlign: "center",
                    }}
                  >
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      onClick={() => {
                        deletemultipleprojects();
                      }}
                      data-bs-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "center",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary popup_btn_close"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
