import React from "react";
import SideBarProcDept from "../../ReusableComponent/SideBarProcDept";
import { selectactivetab } from "../../features/counter/counterSlice";
import { useSelector } from "react-redux";
import Requests from "../Admin/AdminRequests/Requests";
import AllProjectsAdmin from "../Admin/AllProject/AllProjectsAdmin";
import AdminMaterialCatlog from "../Admin/MaterialCatalog/AdminMaterialCatlog";
import PoAdmin from "../Admin/PoAdmin/PoAdmin";
import Logout from "../Login/Logout";
import Configurations from "../Admin/Configurations/Configurations";
import DashboardAdmin from "../Admin/DashboardAdmin/DashboardAdmin";
import Notification from "../Admin/Notification/Notification";
import { useState } from "react";
import Vendor from "../Admin/Vendor/Vendor";

const PdDash = ({ socket, SocketCall }) => {
  const activetabsection = useSelector(selectactivetab);
  const [requestdata, setRequestData] = useState("listing");
  return (
    <div className="d-flex">
      <div className="sidlebarcontainer">
        <SideBarProcDept
        //  socket={socket} setRequestData={setRequestData}
        />
      </div>
      {activetabsection === "Dashboard" && (
        <div className="dashboardtabsection">
          <DashboardAdmin />
        </div>
      )}
      {activetabsection === "Requests" && (
        <div className="dashboardtabsection">
          <Requests
            // socket={socket}
            // SocketCall={SocketCall}
            setRequestData={setRequestData}
            requestdata={requestdata}
          />
        </div>
      )}
      {activetabsection === "Notifications" && (
        <div className="dashboardtabsection">
          <Notification setRequestData={setRequestData} />
        </div>
      )}
      {activetabsection === "All Projects" && (
        <div className="dashboardtabsection">
          <AllProjectsAdmin />
        </div>
      )}
      {activetabsection === "Material Catalogue" && (
        <div className="dashboardtabsection">
          <AdminMaterialCatlog />
        </div>
      )}
      {activetabsection === "All Vendors" && (
        <div className="dashboardtabsection">
          <Vendor />
        </div>
      )}
      {activetabsection === "Purchase Orders" && (
        <div className="dashboardtabsection">
          <PoAdmin />
        </div>
      )}
      {activetabsection === "Configurations" && (
        <div className="dashboardtabsection">
          <Configurations />
        </div>
      )}
      {activetabsection === "Logout" && (
        <div className="dashboardtabsection">
          <Logout />
        </div>
      )}
    </div>
  );
};

export default PdDash;
