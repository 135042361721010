import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectactivetab,
  setPOswitch,
  setRFQID,
} from "../../../../features/counter/counterSlice";
import {
  setProjectScreen,
  setscreen,
} from "../../../../features/Screen/screen";
import ArticleIcon from "@mui/icons-material/Article";
import axiosClientInterceptors from "../../../../ReusableComponent/axiosClentInterceptors";
import { ToWords } from "to-words";

const IndividualVendor = ({
  valuesingle,
  setVendorId,
  setindividualPOcreateID,
}) => {
  const activetabsection = useSelector(selectactivetab);
  const toWords = new ToWords();
  const [RFQ, setRFQ] = useState("");
  const [vendorPyterms, setvendorPyterms] = useState("");
  const [materials, setmaterials] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    axiosClientInterceptors
      .get(`/get-individual-rfq?_id=${valuesingle}`)
      .then((response) => {
        setVendorId(response.data.RFQ[0].vendor_id);
        setmaterials(response.data.RFQ[0].materials);
        setRFQ(response.data.RFQ[0]);
        setvendorPyterms(response.data.vendorPaymentTerms);
        setindividualPOcreateID(response.data.RFQ[0]._id);
        dispatch(setRFQID(response.data.RFQ[0]._id));
      });
  }, []);
  return (
    <div className="w-100 p-3">
      {activetabsection === "All Projects" ? (
        <>
          <p className="m-0">
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setProjectScreen("allusers"));
                dispatch(setscreen("listing"));
                dispatch(setPOswitch(""));
              }}
            >
              All Projects
            </span>{" "}
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setProjectScreen("singleuser"));
                dispatch(setscreen("listing"));
                dispatch(setPOswitch(""));
              }}
            >
              Project Details{" "}
            </span>
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setscreen("listing"));
                dispatch(setPOswitch(""));
              }}
            >
              All Requests
            </span>{" "}
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setscreen("singledata"));
                dispatch(setPOswitch(""));
              }}
            >
              Requests Details{" "}
            </span>
            /{" "}
            <span
              className="fontdocument_dim"
              onClick={() => {
                dispatch(setscreen("singledata"));
                dispatch(setPOswitch("listing of vendors verifyed"));
              }}
            >
              RFQ
            </span>
            /<span className="fontdocument_active">Vendor details</span>
          </p>
        </>
      ) : (
        <p className="m-0">
          <span
            className="fontdocument_dim"
            onClick={() => {
              dispatch(setscreen("listing"));
              dispatch(setPOswitch(""));
            }}
          >
            All Requests
          </span>{" "}
          /{" "}
          <span
            className="fontdocument_dim"
            onClick={() => {
              dispatch(setscreen("singledata"));
              dispatch(setPOswitch(""));
            }}
          >
            Requests Details{" "}
          </span>
          /
          <span
            className="fontdocument_dim"
            onClick={() => {
              dispatch(setscreen("singledata"));
              dispatch(setPOswitch("listing of vendors verifyed"));
            }}
          >
            RFQ
          </span>
          / <span className="fontdocument_active">Vendor Details</span>
        </p>
      )}
      <hr />
      <div
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
          height: "calc(100vh - 94px)",
        }}
      >
        <div className="PersonalInfo">
          <div className="d-flex justify-content-between align-items-center">
            {" "}
            <p className="PersonalInfo_title">Personal Info</p>
          </div>
          <hr />
          <div class="parentPersonalInfo">
            <div class="parentPersonalInfo1">
              <p className="parentPersonalInfo1_text">Vendor ID</p>
              <p className="parentPersonalInfo1_subtext">{RFQ?.vendorId}</p>
            </div>

            <div class="parentPersonalInfo2">
              {" "}
              <p className="parentPersonalInfo1_text">Vendor Name</p>
              <p className="parentPersonalInfo1_subtext"> {RFQ?.vendor_name}</p>
            </div>

            <div class="parentPersonalInfo3">
              {" "}
              <p className="parentPersonalInfo1_text">Company Name</p>
              <p className="parentPersonalInfo1_subtext">
                {" "}
                {RFQ?.company_name}
              </p>
            </div>
            <div class="parentPersonalInfo4">
              {" "}
              <p className="parentPersonalInfo1_text">Mobile Number</p>
              <p className="parentPersonalInfo1_subtext"> +91{RFQ?.phone}</p>
            </div>
            <div class="parentPersonalInfo5">
              {" "}
              <p className="parentPersonalInfo1_text">
                Alternative Mobile Number
              </p>
              <p className="parentPersonalInfo1_subtext">
                +91{RFQ?.alternative_contact_no}
              </p>
            </div>
          </div>
          {/*  */}
          <div class="parentPersonalInfosecond mt-4">
            <div class="parentPersonalInfosecond1">
              {" "}
              <p className="parentPersonalInfo1_text">Email</p>
              <p className="parentPersonalInfo1_subtext">{RFQ?.email}</p>
            </div>
            <div class="parentPersonalInfosecond2">
              {" "}
              <p className="parentPersonalInfo1_text">GST</p>
              <p className="parentPersonalInfo1_subtext">{RFQ?.gst}</p>
            </div>
            <div class="parentPersonalInfosecond3">
              {" "}
              <p className="parentPersonalInfo1_text">Address</p>
              <p className="parentPersonalInfo1_subtext">{RFQ?.address}</p>
            </div>
            <div class="parentPersonalInfosecond4"> </div>
          </div>
        </div>
        <div className="PersonalInfo mt-4">
          <div className="d-flex justify-content-between align-items-center">
            {" "}
            <p className="PersonalInfo_title">Payment Term</p>
          </div>
          <hr />

          {vendorPyterms?.payment_details?.length === 0 ? (
            <div class="PaymentTerm mt-4">
              <div class="PaymentTerm1">
                {" "}
                <p className="parentPersonalInfo1_text">Payment Type</p>
                <p className="parentPersonalInfo1_subtext">
                  {vendorPyterms?.payment_terms}
                </p>
              </div>
              <div class="PaymentTerm2">
                {" "}
                <p className="parentPersonalInfo1_text">Description</p>
                <p className="parentPersonalInfo1_subtext">
                  {vendorPyterms?.advance_persentage === undefined
                    ? `${vendorPyterms?.single_payment_day}  days from the date of  invoice `
                    : `${vendorPyterms?.advance_persentage}%  should be paid before date of invoice`}
                </p>
              </div>
            </div>
          ) : (
            <>
              {vendorPyterms?.payment_terms !== "Installment" && (
                <div class="row mt-4">
                  <div class="col-2">
                    {" "}
                    <p className="parentPersonalInfo1_text">Payment Type</p>
                    <p className="parentPersonalInfo1_subtext">
                      {vendorPyterms?.payment_terms}
                    </p>
                  </div>
                  <div class="col-md-3">
                    {" "}
                    <p className="parentPersonalInfo1_text">Percentage</p>
                    <p className="parentPersonalInfo1_subtext">
                      {vendorPyterms?.advance_persentage === undefined
                        ? `${vendorPyterms?.single_payment_day}  days from the date of  invoice `
                        : `${vendorPyterms?.advance_persentage} % should be paid before date of invoice`}
                    </p>
                  </div>
                </div>
              )}
              <>
                {vendorPyterms?.payment_details?.map((val, i) => (
                  <div class="row mt-4" key={i}>
                    <div class="col-md-2">
                      {" "}
                      <p className="parentPersonalInfo1_text">Payment Type</p>
                      <p className="parentPersonalInfo1_subtext">installment</p>
                    </div>
                    <div class="col-md-2">
                      {" "}
                      <p className="parentPersonalInfo1_text">Percentage</p>
                      <p className="parentPersonalInfo1_subtext">
                        {val?.persentage}%
                      </p>
                    </div>
                    <div class="col-md-2">
                      {" "}
                      <p className="parentPersonalInfo1_text">No. of days</p>
                      <p className="parentPersonalInfo1_subtext">
                        {val?.no_of_days} days
                      </p>
                    </div>
                    <div class="col-md-6">
                      {" "}
                      <p className="parentPersonalInfo1_text">Description</p>
                      <p className="parentPersonalInfo1_subtext">
                        {val.description}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            </>
          )}
        </div>
        <table className="table mt-4 mb-0">
          <tr>
            <th className="th">SL. No.</th>
            <th className="th">Material Description</th>
            <th className="th">HSN/SAC</th>
            <th className="th">UOM</th>
            <th className="th">QTY</th>
            <th className="th">Unit Price</th>
            <th className="th">SGST</th>
            <th className="th">CGST</th>
            <th className="th">Amount</th>
          </tr>
          {materials?.map((val, i) => (
            <tr key={val._id}>
              <td className="td">{i + 1}</td>
              <td className="td">{val.material_name}</td>
              <td className="td">{val.hsn_sac}</td>
              <td className="td">{val.uom}</td>
              <td className="td">{val.quantity}</td>
              <td className="td">{val.rate}</td>
              <td className="td">{val.sgst}%</td>
              <td className="td">{val.cgst}%</td>
              <td className="td">{val.amount?.toFixed(2)}/-</td>
            </tr>
          ))}
        </table>
        <div className=" " style={{ border: "1px solid #dddddd" }}>
          <div class="parenttable">
            <div class="parenttable1">
              {" "}
              <p className="Delivery">
                <span className="dark" onClick={() => console.log(RFQ)}>
                  Expected Delivery Date :
                </span>{" "}
                {RFQ?.expected_delivery_date
                  ? RFQ?.expected_delivery_date
                  : "-"}{" "}
              </p>
            </div>
            <div class="parenttable2">
              <div className="row">
                <div className="col-md-7 ">
                  <p className="text-end SubTotaltext ">Sub Total</p>
                </div>
                <div className="col-md-5">
                  <p className="text-center SubTotaltext">
                    {RFQ.sub_total?.toFixed(2)}/-
                  </p>
                </div>

                <div className="col-md-7 ">
                  <p className="text-end SubTotaltext ">
                    Transportation Charges
                  </p>
                </div>
                <div className="col-md-5">
                  <p className="text-center SubTotaltext">
                    {RFQ.transportation_charges === 0
                      ? "Included"
                      : `${RFQ.transportation_charges?.toFixed(2)}/-`}
                  </p>
                </div>
                <div className="col-md-7 ">
                  <p className="text-end SubTotaltext ">
                    Loading & Unloading Charges
                  </p>
                </div>
                <div className="col-md-5">
                  <p className="text-center SubTotaltext">
                    {" "}
                    {RFQ.load_unload_charges === 0
                      ? "Included"
                      : `${Number(RFQ.load_unload_charges)?.toFixed(2)}/-`}
                  </p>
                </div>
                <div className="col-md-7 ">
                  <p className="text-end SubTotaltext ">Discount</p>
                </div>
                <div className="col-md-5">
                  <p className="text-center SubTotaltext">
                    {RFQ.discount || 0}/-
                  </p>
                </div>
                <div className="col-md-7 ">
                  <p className="text-end SubTotaltext ">
                    <span className="dark">Grand Total</span>{" "}
                  </p>
                </div>
                <div className="col-md-5">
                  <p className="text-center SubTotaltext">
                    {" "}
                    <span className="dark">{RFQ.total?.toFixed(0)}/-</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p
            className="SubTotaltext py-2"
            style={{ border: "1px solid #dddddd" }}
          >
            <span className="dark">Total In Words :</span>{" "}
            {/* {toWords.convert(Number(RFQ?.total?.toFixed(2) || 0))} Rupees Only */}
            {toWords.convert(Number(RFQ?.total?.toFixed(2) || 0), {
              currency: true,
            })}{" "}
          </p>
        </div>
        <button
          style={{ width: "fit-content" }}
          disabled={localStorage.getItem("role") === "Project Manager"}
          className=" d-flex align-items-center me-3 purchase_order_btn mt-3"
          onClick={() => {
            dispatch(setPOswitch("Request detail  from request"));
          }}
        >
          <ArticleIcon
            className="side_nav_icons"
            style={{
              color: "#000",
              marginRight: "5px",
            }}
          />
          <p className="mb-0 pl-3">Create Purchase Order </p>
          {/* </Link> */}
        </button>
      </div>
    </div>
  );
};

export default IndividualVendor;
