import React, { useEffect, useState } from "react";
import Search from "../../../Assets/Img/svg/search.svg";
import axiosClientInterceptors from "../../../ReusableComponent/axiosClentInterceptors";
import AddUserGroups from "./AddUserGroups";
import { CgArrowRightR } from "react-icons/cg";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "../../../Assets/Img/svg/Delete.svg";
import Edit from "../../../Assets/Img/svg/Edit.svg";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { toast } from "react-toastify";
import moment from "moment";
export default function UserGroups() {
  const [searchTerm, setSearchTerm] = useState("");
  const [creditTermsValue, setCreditTermsValue] = useState([]);
  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [opendel, setOpendel] = useState(false);
  const handleOpendel = () => setOpendel(true);
  const handleClosedel = () => setOpendel(false);
  const [toggleEdit, settoggleEdit] = useState(false);
  const [termsValue, setTermsValue] = useState("");
  const [povalue, setPoValue] = useState([]);

  const getAllUserGroups = () => {
    const headers = {
      "content-type": "multipart/form-data",
    };

    axiosClientInterceptors
      .get(`/get-all-user-group`, {
        headers,
      })
      .then((response) => {
        setCreditTermsValue(response.data.result);
      })
      .catch((error) => console.log("Error", error));
  };

  const edituom = () => {
    let data = {
      user_group: termsValue,
    };

    axiosClientInterceptors
      .post(`/update-user-group?_id=${povalue._id}`, data)
      .then((response) => {
        if (response.data.status === "success") {
          getAllUserGroups();
          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) =>
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
  };

  const deleteTerms = () => {
    axiosClientInterceptors
      .delete(`/delete-user-group?_id=${povalue._id}`)
      .then((response) => {
        if (response.data.status === "success") {
          handleClosedel();
          getAllUserGroups();

          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) =>
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
  };
  useEffect(() => {
    getAllUserGroups();
  }, []);

  return (
    <div>
      <div className="MatrialCatalogTab_con p-3">
        <div className="d-flex justify-content-between">
          <div className="details">
            <p className="MaterialSpecification mb-0">User Groups</p>
            <span>{creditTermsValue.length} Groups</span>
          </div>
          <div>
            <AddUserGroups getAllUserGroups={getAllUserGroups} />
          </div>
        </div>
        <hr />
        <div>
          <div className="d-flex mt-3">
            <div className="searchrequst d-flex">
              <input
                type="text"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                style={{ background: "#FFFFFF" }}
                className="w-100 inputsearch"
                placeholder="Search by User Groups"
              />
            </div>
          </div>
          <div style={{ overflow: "scroll", height: "59vh" }}>
            <table className="table table-bordered table-responsive-sm table_material_catalog mt-3">
              <thead>
                <tr>
                  <th>Sl.No.</th>
                  <th>User Group</th>
                  <th>Last Activity Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {creditTermsValue
                  ? creditTermsValue
                      .filter((val) => {
                        if (searchTerm === "") {
                          return val;
                        } else if (
                          val.user_group
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return val;
                        }
                      })
                      .map((po, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleOpen3();
                              setTermsValue(po.user_group);
                              setPoValue(po);
                            }}
                          >
                            <td>{index + 1}.</td>
                            <td className="text-center">{po.user_group}</td>
                            <td className="text-center">
                              {moment(po.updatedAt).format("DD/MM/YYYY, h:mma")}
                            </td>
                            <td>
                              <>
                                <div>
                                  {" "}
                                  <CgArrowRightR
                                    className="partner_back_btn"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <div></div>
                                </div>
                              </>
                            </td>
                          </tr>
                        );
                      })
                  : "No Data"}
              </tbody>
            </table>
          </div>
          <Modal
            open={open3}
            style={{
              background: "rgba(34, 30, 31, 0.9)",
            }}
            className="d-flex justify-content-center align-items-center flex-column "
            onClose={handleClose3}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="filter_container ">
              <div className="m-3">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 Filter_text">User Group Details </p>
                  <CloseIcon
                    onClick={() => {
                      handleClose3();
                      settoggleEdit(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <div className="w-100 ">
                      <p className=" Package_title">User Group Name</p>
                      {!toggleEdit ? (
                        <div
                          className="w-100 p-2 InvoiceNumber_input"
                          style={{
                            background: "#F6F6F6",
                          }}
                        >
                          {povalue.user_group}
                        </div>
                      ) : (
                        <input
                          type="text"
                          defaultValue={povalue.user_group}
                          onChange={(e) => setTermsValue(e.target.value)}
                          name="invoice_number"
                          className="w-100 InvoiceNumber_input"
                          placeholder="eg: Enter User Group"
                        />
                      )}{" "}
                    </div>
                  </div>
                </div>

                {toggleEdit ? (
                  <button
                    className="w-100 configEditDetails mt-4"
                    disabled={termsValue.length === 0}
                    onClick={() => {
                      //   getinvoice();
                      settoggleEdit(false);
                      handleClose3();
                      edituom();
                    }}
                  >
                    <p className="m-0">Save Changes</p>
                  </button>
                ) : (
                  <div
                    className="d-flex justify-content-between align-items-center mt-4"
                    style={{ gap: "20px" }}
                  >
                    <button
                      className="w-50 configDelete"
                      onClick={() => {
                        handleClose3();
                        handleOpendel();
                      }}
                    >
                      <img
                        src={Delete}
                        alt="..."
                        className="me-1"
                        style={{ width: "7%" }}
                      />
                      <p className="m-0">Delete</p>
                    </button>
                    <div
                      className="w-50 configEditDetails "
                      onClick={() => {
                        settoggleEdit(true);
                      }}
                    >
                      <img
                        src={Edit}
                        alt="..."
                        style={{ width: "9%" }}
                        className="me-1"
                      />

                      <p className="m-0">Edit Details</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
          <Modal
            open={opendel}
            style={{
              background: "rgba(34, 30, 31, 0.9)",
            }}
            className="d-flex justify-content-center align-items-center flex-column "
            onClose={handleClosedel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="filter_container_config p-3">
              <div>
                <div className="popup_icon_center">
                  <InfoRoundedIcon className="popup_delete_proj_icon" />
                </div>
                <div className="popup_text_center">
                  <span className="popup_addbankdetails_text">
                    <b> Are you sure want to Delete this User Group ?</b>
                  </span>
                </div>
                <div className="pt-3 d-flex justify-content-between">
                  <div
                    style={{
                      width: "48%",
                      textAlign: "center",
                    }}
                  >
                    <button
                      type="button"
                      className="popup_btn_unlinkaccount"
                      onClick={() => {
                        deleteTerms();
                      }}
                      data-bs-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                  <div
                    style={{
                      width: "48%",
                      textAlign: "center",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary popup_btn_close"
                      onClick={handleClosedel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      {/* <p class="version" style={{ position: "absolute", bottom: "0" }}>
        Version : 0.2.3
      </p> */}
    </div>
  );
}
